import { CURRENCY_TO_SYMBOL_MAP } from "../../basics/TextType/TextType";
import { createSvgBackgroundStyles } from "../../utilities/svgBackgrounds";
import { format } from "date-fns";
import TextInput from "../../basics/TextInput/TextInput";

export interface InvoiceBuilderViewerProps {
  invoiceData: any;
  className?: string;
  onChange?: any;
}

export function InvoiceBuilderViewer({
  invoiceData,
  onChange,
  className,
}: InvoiceBuilderViewerProps) {
  const renderInvoice = (invoice: any) => {
    return (
      <div
        style={{ flex: 1, ...createSvgBackgroundStyles() }}
        className={`bg-gray-200 flex flex-col px-6 py-6 ${className}`}
      >
        <div className="px-6 py-3 flex flex-row">
          <div className="text-3xl font-bold text-gray-600">INVOICE</div>
          <div className="flex-1"></div>
          <div className="text-right">
            <div className="flex flex-row px-6 justify-end">
              <div>INVOICE NO: #</div>
              <div>{invoice.identifier || "-"}</div>
            </div>
            <div className="flex flex-row px-6 justify-end">
              <div>INVOICE DATE: </div>
              <div>
                {invoice.invoiceDate
                  ? format(new Date(invoice.invoiceDate), "MM/dd/yyyy")
                  : "-"}
              </div>
            </div>
            <div className="flex flex-row px-6 mb-6 justify-end">
              <div>DUE DATE: </div>
              <div>
                {invoice.dueOn
                  ? format(new Date(invoice.dueOn), "MM/dd/yyyy")
                  : "-"}
              </div>
            </div>
          </div>
        </div>

        <table className="ml-6 mr-10 divide-y divide-gray-200">
          <thead>
            <tr>
              <th
                scope="col"
                className="py-2 text-left text-xs font-bold text-gray-700 font-medium uppercase"
              >
                Service Item
              </th>
              <th
                scope="col"
                className="py-2 text-left text-xs font-bold text-gray-700 font-medium uppercase"
              >
                Description
              </th>
              <th
                scope="col"
                className="py-2 text-left text-xs font-bold text-gray-700 font-medium uppercase"
              >
                Quantity
              </th>
              <th
                scope="col"
                className="py-2 text-left text-xs font-bold text-gray-700 font-medium uppercase"
              >
                Rate
              </th>
              <th
                scope="col"
                className="py-2 text-right text-xs font-bold text-gray-700 font-medium uppercase"
              >
                Amount
              </th>
            </tr>
          </thead>
          <tbody>
            {invoice?.lineItems?.map((l: any, index: number) => {
              return (
                <tr className="text-left border-t border-gray-300">
                  <td className="py-3 text-xs">{l.serviceItem || "--"}</td>
                  <td className="py-3 text-xs pr-2">{l.description || "--"}</td>
                  <td className="py-3 cursor-pointer">
                    <input
                      value={Number(l.quantity?.toLocaleString())}
                      type="number"
                      onChange={(e) => {
                        onChange({
                          ...invoice,
                          lineItems: invoice.lineItems.map(
                            (li: any, ind2: number) => {
                              if (index === ind2) {
                                return {
                                  ...li,
                                  quantity: Number(e.target.value),
                                  amount:
                                    Number(li.rate) * Number(e.target.value),
                                };
                              } else {
                                return li;
                              }
                            }
                          ),
                        });
                      }}
                      className="hover:underline w-36 focus:underline bg-transparent border-none outline-none px-0 py-1 border-transparent focus:border-transparent focus:ring-0"
                    />
                  </td>
                  <td className="py-3 cursor-pointer">
                    {CURRENCY_TO_SYMBOL_MAP[invoice.currency]}
                    <input
                      value={Number(l.rate?.toLocaleString())}
                      type="number"
                      className="hover:underline w-36 focus:underline bg-transparent border-none outline-none px-0 py-1 border-transparent focus:border-transparent focus:ring-0"
                    />
                  </td>
                  <td className="py-3 text-right">
                    {CURRENCY_TO_SYMBOL_MAP[invoice.currency]}
                    {l.amount?.toLocaleString()}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div>
          <div className="text-xl uppercase mt-10 text-gray-700 px-10 text-right font-medium">
            Subotal: {CURRENCY_TO_SYMBOL_MAP[invoice.currency]}
            {invoice.subtotal?.toLocaleString()}
          </div>
          <div className="text-2xl uppercase mt-3 text-gray-700 px-10 text-right font-medium">
            Total: {CURRENCY_TO_SYMBOL_MAP[invoice.currency]}
            {invoice.totalDue?.toLocaleString()}
          </div>
        </div>
      </div>
    );
  };

  return <>{renderInvoice(invoiceData)}</>;
}
