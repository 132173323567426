import {
  ArrowDownCircleIcon,
  ArrowsPointingOutIcon,
  ArrowUpCircleIcon,
} from "@heroicons/react/24/solid";
import { ArrowsPointingInIcon } from "@heroicons/react/24/outline";
import {
  PieChart,
  Pie,
  Sector,
  Cell,
  ResponsiveContainer,
  PieLabel,
  AreaChart,
  XAxis,
  YAxis,
  Tooltip,
  Area,
} from "recharts";
import { Props } from "../../data/types/Props";
import GraphCard from "../GraphCard/GraphCard";

interface ProjectsOverviewCardProps extends Props {
  data: any;
  title: string;
  tooltip?: string;
  customLabel?: PieLabel;
  classNames?: string;
}

const d = [
  {
    name: "Jan",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Feb",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "Mar",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "Apr",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "May",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "Jun",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "Jul",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

export function ProjectsOverviewCard({
  data,
  tooltip,
  title,
  classNames,
  customLabel,
}: ProjectsOverviewCardProps) {
  let allResources: any = [];
  data?.projects?.map((p: any) => {
    p?.billableResources?.map((b: any) => {
      if (allResources.includes(b.id)) {
        //
      } else {
        allResources.push(b.id);
      }
    });
  });
  return (
    <GraphCard classNames={classNames} tooltip={tooltip} title={title}>
      <div className="flex flex-col h-full w-full">
        <div className="flex flex-1 flex-row border-b border-t">
          <div className="flex flex-1 hover:bg-red-100 bg-red-50 align-center flex-col border-r">
            <div className="pt-5 px-5 text-sm uppercase text-gray-600 font-bold mb-1 flex flex-row items-center">
              <div>Proj. Gross Profit, 30 Days</div>
              <div className="flex flex-1"></div>
              <div className="cursor-pointer text-gray-500 hover:text-black p-1 hover:shadow hover:bg-gray-100 border border-transparent hover:border-black rounded">
                <ArrowsPointingOutIcon height={19} />
              </div>
            </div>
            <div className="pt-1 pl-5 text-xl flex flex-row items-center font-bold text-red-500">
              ${data?.total?.grossProfit?.toLocaleString()}
              <ArrowDownCircleIcon className="ml-2" height={20} />
            </div>
            <div className="w-full h-full">
              <ResponsiveContainer>
                <AreaChart
                  margin={{
                    top: 0,
                    right: 0,
                    left: 0,
                    bottom: 0,
                  }}
                  data={d}
                >
                  <defs>
                    <linearGradient id="colorPv2" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="red" stopOpacity={0.5} />
                      <stop offset="95%" stopColor="red" stopOpacity={0.1} />
                    </linearGradient>
                  </defs>
                  <Area
                    type="natural"
                    dataKey={"uv"}
                    stroke="red"
                    strokeWidth={5}
                    strokeOpacity={0.5}
                    fillOpacity={1}
                    fill="url(#colorPv2)"
                  />
                </AreaChart>
              </ResponsiveContainer>
            </div>
          </div>
          <div className="flex flex-1 hover:bg-green-100 bg-green-50 align-center flex-col">
            <div className="pt-5 px-5 text-sm uppercase text-gray-600 font-bold mb-1 flex flex-row items-center">
              <div>Proj. Billables, 30 Days</div>
              <div className="flex flex-1"></div>
              <div className="cursor-pointer text-gray-500 hover:text-black p-1 hover:shadow hover:bg-gray-100 border border-transparent hover:border-black rounded">
                <ArrowsPointingOutIcon height={19} />
              </div>
            </div>
            <div className="pt-1 pl-5 text-xl flex flex-row items-center font-bold text-green-500">
              ${data?.total?.actualBillableRevenue?.toLocaleString()}{" "}
              <ArrowUpCircleIcon className="ml-2" height={20} />
            </div>
            <div className="w-full h-full">
              <ResponsiveContainer>
                <AreaChart
                  margin={{
                    top: 0,
                    right: 0,
                    left: 0,
                    bottom: 0,
                  }}
                  data={d}
                >
                  <defs>
                    <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.5} />
                      <stop
                        offset="95%"
                        stopColor="#82ca9d"
                        stopOpacity={0.1}
                      />
                    </linearGradient>
                  </defs>
                  <Area
                    type="natural"
                    dataKey={"uv"}
                    stroke="#82ca9d"
                    strokeOpacity={0.9}
                    strokeWidth={5}
                    fillOpacity={1}
                    fill="url(#colorPv)"
                  />
                </AreaChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
        <div style={{ height: "110px" }} className="flex flex-row">
          <div className="pt-5 pl-5 flex flex-1 hover:bg-gray-100 align-center flex-col border-r">
            <div className="text-xs uppercase text-gray-600 font-bold mb-3">
              Active Projects
            </div>
            <div className="text-3xl">{data?.projects?.length}</div>
          </div>
          <div className="pt-5 pl-5 flex flex-1 hover:bg-gray-100 align-center flex-col border-r">
            <div className="text-xs uppercase text-gray-600 font-bold mb-3">
              Active Projects
            </div>
            <div className="text-3xl">{data?.projects?.length}</div>
          </div>
          <div className="pt-5 pl-5 flex flex-1 hover:bg-gray-100 align-center flex-col border-r">
            <div className="text-xs uppercase text-gray-600 font-bold mb-3">
              Active Resources
            </div>
            <div className="text-3xl">{allResources.length}</div>
          </div>
          <div className="pt-5 pl-5 flex flex-1 hover:bg-gray-100 align-center flex-col">
            <div className="text-xs uppercase text-gray-600 font-bold mb-3">
              Active Projects
            </div>
            <div className="text-3xl">{data?.projects?.length}</div>
          </div>
        </div>
      </div>
    </GraphCard>
  );
}
