import { gql } from "@apollo/client";

const GET_TIME_SEGMENTS = gql`
  query listTimeSegments(
    $skip: Int
    $after: String
    $first: Int
    $where: JSON
    $orderBy: TimeSegmentOrder
  ) {
    data: listTimeSegments(
      skip: $skip
      after: $after
      first: $first
      where: $where
      orderBy: $orderBy
    ) {
      edges {
        cursor
        node {
          id
          startingAt
          endingAt
          dayOfSegment
          lengthOfSegmentInHours
          resourceAssignment {
            id
            employeeId
            employee {
              firstName
              lastName
            }
            providerPersonnelId
            providerPersonnel {
              firstName
              lastName
            }
          }
        }
      }
      totalCount
      pageInfo {
        hasNextPage
      }
    }
  }
`;

const GET_TIME_OFF_SEGMENTS = gql`
  query listTimeOffSegments(
    $skip: Int
    $after: String
    $first: Int
    $where: JSON
    $orderBy: TimeOffSegmentOrder
  ) {
    data: listTimeOffSegments(
      skip: $skip
      after: $after
      first: $first
      where: $where
      orderBy: $orderBy
    ) {
      edges {
        cursor
        node {
          id
          startingAt
          endingAt
          dayOfSegment
          lengthOfSegmentInHours
          status
          timeOffType
          segmentType
          description
          employeeId
          providerPersonnelId
        }
      }
      totalCount
      pageInfo {
        hasNextPage
      }
    }
  }
`;

export { GET_TIME_SEGMENTS, GET_TIME_OFF_SEGMENTS };
