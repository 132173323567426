import { gql } from "@apollo/client";

const GET_CUSTOMERS = gql`
  query listCustomers(
    $skip: Int
    $after: String
    $first: Int
    $query: String
    $orderBy: CustomerOrder
  ) {
    data: listCustomers(
      skip: $skip
      after: $after
      first: $first
      query: $query
      orderBy: $orderBy
    ) {
      edges {
        cursor
        node {
          id
          name
          status
          createdAt
          updatedAt
          inTriageFromIntegration
          externalData {
            externalRelationships {
              sourceIntegration {
                id
                target
              }
            }
          }
        }
      }
      totalCount
      pageInfo {
        hasNextPage
      }
    }
  }
`;

const GET_CUSTOMER = gql`
  query getCustomer($id: String!) {
    data: getCustomer(id: $id) {
      id
      name
      status
      createdAt
      updatedAt
      inTriageFromIntegration
      externalData {
        externalRelationships {
          sourceIntegration {
            id
            target
          }
        }
      }
      projects {
        id
        name
        status
        createdAt
        updatedAt
        parentProjectId
      }
    }
  }
`;

const CREATE_CUSTOMER = gql`
  mutation createCustomer($newCustomer: CustomerCreateRequest!) {
    createCustomer(newCustomer: $newCustomer) {
      id
    }
  }
`;

const UPDATE_CUSTOMER = gql`
  mutation updateCustomer(
    $id: String!
    $updatedCustomer: CustomerUpdateRequest!
  ) {
    updateCustomer(id: $id, updatedCustomer: $updatedCustomer) {
      id
    }
  }
`;

export { GET_CUSTOMERS, GET_CUSTOMER, UPDATE_CUSTOMER, CREATE_CUSTOMER };
