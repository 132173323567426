import { Popover } from "@headlessui/react";
import React, { useState } from "react";
import ReactDOM from "react-dom";
import { usePopper } from "react-popper";

export const BasicPopover = ({ button, content }: any) => {
  const [referenceElement, setReferenceElement]: any = React.useState(null);
  const [popperElement, setPopperElement]: any = React.useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement);
  if (document.querySelector("#page") === null) {
    return null;
  }
  return (
    <>
      {button && (
        <Popover>
          {({ open }) => (
            <>
              <Popover.Button ref={setReferenceElement}>
                {button}
              </Popover.Button>
              {ReactDOM.createPortal(
                <>
                  <Popover.Overlay
                    style={{ zIndex: 20 }}
                    className={`${
                      open ? "opacity-30 fixed inset-0" : "opacity-0"
                    } bg-black`}
                  />

                  <Popover.Panel
                    ref={setPopperElement}
                    style={{ ...styles.popper, zIndex: 30 }}
                    {...attributes.popper}
                  >
                    <div className="px-6 py-4 font-gray-700 font-regular bg-white shadow-lg rounded-md border border-gray-300">
                      {content}
                    </div>
                  </Popover.Panel>
                </>,
                document.querySelector("#page") as any
              )}
            </>
          )}
        </Popover>
      )}
    </>
  );
};
