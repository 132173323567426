import { useState } from "react";

export interface DropdownConfiguration {
  options?: DropdownOption[];
  hasStatus?: boolean;
  selected?: any;
  onChange?: any;
  placeholder?: any;
  additionalClasses?: any;
  register?: any;
  validation?: any;
}

export interface DropdownOption {
  label: string;
  value: string;
  enabled?: boolean;
  isSelected?: boolean;
  imageUrl?: string;
}

export function Dropdown({
  options,
  hasStatus,
  selected,
  onChange,
  placeholder,
  additionalClasses,
}: DropdownConfiguration) {
  const [isOpen, setIsOpen] = useState(false);
  const [hoveredOption, setHoveredOption] = useState(null);

  console.log(selected);

  let selectedOption: any = null;
  options?.map((o) => {
    if (o.value === selected) {
      selectedOption = o;
      o.isSelected = true;
    }
  });

  const onMouseEnter = (o: any) => {
    setHoveredOption(o.value);
  };

  const onMouseLeave = (o: any) => {
    setHoveredOption(null);
  };

  const renderCurrentDropdownInner = (sel?: DropdownOption) => {
    return (
      <button
        type="button"
        aria-haspopup="listbox"
        aria-expanded="true"
        aria-labelledby="listbox-label"
        onBlur={() => setIsOpen(false)}
        onClick={() => setIsOpen(!isOpen)}
        className={"cursor-pointer w-full " + (additionalClasses || "")}
      >
        <div className="flex items-center">
          {hasStatus &&
            (sel?.enabled ? (
              <span
                aria-label="Online"
                className="bg-green-400 mr-3 flex-shrink-0 inline-block h-2 w-2 rounded-full"
              ></span>
            ) : (
              <span
                aria-label="Online"
                className="bg-gray-200 mr-3 flex-shrink-0 inline-block h-2 w-2 rounded-full"
              ></span>
            ))}
          {sel?.imageUrl && (
            <span>
              <img className="w-4 mr-3" src={sel?.imageUrl} />
            </span>
          )}
          <span className="block truncate">
            {sel ? (
              sel?.label
            ) : placeholder ? (
              <span className="text-gray-500">{placeholder}</span>
            ) : (
              <span></span>
            )}
          </span>
        </div>
        <span
          onClick={() => setIsOpen(!isOpen)}
          className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"
        >
          <svg
            className="h-5 w-5 text-gray-400"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
              clip-rule="evenodd"
            />
          </svg>
        </span>
      </button>
    );
  };

  const renderOptions = (options: DropdownOption[]) => {
    return (
      <div className="absolute z-20 mt-1 w-full rounded-md bg-white shadow-lg">
        <ul
          tabIndex={-1}
          role="listbox"
          aria-labelledby="listbox-label"
          aria-activedescendant="listbox-item-3"
          className="max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
        >
          {options?.map((o: DropdownOption, index) => {
            return (
              <li
                id={"listbox-item-" + index}
                role="option"
                key={index}
                onMouseOver={() => onMouseEnter(o)}
                onMouseLeave={() => onMouseLeave(o)}
                onMouseDown={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onChange(o);
                  setIsOpen(false);
                }}
                className={`cursor-pointer select-none relative py-2 pl-3 pr-9 ${
                  hoveredOption == o.value
                    ? "text-white bg-blue-600"
                    : "text-gray-900"
                }`}
              >
                <div className="flex items-center">
                  {hasStatus &&
                    (o?.enabled ? (
                      <span
                        aria-label="Online"
                        className="bg-green-400 mr-3 flex-shrink-0 inline-block h-2 w-2 rounded-full"
                      ></span>
                    ) : (
                      <span
                        aria-label="Online"
                        className="bg-gray-200 mr-3 flex-shrink-0 inline-block h-2 w-2 rounded-full"
                      ></span>
                    ))}
                  {o.imageUrl && (
                    <span>
                      <img className="w-4 mr-3" src={o.imageUrl} />
                    </span>
                  )}
                  <span
                    className={`block truncate ${
                      o.isSelected ? "font-semibold" : "font-normal"
                    }`}
                  >
                    {o.label}
                  </span>
                </div>
                {o.isSelected && (
                  <span className="absolute inset-y-0 right-0 flex items-center pr-4">
                    <svg
                      className="h-5 w-5"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </span>
                )}
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  return (
    <div className="">
      <div className="mt-1 relative">
        {renderCurrentDropdownInner(selectedOption)}
        {isOpen && options && renderOptions(options)}
      </div>
    </div>
  );
}
