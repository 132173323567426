import { BookOpenIcon, UserIcon, UsersIcon } from "@heroicons/react/24/solid";

export const entityTypeToIcon = (entityType: string) => {
  if (entityType === "customer") {
    return UsersIcon;
  } else if (entityType === "providerPersonnel") {
    return UserIcon;
  } else if (entityType === "employee") {
    return UserIcon;
  } else if (entityType === "project") {
    return BookOpenIcon;
  }
};
