import { ReactNode } from "react";

interface SecondarySidemenuProps {
  children: any;
  classNames?: string;
  style?: any;
}

export function SecondarySidemenu({
  children,
  classNames,
  style,
}: SecondarySidemenuProps) {
  return (
    <div
      style={{
        height: "100%",
        width: "230px",
        ...style,
      }}
      className={"z-1 border-r " + classNames || ""}
    >
      {children}
    </div>
  );
}
