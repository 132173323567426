import { useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import MinimalForm from "../../components/MinimalForm/MinimalForm";
import RegisterForm from "../../components/RegisterForm/RegisterForm";
import { REGISTER } from "../../data/queries/auth";
import { REGISTER_FORM_CONFIGURATION } from "../../data/types/Auth";

export function Register() {
  const form = useForm<FormData>();
  const { errors, setValue, handleSubmit } = form;
  const history = useHistory();
  let showLoading = false;

  const [register, registerResult] = useMutation(REGISTER);

  if (!!registerResult && registerResult.loading && registerResult.called) {
    showLoading = true;
  } else if (!!registerResult && registerResult.data) {
    // Redirect to login screen
    history.push("/");
  }

  const registerSubmit = async (data: any) => {
    console.log(data);
    register({
      variables: {
        signupInput: data,
      },
    });
  };

  console.log(errors);

  return (
    <>
      <div className="relative bg-white">
        <div className="absolute inset-0">
          <div className="absolute inset-y-0 left-0 w-1/2 bg-gray-50"></div>
        </div>
        <div className="relative mx-auto lg:grid lg:grid-cols-5">
          <div className="bg-gray-50 py-16 px-4 sm:px-6 lg:col-span-3 lg:px-8 lg:py-24 xl:pr-12">
            <div className="max-w-lg mx-auto">
              <h2 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">
                Sign up for Kenso
              </h2>
              <p className="mt-3 text-lg leading-6 text-gray-500">
                A better services experience is just a click away.
              </p>
            </div>
          </div>
          <div className="bg-blue-100 py-16 px-4 sm:px-6 lg:col-span-2 lg:py-24 lg:px-8 xl:pl-12">
            {!showLoading && (
              <div className="max-w-lg mx-auto lg:max-w-none">
                <RegisterForm
                  submitText={"Submit"}
                  form={form}
                  onSubmit={registerSubmit}
                  fields={REGISTER_FORM_CONFIGURATION.fields}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
