import { ReactNode, useEffect } from "react";
import Card from "../../basics/Card/Card";
import { Props } from "../../data/types/Props";
import { BellIcon, SparklesIcon } from "@heroicons/react/24/solid";
import "./AlertsWorkflowCard.css";
import { useLazyQuery } from "@apollo/client";
import { GET_TASKS } from "../../data/queries/tasks";
import { format } from "date-fns";
import { Link, useHistory } from "react-router-dom";
import { IconButton } from "../../basics/Buttons/IconButton";
import { BasicButtonStyles } from "../../basics/Buttons/BasicButton";
import { useLazyListQuerySM } from "../../data/machines/queries";

interface AlertsWorkflowCardProps extends Props {
  title: string;
  tooltip?: ReactNode;
}

function AlertsWorkflowCard({
  children,
  title,
  classNames,
  tooltip,
}: AlertsWorkflowCardProps) {
  const [state, data, send] = useLazyListQuerySM(GET_TASKS);
  const history = useHistory();

  useEffect(() => {
    send({ type: "MAKE_QUERY", variables: { cursor: null } });
  }, []);

  const renderTask = (task: any) => {
    return (
      <div className="flex flex-col w-full border-b py-3">
        <div className="flex flex-row text-gray-700 ">
          <div className="mr-1">{task.name}</div>
          {task.project && <> for {task.project.name}</>}
          <div className="flex-1"></div>
          <div>Due {format(new Date(task.dueOn), "MM/dd/yyyy")}</div>
        </div>
        <div className="text-sm mt-2">{task.description}</div>
        <div className="mt-3">
          {task.action === "APPROVE_INVOICE" && (
            <div>
              <IconButton
                styler={BasicButtonStyles.CLEAR}
                icon={SparklesIcon}
                text="View invoice to approve"
                onClick={() =>
                  history.push(
                    "/invoices/" + task.invoice.id + "?openApprovalSlideOver"
                  )
                }
              />
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <Card dashboardStyle classNames={classNames + " flex flex-col"}>
      <div className="rounded-t-lg py-4 bg-white flex flex-row">
        <div className="px-6">
          <h3 className="text-base font-light dark:text-gray-200 text-black">
            {title}
          </h3>
        </div>
      </div>
      <div className="px-6 pt-2 bg-gray-100 flex flex-1 flex-col px-6 py-2 overflow-auto">
        {state.value === "completed" &&
          data.edges?.map((d: any) => renderTask(d.node))}
      </div>
    </Card>
  );
}

export default AlertsWorkflowCard;
