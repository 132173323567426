import { UserCircleIcon } from "@heroicons/react/24/outline";
import {
  ArrowLeftIcon,
  CalendarIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  TagIcon,
} from "@heroicons/react/24/solid";
import { format, add, sub, isEqual, isSameDay, setHours } from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";
import { useState } from "react";
import { BasicButtonStyles } from "../../basics/Buttons/BasicButton";
import { IconButton } from "../../basics/Buttons/IconButton";
import { SectionHeader } from "../../basics/SectionHeader/SectionHeader";
import { ResourceAssignment } from "../../data/types/ResourceAssignment";
import { TimeOffSegment, TimeSegment } from "../../data/types/Time";

interface TimeSheetDisplayProps {
  segments: TimeSegment[];
  timeOffSegments: TimeOffSegment[];
  startDate: any;
  endDate: any;
  setStartDate: any;
  setEndDate: any;
  resources: ResourceAssignment[];
}

function TimeSheetDisplay({
  segments,
  timeOffSegments,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  resources,
}: TimeSheetDisplayProps) {
  const [showInactive, setShowInactive] = useState(false);

  const getDaysSpreadArray = (startDate: Date, endDate: Date) => {
    let days = [];
    let currentDate = startDate;
    days.push(currentDate);
    while (!isSameDay(currentDate, endDate)) {
      currentDate = add(currentDate, { days: 1 });
      days.push(currentDate);
    }
    return days;
  };

  const days = segments ? getDaysSpreadArray(startDate, endDate) : [];

  const changeWeek = (direction: "PREV" | "NEXT") => {
    if (direction === "NEXT") {
      let newStartDate = add(startDate, { days: 7 });
      setStartDate(newStartDate);
      setEndDate(add(newStartDate, { days: 6 }));
    } else {
      let newStartDate = sub(startDate, { days: 7 });
      setStartDate(newStartDate);
      setEndDate(add(newStartDate, { days: 6 }));
    }
  };

  const datesAreOnSameDay = (first: Date, second: Date) => {
    return isSameDay(first, second);
  };

  const renderColumns = (resource: ResourceAssignment) => {
    let resourceId = resource.id;
    if (!resourceId) return <></>;
    let timeEntries = segments
      ? segments.filter(
          (s: TimeSegment) => s.resourceAssignment.id === resourceId
        )
      : [];
    let timeOffEntries = timeOffSegments
      ? timeOffSegments.filter(
          (s: TimeOffSegment) =>
            (resource.employeeId && s.employeeId === resource.employeeId) ||
            (resource.providerPersonnelId &&
              s.providerPersonnelId === resource.providerPersonnelId)
        )
      : [];
    const days = getDaysSpreadArray(startDate, endDate);
    return days.map((d: Date) => {
      let toShow = (
        <div
          className="border-r hover:bg-blue-50 cursor-pointer bg-gray-50 flex flex-row align-center items-center justify-center"
          style={{ width: "140px" }}
        >
          0.0 h
        </div>
      );
      timeEntries.map((m: TimeSegment) => {
        if (m && datesAreOnSameDay(d, new Date(m?.dayOfSegment as any))) {
          toShow = (
            <div
              className="border-r hover:bg-blue-200 cursor-pointer bg-blue-100 flex flex-row align-center items-center justify-center"
              style={{ width: "140px" }}
            >
              {Number(m.lengthOfSegmentInHours).toFixed(1)} h
            </div>
          );
        }
      });
      timeOffEntries.map((m: TimeOffSegment) => {
        if (m && datesAreOnSameDay(d, new Date(m?.dayOfSegment as any))) {
          toShow = (
            <div
              className="border-r hover:bg-red-200 cursor-pointer bg-red-100 flex flex-row align-center items-center justify-center"
              style={{ width: "140px" }}
            >
              PTO: {Number(m.lengthOfSegmentInHours).toFixed(1)} h
            </div>
          );
        }
      });
      return toShow;
    });
  };

  const renderRows = () => {
    return resources.map((r: ResourceAssignment, index: number) => {
      if (showInactive) {
      } else if (r.status === "INACTIVE") {
        return <></>;
      }

      return (
        <div
          className={`flex flex-row text-sm ${index === 0 ? "" : "border-t"}`}
        >
          <div className="py-3 px-4 font-bold w-56 border-r flex flex-row">
            <UserCircleIcon className="mr-2" height={20} />
            {r?.employee
              ? r?.employee?.firstName
              : r?.providerPersonnel?.firstName}{" "}
            {r?.employee ? "(employee)" : "(provider)"}
          </div>
          <div className="flex flex-1">{renderColumns(r)}</div>
        </div>
      );
    });
  };

  const renderColumnHeaders = () => {
    const days = getDaysSpreadArray(startDate, endDate);
    return days.map((d: Date, index) => {
      return (
        <div
          style={{ width: "140px" }}
          className={`flex flex-row justify-center py-2 ${
            index !== days.length - 1 ? "border-r" : ""
          }`}
        >
          <div> {format(d, "MM/dd/yyyy")}</div>
        </div>
      );
    });
  };

  return (
    <div>
      <div style={{ marginBottom: "30px" }}>
        <SectionHeader
          borderEnabled
          title="Time Entries"
          rightContent={
            <>
              <div className={"ml-4 flex-row flex items-center align-center"}>
                <IconButton
                  classNames="mr-2"
                  text={"Prev"}
                  icon={ChevronLeftIcon}
                  styler={BasicButtonStyles.CLEAR}
                  onClick={() => changeWeek("PREV")}
                />
                <CalendarIcon height={20} />
                <div className="ml-1 text-sm">
                  {format(startDate, "MM/dd/yyyy")} -{" "}
                  {format(endDate, "MM/dd/yyyy")}
                </div>
                <IconButton
                  classNames="ml-2"
                  text={"Next"}
                  icon={ChevronRightIcon}
                  styler={BasicButtonStyles.CLEAR}
                  onClick={() => changeWeek("NEXT")}
                />
              </div>
            </>
          }
        />
      </div>
      <div className="flex flex-row">
        <div className="w-56 px-4"></div>
        <div className="text-xs text-gray-600 font-bold bg-white border-t border-l border-r rounded-t-md flex flex-row">
          {renderColumnHeaders()}
        </div>
      </div>
      <div className="border bg-white overflow-hidden rounded-md">
        {renderRows()}
      </div>
      <div style={{ marginTop: "10px" }}>
        <IconButton
          text={`${showInactive ? "Hide" : "Show"} Inactive Resources`}
          icon={TagIcon}
          iconClassNames="mr-2"
          styler={BasicButtonStyles.CLEAR}
          onClick={() => setShowInactive(!showInactive)}
        />
      </div>
    </div>
  );
}

export default TimeSheetDisplay;
