import PageHeader from "../../components/PageHeader/PageHeader";

export function ClientDashboard() {
  return (
    <>
      <PageHeader
        disableBg={true}
        disableBorder={true}
        disableBreadcrumbs={true}
      />
      <div className="py-6">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          <h1 className="text-2xl font-semibold text-gray-900 dark:text-white">
            Dashboard
          </h1>
        </div>
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 md:px-8 flex-row flex">
          aaa
        </div>
      </div>
    </>
  );
}
