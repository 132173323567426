import { BaseModalProps } from "../../basics/ModalWrapper/ModalWrapper";
import MinimalForm from "../../components/MinimalForm/MinimalForm";
import { useForm } from "react-hook-form";
import SlideOverWrapper from "../../basics/SlideOverWrapper/SlideOverWrapper";
import { useEffect, useState } from "react";
import { FormFieldConfiguration } from "../../data/types/Form";
import { BUILD_EXTERNAL_RESOURCE_FORM_CONFIGURATION } from "../../data/types/ExternalResource";
import { ProjectScopeOfWork } from "../../data/types/ProjectScopeOfWork";
import Card from "../../basics/Card/Card";
import { IconButton } from "../../basics/Buttons/IconButton";
import { PencilIcon, PlusIcon } from "@heroicons/react/24/outline";
import {
  BasicButton,
  BasicButtonStyles,
} from "../../basics/Buttons/BasicButton";

export interface ScopeOfWorkAddEditSlideOverProps extends BaseModalProps {
  scopesOfWork?: ProjectScopeOfWork[];
  onSelect?: any;
}

function ScopeOfWorkAddEditSlideOver({
  open,
  setOpen,
  onSubmit,
  scopesOfWork,
  onSelect,
}: ScopeOfWorkAddEditSlideOverProps) {
  let initialValues: any = {};

  const form = useForm<FormData>({ defaultValues: initialValues });
  const [editMode, setEditMode] = useState(false);
  const [toEdit, setToEdit] = useState(null);
  const { errors, setValue, handleSubmit, watch } = form;

  useEffect(() => {
    setEditMode(false);
    setToEdit(null);
  }, [open]);

  /* SET FIELDS */
  let fields: FormFieldConfiguration[] = [];

  const submitForm = (data: any) => {};

  const setEdit = (item: any) => {
    setEditMode(true);
    setToEdit(item);
  };

  const renderEditMode = () => {
    return (
      <MinimalForm
        fields={fields}
        optionsMap={{}}
        submitText={"Submit"}
        form={form}
        onSubmit={submitForm}
      />
    );
  };

  return (
    <SlideOverWrapper
      title={`${editMode ? "Edit" : "Choose"} Scope of Work`}
      open={open}
      setOpen={setOpen}
    >
      {renderEditMode()}
    </SlideOverWrapper>
  );
}

export default ScopeOfWorkAddEditSlideOver;
