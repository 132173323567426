import { useLazyQuery, useMutation } from "@apollo/client";
import { CREATE_PROJECT, GET_PROJECTS } from "../../data/queries/projects";
import { PaginatedResponse } from "../../data/types/PaginatedResponse";
import { useEffect, useState } from "react";
import MinimalForm from "../../components/MinimalForm/MinimalForm";
import FormCard from "../../components/FormCard/FormCard";
import { useForm } from "react-hook-form";

import { useHistory, useLocation } from "react-router-dom";
import {
  ProjectCreateUpdateArgs,
  PROJECT_FORM_CONFIGURATION,
} from "../../data/types/Project";
import { GET_CUSTOMERS } from "../../data/queries/customers";
import { Customer } from "../../data/types/Customer";
import { AddEditViewHeader } from "../../components/AddEditViewHeader/AddEditViewHeader";

export function AddEditProject() {
  const [getData, { loading, error, data }] = useLazyQuery(GET_CUSTOMERS);
  const [createProject, createResult] = useMutation(CREATE_PROJECT, {
    refetchQueries: [
      {
        query: GET_PROJECTS,
      },
    ],
  });
  const [cursor, setCursor] = useState(null);
  const paginatedData: PaginatedResponse<Customer> = data?.data;
  const form = useForm<FormData>();
  const history = useHistory();
  const location = useLocation();
  const segments = location.pathname.split("/");
  const id = segments[segments.length - 1];
  let mode;

  if (id === "add") {
    mode = "add";
  } else {
    mode = "edit";
  }

  useEffect(() => {
    if (!createResult.error && createResult.data) {
      history.push("/projects");
    }
  }, [createResult]);

  useEffect(() => {
    getData({
      variables: {
        cursor,
      },
    });
  }, []);

  const { errors, setValue, handleSubmit } = form;

  const submitForm = async (data: ProjectCreateUpdateArgs) => {
    await createProject({
      variables: {
        projectToCreate: data,
      },
    });
  };

  return (
    <>
      <AddEditViewHeader
        entityName={"Project"}
        onCancel={() => history.goBack()}
        onSubmit={submitForm}
      />
      <div className="p-8">
        <FormCard>
          <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Basic Information
            </h3>
          </div>
          <MinimalForm
            submitText={"Submit"}
            form={form}
            hideSubmitButton={true}
            optionsMap={{
              customers: paginatedData
                ? paginatedData.edges.map((e) => {
                    return {
                      value: e.node.id,
                      label: e.node.name,
                    };
                  })
                : null,
            }}
            onSubmit={submitForm}
            fields={PROJECT_FORM_CONFIGURATION.fields}
          />
        </FormCard>
      </div>
    </>
  );
}
