import { gql } from "@apollo/client";

const GET_ITEM_DEFINITIONS = gql`
  query listItemDefinitions(
    $skip: Int
    $after: String
    $first: Int
    $where: JSON
    $orderBy: ItemDefinitionOrder
  ) {
    data: listItemDefinitions(
      skip: $skip
      after: $after
      first: $first
      where: $where
      orderBy: $orderBy
    ) {
      edges {
        cursor
        node {
          id
          name
          createdAt
          updatedAt
          itemType
          billingType
          rate
          rateCurrency
          rateTimeUnits
          inTriageFromIntegration
          externalData {
            externalRelationships {
              sourceIntegration {
                id
                target
              }
            }
          }
        }
      }
      totalCount
      pageInfo {
        hasNextPage
      }
    }
  }
`;

const GET_ITEM_DEFINITION = gql`
  query getItemDefinition($id: String!) {
    data: getItemDefinition(id: $id) {
      id
      name
      createdAt
      updatedAt
      type
      inTriageFromIntegration
      externalData {
        externalRelationships {
          sourceIntegration {
            id
            target
          }
        }
      }
    }
  }
`;

export { GET_ITEM_DEFINITIONS, GET_ITEM_DEFINITION };
