export interface BasicButtonProps {
  type?: "submit" | "button" | "reset";
  text?: any;
  color?: string;
  styler?: BasicButtonStyles;
  classNames?: string;
  onClick?: any;
  style?: any;
  small?: boolean;
}

export enum BasicButtonStyles {
  REGULAR,
  INVERTED,
  CLEAR,
  ZERO,
  DROPDOWN,
}

export function BasicButton({
  type = "submit",
  text,
  color = "blue",
  styler = BasicButtonStyles.REGULAR,
  classNames,
  onClick,
  style,
  small,
}: BasicButtonProps) {
  let baseClass = "";
  switch (styler) {
    case BasicButtonStyles.REGULAR:
      baseClass = `inline-flex items-center shadow-md px-4 py-2 border border-transparent ${
        small ? "text-xs" : "text-sm"
      } font-medium rounded-md text-white bg-${color}-600 hover:bg-${color}-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-${color}-500 ${classNames}`;
      break;
    case BasicButtonStyles.CLEAR:
      baseClass = `inline-flex items-center px-2 py-1 border border-${color}-600 ${
        small ? "text-xs" : "text-sm"
      } text-${color}-600 font-medium rounded-md hover:bg-white hover:shadow bg-none hover:bg-gray-050 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-${color}-500 ${classNames}`;
      break;
    case BasicButtonStyles.ZERO:
      baseClass = `inline-flex items-center border-none ${
        small ? "text-xs" : "text-sm"
      } text-${color}-600 font-medium hover:bg-gray-050 bg-none ${classNames}`;
      break;
    case BasicButtonStyles.DROPDOWN:
      baseClass = `inline-flex items-center px-4 py-2 rounded-md border border-${color}-400 ${
        small ? "text-xs" : "text-sm"
      } text-${color}-600 font-medium hover:bg-gray-50 bg-white shadow-sm ${classNames}`;
      break;
  }
  return (
    <button
      style={style}
      onClick={onClick}
      type={type}
      className={`${baseClass}`}
    >
      {text}
    </button>
  );
}
