import { gql } from "@apollo/client";

const UPDATE_PROVIDER = gql`
  mutation updateProvider($oroviderToUpdate: ProviderUpdateRequest!) {
    updateProvider(providerToUpdate: $providerToUpdate) {
      id
      name
    }
  }
`;

const GET_PROVIDERS = gql`
  query listProviders(
    $skip: Int
    $after: String
    $first: Int
    $query: String
    $orderBy: ProviderOrder
  ) {
    data: listProviders(
      skip: $skip
      after: $after
      first: $first
      query: $query
      orderBy: $orderBy
    ) {
      edges {
        cursor
        node {
          id
          name
          firstName
          lastName
          type
          contractualType
          billingType
          createdAt
          updatedAt
          inTriageFromIntegration
          personnel {
            id
            firstName
            lastName
            baseCostRate
            costRateCurrency
            costRateTimeUnit
            providerId
          }
          externalData {
            externalRelationships {
              sourceIntegration {
                id
                target
              }
            }
          }
        }
      }
      totalCount
      pageInfo {
        hasNextPage
      }
    }
  }
`;

const GET_PROVIDER = gql`
  query getProvider($id: String!) {
    data: getProvider(id: $id) {
      id
      name
      firstName
      lastName
      createdAt
      updatedAt
      inTriageFromIntegration
      personnel {
        id
        firstName
        lastName
        baseCostRate
        costRateCurrency
        costRateTimeUnit
        providerId
      }
    }
  }
`;

export { GET_PROVIDERS, GET_PROVIDER, UPDATE_PROVIDER };
