import { gql } from "@apollo/client";

const UPDATE_WORKFLOW_SPECIFICATION = gql`
  mutation updateWorkflowSpecification(
    $workflowSpecificationToUpdate: WorkflowSpecificationUpdateRequest!
  ) {
    updateWorkflowSpecification(
      workflowSpecificationToUpdate: $workflowSpecificationToUpdate
    ) {
      id
      name
    }
  }
`;

const GET_WORKFLOWS = gql`
  query listWorkflowInstances(
    $skip: Int
    $after: String
    $first: Int
    $query: String
    $orderBy: WorkflowInstanceOrder
  ) {
    data: listWorkflowInstances(
      skip: $skip
      after: $after
      first: $first
      query: $query
      orderBy: $orderBy
    ) {
      edges {
        cursor
        node {
          id
          status
        }
      }
      totalCount
      pageInfo {
        hasNextPage
      }
    }
  }
`;

const GET_WORKFLOW_SPECIFICATIONS = gql`
  query listWorkflowSpecifications(
    $skip: Int
    $after: String
    $first: Int
    $query: String
    $orderBy: WorkflowSpecificationOrder
  ) {
    data: listWorkflowSpecifications(
      skip: $skip
      after: $after
      first: $first
      query: $query
      orderBy: $orderBy
    ) {
      edges {
        cursor
        node {
          id
          name
          specification {
            steps {
              id
              nextStepId
              type
              stepConfig
            }
          }
        }
      }
      totalCount
      pageInfo {
        hasNextPage
      }
    }
  }
`;

export {
  GET_WORKFLOWS,
  GET_WORKFLOW_SPECIFICATIONS,
  UPDATE_WORKFLOW_SPECIFICATION,
};
