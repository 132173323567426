import { gql } from "@apollo/client";

const GET_TASKS = gql`
  query listTasks(
    $skip: Int
    $after: String
    $first: Int
    $query: String
    $orderBy: TaskOrder
  ) {
    data: listTasks(
      skip: $skip
      after: $after
      first: $first
      query: $query
      orderBy: $orderBy
    ) {
      edges {
        cursor
        node {
          id
          name
          description
          action
          startingAt
          dueOn
          status
          target
          assignedTo {
            id
            firstName
            lastName
          }
          project {
            id
            name
          }
          invoice {
            id
          }
        }
      }
      totalCount
      pageInfo {
        hasNextPage
      }
    }
  }
`;

export { GET_TASKS };
