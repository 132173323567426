import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { inspect } from "@xstate/inspect";
import {
  ApolloProvider,
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  DefaultOptions,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_SERVER_BASE_URL + "/graphql",
  credentials: "include",
});

inspect({
  // options
  url: "https://stately.ai/viz?inspect", // (default)
  iframe: false, // open in new window
});

// Current tenant ID
const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("currentTenantId");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      "x-tenant-id": token ? token : "",
    },
  };
});

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: "no-cache",
    errorPolicy: "ignore",
  },
  query: {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  },
};

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    // prevents typename from being sent in GraphQL mutations
    addTypename: false,
  }),
  defaultOptions: defaultOptions,
});

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
