import { useLazyQuery } from "@apollo/client";
import { PencilIcon, PlusIcon } from "@heroicons/react/24/outline";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { BasicButtonStyles } from "../../basics/Buttons/BasicButton";
import { IconButton } from "../../basics/Buttons/IconButton";
import { DefaultPagePaddingAndWidth } from "../../basics/DefaultPagePaddingAndWidth/DefaultPagePaddingAndWidth";
import { LoadingWrapper } from "../../basics/LoadingWrapper/LoadingWrapper";
import { SectionHeader } from "../../basics/SectionHeader/SectionHeader";
import Table from "../../basics/Table/Table";
import BaseDetailView from "../../components/BaseDetailView/BaseDetailView";
import { DetailViewHeader } from "../../components/DetailViewHeader/DetailViewHeader";
import { LazyQuerySM, useLazyQuerySM } from "../../data/machines/queries";
import { GET_PROVIDER } from "../../data/queries/providers";
import {
  Provider as Prov,
  PROVIDER_DETAIL_CONFIGURATION,
  PROVIDER_PERSONNEL_TABLE_CONFIGURATION,
} from "../../data/types/Provider";

export function Provider() {
  const [state, data, send]: LazyQuerySM<Prov> = useLazyQuerySM(GET_PROVIDER);
  const location = useLocation();
  const segments = location.pathname.split("/");
  const id = segments[segments.length - 1];

  useEffect(() => {
    send({ type: "MAKE_QUERY", variables: { id: id } });
  }, []);
  return (
    <>
      <LoadingWrapper state={state.value}>
        <DetailViewHeader
          name={data?.name}
          entityName="Provider"
          onEdit={() => {}}
        />
      </LoadingWrapper>
      <DefaultPagePaddingAndWidth className="flex flex-row">
        <LoadingWrapper state={state.value} loadingType={"skeleton"}>
          <BaseDetailView
            dataObject={data}
            title={"Provider"}
            config={PROVIDER_DETAIL_CONFIGURATION}
          />
        </LoadingWrapper>
        <div className="flex flex-1 ml-10 flex-col mb-3 pt-5">
          <LoadingWrapper state={state.value}>
            <SectionHeader
              title="Personnel"
              button={
                <IconButton
                  icon={PlusIcon}
                  text="New"
                  small
                  iconClassNames="mr-1"
                  onClick={() => console.log()}
                />
              }
            />
            <Table
              data={data?.personnel || []}
              columns={PROVIDER_PERSONNEL_TABLE_CONFIGURATION}
              start={1}
              end={data?.personnel?.length || 0}
              onChangePage={() => {}}
              actions={[
                {
                  label: "Edit",
                  color: "blue",
                  icon: PencilIcon,
                  onClick: (i: any) => console.log(i),
                },
              ]}
            />
          </LoadingWrapper>
        </div>
      </DefaultPagePaddingAndWidth>
    </>
  );
}
