import { gql } from "@apollo/client";

const GET_BILLS = gql`
  query listBills(
    $skip: Int
    $after: String
    $first: Int
    $where: JSON
    $orderBy: BillOrder
  ) {
    data: listBills(
      skip: $skip
      after: $after
      first: $first
      where: $where
      orderBy: $orderBy
    ) {
      edges {
        cursor
        node {
          id
          dueOn
          billDate
          totalDue
          balance
          currency
          status
          project {
            id
            name
          }
          documentsWithUrls {
            id
            name
            presignedDownloadUrl
            presignedThumbnailUrl
            type
          }
          createdAt
          updatedAt
          inTriageFromIntegration
          externalData {
            externalRelationships {
              sourceIntegration {
                id
                target
              }
            }
          }
        }
      }
      totalCount
      pageInfo {
        hasNextPage
      }
    }
  }
`;

const GET_BILL = gql`
  query getBill($id: String!) {
    data: getBill(id: $id) {
      id
      createdAt
      updatedAt
      currency
      status
      identifier
      dueOn
      billDate
      totalDue
      balance
      currency
      status
      subtotal
      project {
        id
        name
      }
      lineItems {
        description
        rate
        amount
        quantity
        identifier
      }
      inTriageFromIntegration
      externalData {
        externalRelationships {
          sourceIntegration {
            id
            target
          }
        }
      }
    }
  }
`;

export { GET_BILLS, GET_BILL };
