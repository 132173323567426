import { gql } from "@apollo/client";

const GET_COMMISSION_PLANS = gql`
  query listCommissionPlans(
    $skip: Int
    $after: String
    $first: Int
    $query: String
    $orderBy: CommissionPlanOrder
  ) {
    data: listCommissionPlans(
      skip: $skip
      after: $after
      first: $first
      query: $query
      orderBy: $orderBy
    ) {
      edges {
        cursor
        node {
          id
          createdAt
          updatedAt
          name
          description
          versions {
            id
            createdAt
            updatedAt
          }
        }
      }
      totalCount
      pageInfo {
        hasNextPage
      }
    }
  }
`;

const GET_COMMISSION_ENTRIES = gql`
  query listCommissionEntries(
    $skip: Int
    $after: String
    $first: Int
    $query: String
    $orderBy: CommissionEntryOrder
  ) {
    data: listCommissionEntries(
      skip: $skip
      after: $after
      first: $first
      query: $query
      orderBy: $orderBy
    ) {
      edges {
        cursor
        node {
          id
          createdAt
          updatedAt
          value
          currency
          commissionAssignment {
            employeeId
            employee {
              id
              firstName
              lastName
            }
            providerPersonnelId
            providerPersonnel {
              id
              firstName
              lastName
            }
          }
        }
      }
      totalCount
      pageInfo {
        hasNextPage
      }
    }
  }
`;

const GET_COMMISSION_ASSIGNMENTS = gql`
  query listCommissionAssignments(
    $skip: Int
    $after: String
    $first: Int
    $query: String
    $orderBy: CommissionAssignmentOrder
  ) {
    data: listCommissionAssignments(
      skip: $skip
      after: $after
      first: $first
      query: $query
      orderBy: $orderBy
    ) {
      edges {
        cursor
        node {
          id
          createdAt
          updatedAt
          target
          type
          planVersion {
            id
            commissionPlan {
              name
              description
            }
          }
          entries {
            value
            currency
            paidDate
            status
          }
          employeeId
          employee {
            id
            firstName
            lastName
          }
          providerPersonnelId
          providerPersonnel {
            id
            firstName
            lastName
          }
          employeeTargetId
          employeeTarget {
            id
            firstName
            lastName
          }
          providerPersonnelTargetId
          providerPersonnelTarget {
            id
            firstName
            lastName
          }
        }
      }
      totalCount
      pageInfo {
        hasNextPage
      }
    }
  }
`;

const GET_COMMISSION_ASSIGNMENT = gql`
  query getCommissionAssignment($id: String!) {
    data: getCommissionAssignment(id: $id) {
      id
      createdAt
      updatedAt
      target
      type
      planVersion {
        id
        identifier
        strategies {
          strategyCalculationPercentage
          strategyCalculationFixedValue
          strategyCalculationPayoutCurrency
          strategyCalculationType
          strategyCalculationScope
          strategyCalculationTimePeriod
          strategyMinimumRetainedQuantity
          strategyMinimumRetainedUnits
          strategyMinimumRetainedType
        }
        commissionPlan {
          name
        }
      }
      entries {
        value
        currency
        paidDate
        status
      }
      employeeId
      employee {
        id
        firstName
        lastName
      }
      providerPersonnelId
      providerPersonnel {
        id
        firstName
        lastName
      }
      employeeTargetId
      employeeTarget {
        id
        firstName
        lastName
      }
      providerPersonnelTargetId
      providerPersonnelTarget {
        id
        firstName
        lastName
      }
    }
  }
`;

export {
  GET_COMMISSION_ENTRIES,
  GET_COMMISSION_PLANS,
  GET_COMMISSION_ASSIGNMENTS,
  GET_COMMISSION_ASSIGNMENT,
};
