import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { DefaultDataMachineState } from "../../data/machines/queries";

export interface LoadingWrapperProps {
  children: React.ReactNode;
  loadingType?: "spinner" | "skeleton";
  state: DefaultDataMachineState;
  skeletonCount?: number;
  loadingClassName?: string;
  loadingWrapperClassName?: string;
}

export function LoadingWrapper({
  children,
  state,
  loadingType = "spinner",
  skeletonCount = 10,
  loadingClassName,
  loadingWrapperClassName,
}: LoadingWrapperProps): JSX.Element {
  return (
    <>
      {state === "loading" && (
        <div className={loadingWrapperClassName}>
          {loadingType === "spinner" && (
            <div
              className={
                "py-10 w-full align-center items-center flex flex-row justify-center relative text-center " +
                loadingClassName
              }
            >
              <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12"></div>
            </div>
          )}
          {loadingType === "skeleton" && (
            <Skeleton
              className={loadingClassName}
              style={{ fontSize: "30px", marginBottom: "10px" }}
              count={skeletonCount}
            />
          )}
        </div>
      )}
      {state === "completed" && children}
    </>
  );
}
