import { gql } from "@apollo/client";

const GET_JOB_POSTS = gql`
  query listJobPosts(
    $skip: Int
    $after: String
    $first: Int
    $query: String
    $orderBy: JobPostOrder
  ) {
    data: listJobPosts(
      skip: $skip
      after: $after
      first: $first
      query: $query
      orderBy: $orderBy
    ) {
      edges {
        cursor
        node {
          id
          title
          published
          createdAt
          updatedAt
        }
      }
      totalCount
      pageInfo {
        hasNextPage
      }
    }
  }
`;

const GET_JOB_POST = gql`
  query getJobPost($id: String!) {
    data: getJobPost(id: $id) {
      id
      title
      createdAt
      updatedAt
    }
  }
`;

export { GET_JOB_POSTS, GET_JOB_POST };
