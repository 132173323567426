import { ReactNode, useState } from "react";
import { MAX_INNER_PAGE_WIDTH } from "../../data/constants";

interface HeroCardGridProps {
  sections: HeroCardGridSection[];
}

interface HeroCardGridSection {
  customElement?: ReactNode; // Overrides the other stuff
  label?: string;
  value?: any;
  badgeColor?: string;
  badgeText?: string;
  badgeDirectionIcon?: string;
  icon?: any;
  previousValue?: any;
}

function HeroCardGrid({ sections }: HeroCardGridProps) {
  const renderStatBlock = (stat: HeroCardGridSection) => {
    return (
      <div className="flex flex-col">
        <dd className="flex items-baseline md:block lg:flex">
          {stat.customElement}
        </dd>
      </div>
    );
  };

  return (
    <div
      style={{ width: "300px", minWidth: "300px" }}
      className="bg-white h-full border mr-5 flex flex-col rounded-lg overflow-hidden shadow-sm"
    >
      {sections.map((a: any) => renderStatBlock(a))}
    </div>
  );
}

export default HeroCardGrid;
