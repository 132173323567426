export interface CheckboxProps {
  label?: any;
  style?: any;
  className?: string;
  checked?: boolean;
  onChange?: any;
}
export const Checkbox = ({
  label,
  style,
  className,
  checked,
  onChange,
}: CheckboxProps) => {
  return (
    <div className="flex flex-row items-center w-full">
      <input
        style={style}
        checked={checked}
        onChange={(a) => onChange(a.target.checked)}
        className={
          (checked
            ? "w-5 h-5 shadow-md text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 "
            : "w-5 h-5 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600") +
          className
        }
        type="checkbox"
      />
      {label && <label className="ml-2 text-xs text-gray-600">{label}</label>}
    </div>
  );
};
