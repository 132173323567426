import { ClockIcon } from "@heroicons/react/24/outline";
import React, { memo } from "react";

import { Handle, Position } from "react-flow-renderer";
import { Dropdown } from "../../../basics/Dropdown/Dropdown";

export default memo(({ data, id }: any) => {
  return (
    <>
      {data.options?.nodeConnectionType !== "input" && (
        <Handle
          type="target"
          position={Position.Left}
          style={{ background: "#555" }}
        />
      )}
      <div className="flex flex-row align-middle">
        {data.icon && <data.icon width={22} height={22} />}
        <div>{data.label}</div>
      </div>
      <Dropdown
        additionalClasses={
          "relative block w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 px-3 text-left cursor-pointer focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
        }
        onChange={(e: any) => {
          console.log(e);
          data.onChange({ ...e, id });
        }}
        placeholder={data.options?.placeholder}
        selected={data.options?.selected || null}
        options={data.options?.dropdownOptions || []}
      />
      <Handle type="source" position={Position.Right} id="b" />
    </>
  );
});
