import { TableColumn } from "../../basics/Table/Table";
import { FormConfiguration } from "./Form";
import { DetailViewConfiguration } from "../../components/BaseDetailView/BaseDetailView";

export interface JobPost {
  id: string;
  tenantId: string;
  createdAt: Date;
  updatedAt: Date;
  title: string;
}

export const JOB_POST_DETAIL_CONFIGURATION: DetailViewConfiguration = {
  sections: [
    {
      title: "Basic Information",
      subtitle: "Some basic info about your Provider",
      fields: [
        {
          key: "title",
          label: "Title",
        },
      ],
    },
  ],
};

export const JOB_POST_TABLE_CONFIGURATION: TableColumn[] = [
  {
    key: "title",
    label: "Title",
    displayType: "link",
    linkToKey: "id",
    linkToPrefix: "/jobposts/",
    styles: "text-gray-600 font-medium",
  },
  {
    key: "baseRate",
    label: "Base Billable Rate",
    type: "rate",
    typeConfiguration: {
      rateUnitKey: "rateCurrency",
      rateTimeUnitKey: "rateTimeUnit",
    },
  },
  {
    key: "baseCostRate",
    label: "Base Cost Rate",
    type: "rate",
    typeConfiguration: {
      rateUnitKey: "costRateCurrency",
      rateTimeUnitKey: "costRateTimeUnit",
    },
  },
];

export const JOB_POST_FORM_CONFIGURATION: FormConfiguration = {
  requiredOptionsLoads: ["customers"],
  fields: [
    {
      name: "name",
      type: "textInput",
      validation: { required: true },
      props: {
        label: "Provider Name",
        width: 3,
      },
    },
    {
      name: "customerId",
      type: "select",
      optionsPlaceholder: "customers",
      isNotEditable: true,
      validation: { required: true },
      props: {
        label: "Customer",
        width: 3,
      },
    },
  ],
};
