import { PlusIcon, TrashIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { Fragment, useState } from "react";
import { IconButton } from "../../basics/Buttons/IconButton";
import { DefaultPagePaddingAndWidth } from "../../basics/DefaultPagePaddingAndWidth/DefaultPagePaddingAndWidth";
import { DropdownButton } from "../../basics/DropdownButton/DropdownButton";
import { LoadingWrapper } from "../../basics/LoadingWrapper/LoadingWrapper";
import { PageTitle } from "../../basics/PageTitle/PageTitle";
import Pagination from "../../basics/Pagination/Pagination";
import Table, { TableAction, TableColumn } from "../../basics/Table/Table";
import { DefaultDataMachineState } from "../../data/machines/queries";
import { PaginatedResponse } from "../../data/types/PaginatedResponse";
import { formatBaseItemForList } from "../../utilities/dates";
import PageHeader from "../PageHeader/PageHeader";

interface ListViewProps {
  data?: PaginatedResponse<any>;
  state: DefaultDataMachineState;
  error?: any;
  columns: TableColumn[];
  actions?: TableAction[];
  title: string;
  entityName?: string;
  cursor: number;
  setCursor: any;
  onAddNew?: any;
  onSelectItem?: any;
  onClickTriage?: any;
  disableHeader?: boolean;
  heroComponent?: any;
  sideComponent?: any;
  onDelete?: any;
}

function ListView({
  columns,
  actions,
  data,
  state,
  error,
  title,
  cursor,
  setCursor,
  onAddNew,
  entityName,
  onSelectItem,
  onClickTriage,
  disableHeader,
  heroComponent,
  sideComponent,
  onDelete,
}: ListViewProps) {
  const [selectionModeEnabled, setSelectionModeEnabled] = useState(false);
  const [selectedItems, setSelectedItems] = useState<any[]>([]);

  return (
    <div className="flex flex-row h-full">
      {sideComponent ? sideComponent : <></>}
      <div
        className="mx-auto"
        style={sideComponent ? { maxWidth: "calc(100vw - 480px)" } : {}}
      >
        {!disableHeader && (
          <PageHeader
            disableBreadcrumbs={true}
            disableBg={true}
            disableShadow={true}
            disableBorder
            sticky={true}
            leftContent={<PageTitle title={title} />}
            rightContent={
              <div className="flex flex-row items-center">
                {selectionModeEnabled ? (
                  <>
                    <IconButton
                      icon={TrashIcon}
                      onClick={() => {
                        onDelete(selectedItems);
                        setSelectionModeEnabled(false);
                      }}
                      classNames="mr-3"
                      text="Delete Selected"
                      color="red"
                    />
                    <IconButton
                      icon={XMarkIcon}
                      onClick={() => setSelectionModeEnabled(false)}
                      classNames=""
                      text="Cancel Deletion"
                      color="gray"
                    />
                  </>
                ) : (
                  <>
                    <IconButton
                      icon={PlusIcon}
                      onClick={onAddNew}
                      text={"New"}
                      classNames="mr-3"
                    />
                    <DropdownButton
                      items={[
                        {
                          text: "Triage",
                          onClick: () => console.log("triage"),
                        },
                        {
                          text: "Delete Many",
                          color: "red",
                          onClick: () => setSelectionModeEnabled(true),
                        },
                      ]}
                    />
                  </>
                )}
              </div>
            }
          />
        )}
        <DefaultPagePaddingAndWidth>
          {heroComponent ? heroComponent : <></>}
          <LoadingWrapper loadingWrapperClassName="mt-3" state={state}>
            <>
              {data?.totalCount === 0 ? (
                <div
                  style={{
                    width: "700px",
                    maxWidth: "100%",
                    marginTop: "10vh",
                  }}
                  className="text-xl mx-auto align-center items-center flex flex-row justify-center font-semibold text-gray-400"
                >
                  <div className="flex flex-1 border-gray-300 border-b-2 border-dashed h-1" />
                  <div className="px-4">NO RECORDS</div>
                  <div className="flex flex-1 border-gray-300 border-b-2 border-dashed h-1" />
                </div>
              ) : (
                <div className="py-3 w-full relative">
                  <Table
                    data={data?.edges?.map((n) =>
                      formatBaseItemForList(n.node)
                    )}
                    start={cursor ? Number(cursor) : 1}
                    end={data ? data?.totalCount + Number(cursor) : 0}
                    columns={columns}
                    onChangePage={setCursor}
                    actions={actions}
                    onSelectItem={onSelectItem}
                    triageEnabled={true}
                    onClickTriage={onClickTriage}
                    selectionMode={selectionModeEnabled}
                    onSelectItems={(items: any) => {
                      console.log("currently selected", items);
                      setSelectedItems(items);
                    }}
                    selectedItems={selectedItems}
                    paginationComponent={
                      <Pagination
                        page={1}
                        pages={1}
                        count={
                          data?.edges?.map((n) => formatBaseItemForList(n.node))
                            ?.length || 0
                        }
                        start={cursor ? Number(cursor) : 1}
                        end={data ? data?.totalCount + Number(cursor) : 0}
                        onChangePage={setCursor}
                      />
                    }
                  />
                </div>
              )}
            </>
          </LoadingWrapper>
        </DefaultPagePaddingAndWidth>
      </div>
    </div>
  );
}

export default ListView;
