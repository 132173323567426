import { BaseModalProps } from "../../basics/ModalWrapper/ModalWrapper";
import MinimalForm from "../../components/MinimalForm/MinimalForm";
import { useForm } from "react-hook-form";
import SlideOverWrapper from "../../basics/SlideOverWrapper/SlideOverWrapper";
import { useEffect } from "react";
import { FormFieldConfiguration } from "../../data/types/Form";
import { BUILD_JOB_ROLE_ASSIGNMENT_FORM_CONFIGURATION } from "../../data/types/JobRoleAssignment";
import { formatJobRoleForOptionsList, JobRole } from "../../data/types/JobRole";
import { EntityUnchangeableDataBlock } from "../../basics/EntityStaticDataBlock/EntityStaticDataBlock";
import { Project } from "../../data/types/Project";
import { Link } from "react-router-dom";

export interface JobRoleAssignmentAddEditSlideOverProps extends BaseModalProps {
  toEdit?: any;
  jobRoles?: JobRole[];
  project: Project;
}

function JobRoleAssignmentAddEditSlideOver({
  open,
  setOpen,
  onSubmit,
  toEdit,
  jobRoles,
  project,
}: JobRoleAssignmentAddEditSlideOverProps) {
  let initialValues: any = {};

  const form = useForm<FormData>({ defaultValues: initialValues });
  const { errors, setValue, handleSubmit, watch } = form;

  /**
   * Set Initial Values for Form
   */
  useEffect(() => {
    if (toEdit && open) {
      setTimeout(() => {
        Object.keys(toEdit).forEach((k) => {
          // Handle dates
          if (k === "recruitingStartDate" || k === "targetFillDate") {
            setValue(k, new Date(toEdit[k]));
          } else {
            setValue(k, toEdit[k]);
          }
        });
      });
    }
  }, [toEdit, open]);

  /* SET FIELDS */
  let fields: FormFieldConfiguration[] =
    BUILD_JOB_ROLE_ASSIGNMENT_FORM_CONFIGURATION(toEdit).fields;

  const submitForm = (data: any) => {
    return toEdit ? onSubmit(data, toEdit.id) : onSubmit(data);
  };

  const renderEdit = () => {
    return (
      <MinimalForm
        fields={fields}
        optionsMap={{
          jobRoles: jobRoles?.map((r: JobRole) =>
            formatJobRoleForOptionsList(r)
          ),
        }}
        submitText={"Submit"}
        form={form}
        onSubmit={submitForm}
      />
    );
  };

  return (
    <SlideOverWrapper
      title={`Add or edit Job Role Needs`}
      open={open}
      setOpen={setOpen}
    >
      {toEdit && (
        <EntityUnchangeableDataBlock
          title={
            <>
              Role:{" "}
              <Link to={"/jobroles/" + toEdit?.role?.id}>
                <a className="text-blue-700 underline font-bold">
                  {toEdit?.role?.name || ""}
                </a>
              </Link>
            </>
          }
          subtitle={"Needed for " + project.name}
        />
      )}
      {renderEdit()}
    </SlideOverWrapper>
  );
}

export default JobRoleAssignmentAddEditSlideOver;
