export interface EntityUnchangeableDataBlock {
  title: any;
  subtitle?: string;
}

export function EntityUnchangeableDataBlock({
  title,
  subtitle,
}: EntityUnchangeableDataBlock) {
  return (
    <div className="border-t border-b bg-blue-100 px-6 py-4">
      <div className="text-lg font-medium">{title}</div>
      {subtitle && <div className="text-sm mt-3 italic">{subtitle}</div>}
    </div>
  );
}
