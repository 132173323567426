import { TableColumn } from "../../basics/Table/Table";
import { FormConfiguration } from "./Form";
import { DetailViewConfiguration } from "../../components/BaseDetailView/BaseDetailView";

export interface JobRole {
  id: string;
  tenantId: string;
  createdAt: Date;
  updatedAt: Date;
  name: string;
}

export interface JobRoleCreateUpdateArgs {
  name?: string;
}

export const formatJobRoleForOptionsList = (e: JobRole) => {
  return {
    label: e.name,
    value: e.id,
  };
};

export const BUILD_JOB_ROLE_FORM_CONFIGURATION = (
  isEdit: boolean
): FormConfiguration => {
  return {
    fields: [
      {
        name: "name",
        type: "textInput",
        validation: { required: true },
        props: {
          label: "Name",
          width: 6,
        },
      },
    ],
  };
};

export const JOB_ROLE_DETAIL_CONFIGURATION: DetailViewConfiguration = {
  sections: [
    {
      title: "Basic Information",
      subtitle: "Some basic info about your Provider",
      fields: [
        {
          key: "name",
          label: "Name",
        },
      ],
    },
  ],
};

export const JOB_ROLE_TABLE_CONFIGURATION: TableColumn[] = [
  {
    key: "name",
    label: "Name",
    displayType: "link",
    linkToKey: "id",
    linkToPrefix: "/jobroles/",
    styles: "text-gray-600 font-medium",
  },
  {
    key: "openSlots",
    label: "Number of Open Slots",
  },
  {
    key: "baseRate",
    label: "Base Billable Rate",
    type: "rate",
    typeConfiguration: {
      rateUnitKey: "rateCurrency",
      rateTimeUnitKey: "rateTimeUnit",
    },
  },
  {
    key: "baseCostRate",
    label: "Base Cost Rate",
    type: "rate",
    typeConfiguration: {
      rateUnitKey: "costRateCurrency",
      rateTimeUnitKey: "costRateTimeUnit",
    },
  },
];

export const JOB_ROLE_FORM_CONFIGURATION: FormConfiguration = {
  requiredOptionsLoads: ["customers"],
  fields: [
    {
      name: "name",
      type: "textInput",
      validation: { required: true },
      props: {
        label: "Provider Name",
        width: 3,
      },
    },
    {
      name: "customerId",
      type: "select",
      optionsPlaceholder: "customers",
      isNotEditable: true,
      validation: { required: true },
      props: {
        label: "Customer",
        width: 3,
      },
    },
  ],
};
