import { TableColumn } from "../../basics/Table/Table";
import { DetailViewConfiguration } from "../../components/BaseDetailView/BaseDetailView";
import { BaseApiEntity } from "./base/BaseEntity";
import { FormConfiguration } from "./Form";
import { MergeConfig } from "./MergeEntities";
import {
  CURRENCIES,
  CURRENCIES_OPTIONS,
  EMPLOYEE_STATUSES_OPTIONS,
  RATE_TIMEUNITS,
  RATE_TIMEUNITS_OPTIONS,
} from "./Shared";

export interface Employee extends BaseApiEntity {
  firstName: string;
  lastName: string;
  baseRate?: number;
  rateCurrency?: CURRENCIES;
  rateTimeUnit?: RATE_TIMEUNITS;
  baseCostRate?: number;
  costRateCurrency?: CURRENCIES;
  costRateTimeUnit?: RATE_TIMEUNITS;
  customFields: object;
  status: string;
}

export const formatEmployeeForOptionsList = (e: Employee) => {
  return {
    label: e.firstName + " " + e.lastName,
    value: e.id,
  };
};

export const EMPLOYEE_DETAIL_CONFIGURATION: DetailViewConfiguration = {
  sections: [
    {
      title: "Basic Information",
      subtitle: "Some basic info about this Billable Resource",
      fields: [
        {
          key: "firstName",
          label: "First Name",
        },
        {
          key: "lastName",
          label: "Last Name",
        },
        {
          key: "baseRate",
          label: "Base Billable Rate",
          type: "rate",
          typeConfiguration: {
            rateUnitKey: "rateCurrency",
            rateTimeUnitKey: "rateTimeUnit",
          },
        },
        {
          key: "baseCostRate",
          label: "Base Cost Rate",
          type: "rate",
          typeConfiguration: {
            rateUnitKey: "costRateCurrency",
            rateTimeUnitKey: "costRateTimeUnit",
          },
        },
        {
          key: "fringeCostSet.name",
          label: "Fringe Cost Set",
          type: "composite",
        },
      ],
    },
  ],
};

export const EMPLOYEE_TABLE_CONFIGURATION: TableColumn[] = [
  {
    key: "firstName + lastName",
    label: "Name",
    type: "composite",
    displayType: "link",
    linkToKey: "id",
    linkToPrefix: "/employees/",
    styles: "text-gray-600 font-medium",
  },
  {
    key: "status",
    label: "Status",
    rowStyleKey: "badgeColor",
    styles:
      "inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium",
    displayType: "badge",
  },
  {
    key: "baseRate",
    label: "Base Billable Rate",
    type: "rate",
    typeConfiguration: {
      rateUnitKey: "rateCurrency",
      rateTimeUnitKey: "rateTimeUnit",
    },
  },
  {
    key: "baseCostRate",
    label: "Base Cost Rate",
    type: "rate",
    typeConfiguration: {
      rateUnitKey: "costRateCurrency",
      rateTimeUnitKey: "costRateTimeUnit",
    },
  },
  {
    key: "updatedAt",
    label: "Updated At",
  },
  {
    key: "createdAt",
    label: "Created At",
  },
];

export const BUILD_EMPLOYEE_FORM_CONFIGURATION = (
  toEdit: any
): FormConfiguration => {
  return {
    fields: [
      {
        name: "Basic Info",
        type: "divider",
      },
      {
        name: "firstName",
        type: "textInput",
        validation: { required: true },
        props: {
          label: "First Name",
          width: 3,
        },
      },
      {
        name: "lastName",
        type: "textInput",
        validation: { required: true },
        props: {
          label: "Last Name",
          width: 3,
        },
      },
      {
        name: "status",
        type: "select",
        options: EMPLOYEE_STATUSES_OPTIONS,
        placeholder: "Status",
        validation: { required: true },
        props: {
          label: "Status",
          width: 3,
        },
      },
      {
        name: "Financials",
        type: "divider",
      },
      {
        name: "baseRate",
        type: "textInput",
        placeholder: "Base Bill Rate",
        validation: { valueAsNumber: true },
        props: {
          label: "Base Bill Rate",
          width: 3,
          subType: "number",
        },
      },
      {
        name: "rateTimeUnit",
        type: "select",
        options: RATE_TIMEUNITS_OPTIONS,
        placeholder: "Rate Per Period",
        validation: {},
        props: {
          label: "Per",
          width: 3,
        },
      },
      {
        name: "baseCostRate",
        type: "textInput",
        placeholder: "Base Cost Rate (salary)",
        validation: { valueAsNumber: true },
        props: {
          label: "Base Cost Rate (salary)",
          width: 3,
          subType: "number",
        },
      },
      {
        name: "costRateTimeUnit",
        type: "select",
        options: RATE_TIMEUNITS_OPTIONS,
        placeholder: "Rate Per Period",
        validation: {},
        props: {
          label: "Per",
          width: 3,
        },
      },
      {
        name: "rateCurrency",
        type: "select",
        options: CURRENCIES_OPTIONS,
        placeholder: "Rate Currency",
        validation: { required: true },
        props: {
          label: "Rate Currency",
          width: 3,
        },
      },
      {
        name: "costRateCurrency",
        type: "select",
        options: CURRENCIES_OPTIONS,
        placeholder: "Cost Rate Currency",
        validation: { required: true },
        props: {
          label: "Cost Rate Currency",
          width: 3,
        },
      },
      {
        name: "fringeCostSetId",
        type: "select",
        optionsPlaceholder: "fringeCostSets",
        placeholder: "Select a Fringe Cost Set",
        validation: { required: false },
        props: {
          label: "Fringe Cost Set",
          width: 6,
        },
      },
    ],
  };
};

export const EMPLOYEE_MERGE_CONFIGURATION: MergeConfig = {
  fields: [
    {
      type: "SELECT_ONE",
      tableField: {
        key: "firstName",
        label: "First Name",
      },
      formField: {
        name: "name",
        type: "textInput",
        validation: { required: true },
        props: {
          label: "First Name",
          width: 3,
        },
      },
    },
    {
      type: "SELECT_ONE",
      tableField: {
        key: "lastName",
        label: "Last Name",
      },
      formField: {
        name: "lastName",
        type: "textInput",
        validation: { required: true },
        props: {
          label: "Last Name",
          width: 3,
        },
      },
    },
    {
      type: "SELECT_ONE",
      tableField: {
        key: "baseRate",
        label: "Base Billable Rate",
        type: "rate",
        typeConfiguration: {
          rateUnitKey: "rateCurrency",
          rateTimeUnitKey: "rateTimeUnit",
        },
      },
      formField: {
        name: "rate",
        type: "textInput",
        placeholder: "Base Bill Rate",
        validation: { valueAsNumber: true },
        props: {
          label: "Base Bill Rate",
          width: 3,
          subType: "number",
        },
      },
    },
    {
      type: "SELECT_ONE",
      hideInTable: true,
      formField: {
        name: "rateTimeUnit",
        type: "select",
        options: RATE_TIMEUNITS_OPTIONS,
        placeholder: "Rate Per Period",
        validation: {},
        props: {
          label: "Per",
          width: 3,
        },
      },
    },
    {
      type: "SELECT_ONE",
      tableField: {
        key: "baseCostRate",
        label: "Base Cost Rate",
        type: "rate",
        typeConfiguration: {
          rateUnitKey: "costRateCurrency",
          rateTimeUnitKey: "costRateTimeUnit",
        },
      },
      formField: {
        name: "costRate",
        type: "textInput",
        placeholder: "Cost Rate/Salary",
        validation: { valueAsNumber: true },
        props: {
          label: "Cost Rate/Salary",
          width: 3,
          subType: "number",
        },
      },
    },
    {
      type: "SELECT_ONE",
      hideInTable: true,
      formField: {
        name: "costRateTimeUnit",
        type: "select",
        options: RATE_TIMEUNITS_OPTIONS,
        placeholder: "Cost Rate Per Period",
        validation: {},
        props: {
          label: "Per",
          width: 3,
        },
      },
    },
  ],
};
