import { useLazyQuery, useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import AlertsWorkflowCard from "../../components/AlertsWorkflowsCard/AlertsWorkflowCard";
import { AreaGraphCard } from "../../components/AreaGraphCard/AreaGraphCard";
import { ProjectsOverviewCard } from "../../components/ProjectsOverviewCard/ProjectsOverviewCard";
import { GET_PORTFOLIO_FINANCIALS } from "../../data/queries/projects";
import PortfolioUtilizationCard from "../../components/PortfolioUtilizationCard/PortfolioUtilizationCard";
import { TasksButton } from "../../components/TasksButton/TasksButton";
import { Responsive, WidthProvider } from "react-grid-layout";
import { ChevronDownIcon, PlusCircleIcon } from "@heroicons/react/24/solid";
import { SecondarySidemenu } from "../../components/SecondarySidemenu/SecondarySidemenu";
import PageHeader from "../../components/PageHeader/PageHeader";
import { DefaultPagePaddingAndWidth } from "../../basics/DefaultPagePaddingAndWidth/DefaultPagePaddingAndWidth";
import SidemenuClearListTabs from "../../components/SidemenuClearListTabs/SidemenuClearListTabs";
const ResponsiveReactGridLayout = WidthProvider(Responsive);

const data = [
  {
    name: "Jan",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Feb",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "Mar",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "Apr",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "May",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "Jun",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "Jul",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

const CustomTooltip = ({ active, payload, label }: any) => {
  if (active) {
    if (!payload || !payload[0]) {
      return <div />;
    }
    return (
      <div className="bg-white sm:rounded-md shadow p-2">
        <p className="label">{`Monthly Revenue : $ ${payload[0].value}`}</p>
      </div>
    );
  }

  return null;
};

export function Dashboard() {
  const [getPortfolioFinancials, portfolioData] = useLazyQuery(
    GET_PORTFOLIO_FINANCIALS
  );
  const [isAlertsOpen, setAlertsOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [dashboard, setDashboard]: any = useState("dashboard");

  useEffect(() => {
    getPortfolioFinancials({
      variables: {
        numberOfDays: 30,
      },
    });
  }, []);

  if (portfolioData) {
    console.log(portfolioData);
  }

  // initial layout
  const layout = [
    { i: "a", w: 6, h: 6, x: 0, y: 0 },
    { i: "b", w: 6, h: 6, x: 6, y: 0 },
    { i: "c", w: 6, h: 6, x: 0, y: 6 },
    { i: "d", w: 6, h: 6, x: 6, y: 6 },
  ];

  return (
    <>
      <div className="flex flex-row h-full relative">
        <DefaultPagePaddingAndWidth className="pt-7">
          <ResponsiveReactGridLayout
            className="layout"
            layouts={{ lg: layout }}
            margin={[10, 10]}
            containerPadding={[10, 10]}
            isResizable={false}
            cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
            rowHeight={52}
          >
            <div key="a">
              <ProjectsOverviewCard
                classNames={"w-full h-full"}
                title={"Portfolio Overview"}
                data={portfolioData?.data?.data?.future}
              />
            </div>
            <div key="b">
              <AlertsWorkflowCard
                classNames={"w-full h-full"}
                title={"Current & Upcoming Tasks"}
              ></AlertsWorkflowCard>
            </div>
            <div key="c">
              <AreaGraphCard
                classNames={"w-full h-full"}
                title={"Revenue - Projection vs Actuals"}
                tooltip={
                  "Shows both Kenso Projected Revenue vs Actual Revenue Invoiced"
                }
                areaDataKey="uv"
                xAxisDataKey="name"
                yAxisUnit="$"
                data={data}
                CustomTooltip={CustomTooltip}
              />
            </div>
            <div key="d">
              <PortfolioUtilizationCard
                classNames={"w-full h-full"}
                tooltip={
                  "Shows both Kenso Projected Revenue vs Actual Revenue Invoiced"
                }
              />
            </div>
          </ResponsiveReactGridLayout>
        </DefaultPagePaddingAndWidth>
      </div>
    </>
  );
}
