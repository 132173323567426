/**
 * Shared
 */

import { FormFieldOption } from "../../data/types/Form";

export type UTILIZATION_AMOUNT_UNITS = "HOUR" | "WEEK" | "DAY" | "YEAR";

export const UTILIZATION_AMOUNT_UNITS_OPTIONS: FormFieldOption[] = [
  {
    value: "WEEK",
    label: "Week",
  },
  {
    value: "HOUR",
    label: "Hour",
  },
  {
    value: "DAY",
    label: "Day",
  },
  {
    value: "YEAR",
    label: "Year",
  },
];

export const UTILIZATION_AMOUNT_UNITS_OPTIONS_PLURAL: FormFieldOption[] = [
  {
    value: "WEEK",
    label: "Weeks",
  },
  {
    value: "HOUR",
    label: "Hours",
  },
  {
    value: "DAY",
    label: "Days",
  },
  {
    value: "YEAR",
    label: "Years",
  },
];

export const RATE_TIMEUNITS_OPTIONS: FormFieldOption[] = [
  {
    value: "ONCE",
    label: "One Time",
  },
  {
    value: "HOURLY",
    label: "Per Hour",
  },
  {
    value: "DAILY",
    label: "Per Day",
  },
  {
    value: "WEEKLY",
    label: "Per Week",
  },
  {
    value: "MONTHLY",
    label: "Per Month",
  },
  {
    value: "YEARLY",
    label: "Per Year",
  },
];

export enum RESOURCE_ASSIGNMENT_STATUSES {
  PROPOSED = "PROPOSED",
  PENDING = "PENDING",
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export const RESOURCE_ASSIGNMENT_STATUSES_OPTIONS: FormFieldOption[] = [
  {
    value: "PROPOSED",
    label: "Proposed",
  },
  {
    value: "PENDING",
    label: "Pending",
  },
  {
    value: "ACTIVE",
    label: "Active",
  },
  {
    value: "INACTIVE",
    label: "Inactive",
  },
];

export enum EMPLOYEE_STATUSES {
  PENDING = "PENDING",
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export const EMPLOYEE_STATUSES_OPTIONS: FormFieldOption[] = [
  {
    value: "PENDING",
    label: "Pending",
  },
  {
    value: "ACTIVE",
    label: "Active",
  },
  {
    value: "INACTIVE",
    label: "Inactive",
  },
];

export const YES_NO_BOOLEAN_OPTIONS: FormFieldOption[] = [
  {
    value: true,
    label: "Yes",
  },
  {
    value: false,
    label: "No",
  },
];

export type CURRENCIES = "USD" | "EUR" | "JPY";

export const CURRENCIES_OPTIONS: FormFieldOption[] = [
  {
    value: "USD",
    label: "US Dollar",
  },
];

export type BILLABLE_RESOURCE_SOURCE = "PROViDER" | "EMPLOYEE";

export type RATE_TIMEUNITS =
  | "ONCE"
  | "HOURLY"
  | "DAILY"
  | "WEEKLY"
  | "MONTHLY"
  | "YEARLY";

export const CUSTOMER_STATUSES_OPTIONS: FormFieldOption[] = [
  {
    value: "ACTIVE",
    label: "Active",
  },
  {
    value: "INACTIVE",
    label: "Inactive",
  },
  {
    value: "LEAD",
    label: "Lead",
  },
  {
    value: "RECRUITING",
    label: "Recruiting",
  },
];

export type CUSTOMER_STATUSES = "ACTIVE" | "INACTIVE" | "LEAD" | "RECRUITING";
