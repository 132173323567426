import { useLazyQuery, useQuery } from "@apollo/client";
import ListView from "../../components/ListView/ListView";
import { GET_USERS } from "../../data/queries/users";
import { PaginatedResponse } from "../../data/types/PaginatedResponse";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { User, USER_TABLE_CONFIGURATION } from "../../data/types/User";
import Row from "../../basics/Row";
import Column from "../../basics/Column";
import LeftTabsList from "../../components/LeftTabsList/LeftTabsList";
import {
  CalculatorIcon,
  CurrencyDollarIcon,
  UsersIcon,
} from "@heroicons/react/24/solid";
import {
  GET_CHARGEBEE_HOSTED_PAGE,
  GET_TENANT_SUBSCRIPTION,
} from "../../data/queries/tenant";
import { DefaultPagePaddingAndWidth } from "../../basics/DefaultPagePaddingAndWidth/DefaultPagePaddingAndWidth";
import { PageTitle } from "../../basics/PageTitle/PageTitle";
import {
  LazyListQuerySM,
  useLazyListQuerySM,
} from "../../data/machines/queries";

declare global {
  var Chargebee: any;
}

export function Settings() {
  const [state, data, send]: LazyListQuerySM<User> =
    useLazyListQuerySM(GET_USERS);
  const history = useHistory();
  const [cursor, setCursor] = useState(null);
  const [tab, setTab] = useState("sub");
  const [cbInstance, setCbInstance]: any = useState(null);
  const [getHostedPage, dataHostedPage] = useLazyQuery(
    GET_CHARGEBEE_HOSTED_PAGE
  );
  const [getSubscription, subscriptionData] = useLazyQuery(
    GET_TENANT_SUBSCRIPTION
  );

  useEffect(() => {
    const cbInstance = Chargebee.init({
      site: "kenso-test", // your test site
      //domain: "https://www.kenso.ai", // this is an optional parameter.
      // Use this, if custom domain is enabled for your site
    });
    setCbInstance(cbInstance);
    getSubscription();
    //getHostedPageForUpgradeData();
  }, []);

  // todo react components for stuff

  /*useEffect(() => {
    if (dataHostedPage?.data?.data?.hostedPageUrl) {
      window.location = dataHostedPage.data.data.hostedPageUrl;
    }
  }, [dataHostedPage]);*/

  // todo right now its for initial checkout...
  const getHostedPageForUpgradeData = () => {
    // get url from backend
    getHostedPage({
      variables: {
        type: "initial",
      },
    });
  };

  useEffect(() => {
    send({
      type: "MAKE_QUERY",
      variables: { cursor },
    });
  }, [cursor]);

  console.log("dataHostedPage", dataHostedPage);

  const subData = subscriptionData?.data?.data;

  return (
    <>
      <Row classNames="h-full">
        <Column>
          <LeftTabsList
            selected={tab}
            onSelect={setTab}
            items={[
              {
                Icon: CurrencyDollarIcon,
                label: "Subscription",
                value: "sub",
              },
              {
                Icon: CalculatorIcon,
                label: "Calculation Settings",
                value: "calc",
              },
              {
                Icon: UsersIcon,
                label: "Users",
                value: "users",
              },
            ]}
          />
        </Column>
        <DefaultPagePaddingAndWidth className="pt-10">
          {tab === "sub" && (
            <div>
              <PageTitle title="Subscription Settings" />
              <div className="p-5 mt-7 text-xl bg-white border">
                <div>Trial End Date: {subData?.trialEndDate}</div>
                <div>In Trial: {subData?.inTrial ? "YES" : "NO"}</div>
              </div>
            </div>
          )}
          {tab === "users" && (
            <ListView
              title={"Users"}
              data={state.value === "completed" ? data : undefined}
              state={state.value}
              error={state.value === "error" ? state.context.error : undefined}
              columns={USER_TABLE_CONFIGURATION}
              onAddNew={() => history.push("/users/add")}
              cursor={cursor || 0}
              setCursor={setCursor}
            />
          )}
        </DefaultPagePaddingAndWidth>
      </Row>
    </>
  );
}
