import * as React from "react";
import { formFields } from "../../data/forms/fields";
import { BaseFormProps, FormFieldConfiguration } from "../../data/types/Form";

interface RegisterFormProps extends BaseFormProps {
  submitText: string;
}

function RegisterForm({
  fields,
  submitText,
  form,
  optionsMap,
  onSubmit,
  form: { register, setValue, handleSubmit, errors, control },
}: RegisterFormProps) {
  const renderSubmitButton = () => {
    return (
      <div className="px-4 py-3 text-left sm:px-6">
        <button
          type="submit"
          className="inline-flex items-center shadow-md px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 "
        >
          {submitText}
        </button>
        <a
          className="inline-flex items-center shadow-md px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 "
          href="javascript:void(0)"
          data-cb-type="checkout"
          data-cb-plan-id="startup-plan"
          data-cb-plan-quantity="1"
        >
          {" "}
          subscribe{" "}
        </a>
      </div>
    );
  };

  const formWrapperClass = "py-6 px-4 space-y-6 sm:p-6";
  const formGridClass = "grid grid-cols-6 gap-6";

  const injectStyling = (field: FormFieldConfiguration) => {
    if (field.type == "textInput") {
      field.props = {
        ...field.props,
        wrapperClass: `col-span-6 sm:col-span-${field?.props?.width || 12}`,
        inputWrapperClass: "",
        labelClass: "block text-sm font-medium text-gray-700",
        errorClass:
          "mt-1 block w-full border border-red-300 text-red-900 placeholder-red-300 shadow-sm py-2 px-3  focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md",
        inputClass:
          "mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm",
      };
    } else if (field.type == "select") {
      field.props = {
        ...field.props,
        wrapperClass: `col-span-6 sm:col-span-${field?.props?.width || 12}`,
        labelClass: "block text-sm font-medium text-gray-700",
        errorClass:
          "mt-1 block w-full border border-red-300 text-red-900 placeholder-red-300 shadow-sm py-2 px-3  focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md",
        inputClass:
          "relative block w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 px-3 text-left cursor-pointer focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm",
      };
    }
    return field;
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={formWrapperClass}>
        <div className={formGridClass}>
          {fields.map((field: FormFieldConfiguration) => {
            field = injectStyling(field);
            const FieldInput: any = formFields[field.type];
            return (
              <FieldInput
                {...field.props}
                options={
                  field.optionsPlaceholder && optionsMap
                    ? optionsMap[field.optionsPlaceholder] || []
                    : []
                }
                name={field.name}
                validation={field.validation}
                placeholder={field.placeholder}
                error={errors[field.name]}
                register={register}
                control={control}
              />
            );
          })}
        </div>
      </div>
      {renderSubmitButton()}
    </form>
  );
}

export default RegisterForm;
