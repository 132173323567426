import { useLazyQuery } from "@apollo/client";
import { BellIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { GET_TASKS } from "../../data/queries/tasks";
import { AlertsSlideOver } from "../AlertsSlideOver/AlertsSlideOver";

export function TasksButton() {
  const [getTasks, { loading, data }] = useLazyQuery(GET_TASKS);
  const [isAlertsOpen, setAlertsOpen] = useState(false);

  useEffect(() => {
    getTasks({
      variables: { cursor: null },
    });
  }, []);

  return (
    <>
      <div
        style={{
          height: "30px",
          width: "30px",
        }}
        onClick={() => setAlertsOpen(true)}
        className="relative flex flex-row items-center align-center justify-center hover:bg-gray-200 text-gray-700 hover:text-black rounded-full cursor-pointer"
      >
        <BellIcon className="text-gray-500" width={22} />
        <div
          style={{ top: "-3px", right: "-6px" }}
          className="bg-red-600 text-white absolute h-4 w-6 text-center text-xs font-semibold rounded-full"
        >
          {data?.data?.edges.length || 0}
        </div>
      </div>
      <AlertsSlideOver
        notifications={data?.data?.edges?.map((e: any) => e.node) || []}
        open={isAlertsOpen}
        setOpen={setAlertsOpen}
      />
    </>
  );
}
