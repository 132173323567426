import React, { createContext, useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Dashboard } from "./pages/Dashboard/Dashboard";
import TopLevelHeader from "./components/TopLevelHeader/TopLevelHeader";
import { Projects } from "./pages/Project/Projects";
import { WorkflowConfigurations } from "./pages/WorkflowConfigurations/WorkflowConfigurations";
import { Customers } from "./pages/Customer/Customers";
import { AddEditProject } from "./pages/Project/AddEditProject";
import { Project } from "./pages/Project/Project";
import { Customer } from "./pages/Customer/Customer";
import { Settings } from "./pages/Settings/Settings";
import { User } from "./pages/Settings/User";
import { Integrations } from "./pages/Integration/Integrations";
import { Invoices } from "./pages/Invoice/Invoices";
import { Employee } from "./pages/Employee/Employee";
import { Employees } from "./pages/Employee/Employees";
import { ItemsAndRateCards } from "./pages/ItemsAndRateCards/ItemsAndRateCards";
import { Register } from "./pages/Register/Register";
import { useLocalStorage, writeStorage } from "@rehooks/local-storage";
import { Toaster } from "react-hot-toast";
import { JobRole } from "./pages/Recruiting/JobRole";
import { JobPosts } from "./pages/Recruiting/JobPosts";
import { ClientDashboard } from "./pages/ClientDashboard/ClientDashboard";
import { Invoice } from "./pages/Invoice/Invoice";
import { JobPost } from "./pages/Recruiting/JobPost";
import { FringeCosts } from "./pages/FringeCosts/FringeCosts";
import { CommissionPlans } from "./pages/CommissionPlans/CommissionPlans";
import { Providers } from "./pages/Provider/Providers";
import { Provider } from "./pages/Provider/Provider";
import { CommissionAssignment } from "./pages/CommissionPlans/CommissionAssignment";
import { Reports } from "./pages/Reports/Reports";
import { V0alpha2Api, Configuration, Session, Identity } from "@ory/client";
import { Scenarios } from "./pages/Scenarios/Scenarios";
import TopLevelSubHeader from "./components/TopLevelSubHeader/TopLevelSubHeader";
import axios from "axios";
import { Bills } from "./pages/Bill/Bills";
import { Expenses } from "./pages/Expenses/Expenses";

export const AuthContext: any = createContext({
  session: {},
  logoutUrl: "",
  currentTenantId: "",
});

function App() {
  // Get your Ory url from .env
  // Or localhost for local development
  const basePath = process.env.REACT_APP_ORY_URL;
  const ory = new V0alpha2Api(
    new Configuration({
      basePath,
      baseOptions: {
        withCredentials: true,
      },
    })
  );

  axios.defaults.withCredentials = true;

  // Dark MODE
  const [darkModeEnabled] = useLocalStorage("darkModeEnabled");

  const [session, setSession] = useState<Session | undefined>();
  const [logoutUrl, setLogoutUrl] = useState<string | undefined>();
  const [currentTenantId, setCurrentTenantId] = useState<string | undefined>();

  // Returns either the email or the username depending on the user's Identity Schema
  const getUserName = (identity: Identity) =>
    identity.traits.email || identity.traits.username;

  // Second, gather session data, if the user is not logged in, redirect to login
  useEffect(() => {
    if (session) {
      return;
    }

    ory
      .toSession()
      .then(({ data }) => {
        // User has a session!
        console.log("SETTING SESSION", data);
        setSession(data);
        console.log("SETTING FIRST TENANT ID");
        setCurrentTenantId(
          (data.identity.metadata_public as any).tenants[0] as string
        );
        localStorage.setItem(
          "currentTenantId",
          (data.identity.metadata_public as any).tenants[0] as string
        );
        ory.createSelfServiceLogoutFlowUrlForBrowsers().then(({ data }) => {
          console.log("SETTING LOGOUT URL", data.logout_url);
          // Get also the logout url
          setLogoutUrl(data.logout_url);
        });
      })
      .catch((err) => {
        console.error(err);
        // Redirect to login page
        window.location.replace(`${basePath}/ui/login`);
      });
  }, []);

  if (!session) {
    // Still loading
    return (
      <div
        style={{ width: "100vw", height: "100vh" }}
        className="flex flex-col items-center align-center justify-center"
      >
        <span className="loader-global"></span>
      </div>
    );
  }

  let w: any = window;
  w.Intercom("boot", {
    app_id: "zs40tpif",
    name: "test", // Full name
    email: "alex@rackner.com", // Email address
    created_at: new Date().toDateString(),
  });

  return (
    <div
      id="page"
      className={"App" + (darkModeEnabled === "true" ? " dark" : "")}
    >
      <Toaster position="bottom-center" />
      <AuthContext.Provider
        value={{
          session,
          setSession,
          logoutUrl,
          setLogoutUrl,
          currentTenantId,
          setCurrentTenantId,
        }}
      >
        <Router>
          <div style={{ width: "100vw" }} className="top-0 absolute left-0">
            <TopLevelHeader
              currentRoute="/"
              toggleDarkMode={() =>
                writeStorage(
                  "darkModeEnabled",
                  darkModeEnabled === "true" ? "false" : "true"
                )
              }
            />
            <TopLevelSubHeader
              currentRoute="/"
              toggleDarkMode={() =>
                writeStorage(
                  "darkModeEnabled",
                  darkModeEnabled === "true" ? "false" : "true"
                )
              }
            />
          </div>
          <main
            style={{ paddingTop: "80px", height: "100vh" }}
            className="flex-1 flex-col h-full flex bg-gray-50 relative z-1 overflow-y-auto focus:outline-none"
            tabIndex={0}
          >
            <Switch>
              <Route exact path="/register">
                <Register />
              </Route>
              <Route exact path="/">
                <Dashboard />
              </Route>
              <Route exact path="/client-dashboard">
                <ClientDashboard />
              </Route>
              <Route path="/workflows">
                <WorkflowConfigurations />
              </Route>
              <Route path="/projects" exact>
                <Projects />
              </Route>
              <Route path="/projects/add">
                <AddEditProject />
              </Route>
              <Route path="/projects/edit/:id">
                <AddEditProject />
              </Route>
              <Route path="/projects/:id">
                <Project />
              </Route>
              <Route path="/providers/:id">
                <Provider />
              </Route>
              <Route path="/providers" exact>
                <Providers />
              </Route>
              <Route path="/customers" exact>
                <Customers />
              </Route>
              <Route path="/customers/:id">
                <Customer />
              </Route>
              <Route path="/employees/:id" exact>
                <Employee />
              </Route>
              <Route path="/employees" exact>
                <Employees />
              </Route>
              <Route path="/settings" exact>
                <Settings />
              </Route>
              <Route path="/users/:id">
                <User />
              </Route>
              <Route path="/integrations" exact>
                <Integrations />
              </Route>
              <Route path="/invoices/:id" exact>
                <Invoice />
              </Route>
              <Route path="/invoices" exact>
                <Invoices />
              </Route>
              <Route path="/bills" exact>
                <Bills />
              </Route>
              <Route path="/expenses" exact>
                <Expenses />
              </Route>
              <Route path="/recruiting" exact>
                <JobPosts />
              </Route>
              <Route path="/jobposts/:id">
                <JobPost />
              </Route>
              <Route path="/reports" exact>
                <Reports />
              </Route>
              <Route path="/items" exact>
                <ItemsAndRateCards />
              </Route>
              <Route path="/fringecosts" exact>
                <FringeCosts />
              </Route>
              <Route path="/commission" exact>
                <CommissionPlans />
              </Route>
              <Route path="/commission/:id" exact>
                <CommissionAssignment />
              </Route>
              <Route path="/scenarios" exact>
                <Scenarios />
              </Route>
            </Switch>
          </main>
        </Router>
      </AuthContext.Provider>
    </div>
  );
}

export default App;
