import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_PROJECT, GET_PROJECTS } from "../../data/queries/projects";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  formatProjectForOptionsList,
  INVOICE_PROJECT_TABLE_CONFIGURATION,
  PROJECTED_INVOICE_PROJECT_TABLE_CONFIGURATION,
  PROJECT_MERGE_CONFIGURATION,
} from "../../data/types/Project";
import Table from "../../basics/Table/Table";
import { ProjectScopeOfWork } from "../../data/types/ProjectScopeOfWork";
import { formatInvoiceForList } from "../../data/types/Invoice";
import RecentExternalLinksList from "../../basics/RecentExternalLinksList/RecentExternalLinksList";
import { format, startOfWeek } from "date-fns";
import {
  BriefcaseIcon,
  CalendarIcon,
  ClockIcon,
  CurrencyDollarIcon,
  LinkIcon,
  PlusIcon,
  SparklesIcon,
  StarIcon,
  UsersIcon,
} from "@heroicons/react/24/solid";
import toast from "react-hot-toast";
import { DetailViewHeader } from "../../components/DetailViewHeader/DetailViewHeader";
import TeamMemberAddEditSlideOver from "./TeamMemberAddEditSlideOver";
import { GET_PROVIDERS } from "../../data/queries/providers";
import { GET_EMPLOYEES } from "../../data/queries/employees";
import ExternalLinksList from "../../components/ExternalLinksList/ExternalLinksList";
import Gantt from "../../basics/Gantt/Gantt";
import {
  CREATE_RESOURCE_ASSIGNMENT,
  UPDATE_RESOURCE_ASSIGNMENT,
} from "../../data/queries/resourceAssignments";
import {
  ResourceAssignment,
  ResourceAssignmentCreateUpdateArgs,
} from "../../data/types/ResourceAssignment";
import { ScopeOfWorkDetail } from "./ScopeOfWorkDetail";
import ResourceNeedsList from "../../components/ResourceNeedsList/ResourceNeedsList";
import TeamOrResourcesList from "../../components/TeamOrResourcesList/TeamOrResourcesList";
import JobRoleAssignmentAddEditSlideOver from "./JobRoleAssignmentAddEditSlideOver";
import { JobRoleAssignmentCreateUpdateArgs } from "../../data/types/JobRoleAssignment";
import {
  CREATE_JOB_ROLE_ASSIGNMENT,
  UPDATE_JOB_ROLE_ASSIGNMENT,
} from "../../data/queries/jobRoleAssignments";
import { GET_JOB_ROLES } from "../../data/queries/jobRoles";
import { IconButton } from "../../basics/Buttons/IconButton";
import { BasicButtonStyles } from "../../basics/Buttons/BasicButton";
import ExternalResourceAddEditSlideOver from "./ExternalResourceAddEditSlideOver";
import { ExternalResourceCreateUpdateArgs } from "../../data/types/ExternalResource";
import {
  CREATE_EXTERNAL_RESOURCE,
  UPDATE_EXTERNAL_RESOURCE,
} from "../../data/queries/externalResources";
import { GET_SCOPED_ROLES } from "../../data/queries/auth";
import {
  Area,
  AreaChart,
  ReferenceLine,
  ResponsiveContainer,
  Label,
} from "recharts";
import ProjectStatsQuadrant from "./ProjectStatsQuadrant";
import Tabs from "../../basics/Tabs/Tabs";
import { SectionHeader } from "../../basics/SectionHeader/SectionHeader";
import ScopeOfWorkAddEditSlideOver from "./ScopeOfWorkAddEditSlideOver";
import DocumentsList from "../../components/DocumentsList/DocumentsList";
import SidemenuClearListTabs from "../../components/SidemenuClearListTabs/SidemenuClearListTabs";
import {
  GET_TIME_OFF_SEGMENTS,
  GET_TIME_SEGMENTS,
} from "../../data/queries/time";
import TimeSheetDisplay from "../../components/TimeSheetDisplay/TimeSheetDisplay";
import InvoiceAddEditSlideOver from "../Invoice/InvoiceAddEditSlideOver";
import BillingConfigDetailCard from "../../components/BillingConfigDetailCard/BillingConfigDetailCard";
import Card from "../../basics/Card/Card";
import { EntityMergeSlideOver } from "../../components/EntityMergeSlideOver/EntityMergeSlideOver";
import { MERGE_SUPPORTED_ENTITIES } from "../../data/types/MergeEntities";
import { dateToUTC } from "../../utilities/dates";
import { BasicPopover } from "../../basics/BasicPopover/BasicPopover";
import { DefaultPagePaddingAndWidth } from "../../basics/DefaultPagePaddingAndWidth/DefaultPagePaddingAndWidth";
import HeroCardGrid from "../../basics/HeroCardGrid/HeroCardGrid";
import { useLazyQuerySM } from "../../data/machines/queries";

const chartData = [
  {
    name: "Jan",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Feb",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "Mar",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "Apr",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "May",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "Jun",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "Jul",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

export function Project() {
  const location = useLocation();
  const [cursor, setCursor] = useState(null);
  // Team Member Assignment
  const [isTeamMemberEditOpen, setTeamMemberEditOpen] = useState(false);
  const [teamMemberToEdit, setTeamMemberToEdit] = useState(false);
  // Job Role Assignment
  const [isJobRoleAssignmentEditOpen, setJobRoleAssignmentEditOpen] =
    useState(false);
  const [jobRoleAssignmentToEdit, setJobRoleAssignmentToEdit] = useState(false);
  // Project Resources
  const [isExternalResourceEditOpen, setExternalResourceEditOpen] =
    useState(false);
  const [isScopeOfWorkEditOpen, setScopeOfWorkEditOpen] = useState(false);
  const [ExternalResourceToEdit, setExternalResourceToEdit] = useState(false);
  const [tab, setTab] = useState("delivery");
  const [selectedScopeOfWork, setSelectedScopeOfWork] = useState(null);
  const segments = location.pathname.split("/");
  const [projectionPeriod, setProjectionPeriod] = useState("30");
  const id = segments[segments.length - 1];
  const [mergeOpen, setMergeOpen] = useState(false);
  const [isInvoiceAddEditOpen, setIsInvoiceAddEditOpen]: any = useState(null);

  // Queries
  const [state, data, send] = useLazyQuerySM(GET_PROJECT);
  const [getProviders, providerData] = useLazyQuery(GET_PROVIDERS);
  const [getEmployees, employeeData] = useLazyQuery(GET_EMPLOYEES);
  const [getJobRoles, jobRoleData] = useLazyQuery(GET_JOB_ROLES);
  const [getScopedRoles, scopedRoleData] = useLazyQuery(GET_SCOPED_ROLES);
  const [getTimeSegments, timeSegmentsData] = useLazyQuery(GET_TIME_SEGMENTS);
  const [getTimeOffSegments, timeOffSegmentsData] = useLazyQuery(
    GET_TIME_OFF_SEGMENTS
  );
  const [timesheetStartDate, setTimesheetStartDate]: any = useState(null);
  const [timesheetEndDate, setTimesheetEndDate]: any = useState(null);
  // Mutations
  const [updateResourceAssignment, updateResourceAssignmentResult] =
    useMutation(UPDATE_RESOURCE_ASSIGNMENT, {
      onCompleted: () => {
        toast.success("Updated Resource Assignment");
        setTeamMemberEditOpen(false);
        send({ type: "REFETCH" });
      },
    });
  const [updateJobRoleAssignment, updateJobRoleAssignmentResult] = useMutation(
    UPDATE_JOB_ROLE_ASSIGNMENT,
    {
      onCompleted: () => {
        toast.success("Updated Job Role Assignment");
        setJobRoleAssignmentEditOpen(false);
        send({ type: "REFETCH" });
      },
    }
  );
  const [updateExternalResource, updateExternalResourceResult] = useMutation(
    UPDATE_EXTERNAL_RESOURCE,
    {
      onCompleted: () => {
        toast.success("Updated Project Resource");
        setExternalResourceEditOpen(false);
        send({ type: "REFETCH" });
      },
    }
  );
  const [createResourceAssignment, createResourceAssignmentResult] =
    useMutation(CREATE_RESOURCE_ASSIGNMENT, {
      onCompleted: () => {
        toast.success("Created Resource Assignment");
        setTeamMemberEditOpen(false);
        send({ type: "REFETCH" });
      },
    });
  const [createJobRoleAssignment, createJobRoleAssignmentResult] = useMutation(
    CREATE_JOB_ROLE_ASSIGNMENT,
    {
      onCompleted: () => {
        toast.success("Created Job Role Assignment");
        setJobRoleAssignmentEditOpen(false);
        send({ type: "REFETCH" });
      },
    }
  );
  const [createExternalResource, createExternalResourceResult] = useMutation(
    CREATE_EXTERNAL_RESOURCE,
    {
      onCompleted: () => {
        toast.success("Created Project Resource");
        setExternalResourceEditOpen(false);
        send({ type: "REFETCH" });
      },
    }
  );

  let project: any = {},
    allInvoices: any,
    totalRevenueForDays = 0,
    numberOfDays = 0,
    shownSow: any = null;

  useEffect(() => {
    send({
      type: "MAKE_QUERY",
      variables: { id: id, projectionPeriod: Number(projectionPeriod) },
    });
    getProviders();
    getScopedRoles();
    getEmployees();
    getJobRoles();
    setInitialDates();
  }, []);

  useEffect(() => {
    send({
      type: "MAKE_QUERY",
      variables: { id: id, projectionPeriod: Number(projectionPeriod) },
    });
  }, [projectionPeriod]);

  const fetchTimeSegments = () => {
    let shownSow2 = project.scopesOfWork?.find(
      (sow: any) => sow.id === selectedScopeOfWork
    );
    console.log(shownSow2.resources);
    getTimeSegments({
      variables: {
        where: {
          projectScopeOfWorkId: selectedScopeOfWork,
          // TODO handle segments without "day of segment" if that ever happens
          dayOfSegment: {
            gte: timesheetStartDate,
            lte: timesheetEndDate,
          },
        },
      },
    });
    getTimeOffSegments({
      variables: {
        where: {
          // TODO filter on status approved
          OR: [
            {
              employeeId: {
                in: shownSow2.resources
                  .map((resource: any) => resource.employee?.id)
                  .filter((a: any) => a),
              },
            },
            {
              providerPersonnelId: {
                in: shownSow2.resources
                  .map((resource: any) => resource.providerPersonnel?.id)
                  .filter((a: any) => a),
              },
            },
          ],
        },
      },
    });
  };

  useEffect(() => {
    if (selectedScopeOfWork) {
      fetchTimeSegments();
    }
  }, [timesheetStartDate, selectedScopeOfWork]);

  if (state.value === "completed" && data) {
    project = data;
    console.log("NEW PROJECT", project);
    allInvoices = [].concat(project.projectedInvoices?.projectedInvoices || []);
    numberOfDays = project.projectedInvoices?.numberOfDays;
    project.projectedInvoices?.projectedInvoices?.forEach(
      (p: any) => (totalRevenueForDays += Number(p.amount))
    );
    if (!selectedScopeOfWork && project?.scopesOfWork?.length) {
      setSelectedScopeOfWork(
        project.scopesOfWork?.find((sow: any) => sow.status === "ACTIVE").id ||
          project.scopesOfWork[0].id
      );
    }
  }

  if (selectedScopeOfWork) {
    shownSow = project.scopesOfWork?.find(
      (sow: any) => sow.id === selectedScopeOfWork
    );
  }

  const setTeamSlideOverOpen = (resourceAssignment?: any) => {
    // Flatten Scoped Role ID
    setTeamMemberToEdit(
      resourceAssignment
        ? {
            ...resourceAssignment,
            scopedRoleId: resourceAssignment.scopedRoleAttachment?.scopedRoleId,
          }
        : null
    );
    setTeamMemberEditOpen(true);
  };

  const setInitialDates = () => {
    setTimesheetEndDate(dateToUTC(new Date()));
    // Set start date to the first day of the same week as current
    setTimesheetStartDate(
      startOfWeek(dateToUTC(new Date()), {
        weekStartsOn: 1,
      })
    );
    console.log();
  };

  const setJobRoleSlideOverOpen = (jobRole?: any) => {
    setJobRoleAssignmentToEdit(jobRole);
    setJobRoleAssignmentEditOpen(true);
  };

  const submitResourceAssignmentForm = (
    data: ResourceAssignmentCreateUpdateArgs,
    id?: string
  ) => {
    if (id) {
      updateResourceAssignment({
        variables: {
          id,
          updatedResourceAssignment: data,
        },
      });
    } else {
      createResourceAssignment({
        variables: {
          newResourceAssignment: {
            ...data,
            rateCurrency: "USD",
            projectScopeOfWorkId: selectedScopeOfWork,
          },
        },
      });
    }
  };

  const submitExternalResourceForm = (
    data: ExternalResourceCreateUpdateArgs,
    id?: string
  ) => {
    console.log(id);
    if (id) {
      updateExternalResource({
        variables: {
          id,
          updatedExternalResource: data,
        },
      });
    } else {
      createExternalResource({
        variables: {
          newExternalResource: {
            ...data,
            projectId: project.id,
          },
        },
      });
    }
  };

  const submitJobRoleAssignmentForm = (
    data: JobRoleAssignmentCreateUpdateArgs,
    id?: string
  ) => {
    console.log(data, id);
    if (id) {
      updateJobRoleAssignment({
        variables: {
          id,
          updatedJobRoleAssignment: data,
        },
      });
    } else {
      createJobRoleAssignment({
        variables: {
          newJobRoleAssignment: {
            ...data,
            projectScopeOfWorkId: selectedScopeOfWork,
          },
        },
      });
    }
  };

  const renderContractsTab = () => {
    return (
      <div className="px-2 px-8">
        <SectionHeader
          button={
            <IconButton
              icon={PlusIcon}
              styler={BasicButtonStyles.CLEAR}
              text="Add Contract"
              onClick={() => setJobRoleSlideOverOpen()}
            />
          }
          title="In-Scope Agreements"
        />
        {shownSow?.contractualDocumentAttachments?.map((c: any) => {
          return (
            <>
              <div className="border bg-white w-96 shadow-sm flex-row flex">
                <div className="px-5 py-4 flex flex-col w-full">
                  <div className="flex flex-row align-center items-center text-xl capitalize ">
                    {c.document.type}
                    <div className="flex flex-1"></div>
                    <div className="flex flex-row text-xs justify-items-end">
                      <CalendarIcon height="17" />{" "}
                      {format(new Date(c.startsAt), "MM/dd/yyyy")} -{" "}
                      {format(new Date(c.endsAt), "MM/dd/yyyy")}
                    </div>
                  </div>
                  <div className="flex-1 py-4 flex flex-col items-center w-full align-center cursor-pointer hover:shadow-lg justify-center bg-blue-100 my-4 rounded-lg">
                    <div className="break-all text-xs text-blue-500 font-bold">
                      {c.document.name}
                    </div>
                    <div className="text-xs mt-3 uppercase font-bold text-gray-500 ">
                      Version #
                      {c.document?.versions?.length &&
                        c.document.versions[0]?.versionIdentifier}{" "}
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        })}
        <DocumentsList items={project?.documentsWithUrls} onEdit={() => {}} />
        <ExternalLinksList
          onEdit={(e: any) => {
            console.log(e);
            setExternalResourceEditOpen(true);
            setExternalResourceToEdit(e);
          }}
          items={
            project?.externalLinks?.map((a: any) => {
              return {
                logoUrl: "sss",
                externalLink: a,
              };
            }) || []
          }
        />
      </div>
    );
  };

  const renderTeamTab = () => {
    return (
      <div className="px-2 px-8">
        <SectionHeader
          title="Scope Team Members"
          button={
            <IconButton
              icon={PlusIcon}
              styler={BasicButtonStyles.CLEAR}
              text="Add Team Member"
              onClick={() => setTeamSlideOverOpen()}
            />
          }
        />
        <div className="mb-12">
          <TeamOrResourcesList
            type="resources"
            addEdit={setTeamSlideOverOpen}
            items={shownSow?.resources || []}
          />
        </div>
        <SectionHeader
          title="Unmet Resource Requirements"
          borderEnabled={true}
          button={
            <IconButton
              icon={PlusIcon}
              styler={BasicButtonStyles.CLEAR}
              text="Add Resource Requirement"
              onClick={() => setJobRoleSlideOverOpen()}
            />
          }
        />
        <div>
          <ResourceNeedsList
            addEdit={setJobRoleSlideOverOpen}
            items={shownSow?.jobRoleAssignments || []}
          />
        </div>
      </div>
    );
  };

  const renderScheduleTab = () => {
    return (
      <div className="relative px-2 px-8">
        <Gantt />
      </div>
    );
  };

  const renderTimeTab = () => {
    let segments = timeSegmentsData?.data?.data?.edges?.map((n: any) => n.node);
    let timeOffSegments = timeOffSegmentsData?.data?.data?.edges?.map(
      (n: any) => n.node
    );
    console.log(segments);
    return (
      <div className="px-2 px-8">
        <TimeSheetDisplay
          startDate={timesheetStartDate}
          endDate={timesheetEndDate}
          setEndDate={setTimesheetEndDate}
          setStartDate={setTimesheetStartDate}
          segments={segments}
          resources={shownSow?.resources || []}
          timeOffSegments={timeOffSegments}
        />
        {!segments ||
          (!segments.length && (
            <div className="mt-10">
              Link a Time Tracking System or use Manual Entry
            </div>
          ))}
      </div>
    );
  };

  const renderDeliveryTab = () => {
    return (
      <div className="flex flex-1 flex-row">
        <div
          style={{ height: "450px" }}
          className="relative flex flex-1 bg-white shadow-md rounded-md pt-10"
        >
          <ResponsiveContainer>
            <AreaChart
              data={chartData}
              margin={{
                top: 0,
                right: 0,
                left: 0,
                bottom: 0,
              }}
            >
              <defs>
                <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.5} />
                  <stop offset="95%" stopColor="#82ca9d" stopOpacity={0.1} />
                </linearGradient>
              </defs>
              <ReferenceLine
                y={3200}
                stroke="#FF0000"
                position="end"
                strokeDasharray="3 3"
                label={
                  <Label
                    offset={5}
                    position="insideBottomRight"
                    style={{
                      fontSize: "70%",
                      fontWeight: "bold",
                      marginRight: "7%",
                      fill: "rgba(0, 0, 0, 0.5)",
                    }}
                    value="Max Budget"
                  ></Label>
                }
              ></ReferenceLine>
              <Area
                type="monotone"
                dataKey={"uv"}
                stroke="#82ca9d"
                strokeWidth={4}
                strokeOpacity={0.8}
                fillOpacity={1}
                fill="url(#colorPv)"
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
        <div style={{ width: "350px" }} className="flex flex-col ml-10">
          <SectionHeader
            rightContent={
              <BasicPopover
                content={
                  <div className="text-xs">
                    racknerpulse_{project?.id}
                    @sandboxa41310f056c64404b075d453872914d1.mailgun.org
                  </div>
                }
                button={
                  <div className="text-xs flex flex-row items-center align-center">
                    {" "}
                    <LinkIcon className="text-blue-500 h-4 w-4 dark:text-gray-200 mr-2" />{" "}
                    Send updates/files via email
                  </div>
                }
              />
            }
            classNames="w-full"
            borderEnabled
            title="Updates & Tasks"
          />
          <div style={{ maxHeight: "600px" }} className="overflow-y-auto">
            {project?.updates?.map((u: any) => {
              return (
                <Card classNames="p-4 mb-5">
                  <div className="font-bold">{u.type}</div>
                  <div>{u.text}</div>
                </Card>
              );
            })}
            {project?.tasks?.map((t: any) => {
              return (
                <Card classNames="p-4 mb-5">
                  <div className="font-bold">TASK CREATED</div>
                  <div>{t.name}</div>
                  <div>{t.type}</div>
                  <div>{t.description}</div>
                </Card>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  const renderInvoiceTab = () => {
    return (
      <div className="px-2 px-8">
        <BillingConfigDetailCard
          invoicingConfig={project?.customer?.invoicingConfiguration}
          style={{ marginBottom: "25px" }}
        />
        <SectionHeader classNames="mt-8" title="Existing Invoices" />
        {project?.pastInvoices?.pastInvoices?.length ? (
          <>
            {project?.pastInvoices?.pastInvoices?.length && (
              <Table
                data={project?.pastInvoices?.pastInvoices?.map((n: any) => {
                  return formatInvoiceForList(n);
                })}
                columns={INVOICE_PROJECT_TABLE_CONFIGURATION}
                start={cursor ? Number(cursor) : 1}
                end={project?.pastInvoices?.pastInvoices?.length}
                onChangePage={setCursor}
                onSelectItem={(i: any) => console.log(i)}
              />
            )}
          </>
        ) : (
          <>
            <Card classNames="w-96 p-4">
              This project does not have any created invoices yet. Start the
              invoicing cadence by creating a new invoice with smartfill, either
              from scratch or from projected invoices below
            </Card>
            <IconButton
              styler={BasicButtonStyles.REGULAR}
              classNames="mt-4"
              text="Create an Invoice with Smartfill"
              onClick={(i: any) => setIsInvoiceAddEditOpen({})}
              icon={SparklesIcon}
            />
          </>
        )}
        <SectionHeader
          classNames="mt-8"
          title={`${projectionPeriod}-day Projected Invoices (current SoW only)`}
          button={
            <Tabs
              activeTab={projectionPeriod}
              setTab={(t: string) => {
                setProjectionPeriod(t);
              }}
              mode="EVENT"
              onSelect={(t: string) => {
                setProjectionPeriod(t);
              }}
              tabs={[
                {
                  name: "30-day",
                  identifier: "30",
                },
                {
                  name: "60-day",
                  identifier: "60",
                },
                {
                  name: "90-day",
                  identifier: "90",
                },
              ]}
            />
          }
        />
        <Table
          data={allInvoices?.map((n: any) => {
            return formatInvoiceForList(n);
          })}
          columns={PROJECTED_INVOICE_PROJECT_TABLE_CONFIGURATION}
          start={cursor ? Number(cursor) : 1}
          end={allInvoices?.length}
          onChangePage={setCursor}
          onSelectItem={(i: any) => console.log(i)}
          actions={[
            {
              label: "SmartFill",
              color: "blue",
              icon: SparklesIcon,
              onClick: (i: any) => {
                console.log(i);
                let toset = {
                  dateOfInvoice: new Date(i.invoiceDate),
                  periodStartDate: new Date(i.invoicePeriodStartDate),
                  periodEndDate: new Date(i.invoicePeriodEndDate),
                };
                console.log(toset);
                setIsInvoiceAddEditOpen(toset);
              },
            },
          ]}
        />
      </div>
    );
  };

  return (
    <>
      <DetailViewHeader
        name={project?.name}
        entityName="Project"
        hideEdit={true}
        centerContent={
          <SidemenuClearListTabs
            mode="horizontal"
            getter={tab}
            setter={setTab}
            items={[
              {
                identifier: "delivery",
                icon: StarIcon,
                text: "Dashboards",
              },
              {
                identifier: "invoice",
                icon: CurrencyDollarIcon,
                text: "Billing",
              },
              {
                identifier: "team",
                icon: UsersIcon,
                text: "Team",
              },
              {
                identifier: "time",
                icon: ClockIcon,
                text: "Time",
              },
              {
                identifier: "contracts",
                icon: BriefcaseIcon,
                text: "Contracts/Resources",
              },
            ]}
          />
        }
        externalData={project?.externalData}
        onMerge={() => setMergeOpen(true)}
        onEdit={() => {}}
      />

      <DefaultPagePaddingAndWidth className="mt-10 flex flex-row">
        <HeroCardGrid
          sections={[
            {
              customElement: (
                <>
                  <ScopeOfWorkDetail
                    sow={project?.scopesOfWork?.find(
                      (s: ProjectScopeOfWork) => s.id === selectedScopeOfWork
                    )}
                  />
                </>
              ),
            },
            {
              customElement: (
                <ProjectStatsQuadrant
                  stats={[
                    {
                      label: `Proj. ${projectionPeriod}-day Avg Billable`,
                      value: `$${project.projectedInvoices?.actualBillableRevenue?.toLocaleString()}`,
                    },
                    {
                      label: `Proj. ${projectionPeriod}-day Avg Margin`,
                      value: `$${project.projectedInvoices?.grossProfit?.toLocaleString()} (${
                        project.projectedInvoices?.grossProfitPercentage
                      }%)`,
                      emphasis:
                        project.projectedInvoices?.grossProfit > 0
                          ? "green"
                          : "red",
                    },
                    {
                      label: "Team Size",
                      value: `${
                        shownSow?.resources?.filter(
                          (r: ResourceAssignment) => r.status === "ACTIVE"
                        ).length || 0
                      } active resources`,
                    },
                    {
                      label: "Target Weekly Hours",
                      value: `62.5 | 67.5`,
                    },
                  ]}
                />
              ),
            },
            {
              customElement: (
                <div className="py-8 pr-6">
                  <h2 className="text-sm font-semibold mb-3 text-gray-600">
                    Recent Links
                  </h2>
                  <div className="">
                    <RecentExternalLinksList
                      items={
                        project?.externalLinks?.map((a: any) => {
                          return {
                            logoUrl: "sss",
                            externalLink: a,
                          };
                        }) || []
                      }
                    />
                  </div>
                </div>
              ),
            },
          ]}
        />

        {tab === "invoice" ? (
          renderInvoiceTab()
        ) : tab === "contracts" ? (
          renderContractsTab()
        ) : tab === "delivery" ? (
          renderDeliveryTab()
        ) : tab === "team" ? (
          renderTeamTab()
        ) : tab === "schedule" ? (
          renderScheduleTab()
        ) : tab === "time" ? (
          renderTimeTab()
        ) : (
          <></>
        )}
      </DefaultPagePaddingAndWidth>
      <ScopeOfWorkAddEditSlideOver
        open={isScopeOfWorkEditOpen}
        setOpen={setScopeOfWorkEditOpen}
        scopesOfWork={project?.scopesOfWork}
        onSelect={(id: any) => setSelectedScopeOfWork(id)}
        onSubmit={() => {}}
      />
      <EntityMergeSlideOver
        open={mergeOpen}
        entityId={id}
        entityName="Projects"
        formatter={formatProjectForOptionsList}
        singleQuery={GET_PROJECT}
        listQuery={GET_PROJECTS}
        config={PROJECT_MERGE_CONFIGURATION}
        entityType={MERGE_SUPPORTED_ENTITIES.PROJECT}
        setOpen={() => setMergeOpen(false)}
      />
      <JobRoleAssignmentAddEditSlideOver
        open={isJobRoleAssignmentEditOpen}
        setOpen={setJobRoleAssignmentEditOpen}
        onSubmit={submitJobRoleAssignmentForm}
        toEdit={jobRoleAssignmentToEdit}
        project={project}
        jobRoles={jobRoleData?.data?.data?.edges?.map((n: any) => n.node)}
      />
      <ExternalResourceAddEditSlideOver
        open={isExternalResourceEditOpen}
        setOpen={setExternalResourceEditOpen}
        onSubmit={submitExternalResourceForm}
        toEdit={ExternalResourceToEdit}
      />
      <InvoiceAddEditSlideOver
        projectId={id}
        projectName={project.name}
        refetch={() => send({ type: "REFETCH" })}
        customerId={project.customerId}
        open={isInvoiceAddEditOpen !== null}
        periodEndDate={isInvoiceAddEditOpen?.periodEndDate}
        periodStartDate={isInvoiceAddEditOpen?.periodStartDate}
        dateOfInvoice={isInvoiceAddEditOpen?.dateOfInvoice}
        setOpen={setIsInvoiceAddEditOpen}
        invoicingConfig={project?.customer?.invoicingConfiguration}
        onSubmit={() => {}}
      />
      <TeamMemberAddEditSlideOver
        open={isTeamMemberEditOpen}
        setOpen={setTeamMemberEditOpen}
        onSubmit={submitResourceAssignmentForm}
        toEdit={teamMemberToEdit}
        employees={employeeData?.data?.data?.edges?.map((n: any) => n.node)}
        providers={providerData?.data?.data?.edges?.map((n: any) => n.node)}
        scopedRoles={scopedRoleData?.data?.data?.edges?.map((n: any) => n.node)}
      />
    </>
  );
}
