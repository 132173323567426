import { ArrowUpIcon } from "@heroicons/react/24/solid";
import { ReactNode, useState } from "react";
import Card from "../../basics/Card/Card";
import { Props } from "../../data/types/Props";

interface PortfolioUtilizationCardProps extends Props {
  tooltip?: ReactNode;
}

function PortfolioUtilizationCard({
  classNames,
  tooltip,
}: PortfolioUtilizationCardProps) {
  const [mode, setMode] = useState("project");

  const toggleMode = () => {
    setMode(mode === "project" ? "all resources" : "project");
  };

  const renderProjectRow = (isLast: boolean) => {
    return (
      <div
        className={`flex flex-row items-center py-3 ${
          isLast ? "" : "border-b"
        }`}
      >
        <div className="uppercase font-bold text-xs text-gray-600 mr-3">
          Bobtail - Dashboard Implement...
        </div>
        <div className="flex flex-1"></div>
        <div className="flex flex-row bg-green-100 hover:shadow-lg hover:bg-green-200 border-green-200 border-2 hover:border-green-400 p-2 px-6">
          {[0, 1, 2, 3, 4, 1, 2, 3, 4, 5].map((d) => (
            <div className="cursor-pointer border-2 border-green-500 hover:border-gray-700 h-6 w-6 mr-1 bg-green-500"></div>
          ))}
        </div>
      </div>
    );
  };

  const renderResourcesRow = (isLast: boolean) => {
    return (
      <div className={`flex flex-row items-center mb-1`}>
        {[0, 1, 2, 3, 4, 1, 2, 3, 4, 5].map((d) => (
          <div className="cursor-pointer border-2 border-green-500 hover:border-gray-700 h-6 w-6 mr-1 bg-green-500"></div>
        ))}
      </div>
    );
  };

  const renderProjectMode = () => {
    return (
      <div>
        {[0, 1, 2, 3, 1, 2, 3, 2, 3].map((d, index) =>
          renderProjectRow(index === 8 ? true : false)
        )}
      </div>
    );
  };

  const renderResourcesMode = () => {
    return (
      <div className="p-2 bg-gray-100 border border-gray-200 hover:bg-gray-200 hover:border-gray-400 hover:shadow-lg mb-2">
        {[0, 1, 2, 3, 1, 2, 3, 2, 3].map((d, index) =>
          renderResourcesRow(index === 1 ? true : false)
        )}
      </div>
    );
  };

  return (
    <Card dashboardStyle classNames={"group " + classNames}>
      <div
        className={`rounded-t-lg ${
          mode === "project" ? "group-hover:shadow" : ""
        } transition pt-4 pb-4 bg-white flex flex-row items-center`}
      >
        <div className="px-6">
          <h3
            onClick={toggleMode}
            className="text-base font-light dark:text-gray-200 text-black"
          >
            Utilization-Profit Spread by{" "}
            <span className="cursor-pointer text-blue-500 font-bold underline capitalize">
              {mode}
            </span>
          </h3>
        </div>
        <div className="flex flex-1"></div>
        <div className="items-center flex flex-row px-6 text-sm items-center uppercase font-bold text-gray-500">
          Sort By:{" "}
          <div className="ml-3 pb-1 border-b border-gray-500 inline-block flex items-center flex-row">
            Profitability <ArrowUpIcon className="ml-1" height={15} />
          </div>
        </div>
      </div>
      <div style={{ maxHeight: "340px" }} className="px-6 py-2 overflow-auto">
        {mode === "project" ? renderProjectMode() : renderResourcesMode()}
      </div>
    </Card>
  );
}

export default PortfolioUtilizationCard;
