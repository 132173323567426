import { SparklesIcon } from "@heroicons/react/24/solid";
import { format } from "date-fns";
import { useHistory } from "react-router-dom";
import { BasicButtonStyles } from "../../basics/Buttons/BasicButton";
import { IconButton } from "../../basics/Buttons/IconButton";
import SlideOverWrapper from "../../basics/SlideOverWrapper/SlideOverWrapper";

export interface AlertsSlideOverProps {
  open: boolean;
  setOpen: Function;
  notifications: any;
}

export function AlertsSlideOver({ open, setOpen, notifications }: any) {
  const history = useHistory();
  const renderAlerts = () => {
    return notifications?.map((task: any) => {
      return (
        <>
          <div className={"border-b p-3 pl-10 cursor-pointer bg-gray-100 "}>
            <div className={"text-sm font-bold"}>{task.name}</div>
            <div className={"text-xs font-light"}>{task.description}</div>
            <div className="text-xs">
              Due {format(new Date(task.dueOn), "MM/dd/yyyy")}
            </div>
            <div className="text-sm mt-2">{task.description}</div>
            <div className="mt-3">
              {task.action === "APPROVE_INVOICE" && (
                <div>
                  <IconButton
                    styler={BasicButtonStyles.CLEAR}
                    icon={SparklesIcon}
                    text="View invoice to approve"
                    onClick={() =>
                      history.push(
                        "/invoices/" +
                          task.invoice.id +
                          "?openApprovalSlideOver"
                      )
                    }
                  />
                </div>
              )}
            </div>
          </div>
        </>
      );
    });
  };

  return (
    <SlideOverWrapper
      headerClassNames="bg-gray-100 text-black"
      bodyClassNames="bg-white"
      lightHeader={true}
      title={`Current Tasks`}
      open={open}
      setOpen={setOpen}
    >
      <div>{renderAlerts()}</div>
    </SlideOverWrapper>
  );
}
