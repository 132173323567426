import ListView from "../../components/ListView/ListView";
import { CREATE_JOB_ROLE, GET_JOB_ROLES } from "../../data/queries/jobRoles";
import { PaginatedResponse } from "../../data/types/PaginatedResponse";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import {
  JobRole,
  JobRoleCreateUpdateArgs,
  JOB_ROLE_TABLE_CONFIGURATION,
} from "../../data/types/JobRole";
import { useHistory } from "react-router-dom";
import { GET_JOB_POSTS } from "../../data/queries/jobPosts";
import {
  JobPost,
  JOB_POST_TABLE_CONFIGURATION,
} from "../../data/types/JobPost";
import {
  LazyListQuerySM,
  useLazyListQuerySM,
} from "../../data/machines/queries";
import { JobRolesList } from "./JobRolesList";
import { useMutation } from "@apollo/client";
import toast from "react-hot-toast";

export function JobPosts() {
  const [state, data, send]: LazyListQuerySM<JobPost> =
    useLazyListQuerySM(GET_JOB_POSTS);
  const history = useHistory();
  const [cursor, setCursor] = useState(null);
  const [cursorPost, setCursorPost] = useState(null);

  const formRole = useForm<FormData>();
  const [stateRole, dataRole, sendRole] = useLazyListQuerySM(GET_JOB_ROLES);
  const [isJobRoleAddModalOpen, setJobRoleAddModalOpen] = useState(false);
  const form = useForm<FormData>();

  const [createJobRole, createJobRoleResult] = useMutation(CREATE_JOB_ROLE, {
    onCompleted: () => {
      toast.success("Created Job Role");
      setJobRoleAddModalOpen(false);
    },
    refetchQueries: [
      {
        query: GET_JOB_ROLES,
      },
    ],
  });

  useEffect(() => {
    send({ type: "MAKE_QUERY", variables: { cursor } });
    sendRole({ type: "MAKE_QUERY", variables: { cursor: cursorPost } });
  }, [cursor]);

  const submitJobRoleForm = (data: JobRoleCreateUpdateArgs, id?: string) => {
    if (id) {
      //
    } else {
      createJobRole({
        variables: {
          newJobRole: data,
        },
      });
    }
  };

  const { errors, setValue, handleSubmit } = form;
  console.log(errors);

  useEffect(() => {
    send({
      type: "MAKE_QUERY",
      variables: { cursor },
    });
  }, [cursor]);

  return (
    <>
      <ListView
        title={"Job Posts"}
        data={state.value === "completed" ? data : undefined}
        state={state.value}
        error={state.value === "error" ? state.context.error : undefined}
        columns={JOB_POST_TABLE_CONFIGURATION}
        cursor={cursorPost || 0}
        onAddNew={() => history.push("/jobposts/add")}
        setCursor={setCursorPost}
        sideComponent={
          <JobRolesList
            state={stateRole.value}
            items={
              stateRole.value === "completed"
                ? dataRole.edges.map((a: any) => a.node)
                : []
            }
          />
        }
      />
    </>
  );
}
