import { TableColumn } from "../../basics/Table/Table";
import { FormConfiguration } from "./Form";
import { DetailViewConfiguration } from "../../components/BaseDetailView/BaseDetailView";
import { Customer } from "./Customer";
import { formatBaseItemForList } from "../../utilities/dates";

export interface ItemDefinition {
  id: string;
  tenantId: string;
  createdAt: Date;
  updatedAt: Date;
  name: string;
  itemType: string;
  billingType: string;
}

export const formatItemDefinitionForList = (item: any) => {
  return {
    ...formatBaseItemForList(item),
  };
};

export const ITEM_DEFINITION_DETAIL_CONFIGURATION: DetailViewConfiguration = {
  sections: [
    {
      title: "Basic Information",
      subtitle: "Some basic info about your project",
      fields: [
        {
          key: "name",
          label: "Name",
        },
      ],
    },
  ],
};

export const ITEM_DEFINITION_TABLE_CONFIGURATION: TableColumn[] = [
  {
    key: "name",
    displayType: "link",
    linkToKey: "id",
    linkToPrefix: "/items/",
    styles: "text-gray-600 font-medium",
    label: "Name",
  },
  {
    key: "itemType",
    label: "Type",
  },
  {
    key: "billingType",
    label: "Billing Type",
  },
  {
    key: "rate",
    label: "Default Rate",
    type: "rate",
    typeConfiguration: {
      rateUnitKey: "rateCurrency",
      rateTimeUnitKey: "rateTimeUnits",
    },
  },
];

export const ITEM_DEFINITION_FORM_CONFIGURATION: FormConfiguration = {
  requiredOptionsLoads: ["customers"],
  fields: [
    {
      name: "name",
      type: "textInput",
      validation: { required: true },
      props: {
        label: "Project Name",
        width: 3,
      },
    },
    {
      name: "customerId",
      type: "select",
      optionsPlaceholder: "customers",
      isNotEditable: true,
      validation: { required: true },
      props: {
        label: "Customer",
        width: 3,
      },
    },
  ],
};
