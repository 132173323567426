import { gql } from "@apollo/client";

const GET_JOB_ROLES = gql`
  query listJobRoles(
    $skip: Int
    $after: String
    $first: Int
    $query: String
    $orderBy: JobRoleOrder
  ) {
    data: listJobRoles(
      skip: $skip
      after: $after
      first: $first
      query: $query
      orderBy: $orderBy
    ) {
      edges {
        cursor
        node {
          id
          name
          createdAt
          updatedAt
          openSlots
        }
      }
      totalCount
      pageInfo {
        hasNextPage
      }
    }
  }
`;

const GET_JOB_ROLE = gql`
  query getJobRole($id: String!) {
    data: getJobRole(id: $id) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;

const CREATE_JOB_ROLE = gql`
  mutation createJobRole($newJobRole: JobRoleCreateRequest!) {
    createJobRole(newJobRole: $newJobRole) {
      id
    }
  }
`;

const UPDATE_JOB_ROLE = gql`
  mutation updateJobRole($id: String!, $updatedJobRole: JobRoleUpdateRequest!) {
    updateJobRole(id: $id, updatedJobRole: $updatedJobRole) {
      id
    }
  }
`;

export { GET_JOB_ROLES, GET_JOB_ROLE, CREATE_JOB_ROLE, UPDATE_JOB_ROLE };
