import { gql } from "@apollo/client";

const UPDATE_RESOURCE_ASSIGNMENT = gql`
  mutation updateResourceAssignment(
    $id: String!
    $updatedResourceAssignment: ResourceAssignmentUpdateRequest!
  ) {
    updateResourceAssignment(
      id: $id
      updatedResourceAssignment: $updatedResourceAssignment
    ) {
      id
      billable
    }
  }
`;

const CREATE_RESOURCE_ASSIGNMENT = gql`
  mutation createResourceAssignment(
    $newResourceAssignment: ResourceAssignmentCreateRequest!
  ) {
    createResourceAssignment(newResourceAssignment: $newResourceAssignment) {
      id
      billable
    }
  }
`;

const CALCULATE_FAKE_RESOURCE_MARGIN_SUMMARY = gql`
  query calculateFakeResourceMarginSummary(
    $resources: [FakeResource!]!
    $numberOfMonths: Int!
  ) {
    data: calculateFakeResourceMarginSummary(
      resources: $resources
      numberOfMonths: $numberOfMonths
    ) {
      totalGrossMarginAmount
      totalRevenueAmount
      totalCostAmount
      averageGrossMarginPercentage
      resources {
        fakeResourceIdentifier
        totalRevenueAmount
        totalCostAmount
        grossMarginAmount
        grossMarginPercentage
      }
    }
  }
`;

export {
  UPDATE_RESOURCE_ASSIGNMENT,
  CREATE_RESOURCE_ASSIGNMENT,
  CALCULATE_FAKE_RESOURCE_MARGIN_SUMMARY,
};
