/* tslint:disable */
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  GET_WORKFLOWS,
  GET_WORKFLOW_SPECIFICATIONS,
  UPDATE_WORKFLOW_SPECIFICATION,
} from "../../data/queries/workflows";
import React, { useEffect, useState, useRef } from "react";
import ReactFlow, {
  MiniMap,
  Controls,
  Background,
  ReactFlowProvider,
  addEdge,
  removeElements,
  BackgroundVariant,
  isEdge,
} from "react-flow-renderer";
import "./WorkflowConfigurations.css";
import {
  CheckIcon,
  PencilIcon,
  ArrowsRightLeftIcon,
} from "@heroicons/react/24/outline";
import toast from "react-hot-toast";
import {
  convertReactFlowElementstoWorkflowSpec,
  convertWorkflowSpecificationToReactFlowElements,
  WORKFLOW_NODE_TYPES,
  WORKFLOW_STEP_TYPES,
} from "../../utilities/workflows";
import WorkflowStepEditSlideOver from "./WorkflowStepEditSlideOver";
import {
  renderInfoBlock,
  renderTriggerStepInformation,
} from "./NodeTypes/DisplayNode";
import { PlusCircleIcon } from "@heroicons/react/24/solid";
import PageHeader from "../../components/PageHeader/PageHeader";
import { SecondarySidemenu } from "../../components/SecondarySidemenu/SecondarySidemenu";
import { IconButton } from "../../basics/Buttons/IconButton";
import { BasicButtonStyles } from "../../basics/Buttons/BasicButton";
import Card from "../../basics/Card/Card";

const initialElements: any[] = [];

let id = 0;
const getId = () => `dndnode_${id++}`;

export function WorkflowConfigurations() {
  const [getData, { loading, error, data, refetch }] = useLazyQuery(
    GET_WORKFLOW_SPECIFICATIONS
  );

  const [getWorkflowInstances, instancesData] = useLazyQuery(GET_WORKFLOWS);

  const [updateWorkflowSpec, updateWorkflowSpecResult] = useMutation(
    UPDATE_WORKFLOW_SPECIFICATION,
    {
      onCompleted: (res: any) => {
        toast.success("Updated Workflow");
      },
    }
  );

  const [selectedWorkflowId, setSelectedWorkflowId] = useState(null);
  const [selectedWorkflow, setSelectedWorkflow] = useState(null);

  useEffect(() => {
    getData({});
    getWorkflowInstances({});
  }, []);

  const workflows = data?.data?.edges?.map((a: any) => a.node);

  const reactFlowWrapper: any = useRef(null);
  const [reactFlowInstance, setReactFlowInstance]: any = useState(null);
  const [elements, setElements]: any = useState(initialElements);
  const [selectedStep, setSelectedStep]: any = useState(null);
  const [addToStream, setAddToStream]: any = useState(null);
  const [stepModalOpen, setStepModalOpen]: any = useState(false);
  const [mode, setMode]: any = useState("regular");

  const onChange = (event: any) => {
    console.log(event);
    setElements((els: any) =>
      els.map((e: any) => {
        if (isEdge(e) || e.id != event.id) {
          return e;
        }

        const newVal = event.value;

        return {
          ...e,
          data: {
            ...e.data,
            options: {
              ...e.data.options,
              selected: newVal,
            },
          },
        };
      })
    );
  };

  const setStep = (step: any) => {
    setSelectedStep(null);
    setSelectedStep(step);
    setStepModalOpen(true);
  };

  const deleteStep = (step: any) => {
    //todo
  };

  if (data && !selectedWorkflowId) {
    setSelectedWorkflowId(workflows?.[0]?.id);
    setSelectedWorkflow(workflows?.[0]);
    setElements(
      convertWorkflowSpecificationToReactFlowElements(workflows?.[0]).map(
        (e) => {
          return {
            ...e,
            data: {
              ...e.data,
              setStep,
              deleteStep,
            },
          };
        }
      )
    );
  }

  useEffect(() => {
    if (refetch) {
      console.log("REFETCHING");
      refetch({});
    }
  }, [
    updateWorkflowSpecResult &&
      updateWorkflowSpecResult.called &&
      updateWorkflowSpecResult.data,
  ]);

  const onConnect = (params: any) =>
    setElements((els: any) => addEdge(params, els));

  const onElementsRemove = (elementsToRemove: any) =>
    setElements((els: any) => removeElements(elementsToRemove, els));

  const onLoad = (_reactFlowInstance: any) =>
    setReactFlowInstance(_reactFlowInstance);

  const onSave = async () => {
    let instance = reactFlowInstance.toObject();
    let finalWorkflow = convertReactFlowElementstoWorkflowSpec(
      instance.elements,
      workflows.find((w: any) => w.id === selectedWorkflowId)
    );
    await updateWorkflowSpec({
      variables: {
        workflowSpecificationToUpdate: finalWorkflow,
      },
    });
  };

  const onDragOver = (event: any) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  };

  const getNewestId = () => {
    let newest = 0;
    elements.forEach((el: any) => {
      if (el.data && el.data.id && el.data.id > newest) {
        newest = el.data.id;
      }
    });
    return newest + 1;
  };

  const onDrop = (event: any) => {
    event.preventDefault();
    const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
    const data = JSON.parse(
      event.dataTransfer.getData("application/reactflow")
    );
    const position = reactFlowInstance.project({
      x: event.clientX - reactFlowBounds.left,
      y: event.clientY - reactFlowBounds.top,
    });
    const newNode = {
      id: getId(),
      type: data.nodeType,
      position,
      style: data.style || {},
      data: {
        label: `${data.name}`,
        type: data.identifier,
        purpose: data.purpose,
        options: data.options,
        icon: data.icon,
        onChange,
        id: getNewestId(),
      },
    };
    setElements((es: any) => {
      let newArr = es.concat(newNode);
      return newArr;
    });
  };

  const onDragStart = (event: any, nodeType: any) => {
    event.dataTransfer.setData("application/reactflow", nodeType);
    event.dataTransfer.effectAllowed = "move";
  };

  const renderModeSelection = () => {
    return (
      <button
        className="mr-5 capitalize inline-flex shadow-md items-center px-3 py-1 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
        onClick={() => setMode(mode === "advanced" ? "regular" : "advanced")}
      >
        <ArrowsRightLeftIcon height={13} width={13} className="mr-1" />
        Switch to {mode === "regular" ? "advanced" : "regular"} Mode
      </button>
    );
  };

  const renderWorkflowSelection = (w?: any) => {
    return (
      <>
        {workflows?.map((e: any) => {
          let task = e.node;
          return (
            <>
              <div
                className={
                  "border-b p-3 pl-10 cursor-pointer " +
                  (selectedWorkflowId === e.id ? "bg-blue-600 text-white" : "")
                }
                onClick={() => {
                  setSelectedWorkflowId(e.id);
                  setSelectedWorkflow(workflows.find((w: any) => w.id == e.id));
                }}
              >
                <div className={"text-sm font-bold"}>{e.name}</div>
              </div>
            </>
          );
        })}
      </>
    );
  };

  const addTrigger = () => {
    setStep(null);
  };

  const addStreamStep = (stream: any) => {
    setAddToStream(stream);
    setStep(null);
  };

  const renderRegularMode = () => {
    if (!selectedWorkflow) return <></>;
    let workflow: any = selectedWorkflow;
    workflow = workflow.specification;
    // A stream starts with a trigger and ends when there are no more next step definitions
    let streams = [];
    for (let index = 0; index < workflow.steps.length; index++) {
      const currentStep = workflow.steps[index];
      if (currentStep.type === "TRIGGER") {
        // start a new stream
        let stream: any = {
          steps: [currentStep],
        };
        let preStepId = currentStep.nextStepId;
        while (preStepId !== null) {
          let currentStep: any = workflow.steps.find(
            (s: any) => s.id === preStepId
          );
          stream.steps.push(currentStep);
          preStepId = currentStep.nextStepId;
        }
        streams.push(stream);
      }
    }

    return (
      <div
        style={{ width: "60rem" }}
        className="pt-8 mx-auto px-4 sm:px-6 md:px-8 flex flex-col"
      >
        {streams?.map((s: any) => {
          return (
            <div className="flex-1 flex flex-col">
              {s.steps.map((s2: any) => {
                const data: any = WORKFLOW_STEP_TYPES.find((w) => {
                  return w.identifier === s2.type;
                }) || <></>;
                if (s2.type === "TRIGGER") {
                  return (
                    <Card classNames="my-2">
                      <div
                        className={
                          "relative p-2 mb-4 ml-4 flex flex-1 flex-col"
                        }
                      >
                        <div className="flex flex-row">
                          <data.icon className="mr-2" width={22} height={22} />
                          {s2.type}
                        </div>
                        <div>{renderTriggerStepInformation(s2)}</div>
                        <div className="absolute top-2 right-2">
                          <IconButton
                            icon={PencilIcon}
                            text={"Edit"}
                            onClick={() => setStep(s2)}
                            styler={BasicButtonStyles.ZERO}
                          />
                        </div>
                      </div>
                    </Card>
                  );
                } else {
                  return (
                    <Card classNames={"ml-8 my-2"}>
                      <div
                        className={
                          "relative p-2 mb-4 ml-4 flex flex-1 flex-col"
                        }
                      >
                        <div className="flex flex-row">
                          {data.icon && (
                            <data.icon
                              className="mr-2"
                              width={22}
                              height={22}
                            />
                          )}
                          {s2.type}
                        </div>
                        <div>{renderInfoBlock(s2)}</div>
                        <div className="absolute top-2 right-2">
                          <IconButton
                            icon={PencilIcon}
                            text={"Edit"}
                            onClick={() => setStep(s2)}
                            styler={BasicButtonStyles.ZERO}
                          />
                        </div>
                      </div>
                    </Card>
                  );
                }
              })}
              <div className="p-4 pl-8">
                <IconButton
                  text={"Add Action to Stream"}
                  iconClassNames={"mr-1"}
                  styler={BasicButtonStyles.CLEAR}
                  icon={PlusCircleIcon}
                  onClick={() => addStreamStep(s)}
                />
              </div>
            </div>
          );
        })}
        <div className="pt-10 pl-2">
          <IconButton
            text={"Add new Stream"}
            iconClassNames={"mr-1"}
            styler={BasicButtonStyles.REGULAR}
            color={"green"}
            icon={PlusCircleIcon}
            onClick={() => addTrigger()}
          />
        </div>
      </div>
    );
  };

  const renderAdvancedMode = () => {
    return (
      <div className="flex-grow flex flex-1 h-full">
        <div className="border border-gray-200 flex flex-grow bg-white">
          <ReactFlow
            elements={elements}
            onConnect={onConnect}
            onElementsRemove={onElementsRemove}
            onLoad={onLoad}
            onDrop={onDrop}
            nodeTypes={WORKFLOW_NODE_TYPES}
            onDragOver={onDragOver}
          >
            <MiniMap />
            <Controls />
            <Background variant={BackgroundVariant.Lines} gap={20} size={2} />
          </ReactFlow>
        </div>
        <div className="p-4 bg-white">
          <aside>
            {WORKFLOW_STEP_TYPES.map(
              ({ name, identifier, nodeType, style, options, icon: Icon }) => {
                return (
                  <div
                    className="bg-white flex flex-row p-3 text-center bg-blue-100 overflow-hidden shadow cursor-pointer m-3 rounded-lg"
                    onDragStart={(event) =>
                      onDragStart(
                        event,
                        JSON.stringify({
                          name,
                          identifier,
                          nodeType,
                          style,
                          options,
                          icon: Icon,
                        })
                      )
                    }
                    draggable
                  >
                    <Icon className="mr-2" width={22} height={22} />
                    {name}
                  </div>
                );
              }
            )}
          </aside>
        </div>
      </div>
    );
  };

  return (
    <>
      <PageHeader
        disableBreadcrumbs={true}
        leftContent={
          <h2 className="text-xl font-semibold leading-7 text-gray-800 sm:text-xl sm:truncate">
            Workflows & Agents
          </h2>
        }
        subheader={
          <div className="flex flex-row px-10 pb-4 pt-4">
            {renderModeSelection()}
            <div className="flex flex-1" />
            <button
              type="button"
              onClick={onSave}
              className="capitalize inline-flex shadow-md items-center px-3 py-1 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
            >
              <CheckIcon color="white" className="mr-1" height={13} />
              Save Workflow
            </button>
          </div>
        }
        rightContent={
          <button
            type="button"
            onClick={() => {}}
            className="capitalize mr-3 inline-flex shadow-md items-center px-3 py-1 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            <svg
              className="-ml-1 mr-2 h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 6v6m0 0v6m0-6h6m-6 0H6"
              />
            </svg>
            Add Workflow
          </button>
        }
      />
      <ReactFlowProvider>
        <div className="flex flex-row flex-1 z-0 relative">
          <SecondarySidemenu classNames={"w-80 workflow-secondary-sidemenu"}>
            {renderWorkflowSelection()}
          </SecondarySidemenu>
          <div
            className="reactflow-wrapper dndflow flex-1"
            ref={reactFlowWrapper}
          >
            <div className="p-6">
              Table of Existing Workflow instances
              {instancesData?.data?.data?.edges?.map((e: any) => {
                return <div>{e.node.status}</div>;
              })}
            </div>
            {mode === "regular" ? renderRegularMode() : renderAdvancedMode()}
          </div>
        </div>
        <WorkflowStepEditSlideOver
          setOpen={setStepModalOpen}
          open={stepModalOpen}
          toEdit={selectedStep}
          addToStream={addToStream}
        />
      </ReactFlowProvider>
    </>
  );
}
