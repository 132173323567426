export const multiIndexOf = function (
  array: any[],
  result: string,
  key: string
) {
  var idxs = [];
  for (var i = array.length - 1; i >= 0; i--) {
    if (array[i][key] === result) {
      idxs.unshift(i);
    }
  }
  return idxs;
};
