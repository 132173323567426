import { TableColumn } from "../../basics/Table/Table";
import { DetailViewConfiguration } from "../../components/BaseDetailView/BaseDetailView";
import { FormConfiguration } from "./Form";

export interface Integration {
  id: string;
  tenantId: string;
  createdAt: Date;
  updatedAt: Date;
  target: string;
  automated: boolean;
  requiresExternalOauth: boolean;
  syncScheduleFrequency: number;
  syncScheduleFrequencyUnits: string;
}

export const formatIntegrationForOptionsList = (e: Integration) => {
  return {
    label: e.target,
    value: e.id,
  };
};

export const INTEGRATIONS_DETAIL_CONFIGURATION: DetailViewConfiguration = {
  sections: [
    {
      title: "Basic Information",
      subtitle: "Some basic info about your project",
      fields: [
        {
          key: "name",
          label: "Name",
        },
      ],
    },
  ],
};

export const INTEGRATIONS_TABLE_CONFIGURATION: TableColumn[] = [
  {
    key: "target",
    label: "Name",
    displayType: "link",
    linkToKey: "id",
    linkToPrefix: "/integrations/",
  },
  {
    key: "updatedAt",
    label: "Updated At",
  },
  {
    key: "createdAt",
    label: "Created At",
  },
];
