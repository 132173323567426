import { BaseModalProps } from "../../basics/ModalWrapper/ModalWrapper";
import MinimalForm from "../../components/MinimalForm/MinimalForm";
import { useForm } from "react-hook-form";
import SlideOverWrapper from "../../basics/SlideOverWrapper/SlideOverWrapper";
import { useEffect } from "react";
import { FormFieldConfiguration } from "../../data/types/Form";
import {
  formatProviderForOptionsList,
  formatProviderPersonnelForOptionsList,
  Provider,
} from "../../data/types/Provider";
import {
  Employee,
  formatEmployeeForOptionsList,
} from "../../data/types/Employee";
import { BUILD_RESOURCE_ASSIGNMENT_FORM_CONFIGURATION } from "../../data/types/ResourceAssignment";
import { EntityUnchangeableDataBlock } from "../../basics/EntityStaticDataBlock/EntityStaticDataBlock";
import { Link } from "react-router-dom";
import {
  formatScopedRoleForOptionsList,
  ScopedRole,
} from "../../data/types/Auth";

export interface TeamMemberAddEditSlideOverProps extends BaseModalProps {
  toEdit?: any;
  providers?: Provider[];
  employees?: Employee[];
  scopedRoles?: ScopedRole[];
}

function TeamMemberAddEditSlideOver({
  open,
  setOpen,
  onSubmit,
  toEdit,
  providers,
  employees,
  scopedRoles,
}: TeamMemberAddEditSlideOverProps) {
  let initialValues: any = {};

  const form = useForm<FormData>({ defaultValues: initialValues });
  const { errors, setValue, handleSubmit, watch } = form;
  /**
   * Set Initial Values for Form
   */
  useEffect(() => {
    if (toEdit && open) {
      setTimeout(() => {
        Object.keys(toEdit).forEach((k) => {
          setValue(k, toEdit[k]);
        });
      });
    }
  }, [toEdit, open]);

  /* SET FIELDS */
  let fields: FormFieldConfiguration[] =
    BUILD_RESOURCE_ASSIGNMENT_FORM_CONFIGURATION(toEdit).fields;

  const submitForm = (data: any) => {
    delete data["providerMainId"];
    return toEdit ? onSubmit(data, toEdit.id) : onSubmit(data);
  };

  const renderEdit = () => {
    const providerPersonnel: any[] = [];
    providers?.forEach((p: any) => {
      p.personnel.forEach((p2: any) => {
        providerPersonnel.push(formatProviderPersonnelForOptionsList(p2));
      });
    });
    return (
      <MinimalForm
        fields={fields}
        optionsMap={{
          scopedRoles: scopedRoles?.map((s) =>
            formatScopedRoleForOptionsList(s)
          ),
          providerPersonnel: providerPersonnel,
          providers: providers?.map((v: any) =>
            formatProviderForOptionsList(v)
          ),
          employees: employees?.map((e) => formatEmployeeForOptionsList(e)),
        }}
        submitText={"Submit"}
        form={form}
        onSubmit={submitForm}
      />
    );
  };

  return (
    <SlideOverWrapper
      title={`Add or edit team assignment`}
      open={open}
      setOpen={setOpen}
    >
      {toEdit && (
        <EntityUnchangeableDataBlock
          title={
            <>
              {toEdit.source === "EMPLOYEE" ? "Employee" : "Provider Personnel"}
              :{" "}
              <Link
                to={
                  toEdit.source === "EMPLOYEE"
                    ? "/employees/" + toEdit.employee?.id
                    : "/providers/" + toEdit.providerPersonnel?.id
                }
              >
                <a className="text-blue-700 underline font-bold capitalize">
                  {toEdit.source === "EMPLOYEE"
                    ? toEdit.employee?.firstName +
                      " " +
                      toEdit.employee?.lastName
                    : toEdit.providerPersonnel?.firstName +
                      " " +
                      toEdit.providerPersonnel?.lastName}
                </a>
              </Link>
            </>
          }
        />
      )}
      {renderEdit()}
    </SlideOverWrapper>
  );
}

export default TeamMemberAddEditSlideOver;
