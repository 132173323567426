import { useContext, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
  useLocation,
} from "react-router-dom";
import { MagnifyingGlassIcon, Squares2X2Icon } from "@heroicons/react/24/solid";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import { useLazyQuery } from "@apollo/client";
import { AuthContext } from "../../App";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { SMART_SEARCH } from "../../data/queries/search";
import { SearchLightbox } from "../SearchLightbox/SearchLightbox";
import { TasksButton } from "../TasksButton/TasksButton";
import { DropdownButton } from "../../basics/DropdownButton/DropdownButton";

interface TopLevelHeaderProps {
  currentRoute: string;
  toggleDarkMode: any;
}

interface TopLevelHeaderLink {
  text: string;
  route?: string;
  icon?: Function;
  type?: string;
}

function TopLevelHeader({ toggleDarkMode }: TopLevelHeaderProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [isSmall, setIsSmall] = useState(false);
  const context: any = useContext(AuthContext);
  const history = useHistory();

  const breadcrumbs = useBreadcrumbs();

  const [notifications, setNotifications] = useState<any[]>([]);
  const [cursor, setCursor] = useState(null);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [performSearch, performSearchData] = useLazyQuery(SMART_SEARCH);

  const search = (text: string) => {
    console.log(text);
    performSearch({
      variables: {
        search: { text },
      },
    });
  };

  console.log(context);
  const logoutUrl = context.logoutUrl;
  console.log(logoutUrl);

  const location = useLocation();
  const path = location.pathname;

  return (
    <>
      <nav
        style={{ height: "48px" }}
        className="z-10 bg-white border-b border-gray-200 flex flex-row relative items-center"
      >
        <div className="px-5 pl-7 flex flex-row">
          <img className="h-7" src="/kensologo2.png" />
        </div>
        {/* FUTURE */}
        <div className="pl-3 border-l cursor-pointer flex flex-row items-center text-gray-700">
          <div className="text-blue-500">
            <Squares2X2Icon className="mr-1" width={18} />
          </div>
          <div>PSA Admin App</div>
          <ChevronDownIcon width={15} />
        </div>
        <div
          onClick={() => setIsSearchOpen(true)}
          style={{
            width: "380px",
            left: "50%",
            height: "32px",
            transform: "translateX(-50%)",
            zIndex: 10,
            padding: "0 10px",
          }}
          className={`absolute top-2 text-sm text-center bg-gray-100 hover:text-gray-500 hover:shadow-lg hover:bg-gray-300 cursor-pointer flex items-center justify-center flex-row text-center text-gray-400 rounded-lg`}
        >
          <MagnifyingGlassIcon width="20" style={{ marginRight: "10px" }} />
          Search, ask or command
        </div>
        <div className="flex-1"></div>
        <Link to="/settings">
          <div className="border-r flex flex-row items-center pr-2 mr-2">
            <img
              className="h-5 w-auto mr-1"
              src="/sample_customer_logos/RacknerLogo.png"
            />
            <ChevronDownIcon width={15} />
          </div>
        </Link>
        <div style={{ marginBottom: "-5px" }} className="mr-4">
          <TasksButton />
        </div>
        <DropdownButton
          items={[
            {
              text: "My Profile",
              href: "https://auth-dev.kenso.ai/ui/settings",
            },
          ]}
          className="z-20"
          customButton={
            <div
              style={{
                height: "37px",
                width: "39px",
              }}
              className="mr-5 cursor-pointer rounded-full bg-white shadow text-black border border-gray-400 relative flex flex-row items-center align-center justify-center"
            >
              AR
            </div>
          }
        />
      </nav>
      <SearchLightbox
        submit={search}
        setIsOpen={setIsSearchOpen}
        isOpen={isSearchOpen}
        results={performSearchData?.data?.data}
        loading={performSearchData?.loading}
      />
    </>
  );
}

export default TopLevelHeader;
