import { gql } from "@apollo/client";

const GET_REPORT_CONFIGURATIONS = gql`
  query listReportConfigurations(
    $skip: Int
    $after: String
    $first: Int
    $where: JSON
    $orderBy: ReportConfigurationOrder
  ) {
    data: listReportConfigurations(
      skip: $skip
      after: $after
      first: $first
      where: $where
      orderBy: $orderBy
    ) {
      edges {
        cursor
        node {
          id
          name
          description
          runnable
        }
      }
    }
  }
`;

const UPDATE_REPORT_CONFIGURATION = gql`
  mutation updateReportConfiguration(
    $id: String!
    $updatedReportConfiguration: ReportConfigurationUpdateRequest!
  ) {
    updateReportConfiguration(
      id: $id
      updatedReportConfiguration: $updatedReportConfiguration
    ) {
      id
    }
  }
`;

const GET_REPORT = gql`
  query getReport($id: String!) {
    data: getReport(id: $id) {
      reportSections {
        name
        rows
        columns {
          label
          key
          type
        }
      }
    }
  }
`;

export { GET_REPORT_CONFIGURATIONS, GET_REPORT, UPDATE_REPORT_CONFIGURATION };
