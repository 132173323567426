import { TableColumn } from "../../basics/Table/Table";
import { FormConfiguration, FormFieldOption } from "../../data/types/Form";
import { DetailViewConfiguration } from "../../components/BaseDetailView/BaseDetailView";
import { Customer } from "./Customer";
import { formatBaseItemForList } from "../../utilities/dates";
import { Project } from "./Project";
import { BaseApiEntity } from "./base/BaseEntity";

export interface ExternalResource extends BaseApiEntity {
  name: string;
  linkType: EXTERNAL_RESOURCE_TYPES;
  securityType: EXTERNAL_RESOURCE_SECURITY_TYPES;
  url: string;
  logoName: string;
  project?: Project;
  projectId: string;
}

export interface ExternalResourceCreateUpdateArgs {
  name?: string;
  linkType?: EXTERNAL_RESOURCE_TYPES;
  securityType?: EXTERNAL_RESOURCE_SECURITY_TYPES;
  url?: string;
  logoName?: string;
  projectId?: string;
}

enum EXTERNAL_RESOURCE_SECURITY_TYPES {
  PERSONAL = "PERSONAL",
  SHARED_ANY = "SHARED_ANY",
  SHARED_ROLES = "SHARED_ROLES",
}

export const EXTERNAL_RESOURCE_SECURITY_TYPES_OPTIONS: FormFieldOption[] = [
  {
    label: "Me only",
    value: "PERSONAL",
  },
  {
    label: "Shared with all project users",
    value: "SHARED_ANY",
  },
  {
    label: "Shared with specific project roles",
    value: "SHARED_ROLES",
  },
];

enum EXTERNAL_RESOURCE_TYPES {
  PROJECT_MANAGEMENT_SYSTEM = "PROJECT_MANAGEMENT_SYSTEM",
  CODE_REPOSITORY_ORGANIZATION = "CODE_REPOSITORY_ORGANIZATION",
  CODE_REPOSITORY = "CODE_REPOSITORY",
  COLLABORATION_DRIVE = "COLLABORATION_DRIVE",
  CHAT_SYSTEM = "CHAT_SYSTEM",
  CHAT_CHANNEL = "CHAT_CHANNEL",
  EXTERNAL_FILE = "EXTERNAL_FILE",
  SAML_AUTH = "SAML_AUTH",
  CRM_ENTRY = "CRM_ENTRY",
  OTHER = "OTHER",
}

export const EXTERNAL_LINK_CATEGORIES_OPTIONS: FormFieldOption[] = [
  {
    label: "Chat Organization",
    value: "CHAT_SYSTEM",
  },
  {
    label: "Chat Channel",
    value: "CHAT_CHANNEL",
  },
  {
    label: "Drive",
    value: "COLLABORATION_DRIVE",
  },
  {
    label: "Project & Task Tracking",
    value: "PROJECT_MANAGEMENT_SYSTEM",
  },
  {
    label: "Documents",
    value: "EXTERNAL_FILE",
  },
  {
    label: "Code Repositories",
    value: "CODE_REPOSITORY",
  },
  {
    label: "Code Organization",
    value: "CODE_ORGANIZATION",
  },
  {
    label: "Single Sign-On",
    value: "SAML_AUTH",
  },
];

export const EXTERNAL_LINK_PROVIDERS_OPTIONS: FormFieldOption[] = [
  {
    label: "Slack",
    value: "slack",
    imageUrl: `${process.env.PUBLIC_URL}/external_logos/slack.png`,
  },
  {
    label: "Quickbooks",
    value: "quickbooks",
    imageUrl: `${process.env.PUBLIC_URL}/external_logos/quickbooks.png`,
  },
  {
    label: "Upwork",
    value: "upwork",
    imageUrl: `${process.env.PUBLIC_URL}/external_logos/upwork.png`,
  },
  {
    label: "Salesforce",
    value: "salesforce",
    imageUrl: `${process.env.PUBLIC_URL}/external_logos/salesforce.png`,
  },
];

export const EXTERNAL_RESOURCE_DETAIL_CONFIGURATION: DetailViewConfiguration =
  {
    sections: [
      {
        title: "Basic Information",
        subtitle: "Some basic info about your project",
        fields: [
          {
            key: "name",
            label: "Name",
          },
        ],
      },
    ],
  };

export const EXTERNAL_RESOURCE_TABLE_CONFIGURATION: TableColumn[] = [
  {
    key: "name",
    displayType: "link",
    linkToKey: "id",
    linkToPrefix: "/services/",
    styles: "text-gray-600 font-medium",
    label: "Name",
  },
  {
    key: "serviceType",
    label: "Type",
  },
  {
    key: "billingType",
    label: "Billing Type",
  },
];

export const BUILD_EXTERNAL_RESOURCE_FORM_CONFIGURATION = (
  isEdit: boolean
): FormConfiguration => {
  return {
    requiredOptionsLoads: ["customers"],
    fields: [
      {
        name: "name",
        type: "textInput",
        validation: { required: true },
        props: {
          label: "Name",
          width: 6,
        },
      },
      {
        name: "url",
        type: "textInput",
        validation: { required: true },
        props: {
          label: "Link/URL",
          width: 6,
        },
      },
      {
        name: "securityType",
        type: "select",
        options: EXTERNAL_RESOURCE_SECURITY_TYPES_OPTIONS,
        placeholder: "Security",
        validation: { required: true },
        props: {
          label: "Security",
          width: 6,
        },
      },
      {
        name: "linkType",
        type: "select",
        options: EXTERNAL_LINK_CATEGORIES_OPTIONS,
        placeholder: "Type",
        validation: { required: true },
        props: {
          label: "Type",
          width: 6,
        },
      },
      {
        name: "logoName",
        type: "select",
        options: EXTERNAL_LINK_PROVIDERS_OPTIONS,
        placeholder: "Provider",
        validation: { required: true },
        props: {
          label: "Provider",
          width: 6,
        },
      },
    ],
  };
};
