import { ClockIcon, ArrowPathIcon, WifiIcon } from "@heroicons/react/24/solid";
import { ProjectScopeOfWork } from "../../data/types/ProjectScopeOfWork";
import { format } from "date-fns";

interface ScopeOfWorkDetailProps {
  sow: ProjectScopeOfWork;
}

export function ScopeOfWorkDetail({ sow }: ScopeOfWorkDetailProps) {
  if (sow == null)
    return (
      <>
        <div className="w-full my-auto">
          <div className="flex flex-row items-center">
            <div className="w-full">
              <div className="pl-10 pr-6 flex flex-row items-center">
                <h1 className="text-lg font-bold uppercase text-gray-600 font-regular mb-2">
                  No Scopes of Work Created YET
                </h1>
              </div>
              <div className="pl-10 pr-6 text-sm text-gray-500 font-semibold mb-2 flex flex-row items-center">
                Add one!
              </div>
            </div>
          </div>
        </div>
      </>
    );
  return (
    <div className="w-full my-auto pl-3 pt-1">
      <div className="flex flex-row items-center">
        <div className="w-full">
          <div className="pr-6 flex flex-row items-center">
            <h1 className="text-lg font-bold uppercase text-gray-600 font-regular mb-2">
              Scope Of Work - #{sow.identifier}
            </h1>
          </div>
          <div className="pr-6 text-sm text-gray-500 font-semibold mb-2 flex flex-row items-center">
            Contractual:{" "}
            {sow.startingAt
              ? format(new Date(sow.startingAt), "MM/dd/yyyy")
              : "TBD"}{" "}
            -{" "}
            {sow.endingAt
              ? format(new Date(sow.endingAt), "MM/dd/yyyy")
              : "TBD"}
          </div>
          <div className="inline-flex items-center px-3 py-1 shadow-sm rounded-lg text-xs font-bold bg-green-100 border border-green-500 text-green-700 md:mt-2 lg:mt-0">
            <WifiIcon className="mr-1" height={13} /> {sow.status}
          </div>
          <div className="border-b mb-3"></div>
          <div className="pr-6 flex flex-row items-center">
            <div className="ml-2 inline-flex flex-row items-center px-3 py-1 shadow-sm rounded-lg text-xs font-bold bg-green-100 border border-green-500 text-green-700 md:mt-2 lg:mt-0">
              <ClockIcon className="mr-1" height={13} /> {sow.billingType}
            </div>
            <div className="ml-2 inline-flex flex-row items-center px-3 py-1 shadow-sm rounded-lg text-xs font-bold bg-red-100 border border-red-500 text-red-700 md:mt-2 lg:mt-0">
              <ArrowPathIcon className="mr-1" height={13} /> Renews Soon
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
