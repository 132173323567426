import { gql } from "@apollo/client";

const CREATE_PROJECT = gql`
  mutation createProject($projectToCreate: ProjectCreateRequest!) {
    createProject(projectToCreate: $projectToCreate) {
      id
      name
    }
  }
`;

const UPDATE_PROJECT = gql`
  mutation updateProject($id: String, $updatedProject: ProjectUpdateRequest!) {
    updateProject(id: $id, updatedProject: $updatedProject) {
      id
      name
    }
  }
`;

const GET_PROJECTS = gql`
  query listProjects(
    $skip: Int
    $after: String
    $first: Int
    $where: JSON
    $orderBy: ProjectOrder
  ) {
    data: listProjects(
      skip: $skip
      after: $after
      first: $first
      where: $where
      orderBy: $orderBy
    ) {
      edges {
        cursor
        node {
          id
          name
          createdAt
          updatedAt
          inTriageFromIntegration
          status
          startingAt
          endingAt
          customer {
            id
            name
          }
          externalData {
            externalRelationships {
              sourceIntegration {
                id
                target
              }
            }
          }
        }
      }
      totalCount
      pageInfo {
        hasNextPage
      }
    }
  }
`;

const GET_PROJECT = gql`
  query getProject($id: String!, $projectionPeriod: Int) {
    data: getProject(id: $id, projectionPeriod: $projectionPeriod) {
      id
      name
      createdAt
      updatedAt
      scopesOfWork {
        id
        identifier
        billingType
        description
        status
        startingAt
        endingAt
        createdAt
        updatedAt
        rate
        rateCurrency
        rateTimeUnit
        cappedCost
        milestones {
          id
        }
        contractualDocumentAttachments {
          id
          attachmentTargetType
          startsAt
          endsAt
          document {
            id
            name
            activeVersionId
            type
            versions {
              fileNameInBucket
              versionIdentifier
            }
          }
        }
        jobRoleAssignments {
          id
          quantity
          targetFillDate
          recruitingStartDate
          role {
            id
            name
          }
        }
        resources {
          id
          description
          source
          status
          utilizationAmount
          utilizationAmountUnits
          utilizationAmountPerUnits
          billable
          rate
          rateCurrency
          rateTimeUnit
          costRate
          costRateCurrency
          costRateTimeUnit
          scopedRoleAttachment {
            scopedRoleId
          }
          calculated {
            finalRate
            finalRateCurrency
            finalRateTimeUnit
            finalCostRate
            finalCostRateCurrency
            finalCostRateTimeUnit
            finalMargin
            finalMarginTimeUnit
            finalMarginPercentage
            totalTrackedHours
          }
          employeeId
          employee {
            id
            firstName
            lastName
            baseRate
            rateTimeUnit
            rateCurrency
            baseCostRate
            costRateTimeUnit
            costRateCurrency
          }
          providerPersonnelId
          providerPersonnel {
            id
            firstName
            lastName
            baseCostRate
            costRateTimeUnit
            costRateCurrency
          }
        }
      }
      startingAt
      endingAt
      externalLinks {
        id
        name
        logoName
        url
        linkType
        securityType
      }
      tasks {
        id
        description
        name
        target
        action
      }
      documentsWithUrls {
        id
        name
        activeVersionId
        type
        presignedDownloadUrl
        presignedThumbnailUrl
        versions {
          fileNameInBucket
          versionIdentifier
        }
      }
      updates {
        text
        type
        documents {
          id
          name
          activeVersionId
          type
          versions {
            id
            versionIdentifier
          }
        }
      }
      pastInvoices {
        pastInvoices {
          id
          totalDue
          invoiceDate
          periodStartDate
          periodEndDate
          dueOn
          currency
          balance
          status
          documentsWithUrls {
            id
            name
            presignedDownloadUrl
            type
          }
        }
        actualBillableRevenue
        grossProfit
        cost
      }
      projectedInvoices {
        projectedInvoices {
          amount
          invoiceDate
          invoicePeriodStartDate
          invoicePeriodEndDate
          dueOn
          currency
        }
        actualBillableRevenue
        grossProfit
        grossProfitPercentage
        cost
        numberOfDays
        months {
          monthNumber
          monthName
          year
        }
      }
      customerId
      customer {
        id
        name
        invoicingConfiguration {
          workflowAttachments {
            id
            workflowSpecification {
              id
              name
            }
          }
          cadenceConfig {
            leadTime
            leadTimeUnits
            cadence
            cadenceUnits
            paymentTerms
            paymentTermsUnits
          }
        }
      }
      externalData {
        externalRelationships {
          sourceIntegration {
            id
            target
          }
        }
      }
    }
  }
`;

const GET_PREDICTED_INVOICE_FOR_PROJECT = gql`
  query getPredictedInvoiceForProject(
    $projectId: String!
    $dateOfInvoice: Date!
    $overrideInvoicePeriodStart: Date
    $overrideInvoicePeriodEnd: Date
  ) {
    data: getPredictedInvoiceForProject(
      id: $projectId
      dateOfInvoice: $dateOfInvoice
      overrideInvoicePeriodStart: $overrideInvoicePeriodStart
      overrideInvoicePeriodEnd: $overrideInvoicePeriodEnd
    ) {
      currency
      identifier
      dueOn
      invoiceDate
      totalDue
      balance
      currency
      subtotal
      lineItems {
        description
        serviceItem
        rate
        amount
        quantity
        identifier
      }
    }
  }
`;

const GET_PORTFOLIO_FINANCIALS = gql`
  query getPortfolioFinancials($numberOfDays: Int!) {
    data: getPortfolioFinancials(numberOfDays: $numberOfDays) {
      future {
        projects {
          project {
            name
          }
          projectId
          numberOfDays
          actualBillableRevenue
          grossProfit
          cost
          grossProfitPercentage
          billableResources {
            id
            description
            source
            status
            utilizationAmount
            utilizationAmountUnits
            utilizationAmountPerUnits
            billable
            rate
            rateCurrency
            rateTimeUnit
            costRate
            costRateCurrency
            costRateTimeUnit
            calculated {
              finalRate
              finalRateCurrency
              finalRateTimeUnit
              finalCostRate
              finalCostRateCurrency
              finalCostRateTimeUnit
              finalMargin
              finalMarginTimeUnit
              finalMarginPercentage
              totalTrackedHours
            }
            employeeId
            employee {
              id
              firstName
              lastName
              baseRate
              rateTimeUnit
              rateCurrency
              baseCostRate
              costRateTimeUnit
              costRateCurrency
            }
            providerPersonnelId
            providerPersonnel {
              id
              firstName
              lastName
              baseCostRate
              costRateTimeUnit
              costRateCurrency
            }
          }
        }
        total {
          numberOfDays
          actualBillableRevenue
          grossProfit
          cost
        }
      }
    }
  }
`;

export {
  GET_PROJECTS,
  GET_PROJECT,
  CREATE_PROJECT,
  GET_PORTFOLIO_FINANCIALS,
  GET_PREDICTED_INVOICE_FOR_PROJECT,
  UPDATE_PROJECT,
};
