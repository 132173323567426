import Card from "../../basics/Card/Card";
import { Props } from "../../data/types/Props";

interface BillingConfigDetailCardProps extends Props {
  title?: string;
  classNames?: string;
  invoicingConfig: any;
}

function BillingConfigDetailCard({
  classNames,
  style,
  title,
  invoicingConfig,
}: BillingConfigDetailCardProps) {
  return (
    <Card classNames={classNames} style={style}>
      <div className="flex flex-row">
        <div className="p-6 flex-1">
          <h3 className="text-xl font-medium mb-3">Billing Cadence</h3>
          <div>
            Cadence: {invoicingConfig?.cadenceConfig?.cadence}{" "}
            {invoicingConfig?.cadenceConfig?.cadenceUnits}
          </div>
          <div>
            Lead Time: {invoicingConfig?.cadenceConfig?.leadTime}{" "}
            {invoicingConfig?.cadenceConfig?.leadTimeUnits}
          </div>
          <div>
            Payment Terms: {invoicingConfig?.cadenceConfig?.paymentTerms}{" "}
            {invoicingConfig?.cadenceConfig?.paymentTermsUnits}
          </div>
        </div>
        <div className="p-6 flex-1 border-l">
          <h3 className="text-xl font-medium mb-3">Workflows</h3>
          {invoicingConfig?.workflowAttachments?.map((workflow: any) => {
            return (
              <div className="mb-5 p-3 bg-gray-100 rounded-md border">
                <div>{workflow.workflowSpecification.name}</div>
              </div>
            );
          })}
        </div>
      </div>
    </Card>
  );
}

export default BillingConfigDetailCard;
