import { TrashIcon, XMarkIcon } from "@heroicons/react/24/outline";
import {
  CheckIcon,
  ChevronDownIcon,
  PencilIcon,
} from "@heroicons/react/24/solid";
import { ReactNode } from "react";
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import PageHeader from "../PageHeader/PageHeader";

interface AddEditViewHeaderProps {
  entityName: string;
  onSubmit: any;
  onCancel: any;
  subheader?: ReactNode;
}

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export function AddEditViewHeader({
  entityName,
  onSubmit,
  onCancel,
  subheader,
}: AddEditViewHeaderProps) {
  return (
    <PageHeader
      disableBreadcrumbs={true}
      sticky={true}
      leftContent={
        <>
          <h2 className="text-xl font-semibold capitalize">Add {entityName}</h2>
        </>
      }
      disableSubheaderDivider={true}
      subheader={subheader}
      rightContent={
        <>
          <button
            type="button"
            onClick={onSubmit}
            className="mr-5 capitalize inline-flex text-sm shadow-md items-center px-3 py-1 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
          >
            <CheckIcon className="mr-2" height={18} />
            Save
          </button>
          <button
            type="button"
            onClick={onCancel}
            className="mr-5 capitalize inline-flex text-sm shadow-md items-center px-3 py-1 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-500 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
          >
            <XMarkIcon className="mr-2" height={18} />
            Cancel
          </button>
        </>
      }
    />
  );
}
