import { gql } from "@apollo/client";

const UPDATE_JOB_ROLE_ASSIGNMENT = gql`
  mutation updateJobRoleAssignment(
    $id: String!
    $updatedJobRoleAssignment: JobRoleAssignmentUpdateRequest!
  ) {
    updateJobRoleAssignment(
      id: $id
      updatedJobRoleAssignment: $updatedJobRoleAssignment
    ) {
      id
    }
  }
`;

const CREATE_JOB_ROLE_ASSIGNMENT = gql`
  mutation createJobRoleAssignment(
    $newJobRoleAssignment: JobRoleAssignmentCreateRequest!
  ) {
    createJobRoleAssignment(newJobRoleAssignment: $newJobRoleAssignment) {
      id
    }
  }
`;

export { UPDATE_JOB_ROLE_ASSIGNMENT, CREATE_JOB_ROLE_ASSIGNMENT };
