import { BaseModalProps } from "../../basics/ModalWrapper/ModalWrapper";
import MinimalForm from "../../components/MinimalForm/MinimalForm";
import { useForm } from "react-hook-form";
import SlideOverWrapper from "../../basics/SlideOverWrapper/SlideOverWrapper";
import { useEffect } from "react";
import { FormFieldConfiguration } from "../../data/types/Form";
import {
  formatProviderForOptionsList,
  formatProviderPersonnelForOptionsList,
  Provider,
} from "../../data/types/Provider";
import {
  Employee,
  formatEmployeeForOptionsList,
} from "../../data/types/Employee";
import { BUILD_RESOURCE_ASSIGNMENT_FORM_CONFIGURATION } from "../../data/types/ResourceAssignment";
import { EntityUnchangeableDataBlock } from "../../basics/EntityStaticDataBlock/EntityStaticDataBlock";
import { Link } from "react-router-dom";
import {
  formatScopedRoleForOptionsList,
  ScopedRole,
} from "../../data/types/Auth";
import { SectionHeader } from "../../basics/SectionHeader/SectionHeader";
import Card from "../../basics/Card/Card";
import { BasicButton } from "../../basics/Buttons/BasicButton";
import {
  RATE_TIMEUNITS_OPTIONS,
  UTILIZATION_AMOUNT_UNITS_OPTIONS,
  UTILIZATION_AMOUNT_UNITS_OPTIONS_PLURAL,
  YES_NO_BOOLEAN_OPTIONS,
} from "../../data/types/Shared";
import { formatFringeCostSetForOptionsList } from "../../data/types/FringeCost";

export interface ProjectScenarioAddEditSlideOverProps extends BaseModalProps {
  toEdit?: any;
  providers?: Provider[];
  employees?: Employee[];
  fringeCostSets?: any[];
}

let resourceFields: FormFieldConfiguration[] = [
  {
    name: "name",
    type: "textInput",
    placeholder: "Name",
    validation: { required: true },
    props: {
      label: "Name",
      width: 6,
    },
  },
  {
    name: "costRate",
    type: "textInput",
    placeholder: "Rate/Salary",
    validation: { valueAsNumber: true, required: true },
    props: {
      label: "Rate/Salary",
      width: 3,
      subType: "number",
    },
  },
  {
    name: "costRateTimeUnit",
    type: "select",
    options: RATE_TIMEUNITS_OPTIONS,
    placeholder: "Rate Per Period",
    validation: { required: true },
    props: {
      label: "Per",
      width: 3,
    },
  },
  {
    name: "fringeCostSetId",
    type: "select",
    options: [],
    placeholder: "Fringe & Tax Cost Set",
    props: {
      label: "Fringe & Tax Cost Set (Optional)",
      width: 6,
    },
  },
];

let utilizationFields: FormFieldConfiguration[] = [
  {
    name: "billable",
    type: "select",
    options: YES_NO_BOOLEAN_OPTIONS,
    validation: { required: true },
    placeholder: "Billable",
    props: {
      label: "Billable",
      width: 6,
    },
  },
  {
    name: "utilizationAmount",
    type: "textInput",
    placeholder: "Utilization Amount",
    validation: { required: true, valueAsNumber: true },
    props: {
      label: "Amount",
      width: 3,
      subType: "number",
    },
  },
  {
    name: "utilizationAmountUnits",
    type: "select",
    options: UTILIZATION_AMOUNT_UNITS_OPTIONS_PLURAL,
    placeholder: "Utilization Units",
    validation: { required: true },
    props: {
      label: "Units",
      width: 3,
    },
  },
  {
    name: "utilizationAmountPerUnits",
    type: "select",
    options: UTILIZATION_AMOUNT_UNITS_OPTIONS,
    placeholder: "Utilization Per Units",
    validation: { required: true },
    props: {
      label: "Per",
      width: 6,
    },
  },

  {
    name: "rate",
    type: "textInput",
    placeholder: "Override Bill Rate",
    validation: { valueAsNumber: true, required: true },
    props: {
      label: "Override Bill Rate",
      width: 3,
      subType: "number",
    },
  },
  {
    name: "rateTimeUnit",
    type: "select",
    options: RATE_TIMEUNITS_OPTIONS,
    placeholder: "Rate Per Period",
    validation: { required: true },
    props: {
      label: "Per",
      width: 3,
    },
  },
];

function ProjectScenarioAddEditSlideOver({
  open,
  setOpen,
  onSubmit,
  toEdit,
  providers,
  employees,
}: ProjectScenarioAddEditSlideOverProps) {
  let initialValues: any = {};

  const form = useForm<FormData>({ defaultValues: initialValues });
  const { errors, setValue, handleSubmit, watch } = form;
  /**
   * Set Initial Values for Form
   */
  useEffect(() => {
    if (toEdit && open) {
      setTimeout(() => {
        Object.keys(toEdit).forEach((k) => {
          setValue(k, toEdit[k]);
        });
      });
    }
  }, [toEdit, open]);

  /* SET FIELDS */
  let fields: FormFieldConfiguration[] =
    BUILD_RESOURCE_ASSIGNMENT_FORM_CONFIGURATION(toEdit).fields;

  const submitForm = (data: any) => {
    delete data["providerMainId"];
    return toEdit ? onSubmit(data, toEdit.id) : onSubmit(data);
  };

  const renderEdit = () => {
    const providerPersonnel: any[] = [];
    providers?.forEach((p: any) => {
      p.personnel.forEach((p2: any) => {
        providerPersonnel.push(formatProviderPersonnelForOptionsList(p2));
      });
    });
    return (
      <MinimalForm
        fields={fields}
        optionsMap={{
          providerPersonnel: providerPersonnel,
          providers: providers?.map((v: any) =>
            formatProviderForOptionsList(v)
          ),
          employees: employees?.map((e) => formatEmployeeForOptionsList(e)),
        }}
        submitText={"Submit"}
        form={form}
        onSubmit={submitForm}
      />
    );
  };

  return (
    <SlideOverWrapper
      title={`Add or edit team assignment`}
      open={open}
      setOpen={setOpen}
    >
      {toEdit && (
        <EntityUnchangeableDataBlock
          title={
            <>
              {toEdit.source === "EMPLOYEE" ? "Employee" : "Provider Personnel"}
              :{" "}
              <Link
                to={
                  toEdit.source === "EMPLOYEE"
                    ? "/employees/" + toEdit.employee?.id
                    : "/providers/" + toEdit.providerPersonnel?.id
                }
              >
                <a className="text-blue-700 underline font-bold capitalize">
                  {toEdit.source === "EMPLOYEE"
                    ? toEdit.employee?.firstName +
                      " " +
                      toEdit.employee?.lastName
                    : toEdit.providerPersonnel?.firstName +
                      " " +
                      toEdit.providerPersonnel?.lastName}
                </a>
              </Link>
            </>
          }
        />
      )}
      {renderEdit()}
    </SlideOverWrapper>
  );
}

export default ProjectScenarioAddEditSlideOver;
