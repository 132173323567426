export function SectionHeader({
  title,
  button,
  rightContent,
  borderEnabled,
  classNames,
}: any) {
  return (
    <div
      style={{ minHeight: "30px" }}
      className={
        "flex flex-row items-center mb-2 " +
        (borderEnabled ? " border-b pb-3 " : "") +
        (classNames ? classNames : "")
      }
    >
      <h1 className="text-md font-semibold text-gray-500">{title}</h1>
      {button && (
        <>
          <div className="flex-1"></div>
          <div className="ml-4">{button}</div>
        </>
      )}
      {rightContent && (
        <>
          <div className="flex-1"></div>
          <div>{rightContent}</div>
        </>
      )}
    </div>
  );
}
