import { MAX_INNER_PAGE_WIDTH } from "../../data/constants";

export const DefaultPagePaddingAndWidth = ({
  children,
  className,
  style,
}: any) => {
  return (
    <div
      className={`mx-auto w-full px-5 md:px-3 md:w-auto ${className}`}
      style={{ maxWidth: "100%", width: MAX_INNER_PAGE_WIDTH, ...style }}
    >
      {children}
    </div>
  );
};
