import { TableColumn } from "../../basics/Table/Table";
import { DetailViewConfiguration } from "../../components/BaseDetailView/BaseDetailView";
import { FormConfiguration } from "./Form";
import { BaseApiEntity } from "./base/BaseEntity";
import { CURRENCIES, RATE_TIMEUNITS } from "./Shared";

export interface FringeCostItem extends BaseApiEntity {
  name: string;
  type: string;
}

export const formatFringeCostSetForOptionsList = (e: any) => {
  return {
    label: e.name,
    value: e.id,
  };
};

export const formatFringeCostItemForOptionsList = (e: FringeCostItem) => {
  return {
    label: e.name,
    value: e.id,
  };
};

export const FRINGE_COST_ITEMS_TABLE_CONFIGURATION: TableColumn[] = [
  {
    key: "name",
    label: "Name",
  },
  {
    key: "type",
    label: "Type",
  },
  {
    key: "rate",
    label: "Per Person Normalized Cost",
    type: "rate",
    typeConfiguration: {
      percentageOf: "salary",
      rateUnitKey: "rateCurrency",
      rateTimeUnitKey: "rateTimeUnit",
      rateValueTypeKey: "rateValueType",
      daysUnitKey: "days",
    },
  },
  {
    key: "assignedToAmount",
    label: "# Assigned To",
  },
];
