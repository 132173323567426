import { gql } from "@apollo/client";

const GET_FRINGE_COST_SETS = gql`
  query listFringeCostSets(
    $skip: Int
    $after: String
    $first: Int
    $query: String
    $orderBy: FringeCostSetOrder
  ) {
    data: listFringeCostSets(
      skip: $skip
      after: $after
      first: $first
      query: $query
      orderBy: $orderBy
    ) {
      edges {
        cursor
        node {
          id
          name
          description
          items {
            id
            type
            name
          }
          createdAt
          updatedAt
        }
      }
      totalCount
      pageInfo {
        hasNextPage
      }
    }
  }
`;

const GET_FRINGE_COST_ITEMS = gql`
  query listFringeCostItems(
    $skip: Int
    $after: String
    $first: Int
    $query: String
    $orderBy: FringeCostItemOrder
  ) {
    data: listFringeCostItems(
      skip: $skip
      after: $after
      first: $first
      query: $query
      orderBy: $orderBy
    ) {
      edges {
        cursor
        node {
          id
          createdAt
          updatedAt
          type
          name
          days
          rate
          rateTimeUnit
          rateCurrency
          rateValueType
        }
      }
      totalCount
      pageInfo {
        hasNextPage
      }
    }
  }
`;

export { GET_FRINGE_COST_SETS, GET_FRINGE_COST_ITEMS };
