import ListView from "../../components/ListView/ListView";
import { DELETE_EMPLOYEES, GET_EMPLOYEES } from "../../data/queries/employees";
import { useEffect, useState } from "react";
import {
  Employee,
  EMPLOYEE_TABLE_CONFIGURATION,
} from "../../data/types/Employee";
import EmployeeAddEditSlideOver from "./EmployeeAddEditSlideOver";
import { GET_FRINGE_COST_SETS } from "../../data/queries/fringeCosts";
import {
  LazyListQuerySM,
  useLazyListQuerySM,
  useMutationSM,
} from "../../data/machines/queries";

export function Employees() {
  const [state, data, send]: LazyListQuerySM<Employee> =
    useLazyListQuerySM(GET_EMPLOYEES);
  const [stateDeleteEmployees, deleteEmployees] = useMutationSM(
    DELETE_EMPLOYEES,
    { onCompleted: () => send({ type: "REFETCH" }) }
  );
  const [cursor, setCursor] = useState(null);
  const [stateFringe, fringeData, sendFringe] =
    useLazyListQuerySM(GET_FRINGE_COST_SETS);
  const [isEmployeeAddModalOpen, setEmployeeAddModalOpen] = useState(false);

  useEffect(() => {
    send({ type: "MAKE_QUERY", variables: { cursor } });
    sendFringe({ type: "MAKE_QUERY", variables: { cursor } });
  }, [cursor]);

  const onDeleteEmployees = (ids: any[]) => {
    console.log("DELETING", ids);
    deleteEmployees({
      ids,
    });
  };

  return (
    <>
      <ListView
        title={"Employees"}
        data={state.value === "completed" ? data : undefined}
        state={state.value}
        error={state.value === "error" ? state.context.error : undefined}
        columns={EMPLOYEE_TABLE_CONFIGURATION}
        onAddNew={() => setEmployeeAddModalOpen(true)}
        cursor={cursor || 0}
        setCursor={setCursor}
        onDelete={(toDelete: any[]) => {
          onDeleteEmployees(
            toDelete.map((a) => {
              return a.id;
            })
          );
        }}
      />
      <EmployeeAddEditSlideOver
        open={isEmployeeAddModalOpen}
        setOpen={setEmployeeAddModalOpen}
        onSubmit={() => {}}
        fringeCostSets={
          stateFringe.value === "completed" ? fringeData.edges : []
        }
      />
    </>
  );
}
