import { Props } from "../../data/types/Props";

export interface CardProps extends Props {
  dashboardStyle?: boolean;
}

function Card({ children, classNames, style, dashboardStyle }: CardProps) {
  return (
    <div
      style={{ ...style }}
      className={
        "bg-white border-gray-200 dark:bg-black " +
        classNames + " " + (dashboardStyle ? "border-gray-100 border" : " border sm:rounded-lg ")
      }
    >
      {children}
    </div>
  );
}

export default Card;
