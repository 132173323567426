export const convertCustomerProjectsToReactFlowElements = (projects: any) => {
  let elements: any[] = [];
  projects.forEach((project: any, index: number) => {
    const newNode = {
      id: `dndnode_${index}`,
      position: { x: 100 + index * 100, y: 125 + index * 100 },
      data: {
        label: project.name,
        id: Number(index),
      },
    };
    elements.push(newNode);
    // Add edge
    if (project.parentProjectId) {
      let parentIndex = projects.findIndex(
        (p: any) => p.id === project.parentProjectId
      );
      const newEdge = {
        id: `e-${index}-${parentIndex}`,
        type: "default",
        source: `dndnode_${index}`,
        target: `dndnode_${parentIndex}`,
      };
      elements.push(newEdge);
    }
  });
  return elements;
};
