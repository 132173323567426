import { BaseModalProps } from "../../basics/ModalWrapper/ModalWrapper";
import MinimalForm from "../../components/MinimalForm/MinimalForm";
import { useForm } from "react-hook-form";
import SlideOverWrapper from "../../basics/SlideOverWrapper/SlideOverWrapper";
import { useEffect } from "react";
import { FormFieldConfiguration } from "../../data/types/Form";
import { BUILD_EMPLOYEE_FORM_CONFIGURATION } from "../../data/types/Employee";
import { formatFringeCostSetForOptionsList } from "../../data/types/FringeCost";

export interface EmployeeAddEditSlideOverProps extends BaseModalProps {
  toEdit?: any;
  fringeCostSets: any[]; // todo replace with model
}

function EmployeeAddEditSlideOver({
  open,
  setOpen,
  onSubmit,
  toEdit,
  fringeCostSets,
}: EmployeeAddEditSlideOverProps) {
  let initialValues: any = {};

  const form = useForm<FormData>({ defaultValues: initialValues });
  const { errors, setValue, handleSubmit, watch } = form;
  /**
   * Set Initial Values for Form
   */
  useEffect(() => {
    if (toEdit && open) {
      setTimeout(() => {
        Object.keys(toEdit).forEach((k) => {
          setValue(k, toEdit[k]);
        });
      });
    }
  }, [toEdit, open]);

  /* SET FIELDS */
  let fields: FormFieldConfiguration[] =
    BUILD_EMPLOYEE_FORM_CONFIGURATION(toEdit).fields;

  const submitForm = (data: any) => {
    return toEdit ? onSubmit(data, toEdit.id) : onSubmit(data);
  };

  const renderEdit = () => {
    return (
      <MinimalForm
        fields={fields}
        optionsMap={{
          fringeCostSets: fringeCostSets?.map((e) =>
            formatFringeCostSetForOptionsList(e.node)
          ),
        }}
        submitText={"Submit"}
        form={form}
        onSubmit={submitForm}
      />
    );
  };

  return (
    <SlideOverWrapper
      title={`Add or edit Employee`}
      subtitle={"Creating an employee will also create a new user"}
      open={open}
      setOpen={setOpen}
    >
      {renderEdit()}
    </SlideOverWrapper>
  );
}

export default EmployeeAddEditSlideOver;
