import {
  ChatBubbleBottomCenterIcon,
  ChevronDoubleRightIcon,
  ChevronUpIcon,
  ClockIcon,
  CurrencyDollarIcon,
  InboxIcon,
} from "@heroicons/react/24/outline";
import { PaperAirplaneIcon } from "@heroicons/react/24/solid";
import DisplayNode from "../pages/WorkflowConfigurations/NodeTypes/DisplayNode";
import DropdownNode from "../pages/WorkflowConfigurations/NodeTypes/DropdownNode";

export const WORKFLOW_STEP_TYPES = [
  {
    name: "Trigger",
    identifier: "TRIGGER",
    icon: ChevronDoubleRightIcon,
    style: {
      border: "1px solid #777",
      borderRadius: "10px",
      minWidth: "200px",
      background: "#fff",
      padding: 12,
    },
    nodeType: "displayNode",
    options: {
      nodeConnectionType: "input",
      placeholder: "Choose Trigger",
      dropdownOptions: [
        {
          label: "Invoice Sent",
          value: "INVOICE_SENT",
        },
        {
          label: "Invoice 30 days overdue",
          value: "INVOICE_OVERDUE_30",
        },
        {
          label: "Invoice 60 days overdue",
          value: "INVOICE_OVERDUE_60",
        },
      ],
    },
  },
  {
    name: "Wait",
    identifier: "WAIT",
    style: {
      border: "1px solid #777",
      borderRadius: "10px",
      minWidth: "200px",
      background: "#fff",
      padding: 12,
    },
    options: {
      placeholder: "Choose wait time",
      dropdownOptions: [
        {
          label: "1 Day",
          value: 600,
        },
        {
          label: "2 Days",
          value: 3600,
        },
      ],
    },
    icon: ClockIcon,
    nodeType: "displayNode",
  },
  {
    name: "Repeat",
    identifier: "REPEAT",
    style: {
      border: "1px solid #777",
      borderRadius: "10px",
      minWidth: "200px",
      background: "#fff",
      padding: 12,
    },
    options: {
      placeholder: "Choose step to repeat until",
      dropdownOptions: [
        {
          label: "1 Day",
          value: 600,
        },
      ],
    },
    icon: ClockIcon,
    nodeType: "displayNode",
  },
  {
    name: "Create Invoice",
    identifier: "CREATE_INVOICE",
    style: {
      border: "1px solid #777",
      borderRadius: "10px",
      minWidth: "200px",
      background: "#fff",
      padding: 12,
    },
    options: {
      placeholder: "Choose wait time",
      dropdownOptions: [
        {
          label: "1 Day",
          value: 600,
        },
        {
          label: "2 Days",
          value: 3600,
        },
      ],
    },
    icon: ClockIcon,
    nodeType: "displayNode",
  },
  {
    name: "Create Task",
    identifier: "MANUAL_TASK",
    style: {
      border: "1px solid #777",
      borderRadius: "10px",
      minWidth: "200px",
      background: "#fff",
      padding: 12,
    },
    options: {
      placeholder: "Choose wait time",
      dropdownOptions: [
        {
          label: "1 Day",
          value: 600,
        },
        {
          label: "2 Days",
          value: 3600,
        },
      ],
    },
    icon: ClockIcon,
    nodeType: "displayNode",
  },
  {
    name: "Email",
    identifier: "EMAIL",
    icon: InboxIcon,
    style: {
      border: "1px solid #777",
      borderRadius: "10px",
      minWidth: "200px",
      background: "#fff",
      padding: 12,
    },
    options: {
      placeholder: "Choose wait time",
      dropdownOptions: [
        {
          label: "1 Day",
          value: 600,
        },
        {
          label: "2 Days",
          value: 3600,
        },
      ],
    },
    nodeType: "displayNode",
  },
  {
    name: "SMS",
    identifier: "SMS",
    icon: ChatBubbleBottomCenterIcon,
    style: {
      border: "1px solid #777",
      borderRadius: "10px",
      minWidth: "200px",
      background: "#fff",
      padding: 12,
    },
    options: {
      placeholder: "Choose wait time",
      dropdownOptions: [
        {
          label: "1 Day",
          value: 600,
        },
        {
          label: "2 Days",
          value: 3600,
        },
      ],
    },
    nodeType: "displayNode",
  },
  {
    name: "Message external integration",
    identifier: "EXTERNAL_INTEGRATION_MESSAGE",
    icon: ChatBubbleBottomCenterIcon,
    style: {
      border: "1px solid #777",
      borderRadius: "10px",
      minWidth: "200px",
      background: "#fff",
      padding: 12,
    },
    options: {
      placeholder: "Choose wait time",
      dropdownOptions: [
        {
          label: "1 Day",
          value: 600,
        },
        {
          label: "2 Days",
          value: 3600,
        },
      ],
    },
    nodeType: "displayNode",
  },
  {
    name: "Direct mail",
    identifier: "PHYSICAL_MAIL",
    icon: PaperAirplaneIcon,
    style: {
      border: "1px solid #777",
      borderRadius: "10px",
      minWidth: "200px",
      background: "#fff",
      padding: 12,
    },
    options: {
      placeholder: "Choose wait time",
      dropdownOptions: [
        {
          label: "1 Day",
          value: 600,
        },
        {
          label: "2 Days",
          value: 3600,
        },
      ],
    },
    nodeType: "displayNode",
  },
];

export const WORKFLOW_NODE_TYPES = {
  selectorNode: DropdownNode,
  displayNode: DisplayNode,
};

export const convertWorkflowSpecificationToReactFlowElements = (
  workflow: any
) => {
  let steps = workflow?.specification?.steps;
  let elements: any[] = [];
  steps.forEach((step: any, index: number) => {
    const newNode = {
      id: `dndnode_${step.id}`,
      type: WORKFLOW_STEP_TYPES.find((t) => t.identifier === step.type)
        ?.nodeType,
      position: { x: 100 + index * 100, y: 125 + index * 100 },
      style: WORKFLOW_STEP_TYPES.find((t) => t.identifier === step.type)?.style,
      data: {
        label: WORKFLOW_STEP_TYPES.find((t) => t.identifier === step.type)
          ?.name,
        type: step.type,
        icon: WORKFLOW_STEP_TYPES.find((t) => t.identifier === step.type)?.icon,
        options: WORKFLOW_STEP_TYPES.find((t) => t.identifier === step.type)
          ?.options,
        id: Number(step.id),
        rawStep: step,
      },
    };
    elements.push(newNode);
    // Add edge
    if (step.nextStepId) {
      const newEdge = {
        id: `e-${step.id}-${step.nextStepId}`,
        type: "default",
        source: `dndnode_${step.id}`,
        target: `dndnode_${step.nextStepId}`,
      };
      elements.push(newEdge);
    }
  });
  return elements;
};

export const convertReactFlowElementstoWorkflowSpec = (
  elements: any,
  workflo: any
) => {
  let workfl = { ...workflo };
  let steps = elements
    .filter((a: any) => {
      if (a.source != null) return false;
      return true;
    })
    .map((e: any) => {
      return {
        ...e.data.rawStep,
        id: e.data.id,
        type: e.data.type,
      };
    });

  // Add to and from
  elements
    .filter((a: any) => {
      if (a.source == null) return false;
      return true;
    })
    .forEach((e: any) => {
      // looking for from
      let sourceId = e.source.replace("dndnode_", "");
      let targetId = e.target.replace("dndnode_", "");
      steps.forEach((s: any) => {
        if (s.id === sourceId) {
          s.nextStepId = targetId;
        }
      });
    });

  workfl = {
    name: workfl.name,
    id: workfl.id,
    specification: {
      steps: steps,
    },
  };
  return workfl;
};

export const translateEntities = (entity: string) => {
  const mapping: any = {
    INVOICE: "Invoices",
  };
  return mapping[entity];
};

export const translateOperator = (operator: string) => {
  switch (operator) {
    case "EQUALS":
      return "equals";
    case "GREATER_THAN":
      return "is more than";
    default:
      return operator;
  }
};

export const translateSpecialValueCase = (value: string) => {
  switch (value) {
    case "CURRENT_DATE":
      return "today";
    default:
      if (value.indexOf("DATE_NET_") > -1) {
        return value.split("_NET_")[1] + " days past";
      }
      return value;
  }
};

export const translateFieldToName = (field: string) => {
  const mapping: any = {
    dueOn: "Due Date",
    createdAt: "Created Date",
    updatedAt: "Last Updated",
  };
  return mapping[field] || "";
};
