import { FormConfiguration, FormFieldOption } from "../../data/types/Form";
import { BaseApiEntity } from "./base/BaseEntity";

export interface WorkflowSpecification extends BaseApiEntity {}

export enum WorkflowConfigurationStepType {
  /* Special Types that don't trigger an activity */
  TRIGGER = "TRIGGER",
  /* Special Types with a specific non-dynamic activity */
  WAIT = "WAIT",
  REPEAT = "REPEAT",
  /* Dynamic Activity Types */
  EMAIL = "EMAIL",
  SMS = "SMS",
  MANUAL_TASK = "MANUAL_TASK",
  USER_NOTIFICATION = "USER_NOTIFICATION",
  SYNC_INTEGRATION = "SYNC_INTEGRATION",
  CONDITIONAL_CONTINUE_OR_FAIL = "CONDITIONAL_CONTINUE_OR_FAIL",
  CONDITIONAL_FORKING_PATHS = "CONDITIONAL_FORKING_PATHS", // Could eventually be a child workflow...
}

export const WORKFLOW_CONFIGURATION_STEP_TYPES_OPTIONS: FormFieldOption[] = [
  { label: "Trigger", value: "TRIGGER" },
  { label: "Wait", value: "WAIT" },
  { label: "Email", value: "EMAIL" },
  { label: "SMS", value: "SMS" },
  { label: "Manual Task", value: "MANUAL_TASK" },
  { label: "User Notification", value: "USER_NOTIFICATION" },
];

export enum WorkflowConfigurationTriggerRelationEntity {
  INVOICE = "INVOICE",
  CUSTOMER = "CUSTOMER",
  PROJECT = "PROJECR",
  PAYMENT = "PAYMENT",
  PROVIDER = "PROVIDER",
  BILLABLE_RESOURCE = "BILLABLE_RESOURCE",
  USER = "USER",
}

export const WORKFLOW_CONFIGURATION_TRIGGER_RELATION_ENTITY_OPTIONS: FormFieldOption[] =
  [
    {
      label: "Invoice",
      value: WorkflowConfigurationTriggerRelationEntity.INVOICE,
    },
    {
      label: "Customer",
      value: WorkflowConfigurationTriggerRelationEntity.CUSTOMER,
    },
    {
      label: "Project",
      value: WorkflowConfigurationTriggerRelationEntity.PROJECT,
    },
  ];

enum WorkflowConfigurationTriggerRelationLogicSpecial {
  INVOICES_IN_CUSTOMER = "INVOICES_IN_CUSTOMER",
}

export const WORKFLOW_CONFIGURATION_TRIGGER_RELATION_LOGIC_SPECIAL_OPTIONS: FormFieldOption[] =
  [
    {
      label: "Evaluate for all customer invoices",
      value:
        WorkflowConfigurationTriggerRelationLogicSpecial.INVOICES_IN_CUSTOMER,
    },
  ];

export const BUILD_WORKFLOW_STEP_FORM_CONFIGURATION = (
  isEdit: any,
  addToStream: any // Adding to an existing stream, disable triggers etc
): FormConfiguration => {
  return {
    requiredOptionsLoads: [],
    fields: [
      {
        name: "Step Type",
        type: "divider",
        toShow: {
          boolean: !isEdit,
        },
      },
      {
        name: "type",
        type: "select",
        placeholder: "Select a step type",
        options: addToStream
          ? WORKFLOW_CONFIGURATION_STEP_TYPES_OPTIONS.filter(
              (f) => f.value !== "TRIGGER"
            )
          : WORKFLOW_CONFIGURATION_STEP_TYPES_OPTIONS,
        toShow: {
          boolean: !isEdit,
        },
        validation: { required: true },
        props: {
          label: "Step Type",
          width: 6,
        },
      },
      /* WAIT */
      {
        name: "Wait Configuration",
        type: "divider",
        toShow: {
          boolean: isEdit && isEdit.type === "WAIT",
          watch: {
            toWatch: "type",
            toShowValue: "WAIT",
          },
        },
      },
      {
        name: "stepConfig.WAIT.waitInMilliseconds",
        type: "textInput",
        placeholder: "Select a step type",
        toShow: {
          boolean: isEdit && isEdit.type === "WAIT",
          watch: {
            toWatch: "type",
            toShowValue: "WAIT",
          },
        },
        validation: { required: true },
        props: {
          label: "Wait amount (milliseconds)",
          width: 6,
        },
      },
      /* EMAIL */
      {
        name: "Email Configuration",
        type: "divider",
        toShow: {
          boolean: isEdit && isEdit.type === "EMAIL",
          watch: {
            toWatch: "type",
            toShowValue: "EMAIL",
          },
        },
      },
      {
        name: "stepConfig.EMAIL.textTemplateOverride",
        type: "textArea",
        placeholder: "Email Template",
        toShow: {
          boolean: isEdit && isEdit.type === "EMAIL",
          watch: {
            toWatch: "type",
            toShowValue: "EMAIL",
          },
        },
        validation: { required: true },
        props: {
          label: "Email Template",
          width: 6,
        },
      },
      /* TRIGGER */
      {
        name: "Trigger Entity",
        type: "divider",
        toShow: {
          boolean: isEdit && isEdit.type === "TRIGGER",
          watch: {
            toWatch: "type",
            toShowValue: "TRIGGER",
          },
        },
      },
      {
        name: "triggerRelation.entity",
        type: "select",
        options: WORKFLOW_CONFIGURATION_TRIGGER_RELATION_ENTITY_OPTIONS,
        placeholder: "Trigger Entity",
        toShow: {
          boolean: isEdit && isEdit.type === "TRIGGER",
          watch: {
            toWatch: "type",
            toShowValue: "TRIGGER",
          },
        },
        validation: { required: true, valueAsNumber: true },
        props: {
          label: "Trigger Entity",
          width: 6,
        },
      },
      {
        name: "triggerRelation.whereSpecial",
        type: "select",
        options: WORKFLOW_CONFIGURATION_TRIGGER_RELATION_LOGIC_SPECIAL_OPTIONS,
        placeholder: "Selector",
        toShow: {
          boolean: isEdit && isEdit.type === "TRIGGER",
          watch: {
            toWatch: "type",
            toShowValue: "TRIGGER",
          },
        },
        validation: { required: true, valueAsNumber: true },
        props: {
          label: "Selector",
          width: 6,
        },
      },
      {
        name: "Trigger Conditions",
        type: "divider",
        toShow: {
          boolean: isEdit && isEdit.type === "TRIGGER",
          watch: {
            toWatch: "type",
            toShowValue: "TRIGGER",
          },
        },
      },
    ],
  };
};
