import { BasicButton, BasicButtonProps } from "./BasicButton";

export interface IconButtonProps extends BasicButtonProps {
  icon: any;
  iconClassNames?: string;
}

export function IconButton(props: IconButtonProps) {
  return (
    <BasicButton
      {...props}
      text={
        <>
          <props.icon className={`h-4 -ml-1 ${props.iconClassNames}`} />{" "}
          <div className={`ml-1`}>{props.text}</div>
        </>
      }
    />
  );
}
