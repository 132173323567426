import { useLazyQuery } from "@apollo/client";
import Table from "../../basics/Table/Table";
import { GET_CUSTOMER, GET_CUSTOMERS } from "../../data/queries/customers";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { GET_INVOICES } from "../../data/queries/invoices";
import { formatBaseItemForList } from "../../utilities/dates";
import { CUSTOMER_INVOICE_TABLE_CONFIGURATION } from "../../data/types/Invoice";
import { CUSTOMER_PROJECT_TABLE_CONFIGURATION } from "../../data/types/Project";
import { DetailViewHeader } from "../../components/DetailViewHeader/DetailViewHeader";
import HeroCardGrid from "../../basics/HeroCardGrid/HeroCardGrid";
import { SecondarySidemenu } from "../../components/SecondarySidemenu/SecondarySidemenu";
import {
  BriefcaseIcon,
  CogIcon,
  CurrencyDollarIcon,
  MapIcon,
  PresentationChartBarIcon,
  QueueListIcon,
  UsersIcon,
} from "@heroicons/react/24/solid";
import { projectSowStatusToBadgeColor } from "../../data/types/ProjectScopeOfWork";
import Tabs from "../../basics/Tabs/Tabs";
import ReactFlow, {
  MiniMap,
  Controls,
  Background,
  ReactFlowProvider,
  addEdge,
  removeElements,
  BackgroundVariant,
  isEdge,
} from "react-flow-renderer";
import { convertCustomerProjectsToReactFlowElements } from "../../utilities/projects";
import { IconButton } from "../../basics/Buttons/IconButton";
import { BasicButtonStyles } from "../../basics/Buttons/BasicButton";
import SidemenuClearListTabs from "../../components/SidemenuClearListTabs/SidemenuClearListTabs";
import { EntityMergeSlideOver } from "../../components/EntityMergeSlideOver/EntityMergeSlideOver";
import { MERGE_SUPPORTED_ENTITIES } from "../../data/types/MergeEntities";
import {
  CUSTOMER_MERGE_CONFIGURATION,
  formatCustomerForOptionsList,
} from "../../data/types/Customer";
import { DefaultPagePaddingAndWidth } from "../../basics/DefaultPagePaddingAndWidth/DefaultPagePaddingAndWidth";

export const customerStatusToBadgeColor = (status: string) => {
  if (status === "ACTIVE") {
    return "bg-green-100 text-green-800";
  } else {
    return "bg-yellow-100 text-yellow-800";
  }
};

export const customerStatusToBadgeText = (status: string) => {
  if (status === "ACTIVE") {
    return "Active";
  } else {
    return "Inactive";
  }
};

const initialElements: any[] = [];

export function Customer() {
  const [getData, { loading, error, data }] = useLazyQuery(GET_CUSTOMER);
  const [getInvoices, getInvoicesResult] = useLazyQuery(GET_INVOICES);
  const location = useLocation();
  const reactFlowWrapper: any = useRef(null);
  const [reactFlowInstance, setReactFlowInstance]: any = useState(null);
  const [tab, setTab] = useState("projects");
  const [projectTab, selectProjectTab] = useState("list");
  const [cursor, setCursor] = useState(null);
  const [mergeOpen, setMergeOpen] = useState(false);
  const [elements, setElements]: any = useState(initialElements);
  const segments = location.pathname.split("/");
  const id = segments[segments.length - 1];

  let customer: any = {};

  useEffect(() => {
    getData({ variables: { id: id } });
    getInvoices({
      variables: {
        where: {
          customerId: id,
        },
      },
    });
  }, []);

  const onLoad = (_reactFlowInstance: any) =>
    setReactFlowInstance(_reactFlowInstance);

  if (!loading && data) {
    customer = data.data;
    customer = {
      ...customer,
      badgeColor: customerStatusToBadgeColor(customer.status),
      projects: customer.projects.map((p: any) => {
        return {
          ...p,
          badgeColor: projectSowStatusToBadgeColor(p?.status),
        };
      }),
    };
    if (
      customer.projects &&
      customer.projects.length > 0 &&
      elements.length == 0
    ) {
      setElements(
        convertCustomerProjectsToReactFlowElements(customer.projects)
      );
    }
  }

  const renderFinancialsTab = () => {
    return (
      <div className="flex-1 px-2 px-8 pt-6">
        <div className="py-2">
          <h2 className="text-lg font-semibold text-gray-900">Invoices</h2>
        </div>
        <Table
          data={getInvoicesResult?.data?.data?.edges?.map((n: any) =>
            formatBaseItemForList(n.node)
          )}
          columns={CUSTOMER_INVOICE_TABLE_CONFIGURATION}
          start={cursor ? Number(cursor) : 1}
          end={getInvoicesResult?.data?.data?.totalCount + Number(cursor)}
          onChangePage={setCursor}
          onSelectItem={(i: any) => console.log(i)}
        />
      </div>
    );
  };

  const renderSettingsTab = () => {
    return (
      <>
        Invoicing Configuration <br></br> Portal configuration
      </>
    );
  };

  const renderProjectsTab = () => {
    return (
      <div className="flex-1 px-2 px-8 pt-6">
        <Tabs
          mode="CONTENT"
          activeTab={projectTab}
          setTab={selectProjectTab}
          tabs={[
            {
              name: "Project Map",
              identifier: "map",
              icon: <MapIcon height={16} />,
              content: (
                <div className="" style={{ height: "calc(100% - 80px)" }}>
                  <ReactFlow elements={elements} onLoad={onLoad}>
                    <Background
                      variant={BackgroundVariant.Lines}
                      gap={20}
                      size={2}
                    />
                  </ReactFlow>
                </div>
              ),
            },
            {
              name: "List",
              identifier: "list",
              icon: <QueueListIcon height={16} />,
              content: (
                <Table
                  data={customer?.projects?.map((n: any) =>
                    formatBaseItemForList(n)
                  )}
                  columns={CUSTOMER_PROJECT_TABLE_CONFIGURATION}
                  start={cursor ? Number(cursor) : 1}
                  end={
                    getInvoicesResult?.data?.data?.totalCount + Number(cursor)
                  }
                  onChangePage={setCursor}
                  onSelectItem={(i: any) => console.log(i)}
                />
              ),
            },
          ]}
        />
      </div>
    );
  };

  return (
    <>
      <DetailViewHeader
        name={customer.name}
        entityName="Customer"
        onEdit={() => {}}
        onMerge={() => setMergeOpen(true)}
        hideEdit={true}
        externalData={customer?.externalData}
        additionalActions={
          <IconButton
            icon={PresentationChartBarIcon}
            color="blue"
            iconClassNames={"mr-2"}
            onClick={() => {}}
            classNames={"mr-3"}
            text="View Customer Portal"
          />
        }
      />
      <DefaultPagePaddingAndWidth>
        <div className="flex flex-row mt-10">
          <SidemenuClearListTabs
            classNames="w-60 bg-none"
            getter={tab}
            setter={setTab}
            items={[
              {
                identifier: "projects",
                icon: BriefcaseIcon,
                text: "Projects",
              },
              {
                identifier: "financials",
                icon: CurrencyDollarIcon,
                text: "Aggregate Financials",
              },
              {
                identifier: "users",
                icon: UsersIcon,
                text: "Stakeholders",
              },
              {
                identifier: "settings",
                icon: CogIcon,
                text: "Customer Settings",
              },
            ]}
          />
          {tab === "projects"
            ? renderProjectsTab()
            : tab === "settings"
            ? renderSettingsTab()
            : renderFinancialsTab()}
        </div>
      </DefaultPagePaddingAndWidth>
      <EntityMergeSlideOver
        open={mergeOpen}
        entityId={id}
        entityName="customer"
        formatter={formatCustomerForOptionsList}
        singleQuery={GET_CUSTOMER}
        listQuery={GET_CUSTOMERS}
        config={CUSTOMER_MERGE_CONFIGURATION}
        entityType={MERGE_SUPPORTED_ENTITIES.CUSTOMER}
        setOpen={() => setMergeOpen(false)}
      />
    </>
  );
}
