import { gql } from "@apollo/client";

const GET_CHARGEBEE_HOSTED_PAGE = gql`
  query getChargeBeeHostedPage($type: String!) {
    data: getChargeBeeHostedPage(type: $type) {
      hostedPageUrl
    }
  }
`;

const GET_TENANT_SUBSCRIPTION = gql`
  query getTenantSubscription {
    data: getTenantSubscription {
      id
      inTrial
      trialEndDate
      isActivePaid
    }
  }
`;

export { GET_CHARGEBEE_HOSTED_PAGE, GET_TENANT_SUBSCRIPTION };
