import { UserIcon } from "@heroicons/react/24/outline";
import {
  PencilIcon,
  PlusIcon,
  UserCircleIcon,
} from "@heroicons/react/24/solid";
import { Link } from "react-router-dom";
import { utilizationToDescriptionString } from "../../utilities/utilization";
import Card from "../../basics/Card/Card";
import { SectionHeader } from "../../basics/SectionHeader/SectionHeader";
import { IconButton } from "../../basics/Buttons/IconButton";
import { BasicButtonStyles } from "../../basics/Buttons/BasicButton";
import { FringeCostItem } from "../../data/types/FringeCost";
import { createSvgBackgroundStyles } from "../../utilities/svgBackgrounds";

interface FringeCostSetsListProps {
  items: any[];
  addEdit?: any;
  state: string;
}

function FringeCostSetsList({
  items,
  addEdit,
  state,
}: FringeCostSetsListProps) {
  const renderItemBlock = (item: any) => {
    return (
      <div
        className="border-b  cursor-pointer"
        onClick={($ev) => {
          $ev.stopPropagation();
          addEdit(item);
        }}
      >
        <div className="p-4 pl-10 hover:bg-gray-100">
          <div className="text-sm text-gray-600 font-bold mb-2">
            {item.name}
          </div>
          <div className="text-xs text-gray-600">{item.description}</div>
          <div className="text-xs text-gray-600 mt-2">
            {item.items?.length} Items | Assigned to 4 employees
          </div>
          <div className="text-xs mt-2 flex flex-row font-bold uppercase text-gray-600 border-t pt-2 mr-3">
            <div>Total Cost</div>
            <div className="flex flex-1"></div>
            <div>$36,000 + 7% Salary</div>
          </div>
          <div className="text-xs text-right font-regular text-gray-600 mr-3">
            Per Year
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      style={{ minWidth: "440px" }}
      className="border-r flex flex-col bg-white shadow-lg h-full"
    >
      <div
        style={{ ...createSvgBackgroundStyles("") }}
        className="pl-10 pr-4 border-b bg-white flex flex-row items-center"
      >
        <div className="py-3 font-bold text-black text-sm uppercase">
          Cost Sets
        </div>
        <div className="flex flex-1"></div>
        <IconButton
          icon={PlusIcon}
          styler={BasicButtonStyles.ZERO}
          classNames={"h-7 font-bold"}
          text="Add Cost Set"
        />
      </div>

      {state === "loading" ? (
        <div className="py-10 w-full align-center items-center flex flex-row justify-center relative text-center">
          <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12"></div>
        </div>
      ) : (
        <div className="overflow-auto">
          {items.map((a) => renderItemBlock(a))}
        </div>
      )}
    </div>
  );
}

export default FringeCostSetsList;
