import { UserIcon } from "@heroicons/react/24/solid";
import { format } from "date-fns";

interface ResourceNeedsListProps {
  items: any[];
  addEdit?: any;
}

function ResourceNeedsList({ items, addEdit }: ResourceNeedsListProps) {
  const getLink = (item: any) => {
    if (item.source === "PROVIDER") {
      return "/providers/" + item.provider.id;
    } else if (item.source === "EMPLOYEE") {
      return "/employees/" + item.employee.id;
    } else {
      return "/";
    }
  };

  const renderItemBlock = (item: any) => {
    let name, utilizationString;
    name =
      item?.role?.name +
      (Number(item.quantity) > 1 ? ` x ${item.quantity}` : "");
    return (
      <div
        onClick={($ev) => {
          $ev.stopPropagation();
          addEdit(item);
        }}
        className="p-3 px-4 w-50 mr-3 cursor-pointer relative bg-white border-gray-200 hover:shadow items-start border rounded-md flex flex-col"
      >
        <div className="text-md flex flex-row align-middle items-center font-bold text-center text-gray-600 hover:text-blue-500">
          <UserIcon height={20} className="mr-2" /> {name}
        </div>
        <div className="text-sm ml-7 mt-3">
          Fill by: {format(new Date(item?.targetFillDate), "MM/dd/YYY")}
        </div>
        <div className="text-sm ml-7 mt-1">
          Recruiting Started: {format(new Date(item?.recruitingStartDate), "MM/dd/YYY")}
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-row items-center mb-3">
      {items.map((a) => renderItemBlock(a))}
    </div>
  );
}

export default ResourceNeedsList;
