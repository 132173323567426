import { ClipboardIcon } from "@heroicons/react/24/solid";
import { format } from "date-fns";
import { Link } from "react-router-dom";

interface TextTypeProps {
  type?: string;
  displayType?: string;
  typeConfiguration?: any;
  fieldKey: string;
  dataObject: any;
  styles?: any;
  linkToPrefix?: string | Function;
  linkToKey?: string | Function;
  rowStyleKey?: string;
  secondaryContentRenderer?: any;
}

export const CURRENCY_TO_SYMBOL_MAP: any = {
  USD: "$",
};

export const RATE_TIMESCALE_TO_DISPLAY_MAP: any = {
  HOURLY: "per hour",
  MONTHLY: "per month",
  YEARLY: "per year",
};

function TextType({
  type,
  typeConfiguration,
  dataObject,
  fieldKey,
  linkToKey,
  styles,
  linkToPrefix,
  displayType,
  rowStyleKey,
  secondaryContentRenderer,
}: TextTypeProps) {
  let string = "";

  // Move down to sub object if configured
  if (typeConfiguration && typeConfiguration.subObject) {
    dataObject = dataObject[typeConfiguration.subObject];
  }

  // Render
  if (!type) {
    string = dataObject[fieldKey];
  } else if (type === "render") {
    return typeConfiguration.render(dataObject);
  } else if (type === "boolean") {
    return dataObject[fieldKey] ? "Yes" : "No";
  } else if (type === "money") {
    string = `${
      CURRENCY_TO_SYMBOL_MAP[dataObject[typeConfiguration.rateUnitKey]]
    }${dataObject[fieldKey]}`;
  } else if (type === "rate") {
    if (
      typeConfiguration.daysUnitKey &&
      dataObject[typeConfiguration.daysUnitKey]
    ) {
      // Show both days and percentage
      string = dataObject[typeConfiguration.daysUnitKey] + " days off";
    } else if (!dataObject[fieldKey]) {
      string = "-";
    }
    // special rate value types
    else if (
      typeConfiguration.rateValueTypeKey &&
      dataObject[typeConfiguration.rateValueTypeKey] === "PERCENTAGE"
    ) {
      string = `
          ${dataObject[fieldKey]}% ${typeConfiguration.percentageOf} ${
        RATE_TIMESCALE_TO_DISPLAY_MAP[
          dataObject[typeConfiguration.rateTimeUnitKey]
        ]
      }`;
    } else {
      // Normal output
      string = `${
        CURRENCY_TO_SYMBOL_MAP[dataObject[typeConfiguration.rateUnitKey]]
      }${dataObject[fieldKey]} ${
        RATE_TIMESCALE_TO_DISPLAY_MAP[
          dataObject[typeConfiguration.rateTimeUnitKey]
        ]
      } ${
        typeConfiguration.percentageKey &&
        dataObject[typeConfiguration.percentageKey]
          ? "(" + dataObject[typeConfiguration.percentageKey] + "%)"
          : ""
      }`;
    }
  } else if (type === "completionRange") {
    string = `${dataObject[typeConfiguration.preKey]}/${dataObject[fieldKey]}`;
  } else if (type === "currency") {
    string = `${
      CURRENCY_TO_SYMBOL_MAP[dataObject[typeConfiguration.rateUnitKey]]
    }${dataObject[fieldKey]}`;
  } else if (type === "date") {
    string = format(new Date(dataObject[fieldKey]), "MM/dd/yyyy");
  } else if (type === "composite") {
    let keyAdditionSegments = fieldKey.split(" + ");
    if (keyAdditionSegments.length == 1) {
      let keyDotSegments = keyAdditionSegments[0].split(".");
      if (keyDotSegments.length > 1) {
        string +=
          dataObject[keyDotSegments[0]?.trim()]?.[keyDotSegments[1]?.trim()] ||
          "-";
      } else {
        string += dataObject[fieldKey.trim()] || "-";
      }
    } else {
      keyAdditionSegments.forEach((seg1, index) => {
        if (index > 0) string += " ";
        let keyDotSegments = seg1.split(".");
        if (keyDotSegments.length > 1) {
          string +=
            dataObject[keyDotSegments[0].trim()][keyDotSegments[1].trim()] ||
            "-";
        } else {
          string += dataObject[seg1] || "-";
        }
      });
    }
  } else if (type === "files") {
    let filesWithUrls = dataObject[fieldKey];
    if (typeConfiguration.number === "first" && filesWithUrls.length) {
      filesWithUrls = [filesWithUrls[0]];
    }
    if (typeConfiguration.documentTypeSelector) {
      filesWithUrls = filesWithUrls.filter((a: any) => {
        return a.type === typeConfiguration.documentTypeSelector;
      });
    }
    return (
      <div className="flex flex-row">
        {filesWithUrls.map((f: any) => {
          return (
            <div>
              <a
                href={f.presignedDownloadUrl}
                className="text-blue-500"
                target="__blank"
              >
                <ClipboardIcon height={25} width={25} />
              </a>
            </div>
          );
        })}
      </div>
    );
  }

  if (displayType === "link") {
    let linkString = "";
    if (linkToKey instanceof Function) {
      linkString = linkToKey(dataObject);
    } else {
      let keyDotSegments = linkToKey!.split(".");
      if (keyDotSegments.length > 1) {
        linkString +=
          dataObject[keyDotSegments[0].trim()][keyDotSegments[1].trim()];
      } else {
        linkString += dataObject[linkToKey!.trim()];
      }
    }

    return (
      <>
        <Link
          to={`${
            linkToPrefix instanceof Function
              ? linkToPrefix(dataObject)
              : linkToPrefix
          }${linkString || ""}`}
        >
          <span className={`${styles} ${dataObject[rowStyleKey || ""]}`}>
            {string}
          </span>
        </Link>
      </>
    );
  } else if (displayType === "badge") {
    return (
      <>
        <span className={`${styles} ${dataObject[rowStyleKey || ""]}`}>
          {string}
        </span>
      </>
    );
  } else if (displayType === "secondaryContent") {
    return (
      <>
        {secondaryContentRenderer ? secondaryContentRenderer(dataObject) : ""}
        {string}
      </>
    );
  } else {
    return <>{string} </>;
  }
}

export default TextType;
