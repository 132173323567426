import { useState } from "react";
import { Link } from "react-router-dom";
import { ExternalResource } from "../../data/types/ExternalResource";
import { cleanUrl } from "../../utilities/links";

interface RecentExternalLinksListProps {
  items: RecentExternalLinksListItem[];
}

interface RecentExternalLinksListItem {
  logoUrl: string;
  externalLink: ExternalResource;
}

function RecentExternalLinksList({ items }: RecentExternalLinksListProps) {
  const renderItemBlock = (item: RecentExternalLinksListItem) => {
    return (
      <div className="mb-3 cursor-pointer bg-none hover:bg-white rounded-md border-gray-200 hover:shadow border">
        <a target="__blank" href={cleanUrl(item.externalLink.url)}>
          <div className="flex flex-row items-center">
            <div className="pl-3 pr-2 py-2">
              <img
                className="w-5 h-auto"
                src={`${process.env.PUBLIC_URL}/external_logos/${item.externalLink.logoName}.png`}
              />
            </div>
            <div className="px-3 py-2 text-xs font-regular text-gray-600">
              {item.externalLink?.name}
            </div>
          </div>
        </a>
      </div>
    );
  };

  return <div>{items.map((a) => renderItemBlock(a))}</div>;
}

export default RecentExternalLinksList;
