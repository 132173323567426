import { BaseModalProps } from "../../basics/ModalWrapper/ModalWrapper";
import MinimalForm from "../../components/MinimalForm/MinimalForm";
import { useForm } from "react-hook-form";
import SlideOverWrapper from "../../basics/SlideOverWrapper/SlideOverWrapper";
import { useEffect } from "react";
import { FormFieldConfiguration } from "../../data/types/Form";
import { BUILD_EMPLOYEE_FORM_CONFIGURATION } from "../../data/types/Employee";

export interface EmployeeAddEditSlideOverProps extends BaseModalProps {
  toEdit?: any;
}

function EmployeeAddEditSlideOver({
  open,
  setOpen,
  onSubmit,
  toEdit,
}: EmployeeAddEditSlideOverProps) {
  let initialValues: any = {};

  const form = useForm<FormData>({ defaultValues: initialValues });
  const { errors, setValue, handleSubmit, watch } = form;

  /**
   * Set Initial Values for Form
   */
  useEffect(() => {
    if (toEdit && open) {
      setTimeout(() => {
        Object.keys(toEdit).forEach((k) => {
          setValue(k, toEdit[k]);
        });
      });
    }
  }, [toEdit, open]);

  /* SET FIELDS */
  let fields: FormFieldConfiguration[] = BUILD_EMPLOYEE_FORM_CONFIGURATION(
    {}
  ).fields;

  const submitForm = (data: any) => {
    return toEdit ? onSubmit(data, toEdit.id) : onSubmit(data);
  };

  const renderEdit = () => {
    return (
      <MinimalForm
        fields={fields}
        optionsMap={{}}
        submitText={"Submit"}
        form={form}
        onSubmit={submitForm}
      />
    );
  };

  return (
    <SlideOverWrapper
      title={`Add or edit Employee`}
      subtitle={"Creating an employee will also create a new user"}
      open={open}
      setOpen={setOpen}
    >
      {renderEdit()}
    </SlideOverWrapper>
  );
}

export default EmployeeAddEditSlideOver;
