// Currently wraps open source Gantt chart, eventually will be fully react-ified...
import "./lib/gantt.scss";
import GanttLib from "./lib/index.jsx";

export interface GanttProps {}

function Gantt({}: GanttProps) {
  var tasks = [
    {
      id: "Task 1",
      name: "Redesign website",
      start: "2016-12-28",
      end: "2016-12-31",
      progress: 20,
      milestone: "M1",
      custom_class: "bar-milestone", // optional
    },
    {
      id: "Task 2",
      name: "Recreate inner piece",
      start: "2016-12-31",
      end: "2017-2-20",
      progress: 20,
      dependencies: "Task 1",
      milestone: "M1",
      custom_class: "bar-milestone", // optional
    },
    {
      id: "Task 3",
      name: "Test Task",
      start: "2016-12-31",
      end: "2017-2-14",
      progress: 20,
      dependencies: "Task 1",
      milestone: "M2",
      custom_class: "bar-milestone", // optional
    },
  ];

  return (
    <GanttLib
      wrapper="#gantt"
      tasks={tasks}
      options={{
        header_height: 50,
        column_width: 30,
        view_modes: ["Quarter Day", "Half Day", "Day", "Week", "Month"],
        view_mode: "Week",
        date_format: "YYYY-MM-DD",
        custom_popup_html: null,
      }}
    />
  );
}

export default Gantt;
