import { gql } from "@apollo/client";

const UPDATE_EXTERNAL_RESOURCE = gql`
  mutation updateExternalResource(
    $id: String!
    $updatedExternalResource: ExternalResourceUpdateRequest!
  ) {
    updateExternalResource(
      id: $id
      updatedExternalResource: $updatedExternalResource
    ) {
      id
    }
  }
`;

const CREATE_EXTERNAL_RESOURCE = gql`
  mutation createExternalResource(
    $newExternalResource: ExternalResourceCreateRequest!
  ) {
    createExternalResource(newExternalResource: $newExternalResource) {
      id
    }
  }
`;

export { UPDATE_EXTERNAL_RESOURCE, CREATE_EXTERNAL_RESOURCE };
