import { useLazyQuery, useMutation } from "@apollo/client";
import { useEffect, useState } from "react";

import { useLocation } from "react-router-dom";
import BaseDetailView from "../../components/BaseDetailView/BaseDetailView";
import { GET_COMMISSION_ASSIGNMENT } from "../../data/queries/commissionPlans";
import { DetailViewHeader } from "../../components/DetailViewHeader/DetailViewHeader";
import {
  CommissionEntry,
  COMMISSION_ASSIGNMENT_DETAIL_CONFIGURATION,
  COMMISSION_ENTRY_TABLE_CONFIGURATION,
  formatCommissionAssignmentForList,
  formatCommissionEntryForList,
} from "../../data/types/CommissionPlan";
import Table from "../../basics/Table/Table";
import { SectionHeader } from "../../basics/SectionHeader/SectionHeader";
import { IconButton } from "../../basics/Buttons/IconButton";
import { PlusIcon } from "@heroicons/react/24/solid";
import { BasicButtonStyles } from "../../basics/Buttons/BasicButton";
import Card from "../../basics/Card/Card";

export function CommissionAssignment() {
  const [getData, { loading, error, data }] = useLazyQuery(
    GET_COMMISSION_ASSIGNMENT
  );
  const location = useLocation();
  const [cursor, setCursor] = useState(null);
  const [mergeOpen, setMergeOpen] = useState(false);
  const [addEditOpen, setAddEditOpen] = useState(false);
  const segments = location.pathname.split("/");
  const id = segments[segments.length - 1];

  let commissionAssignment: any = {};

  useEffect(() => {
    getData({ variables: { id: id } });
  }, []);

  if (!loading && data) {
    commissionAssignment = data.data;
  }

  const formatName = (): any => {
    if (!commissionAssignment) return "";
    const name = commissionAssignment?.employee
      ? commissionAssignment?.employee?.firstName +
        " " +
        commissionAssignment?.employee?.lastName
      : commissionAssignment?.providerPersonnel?.firstName +
        " " +
        commissionAssignment?.providerPersonnel?.lastName;
    const targetName = commissionAssignment?.employeeTarget
      ? commissionAssignment?.employeeTarget?.firstName +
        " " +
        commissionAssignment?.employeeTarget?.lastName
      : commissionAssignment?.providerPersonnelTarget?.firstName +
        " " +
        commissionAssignment?.providerPersonnelTarget?.lastName;
    return {
      name,
      targetName,
    };
  };

  return (
    <>
      <DetailViewHeader
        name={"Commission Assignment Detail"}
        onEdit={() => setAddEditOpen(true)}
        onMerge={() => setMergeOpen(true)}
      />
      <div className="flex-1 px-2 px-6 pt-6">
        <Card classNames="mb-6">
          <div className="p-6 text-lg font-bold">
            {formatName().name}'s {commissionAssignment?.type} commission' on{" "}
            {formatName().targetName}
          </div>
          <div className="px-6 mb-6">
            Commission Plan:{" "}
            <span className="text-blue-500 font-medium underline">
              {commissionAssignment?.planVersion?.commissionPlan?.name}
            </span>
          </div>
          <div className="px-6 mb-6">
            Strategy:
            {
              commissionAssignment?.planVersion?.strategies?.[0]
                ?.strategyCalculationPercentage
            }{" "}
            {
              commissionAssignment?.planVersion?.strategies?.[0]
                ?.strategyCalculationFixedValue
            }{" "}
            {
              commissionAssignment?.planVersion?.strategies?.[0]
                ?.strategyCalculationType
            }{" "}
            {
              commissionAssignment?.planVersion?.strategies?.[0]
                ?.strategyCalculationTimePeriod
            }{" "}
            {
              commissionAssignment?.planVersion?.strategies?.[0]
                ?.strategyCalculationScope
            }
          </div>
        </Card>
        <SectionHeader
          button={
            <IconButton
              icon={PlusIcon}
              styler={BasicButtonStyles.CLEAR}
              text="Calculate new commission entries"
              onClick={() => {}}
            />
          }
          title="Commission Entries"
        />
        <Table
          data={commissionAssignment?.entries?.map((entry: CommissionEntry) =>
            formatCommissionEntryForList(entry)
          )}
          columns={COMMISSION_ENTRY_TABLE_CONFIGURATION}
          start={cursor ? Number(cursor) : 1}
          end={commissionAssignment?.entries?.count + Number(cursor)}
          onChangePage={setCursor}
          onSelectItem={(i: any) => console.log(i)}
        />
      </div>
    </>
  );
}
