import { gql } from "@apollo/client";

const GET_EXPENSE_ITEMS = gql`
  query listExpenseItems(
    $skip: Int
    $after: String
    $first: Int
    $where: JSON
    $orderBy: ExpenseItemOrder
  ) {
    data: listExpenseItems(
      skip: $skip
      after: $after
      first: $first
      where: $where
      orderBy: $orderBy
    ) {
      edges {
        cursor
        node {
          id
          dateOfCharge
          amount
          name
          currency
        }
      }
      totalCount
      pageInfo {
        hasNextPage
      }
    }
  }
`;

export { GET_EXPENSE_ITEMS };
