import { BaseModalProps } from "../../basics/ModalWrapper/ModalWrapper";
import MinimalForm from "../../components/MinimalForm/MinimalForm";
import { useForm } from "react-hook-form";
import SlideOverWrapper from "../../basics/SlideOverWrapper/SlideOverWrapper";
import { useEffect } from "react";
import { FormFieldConfiguration } from "../../data/types/Form";
import { BUILD_RESOURCE_ASSIGNMENT_FORM_CONFIGURATION } from "../../data/types/ResourceAssignment";
import { BUILD_EXTERNAL_RESOURCE_FORM_CONFIGURATION } from "../../data/types/ExternalResource";

export interface ExternalResourceAddEditSlideOverProps extends BaseModalProps {
  toEdit?: any;
}

function ExternalResourceAddEditSlideOver({
  open,
  setOpen,
  onSubmit,
  toEdit,
}: ExternalResourceAddEditSlideOverProps) {
  let initialValues: any = {};

  const form = useForm<FormData>({ defaultValues: initialValues });
  const { errors, setValue, handleSubmit, watch } = form;

  /**
   * Set Initial Values for Form
   */
  useEffect(() => {
    if (toEdit && open) {
      setTimeout(() => {
        Object.keys(toEdit).forEach((k) => {
          setValue(k, toEdit[k]);
        });
      });
    }
  }, [toEdit, open]);

  /* SET FIELDS */
  let fields: FormFieldConfiguration[] =
    BUILD_EXTERNAL_RESOURCE_FORM_CONFIGURATION(toEdit).fields;

  const submitForm = (data: any) => {
    return toEdit ? onSubmit(data, toEdit.id) : onSubmit(data);
  };

  const renderEdit = () => {
    return (
      <MinimalForm
        fields={fields}
        optionsMap={{}}
        submitText={toEdit ? "Update" : "Submit"}
        form={form}
        onSubmit={submitForm}
      />
    );
  };

  return (
    <SlideOverWrapper
      title={`Add or edit resource`}
      open={open}
      setOpen={setOpen}
    >
      {renderEdit()}
    </SlideOverWrapper>
  );
}

export default ExternalResourceAddEditSlideOver;
