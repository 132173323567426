import { useEffect } from "react";
import { MutationHookOptions, useLazyQuery, useMutation } from "@apollo/client";
import { createMachine, assign } from "xstate";
import { DocumentNode } from "graphql";
import { useMachine } from "@xstate/react";
import { PaginatedResponse } from "../types/PaginatedResponse";

interface MachineContext {
  error?: string;
  variables?: any;
  isRefetch?: boolean;
}

type MachineActionsQuery =
  | { type: "MAKE_QUERY"; variables: any }
  | { type: "DONE" }
  | { type: "FAILED"; error: Error }
  | { type: "LOAD" }
  | { type: "REFETCH" };

type MachineActionsMutation =
  | { type: "MAKE_MUTATION"; variables: any }
  | { type: "DONE" }
  | { type: "FAILED"; error: Error }
  | { type: "LOAD" };

export type DefaultDataMachineState =
  | "loading"
  | "completed"
  | "error"
  | "idle"
  | "pre_query";

type TypedStates =
  | { value: "idle"; context: {} }
  | {
      value: "pre_query";
      context: {
        variables?: any;
        isRefetch?: boolean;
      };
    }
  | {
      value: "loading";
      context: {};
    }
  | {
      value: "completed";
      context: {};
    }
  | {
      value: "error";
      context: {
        error: string;
      };
    };

const lazyGraphQLQueryMachine = createMachine<
  MachineContext,
  MachineActionsQuery,
  TypedStates
>({
  id: "lazyGraphQLQuery",
  initial: "idle",
  context: {
    error: undefined,
    variables: undefined,
    isRefetch: undefined,
  },
  states: {
    idle: {
      on: {
        MAKE_QUERY: {
          target: "pre_query",
          actions: assign({
            variables: (_, evt) => {
              return evt.variables;
            },
          }),
        },
      },
    },
    pre_query: {
      on: {
        LOAD: "loading",
      },
    },
    loading: {
      on: {
        DONE: {
          target: "completed",
        },
        FAILED: {
          target: "error",
          actions: assign({
            error: (_, evt) => {
              return evt.error.message;
            },
          }),
        },
      },
    },
    completed: {
      on: {
        MAKE_QUERY: {
          target: "pre_query",
          actions: assign({
            variables: (_, evt) => {
              return evt.variables;
            },
          }),
        },
        REFETCH: {
          target: "pre_query",
          actions: assign<MachineContext, { type: "REFETCH" }>({
            isRefetch: true,
          }),
        },
      },
    },
    error: {
      on: {
        MAKE_QUERY: {
          target: "pre_query",
          actions: assign({
            variables: (_, evt) => {
              return evt.variables;
            },
          }),
        },
      },
    },
  },
});

const graphQLMutationMachine = createMachine<
  MachineContext,
  MachineActionsMutation,
  TypedStates
>({
  id: "graphQLMutation",
  initial: "idle",
  context: {
    error: undefined,
    variables: undefined,
  },
  states: {
    idle: {
      on: {
        MAKE_MUTATION: {
          target: "pre_query",
          actions: assign({
            variables: (_, evt) => {
              return evt.variables;
            },
          }),
        },
      },
    },
    pre_query: {
      on: {
        LOAD: "loading",
      },
    },
    loading: {
      on: {
        DONE: {
          target: "completed",
        },
        FAILED: {
          target: "error",
          actions: assign({
            error: (_, evt) => {
              return evt.error.message;
            },
          }),
        },
      },
    },
    completed: {},
    error: {
      on: {
        MAKE_MUTATION: {
          target: "pre_query",
          actions: assign({
            variables: (_, evt) => {
              return evt.variables;
            },
          }),
        },
      },
    },
  },
});

export type LazyListQuerySM<T> = [
  state: TypedStates,
  data: PaginatedResponse<T>,
  send: (action: MachineActionsQuery) => void
];

export type LazyQuerySM<T> = [
  state: TypedStates,
  data: T,
  send: (action: MachineActionsQuery) => void
];

export type MutationSM = [
  state: TypedStates,
  runMutationWithVariables: (variables: any) => void,
  send: (action: MachineActionsMutation) => void
];

// Any Lazy Query
export const useLazyQuerySM = (
  query: DocumentNode,
  optionalDataFormatter?: Function
): LazyQuerySM<any> => {
  const [doQuery, { loading, error, data, refetch }] = useLazyQuery(query);
  const [state, send] = useMachine(
    lazyGraphQLQueryMachine,
    process.env.REACT_APP_ENV === "local"
      ? {
          devTools: true,
        }
      : {}
  );
  //Set the correct state on mount
  useEffect(() => {
    if (state.matches)
      if (state.matches("pre_query")) {
        if (state.context.isRefetch) {
          refetch({ variables: state.context.variables });
          send("LOAD");
        } else {
          doQuery({ variables: state.context.variables });
          send("LOAD");
        }
      } else if (state.matches("loading") && !loading) {
        if (data) {
          send({
            type: "DONE",
          });
        } else if (error) {
          send({ type: "FAILED", error });
        }
      }
  }, [data, error, send, state, loading]);
  let formattedData = data?.data;
  if (optionalDataFormatter) {
    formattedData = optionalDataFormatter(data?.data);
  }
  return [state as TypedStates, formattedData, send];
};

// Specific to List Pages where we need to do pagination, formatting etc
export const useLazyListQuerySM = (
  query: DocumentNode,
  listFormatter?: (node: any) => any
): LazyListQuerySM<any> => {
  return useLazyQuerySM(query, (data: PaginatedResponse<any>) => {
    const formatted = {
      ...data,
      edges: data?.edges?.map((d: any) => {
        return {
          ...d,
          node: listFormatter ? listFormatter(d.node) : d.node,
        };
      }),
    };
    return formatted;
  });
};

// Mutations of any kind (create, add, delete)
export const useMutationSM = (
  mutation: DocumentNode,
  configuration: any
): MutationSM => {
  const [doMutation, { loading, error, data }] = useMutation(
    mutation,
    configuration
  );
  const [state, send] = useMachine(
    graphQLMutationMachine,
    process.env.REACT_APP_ENV === "local"
      ? {
          devTools: true,
        }
      : {}
  );
  //Set the correct state on mount
  useEffect(() => {
    if (state.matches)
      if (state.matches("pre_query")) {
        if (state.context.isRefetch) {
          send("LOAD");
        } else {
          doMutation({ variables: state.context.variables });
          send("LOAD");
        }
      } else if (state.matches("loading") && !loading) {
        if (data) {
          send({
            type: "DONE",
          });
        } else if (error) {
          send({ type: "FAILED", error });
        }
      }
  }, [data, error, send, state, loading]);
  const runMutationWithVariables = (variables: any) => {
    send({ type: "MAKE_MUTATION", variables });
  };
  return [state as TypedStates, runMutationWithVariables, send];
};
