import { gql } from "@apollo/client";

const GET_USERS = gql`
  query getUsers {
    data: listUsers {
      edges {
        cursor
        node {
          id
          firstName
          lastName
          createdAt
          updatedAt
        }
      }
      totalCount
      pageInfo {
        hasNextPage
      }
    }
  }
`;

const GET_USER = gql`
  query getUser($id: String!) {
    data: getUser(id: $id) {
      id
      firstName
      lastName
      createdAt
      updatedAt
    }
  }
`;

export { GET_USERS, GET_USER };
