import { useLazyQuery } from "@apollo/client";
import { useEffect } from "react";

import { useLocation } from "react-router-dom";
import BaseDetailView from "../../components/BaseDetailView/BaseDetailView";
import { USER_DETAIL_CONFIGURATION } from "../../data/types/User";
import { GET_USER } from "../../data/queries/users";
import { DetailViewHeader } from "../../components/DetailViewHeader/DetailViewHeader";

export function User() {
  const [getData, { loading, error, data }] = useLazyQuery(GET_USER);
  const location = useLocation();
  const segments = location.pathname.split("/");
  const id = segments[segments.length - 1];

  let user: any = {};

  useEffect(() => {
    getData({ variables: { id: id } });
  }, []);

  if (!loading && data) {
    user = data.data;
  }

  return (
    <>
      <DetailViewHeader
        name={user.firstName + " " + user.lastName}
        onEdit={() => {}}
        hideEdit={true}
      />
      <BaseDetailView
        dataObject={user}
        title={"User"}
        config={USER_DETAIL_CONFIGURATION}
      />
    </>
  );
}
