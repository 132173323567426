import { gql } from "@apollo/client";

const GET_INTEGRATIONS = gql`
  query listIntegrations(
    $skip: Int
    $after: String
    $first: Int
    $query: String
    $orderBy: IntegrationOrder
  ) {
    data: listIntegrations(
      skip: $skip
      after: $after
      first: $first
      query: $query
      orderBy: $orderBy
    ) {
      edges {
        cursor
        node {
          id
          target
          createdAt
          updatedAt
          syncing
          integrationExportConfiguration {
            maps {
              integrationEntityToExport
              fieldMaps {
                integrationKey
              }
            }
          }
          integrationIngestConfiguration {
            entityConfigs {
              integrationEntityToIngest
              fieldMaps {
                integrationKey
              }
            }
          }
        }
      }
      totalCount
      pageInfo {
        hasNextPage
      }
    }
  }
`;

const GET_INTEGRATION = gql`
  query getIntegration($id: String!) {
    data: getIntegration(id: $id) {
      id
      target
      createdAt
      updatedAt
    }
  }
`;

const SYNC_INTEGRATION = gql`
  mutation syncIntegration($id: String!) {
    data: syncIntegration(integrationId: $id) {
      id
      syncing
    }
  }
`;

const DELETE_INTEGRATION = gql`
  mutation deleteIntegration($id: String!) {
    deleteIntegration(id: $id) {
      id
    }
  }
`;

const CREATE_INTEGRATION = gql`
  mutation createIntegration(
    $target: IntegrationTarget!
    $clientId: String
    $clientSecret: String
    $mondayBoardId: String
  ) {
    createIntegration(
      target: $target
      clientId: $clientId
      clientSecret: $clientSecret
      mondayBoardId: $mondayBoardId
    ) {
      integration {
        id
        target
      }
      oauthUrl
    }
  }
`;

export {
  GET_INTEGRATIONS,
  GET_INTEGRATION,
  SYNC_INTEGRATION,
  CREATE_INTEGRATION,
  DELETE_INTEGRATION,
};
