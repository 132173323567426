import { BaseModalProps } from "../../basics/ModalWrapper/ModalWrapper";
import MinimalForm from "../../components/MinimalForm/MinimalForm";
import { useForm } from "react-hook-form";
import SlideOverWrapper from "../../basics/SlideOverWrapper/SlideOverWrapper";
import { useEffect } from "react";
import { EntityUnchangeableDataBlock } from "../../basics/EntityStaticDataBlock/EntityStaticDataBlock";
import { BUILD_WORKFLOW_STEP_FORM_CONFIGURATION } from "../../data/types/WorkflowSpecification";
import { FormFieldConfiguration } from "../../data/types/Form";

export interface WorkflowStepEditModalProps extends BaseModalProps {
  toEdit?: any;
  addToStream?: any;
}

function WorkflowStepEditModal({
  open,
  setOpen,
  toEdit,
  onSubmit,
  addToStream,
}: WorkflowStepEditModalProps) {
  console.log(toEdit);
  let initialValues: any = {};

  const form = useForm<FormData>({ defaultValues: initialValues });
  const { errors, setValue, handleSubmit, watch } = form;

  /**
   * Set Initial Values for Form
   */
  useEffect(() => {
    if (toEdit && open) {
      setTimeout(() => {
        Object.keys(toEdit).forEach((k) => {
          setValue(k, toEdit[k]);
        });
      });
    }
  }, [toEdit, open]);

  console.log(toEdit);

  /* SET FIELDS */
  let fields: FormFieldConfiguration[] = BUILD_WORKFLOW_STEP_FORM_CONFIGURATION(
    toEdit,
    addToStream
  ).fields;

  const submitForm = (data: any) => {
    return toEdit ? onSubmit(data, toEdit.id) : onSubmit(data);
  };

  const renderEdit = () => {
    return (
      <MinimalForm
        fields={fields}
        optionsMap={{}}
        submitText={"Submit"}
        form={form}
        onSubmit={submitForm}
      />
    );
  };

  return (
    <SlideOverWrapper
      title={`Add or edit Workflow Step`}
      open={open}
      setOpen={setOpen}
    >
      {toEdit && <EntityUnchangeableDataBlock title={<>{toEdit.type}</>} />}
      {renderEdit()}
    </SlideOverWrapper>
  );
}

export default WorkflowStepEditModal;
