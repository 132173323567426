import { TableColumn } from "../../basics/Table/Table";
import { DetailViewConfiguration } from "../../components/BaseDetailView/BaseDetailView";
import { FormConfiguration } from "./Form";
import { formatBaseItemForList } from "../../utilities/dates";
import { CURRENCIES, RATE_TIMEUNITS, RATE_TIMEUNITS_OPTIONS } from "./Shared";

export interface ProjectScopeOfWork {
  id: string;
  tenantId: string;
  createdAt: Date;
  updatedAt: Date;
  startingAt?: Date;
  endingAt?: Date;
  billingType: ProjectScopeOfWorkBillingType;
  identifier: string;
  description?: string;
  cappedCost?: number;
  status: ProjectScopeOfWorkStatus;
  rate?: number;
  rateCurrency?: CURRENCIES;
  rateTimeUnit?: RATE_TIMEUNITS;
  projectId: string;
}

export interface ProjectScopeOfWorkCreateUpdateArgs {
  createdAt?: Date;
  updatedAt?: Date;
  startingAt?: Date;
  endingAt?: Date;
  billingType?: ProjectScopeOfWorkBillingType;
  identifier?: string;
  description?: string;
  cappedCost?: number;
  status: ProjectScopeOfWorkStatus;
  rate?: number;
  rateCurrency?: CURRENCIES;
  rateTimeUnit?: RATE_TIMEUNITS;
  projectId?: string;
}

export enum ProjectScopeOfWorkBillingType {
  TIME_AND_MATERIALS = "TIME_AND_MATERIALS",
  FIXED_PRICE = "FIXED_PRICE",
  MILESTONE_PAYMENTS = "MILESTONE_PAYMENTS",
}

export enum ProjectScopeOfWorkStatus {
  ACTIVE = "ACTIVE",
  DECLINED = "DECLINED",
  DEPRECATED = "DEPRECATED",
  ESTIMATION = "ESTIMATION",
  PROPOSED_PENDING = "PROPOSED_PENDING",
}

export const formatScopeOfWorkForList = (item: any) => {
  return {
    ...formatBaseItemForList(item, ["startingAt", "endingAt"]),
    badgeColor: projectSowStatusToBadgeColor(item.status),
  };
};

export const formatScopeOfWorkForOptionsList = (
  s: ProjectScopeOfWork,
  prefix?: string
) => {
  return {
    label: (prefix ? prefix : "") + s.identifier + " " + s.status,
    value: s.id,
  };
};

export const projectSowStatusToBadgeColor = (status: string) => {
  if (status === "ACCEPTED") {
    return "bg-green-100 text-green-800";
  } else {
    return "bg-yellow-100 text-yellow-800";
  }
};

export const PROJECT_SOW_DETAIL_CONFIGURATION: DetailViewConfiguration = {
  sections: [
    {
      title: "Basic Information",
      subtitle: "Some basic info about your project scope of work",
      fields: [
        {
          key: "identifier",
          label: "Identifier",
        },
      ],
    },
  ],
};

export const PROJECT_SOW_TABLE_CONFIGURATION: TableColumn[] = [
  {
    key: "identifier",
    label: "Identifier",
    displayType: "link",
    linkToKey: "id",
    linkToPrefix: "/sow/",
    styles: "text-gray-600 font-medium",
  },
  {
    key: "updatedAt",
    label: "Updated At",
  },
  {
    key: "createdAt",
    label: "Created At",
  },
];

export const MILESTONES_PROJECT_SOW_TABLE_CONFIGURATION: TableColumn[] = [
  {
    key: "name",
    label: "Name",
    displayType: "link",
    linkToKey: "id",
    linkToPrefix: "/milestone/",
    styles: "text-gray-600 font-medium",
  },
  {
    key: "startingAt",
    label: "Starting At",
  },
  {
    key: "endingAt",
    label: "Ending At",
  },
];

export const BUILD_RESOURCE_ASSIGNMENT_FORM_CONFIGURATION = (
  isEdit: boolean
): FormConfiguration => {
  return {
    requiredOptionsLoads: ["customers"],
    fields: [
      {
        name: "Source Information",
        type: "divider",
      },
      {
        name: "source",
        type: "select",
        placeholder: "Select a Type",
        options: [
          { label: "Employee", value: "EMPLOYEE" },
          { label: "Provider", value: "PROVIDER" },
        ],
        validation: { required: true },
        toShow: {
          boolean: !isEdit,
        },
        props: {
          label: "Resource Type",
          width: 6,
        },
      },
      {
        name: "Scope Financials",
        type: "divider",
      },
      {
        name: "rate",
        type: "textInput",
        placeholder: "Override Bill Rate",
        validation: { valueAsNumber: true },
        props: {
          label: "Override Bill Rate",
          width: 3,
          subType: "number",
        },
      },
      {
        name: "rateTimeUnit",
        type: "select",
        options: RATE_TIMEUNITS_OPTIONS,
        placeholder: "Rate Per Period",
        validation: {},
        props: {
          label: "Per",
          width: 3,
        },
      },
    ],
  };
};
