import { gql } from "@apollo/client";

const GET_INVOICES = gql`
  query listInvoices(
    $skip: Int
    $after: String
    $first: Int
    $where: JSON
    $orderBy: InvoiceOrder
  ) {
    data: listInvoices(
      skip: $skip
      after: $after
      first: $first
      where: $where
      orderBy: $orderBy
    ) {
      edges {
        cursor
        node {
          id
          dueOn
          invoiceDate
          totalDue
          balance
          currency
          status
          customer {
            id
            name
          }
          project {
            id
            name
          }
          documentsWithUrls {
            id
            name
            presignedDownloadUrl
            presignedThumbnailUrl
            type
          }
          createdAt
          updatedAt
          inTriageFromIntegration
          externalData {
            externalRelationships {
              sourceIntegration {
                id
                target
              }
            }
          }
        }
      }
      totalCount
      pageInfo {
        hasNextPage
      }
    }
  }
`;

const GET_INVOICE = gql`
  query getInvoice($id: String!) {
    data: getInvoice(id: $id) {
      id
      createdAt
      updatedAt
      currency
      status
      identifier
      dueOn
      invoiceDate
      totalDue
      balance
      currency
      status
      subtotal
      customer {
        id
        name
      }
      project {
        id
        name
      }
      documentsWithUrls {
        id
        name
        presignedDownloadUrl
        presignedThumbnailUrl
        type
      }
      lineItems {
        description
        rate
        amount
        quantity
        identifier
      }
      inTriageFromIntegration
      externalData {
        externalRelationships {
          sourceIntegration {
            id
            target
          }
        }
      }
    }
  }
`;

const CREATE_INVOICE = gql`
  mutation createInvoice($newInvoice: InvoiceCreateRequest!) {
    createInvoice(newInvoice: $newInvoice) {
      id
    }
  }
`;

const UPDATE_INVOICE = gql`
  mutation updateInvoice($id: String!, $updatedInvoice: InvoiceCreateRequest!) {
    updateInvoice(id: $id, updatedInvoice: $updatedInvoice) {
      id
    }
  }
`;

const SYNC_INVOICE_TO_INTEGRATION = gql`
  mutation syncInvoiceToIntegration($id: String!, $integrationId: String!) {
    syncInvoiceToIntegration(id: $id, integrationId: $integrationId) {
      id
    }
  }
`;

export {
  GET_INVOICES,
  GET_INVOICE,
  CREATE_INVOICE,
  UPDATE_INVOICE,
  SYNC_INVOICE_TO_INTEGRATION,
};
