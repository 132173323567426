interface LeftTabsListProps {
  items?: LeftTabsListItem[];
  selected: string;
  onSelect: any;
}

interface LeftTabsListItem {
  label: string;
  value: string;
  Icon: any;
}

function LeftTabsList({ items, selected, onSelect }: LeftTabsListProps) {
  const renderItem = (i: any) => {
    return (
      <div
        onClick={() => onSelect(i.value)}
        className={`p-4 cursor-pointer text-sm font-medium  flex-row flex items-center border-transparent border-l-4 ${
          i.value === selected
            ? "text-blue-500 bg-blue-100 border-blue-500"
            : "text-gray-600  "
        }`}
      >
        <i.Icon className="mr-2" height="18" /> {i.label}
      </div>
    );
  };

  return (
    <div
      style={{ width: "230px" }}
      className="border-r bg-white shadow-lg h-full "
    >
      <div className="">{items?.map((i) => renderItem(i))}</div>
    </div>
  );
}

export default LeftTabsList;
