import { useLazyQuery, useMutation } from "@apollo/client";
import { useEffect, useState } from "react";

import { useLocation } from "react-router-dom";
import BaseDetailView from "../../components/BaseDetailView/BaseDetailView";
import {
  Employee as Employee2,
  EMPLOYEE_DETAIL_CONFIGURATION,
  EMPLOYEE_MERGE_CONFIGURATION,
  formatEmployeeForOptionsList,
} from "../../data/types/Employee";
import {
  GET_EMPLOYEE,
  GET_EMPLOYEES,
  UPDATE_EMPLOYEE,
} from "../../data/queries/employees";
import { DetailViewHeader } from "../../components/DetailViewHeader/DetailViewHeader";
import { EntityMergeSlideOver } from "../../components/EntityMergeSlideOver/EntityMergeSlideOver";
import { MERGE_SUPPORTED_ENTITIES } from "../../data/types/MergeEntities";
import EmployeeAddEditSlideOver from "./EmployeeAddEditSlideOver";
import { GET_FRINGE_COST_SETS } from "../../data/queries/fringeCosts";
import { DefaultPagePaddingAndWidth } from "../../basics/DefaultPagePaddingAndWidth/DefaultPagePaddingAndWidth";
import { LazyQuerySM, useLazyQuerySM } from "../../data/machines/queries";
import { LoadingWrapper } from "../../basics/LoadingWrapper/LoadingWrapper";

export function Employee() {
  const [state, data, send]: LazyQuerySM<Employee2> =
    useLazyQuerySM(GET_EMPLOYEE);
  const [getSetData, setData] = useLazyQuery(GET_FRINGE_COST_SETS);
  const location = useLocation();
  const [mergeOpen, setMergeOpen] = useState(false);
  const [addEditOpen, setAddEditOpen] = useState(false);
  const segments = location.pathname.split("/");
  const id = segments[segments.length - 1];

  useEffect(() => {
    send({ type: "MAKE_QUERY", variables: { id: id } });
    getSetData();
  }, []);

  const [editEmployee, editEmployeeData] = useMutation(UPDATE_EMPLOYEE, {
    refetchQueries: [
      {
        query: GET_EMPLOYEE,
        variables: {
          id: id,
        },
      },
    ],
  });
  const onEditEmployee = (data: any) => {
    editEmployee({
      variables: {
        id: id,
        updatedEmployee: data,
      },
    });
    setAddEditOpen(false);
  };

  return (
    <>
      <LoadingWrapper state={state.value}>
        <DetailViewHeader
          name={data?.firstName + " " + data?.lastName}
          entityName="Employee"
          onEdit={() => setAddEditOpen(true)}
          onMerge={() => setMergeOpen(true)}
          externalData={data?.externalData}
        />
      </LoadingWrapper>
      <DefaultPagePaddingAndWidth className="flex flex-row">
        <LoadingWrapper state={state.value}>
          <BaseDetailView
            dataObject={data}
            title={"Employee"}
            config={EMPLOYEE_DETAIL_CONFIGURATION}
          />
        </LoadingWrapper>
        <div className="flex flex-1 ml-10 flex-col mb-3 pt-5">
          todo add resource assignments here
        </div>
      </DefaultPagePaddingAndWidth>
      <EntityMergeSlideOver
        open={mergeOpen}
        entityId={id}
        entityName="Employees"
        formatter={formatEmployeeForOptionsList}
        singleQuery={GET_EMPLOYEE}
        listQuery={GET_EMPLOYEES}
        config={EMPLOYEE_MERGE_CONFIGURATION}
        entityType={MERGE_SUPPORTED_ENTITIES.EMPLOYEE}
        setOpen={() => setMergeOpen(false)}
      />
      <EmployeeAddEditSlideOver
        open={addEditOpen}
        setOpen={setAddEditOpen}
        onSubmit={onEditEmployee}
        toEdit={data}
        fringeCostSets={setData?.data?.data?.edges}
      />
    </>
  );
}
