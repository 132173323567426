import ChipSelectInput from "../../basics/ChipSelectInput/ChipSelectInput";
import DateInput from "../../basics/DateInput/DateInput";
import DropdownInput from "../../basics/DropdownInput/DropdownInput";
import TextAreaInput from "../../basics/TextAreaInput/TextAreaInput";
import TextInput from "../../basics/TextInput/TextInput";

export const formFields: any = {
  textInput: TextInput,
  textArea: TextAreaInput,
  text: TextInput,
  select: DropdownInput,
  date: DateInput,
  chips: ChipSelectInput,
};
