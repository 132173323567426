import { useLazyQuery } from "@apollo/client";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import BaseDetailView from "../../components/BaseDetailView/BaseDetailView";
import { DetailViewHeader } from "../../components/DetailViewHeader/DetailViewHeader";
import {
  JobPost as JP,
  JOB_POST_DETAIL_CONFIGURATION,
} from "../../data/types/JobPost";
import { GET_JOB_POST } from "../../data/queries/jobPosts";
import { LazyQuerySM, useLazyQuerySM } from "../../data/machines/queries";
import { LoadingWrapper } from "../../basics/LoadingWrapper/LoadingWrapper";
import { DefaultPagePaddingAndWidth } from "../../basics/DefaultPagePaddingAndWidth/DefaultPagePaddingAndWidth";

export function JobPost() {
  const [state, data, send]: LazyQuerySM<JP> = useLazyQuerySM(GET_JOB_POST);
  const location = useLocation();
  const segments = location.pathname.split("/");
  const id = segments[segments.length - 1];

  useEffect(() => {
    send({ type: "MAKE_QUERY", variables: { id: id } });
  }, []);

  return (
    <>
      <LoadingWrapper state={state.value}>
        <DetailViewHeader name={data?.title} onEdit={() => {}} />
      </LoadingWrapper>
      <DefaultPagePaddingAndWidth>
        <LoadingWrapper state={state.value}>
          <BaseDetailView
            dataObject={data}
            title={"Job Post"}
            config={JOB_POST_DETAIL_CONFIGURATION}
          />
        </LoadingWrapper>
      </DefaultPagePaddingAndWidth>
    </>
  );
}
