import { useLazyQuery, useMutation } from "@apollo/client";
import { ArrowPathIcon } from "@heroicons/react/24/solid";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { IconButton } from "../../basics/Buttons/IconButton";
import Card from "../../basics/Card/Card";
import { DropdownButton } from "../../basics/DropdownButton/DropdownButton";
import { LoadingWrapper } from "../../basics/LoadingWrapper/LoadingWrapper";
import { InvoiceBuilderViewer } from "../../components/InvoiceBuilderViewer/InvoiceBuilderViewer";
import {
  LazyListQuerySM,
  LazyQuerySM,
  useLazyListQuerySM,
  useLazyQuerySM,
  useMutationSM,
} from "../../data/machines/queries";
import { GET_INTEGRATIONS } from "../../data/queries/integrations";
import {
  GET_INVOICE,
  SYNC_INVOICE_TO_INTEGRATION,
  UPDATE_INVOICE,
} from "../../data/queries/invoices";
import { Integration } from "../../data/types/Integration";
import { Invoice as Inv } from "../../data/types/Invoice";

export function Invoice() {
  const [state, data, send]: LazyQuerySM<Inv> = useLazyQuerySM(GET_INVOICE);
  const [
    stateIntegrations,
    dataIntegrations,
    sendIntegrations,
  ]: LazyListQuerySM<Integration> = useLazyListQuerySM(GET_INTEGRATIONS);
  const location = useLocation();
  const segments = location.pathname.split("/");
  const id = segments[segments.length - 1];
  const [syncInvoiceState, syncInvoiceSend] = useMutationSM(
    SYNC_INVOICE_TO_INTEGRATION,
    {
      onCompleted: (data: any) => {
        send({ type: "REFETCH" });
      },
    }
  );
  const [stateUpdateInvoice, sendUpdateInvoice] = useMutationSM(
    UPDATE_INVOICE,
    {
      onCompleted: (data: any) => {
        send({ type: "REFETCH" });
      },
    }
  );
  const [invoiceData, setInvoiceData]: any = useState({});

  useEffect(() => {
    if (state.value === "completed") {
      setInvoiceData(data);
    }
  }, [state, data]);

  useEffect(() => {
    send({ type: "MAKE_QUERY", variables: { id: id } });
    sendIntegrations({
      type: "MAKE_QUERY",
      variables: { cursor: null },
    });
  }, []);

  return (
    <>
      <div className="flex flex-row w-full h-full">
        <div
          className="flex flex-col p-8 border-r"
          style={{
            maxWidth: "350px",
          }}
        >
          <DropdownButton
            className="flex"
            style={{ width: "100%" }}
            items={[
              {
                text: "Delete",
                onClick: () => {},
              },
            ]}
          />

          <>
            <LoadingWrapper state={stateIntegrations.value}>
              <IconButton
                onClick={() => {
                  let intId = dataIntegrations?.edges?.find(
                    (e: any) => e.node.target === "QUICKBOOKS"
                  );
                  console.log(intId, id);
                  syncInvoiceSend({
                    type: "MAKE_MUTATION",
                    variables: {
                      id,
                      // todo fix for some sort of selector ONLY for invoices not already tied to an integration
                      integrationId: intId?.node?.id,
                    },
                  });
                }}
                icon={ArrowPathIcon}
                iconClassNames="mr-2"
                color="green"
                text={"Sync updates to Quickbooks"}
              />
            </LoadingWrapper>
            {invoiceData?.documentsWithUrls?.[0] && (
              <a
                className="block"
                target="_blank"
                href={invoiceData?.documentsWithUrls?.[0]?.presignedDownloadUrl}
              >
                <div className="flex flex-col bg-blue-100 w-full mt-5 align-center items-center rounded-md shadow-lg p-4">
                  <div className="mb-3 font-bold text-sm">
                    Saved Quickbooks Invoice PDF
                  </div>
                  {invoiceData?.documentsWithUrls?.[0]
                    ?.presignedThumbnailUrl && (
                    <div>
                      <img
                        className="h-60"
                        src={
                          invoiceData?.documentsWithUrls?.[0]
                            ?.presignedThumbnailUrl
                        }
                      />
                    </div>
                  )}
                </div>
              </a>
            )}
            <div className="mt-5">
              View Invoice in Quickbooks:{" "}
              <a className="text-blue-500 font-bold" href="https://rackner.com">
                https://rackner.com
              </a>
            </div>
            <div className="flex flex-1"></div>
            <div className="text-md font-bold p-2">Invoice History</div>
            <Card classNames="p-5 mt-2"></Card>
            <Card classNames="p-5 mt-2"></Card>
          </>
        </div>
        <div className="flex flex-1 flex-col h-full bg-gray-100">
          <div
            className="h-full flex"
            style={{ maxWidth: "1000px", margin: "0 auto" }}
          >
            <LoadingWrapper state={state.value}>
              <InvoiceBuilderViewer
                onChange={(data: any) => setInvoiceData(data)}
                invoiceData={invoiceData}
              />
            </LoadingWrapper>
          </div>
        </div>
      </div>
    </>
  );
}
