import { FormConfiguration } from "./Form";
import { BaseApiEntity } from "./base/BaseEntity";

export interface ScopedRole extends BaseApiEntity {
  name: string;
  description: string;
}

export const formatScopedRoleForOptionsList = (v: ScopedRole) => {
  return {
    label: v.name,
    value: v.id,
  };
};

export const REGISTER_FORM_CONFIGURATION: FormConfiguration = {
  fields: [
    {
      name: "firstName",
      type: "textInput",
      validation: { required: true },
      props: {
        label: "First Name",
        width: 3,
      },
    },
    {
      name: "lastName",
      type: "textInput",
      validation: { required: true },
      props: {
        label: "Last Name",
        width: 3,
      },
    },
    {
      name: "email",
      type: "textInput",
      validation: { required: true },
      props: {
        label: "Email",
        width: 6,
      },
    },
    {
      name: "password",
      type: "textInput",
      validation: { required: true },
      props: {
        label: "Password",
        width: 6,
        subType: "password",
      },
    },
    {
      name: "companyName",
      type: "textInput",
      validation: { required: true },
      props: {
        label: "Company Name",
        width: 6,
      },
    },
  ],
};
