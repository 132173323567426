import {
  PieChart,
  Pie,
  Sector,
  Cell,
  ResponsiveContainer,
  PieLabel,
  BarChart,
  Bar,
  YAxis,
  XAxis,
  Tooltip,
} from "recharts";
import { Props } from "../../data/types/Props";
import GraphCard from "../GraphCard/GraphCard";

interface BarGraphCardProps extends Props {
  data: any;
  title: string;
  tooltip?: string;
  xAxisDataKey: string;
  yAxisDataKey: string;
  classNames?: string;
  style?: any;
}

export function BarGraphCard({
  data,
  tooltip,
  title,
  xAxisDataKey,
  yAxisDataKey,
  classNames,
  style,
}: BarGraphCardProps) {
  console.log(data);
  return (
    <GraphCard
      style={style}
      classNames={classNames}
      tooltip={tooltip}
      title={title}
    >
      <ResponsiveContainer>
        <BarChart
          data={data}
          margin={{
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
          }}
        >
          <Tooltip />
          <XAxis dataKey={xAxisDataKey} />
          <YAxis />
          <Bar fill="#8884d8" dataKey={yAxisDataKey}></Bar>
        </BarChart>
      </ResponsiveContainer>
    </GraphCard>
  );
}
