import { FormConfiguration } from "./Form";
import { BaseApiEntity } from "./base/BaseEntity";
import { JobRole } from "./JobRole";
import { ProjectScopeOfWork } from "./ProjectScopeOfWork";

export interface JobRoleAssignment extends BaseApiEntity {
  quantity: number;
  role: JobRole;
  projectScopeOfWork?: ProjectScopeOfWork;
  projectScopeOfWorkId?: string;
}

export interface JobRoleAssignmentCreateUpdateArgs {
  quantity: number;
  roleId?: string;
  projectScopeOfWorkId?: string;
}

export const BUILD_JOB_ROLE_ASSIGNMENT_FORM_CONFIGURATION = (
  isEdit: boolean
): FormConfiguration => {
  return {
    requiredOptionsLoads: ["jobRoles"],
    fields: [
      {
        name: "roleId",
        type: "select",
        optionsPlaceholder: "jobRoles",
        placeholder: "Select a Job Role",
        validation: { required: true },
        toShow: {
          boolean: !isEdit,
        },
        props: {
          label: "Job Role",
          width: 6,
        },
      },
      {
        name: "quantity",
        type: "textInput",
        validation: { required: true, valueAsNumber: true },
        props: {
          label: "Quantity Required",
          width: 6,
          subType: "number",
        },
      },
      {
        name: "recruitingStartDate",
        type: "date",
        validation: { required: true },
        props: {
          label: "Recruiting Start Date",
          width: 6,
        },
      },
      {
        name: "targetFillDate",
        type: "date",
        validation: { required: true },
        props: {
          label: "Target Fill Date",
          width: 6,
        },
      },
    ],
  };
};
