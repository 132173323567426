import { TableColumn } from "../../basics/Table/Table";
import { FormConfiguration } from "./Form";
import { DetailViewConfiguration } from "../../components/BaseDetailView/BaseDetailView";
import { Customer } from "./Customer";
import { MergeConfig } from "./MergeEntities";
import { RATE_TIMEUNITS_OPTIONS } from "./Shared";

export interface Project {
  id: string;
  tenantId: string;
  createdAt: Date;
  updatedAt: Date;
  startingAt: Date;
  endingAt: Date;
  name: string;
  customerId: string;
  customer: Customer;
  customFields: object;
  status: string;
}

export interface ProjectCreateUpdateArgs {
  name: string;
  customerId: string;
}

export const formatProjectForOptionsList = (e: Project) => {
  return {
    label: e.name,
    value: e.id,
  };
};

export const projectStatusToBadgeText = (status: string) => {
  if (status === "ACTIVE") {
    return "Active";
  } else {
    return "Inactive";
  }
};

export const projectStatusToBadgeColor = (status: string) => {
  if (status === "ACTIVE") {
    return "bg-green-100 text-green-800";
  } else {
    return "bg-yellow-100 text-yellow-800";
  }
};

export const PROJECT_DETAIL_CONFIGURATION: DetailViewConfiguration = {
  sections: [
    {
      hideHeader: true,
      fields: [
        {
          key: "name",
          label: "Name",
        },
      ],
    },
  ],
};

export const PROJECT_TABLE_CONFIGURATION: TableColumn[] = [
  {
    key: "name",
    label: "Name",
    displayType: "link",
    linkToKey: "id",
    linkToPrefix: "/projects/",
    styles: "text-gray-600 font-medium",
  },
  {
    key: "status",
    label: "Status",
    rowStyleKey: "badgeColor",
    styles:
      "inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium",
    displayType: "badge",
  },
  {
    key: "customer.name",
    label: "Customer",
    type: "composite",
  },
  {
    key: "startingAt",
    label: "Start Date",
    type: "date",
  },
  {
    key: "endingAt",
    label: "End Date",
    type: "date",
  },
];

export const INVOICE_PROJECT_TABLE_CONFIGURATION: TableColumn[] = [
  {
    key: "totalDue",
    label: "Total Due",
    displayType: "link",
    linkToKey: "id",
    type: "currency",
    typeConfiguration: {
      rateUnitKey: "currency",
    },
    linkToPrefix: "/invoices/",
    styles: "text-gray-600 font-medium",
  },
  {
    key: "balance",
    label: "Balance",
    type: "currency",
    typeConfiguration: {
      rateUnitKey: "currency",
    },
  },
  {
    key: "status",
    label: "Status",
    displayType: "badge",
    rowStyleKey: "badgeColor",
    styles:
      "inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium",
  },
  {
    key: "invoicePeriodStartDate",
    label: "Invoice Period",
    type: "render",
    typeConfiguration: {
      render: (r: any) => {
        return `${r.periodStartDate} - ${r.periodEndDate}`;
      },
    },
  },
  {
    key: "invoiceDate",
    label: "Invoice Date",
    type: "date",
  },
  {
    key: "dueOn",
    label: "Due On",
    type: "date",
  },
  {
    key: "documentsWithUrls",
    label: "Invoice PDF",
    type: "files",
    typeConfiguration: {
      number: "first", // many
      documentTypeSelector: "INVOICE",
    },
  },
];

export const PROJECTED_INVOICE_PROJECT_TABLE_CONFIGURATION: TableColumn[] = [
  {
    label: "Projected Amount",
    key: "amount",
    type: "currency",
    typeConfiguration: {
      rateUnitKey: "currency",
    },
  },
  {
    key: "invoicePeriodStartDate",
    label: "Invoice Period",
    type: "render",
    typeConfiguration: {
      render: (r: any) => {
        return `${r.invoicePeriodStartDate} - ${r.invoicePeriodEndDate}`;
      },
    },
  },
  {
    key: "invoiceDate",
    label: "Invoice Date",
  },
  {
    key: "dueOn",
    label: "Due On",
  },
];

export const SOW_PROJECT_TABLE_CONFIGURATION: TableColumn[] = [
  {
    key: "identifier",
    label: "Identifier",
    displayType: "link",
    linkToKey: "id",
    linkToPrefix: "/sow/",
    styles: "text-gray-600 font-medium",
  },
  {
    key: "status",
    label: "Status",
    rowStyleKey: "badgeColor",
    styles:
      "inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium",
    displayType: "badge",
  },
  {
    label: "Milestones",
    type: "composite",
    key: "milestones.length",
  },
  {
    key: "startingAt",
    label: "Start Date",
  },
  {
    key: "endingAt",
    label: "End Date",
  },
];

export const CUSTOMER_PROJECT_TABLE_CONFIGURATION: TableColumn[] = [
  {
    key: "name",
    label: "Name",
    displayType: "link",
    linkToKey: "id",
    linkToPrefix: "/projects/",
    styles: "text-gray-600 font-medium",
  },
  {
    key: "status",
    label: "Status",
    rowStyleKey: "badgeColor",
    styles:
      "inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium",
    displayType: "badge",
  },
  {
    key: "createdAt",
    label: "Created",
  },
];

export const PROJECT_FORM_CONFIGURATION: FormConfiguration = {
  requiredOptionsLoads: ["customers"],
  fields: [
    {
      name: "name",
      type: "textInput",
      validation: { required: true },
      props: {
        label: "Project Name",
        width: 3,
      },
    },
    {
      name: "customerId",
      type: "select",
      optionsPlaceholder: "customers",
      placeholder: "Select a customer",
      isNotEditable: true,
      validation: { required: true },
      props: {
        label: "Customer",
        width: 3,
      },
    },
  ],
};

export const PROJECT_MERGE_CONFIGURATION: MergeConfig = {
  fields: [
    {
      type: "SELECT_ONE",
      tableField: {
        key: "name",
        label: "Name",
      },
      formField: {
        name: "name",
        type: "textInput",
        validation: { required: true },
        props: {
          label: "Name",
          width: 3,
        },
      },
    },
    {
      type: "INTEGRATIONS",
      tableField: {
        key: "integrationConnections",
        label: "Integration Connections",
      },
      formField: {
        name: "integrationConnections",
        type: "textInput",
        validation: { required: true },
        props: {
          label: "Integration Connections",
          width: 3,
        },
      },
    },
  ],
};
