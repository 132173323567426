import { gql } from "@apollo/client";

const SMART_SEARCH = gql`
  query getTextToSqlResult($search: SmartSearchInput!) {
    data: getTextToSqlResult(search: $search) {
      text
      sqlText
      rawData
      componentConfig {
        type
        xaxis
        yaxis
      }
    }
  }
`;

export { SMART_SEARCH };
