import * as React from "react";
import { BaseInputProps } from "../../data/types/Form";
import DatePicker from "react-datepicker";
import { Controller } from "react-hook-form";

function DateInput({
  label,
  name,
  labelClass,
  wrapperClass,
  inputWrapperClass,
  inputClass,
  errorClass,
  error,
  register,
  validation,
  control,
}: BaseInputProps) {
  return (
    <div className={wrapperClass}>
      {label && (
        <label htmlFor={name} className={labelClass}>
          {label}
        </label>
      )}
      <div style={{ position: "relative" }} className={inputWrapperClass}>
        <Controller
          name={name || "input"}
          control={control}
          rules={validation}
          render={(
            { onChange, onBlur, value, name, ref },
            { invalid, isTouched, isDirty }
          ) => (
            <DatePicker
              selected={value}
              className={`${inputClass} ${invalid ? "" : ""}`}
              onChange={(e: any) => {
                console.log(e);
                onChange(e);
              }}
            />
          )}
        />
        {error && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <svg
              className="h-5 w-5 text-red-500"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                clip-rule="evenodd"
              />
            </svg>
          </div>
        )}
      </div>
      {error && (
        <p className="mt-2 text-sm text-red-600">
          {error.type == "required" ? label + " is required" : error.text}
        </p>
      )}
    </div>
  );
}

export default DateInput;
