import {
  PieChart,
  Pie,
  Sector,
  Cell,
  ResponsiveContainer,
  PieLabel,
  Tooltip,
} from "recharts";
import { Props } from "../../data/types/Props";
import GraphCard from "../GraphCard/GraphCard";

interface PieGraphCardProps extends Props {
  data: any[];
  title: string;
  tooltip?: string;
  xAxisDataKey: string;
  yAxisDataKey: string;
  customLabel?: PieLabel;
  classNames?: string;
  style?: any;
}

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

export function PieGraphCard({
  data,
  tooltip,
  title,
  xAxisDataKey,
  yAxisDataKey,
  customLabel,
  classNames,
  style,
}: PieGraphCardProps) {
  console.log(data);
  return (
    <GraphCard
      style={style}
      classNames={classNames}
      tooltip={tooltip}
      title={title}
    >
      <ResponsiveContainer>
        <PieChart
          margin={{
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
          }}
        >
          <Tooltip />
          <Pie
            data={data}
            outerRadius={80}
            fill="#8884d8"
            dataKey={xAxisDataKey}
            nameKey={yAxisDataKey}
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </GraphCard>
  );
}
