import { useLazyQuery, useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import { BasicButton } from "../../basics/Buttons/BasicButton";
import { IconButton } from "../../basics/Buttons/IconButton";
import Card from "../../basics/Card/Card";
import { Dropdown } from "../../basics/Dropdown/Dropdown";
import SlideOverWrapper from "../../basics/SlideOverWrapper/SlideOverWrapper";
import { selectIntegrationImageFromExternalRelationship } from "../../basics/Table/Table";
import TextType from "../../basics/TextType/TextType";
import { MERGE_ENTITIES } from "../../data/queries/merge";
import { MergeConfig, MergeFieldConfig } from "../../data/types/MergeEntities";

export interface EntityMergeSlideOverProps {
  open: boolean;
  setOpen: Function;
  notifications: any;
  entityId: string;
  entityType: string;
  entityName: string;
  singleQuery: any;
  listQuery: any;
  formatter: any;
  config: MergeConfig;
}

export function EntityMergeSlideOver({
  open,
  setOpen,
  entityId,
  entityType,
  entityName,
  singleQuery,
  listQuery,
  formatter,
  config,
}: any) {
  const [getOriginalData, originalData] = useLazyQuery(singleQuery);
  const [getListData, listData] = useLazyQuery(listQuery);
  const [getToMergeData, toMergeData] = useLazyQuery(singleQuery);
  const [toMergeId, setToMergeId]: any = useState({});
  const [mergeMaster, setMergeMaster]: any = useState(entityId);
  const [mergeMaps, setMergeMaps]: any = useState({}); // support for individual fields

  const [mergeEntities, mergeEntitiesResult] = useMutation(MERGE_ENTITIES, {
    onCompleted: () => setOpen(false),
  });

  useEffect(() => {
    if (open) {
      getOriginalData({ variables: { id: entityId } });
      getListData();
    }
  }, [entityId, open]);

  useEffect(() => {
    if (!toMergeId?.value) return;
    getToMergeData({ variables: { id: toMergeId.value } });
  }, [toMergeId]);

  const commitMerge = () => {
    mergeEntities({
      variables: {
        toMergeId: toMergeId?.value,
        masterId: mergeMaster,
        entityType,
      },
    });
  };

  const modelForIntegrations = (data: any) => {
    if (!data) return;
    let d = data?.externalData?.externalRelationships;
    let integrationConnections = d?.map((ic: any) => {
      return ic.sourceIntegration;
    });
    return {
      ...data,
      integrationConnections,
    };
  };

  const renderTable = () => {
    const orig = modelForIntegrations(originalData?.data?.data);
    if (!orig) return <div></div>;
    const toMerge = modelForIntegrations(toMergeData?.data?.data);
    const list = listData?.data?.data?.edges?.map((e: any) => e.node) || [];
    // Header is based on the option formatter

    // TODO should be able to select individual fields
    return (
      <>
        <Card>
          <div className="flex flex-col border text-sm">
            <div className="flex flex-row">
              <div className="flex flex-1 p-4 border-r font-bold">Field</div>
              <div
                className={`flex flex-1 p-4 border-r cursor-pointer ${
                  mergeMaster === orig?.id ? "bg-green-100" : ""
                }`}
                onClick={() => setMergeMaster(orig?.id)}
              >
                Original
              </div>
              {toMerge ? (
                <>
                  <div
                    className={`flex flex-1 p-4 cursor-pointer ${
                      mergeMaster === toMerge?.id ? "bg-green-100" : ""
                    }`}
                    onClick={() => setMergeMaster(toMerge?.id)}
                  >
                    Merge With
                  </div>
                  <div className={`w-40 p-4`}></div>
                </>
              ) : (
                <div className="flex flex-1 p-4">
                  <Dropdown
                    additionalClasses={
                      "relative block bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 px-3 text-left cursor-pointer focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    }
                    onChange={(e: any) => {
                      setToMergeId(e);
                    }}
                    placeholder={"Select item to merge from " + entityName}
                    selected={toMergeId}
                    options={list
                      .filter((e: any) => e.id !== entityId)
                      .map((e: any) => formatter(e))}
                  />
                </div>
              )}
            </div>
            {config.fields
              .filter((f: MergeFieldConfig) => !f.hideInTable)
              .map((field: MergeFieldConfig) => {
                return (
                  <div
                    className="flex flex-row border-t"
                    key={field.formField.name}
                  >
                    <div className="flex flex-1 p-4 border-r font-bold">
                      {field.tableField?.label}
                    </div>
                    <div
                      className={`flex flex-1 p-4 border-r cursor-pointer  ${
                        mergeMaster === orig?.id ? "bg-green-100" : ""
                      }`}
                      onClick={() =>
                        setMergeMaps({
                          ...mergeMaps,
                          key: field.tableField?.key,
                          id: orig?.id,
                        })
                      }
                    >
                      {renderFieldValues(field, orig)}
                    </div>
                    {toMerge ? (
                      <>
                        <div
                          className={`flex flex-1 p-4 cursor-pointer ${
                            mergeMaster === toMerge?.id ? "bg-green-100" : ""
                          }`}
                          onClick={() => setMergeMaps(toMerge?.id)}
                        >
                          {renderFieldValues(field, toMerge)}
                        </div>
                        {field.type === "INTEGRATIONS" ? (
                          <div className="w-40 align-center flex flex-row p-4">
                            <IconButton icon="test" text={"Combine Both"} />
                          </div>
                        ) : (
                          <div className="w-40"></div>
                        )}
                      </>
                    ) : (
                      <div className={`flex flex-1 p-4`}>--</div>
                    )}
                  </div>
                );
              })}
          </div>
        </Card>
      </>
    );
  };

  const renderFieldValues = (field: MergeFieldConfig, data: any) => {
    if (field.type === "SELECT_ONE") {
      return (
        <TextType
          dataObject={data}
          type={field.tableField?.type}
          displayType={field.tableField?.displayType}
          typeConfiguration={field.tableField?.typeConfiguration}
          fieldKey={field.tableField?.key || ""}
        />
      );
    } else if (field.type === "INTEGRATIONS") {
      return (
        <>
          {data?.integrationConnections?.map((i: any) => {
            if (!i) return;
            return (
              <div className="px-4 pl-6 flex flex-row align-center items-center">
                {selectIntegrationImageFromExternalRelationship({
                  sourceIntegration: i,
                })}
                {i?.target}
              </div>
            );
          })}
        </>
      );
    }
  };

  return (
    <SlideOverWrapper
      bodyClassNames="bg-gray-100 flex-col"
      bodyStyles={{ maxWidth: "1000px" }}
      title={`Merge ${entityName}`}
      open={open}
      setOpen={setOpen}
    >
      <div className="p-4">{renderTable()}</div>
      <div className="flex-1"></div>
      <div className="px-4 py-3 bg-white sm:px-6 border-t align-middle items-center flex flex-row">
        <BasicButton
          onClick={() => commitMerge()}
          text={"Confirm Merge"}
          color={"green"}
        />
      </div>
    </SlideOverWrapper>
  );
}
