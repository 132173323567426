export const utilizationToDescriptionString = ({
  utilizationAmount,
  utilizationAmountPerUnits,
  utilizationAmountUnits,
}: any) => {
  return (
    utilizationAmount +
    " " +
    utilizationAmountUnitToDescription(utilizationAmountUnits) +
    " per " +
    utilizationAmountUnitPerToDescription(utilizationAmountPerUnits)
  );
};

const utilizationAmountUnitToDescription = (unit: string) => {
  switch (unit) {
    case "WEEK":
      return "weeks";
    case "HOUR":
      return "hours";
    case "MONTH":
      return "months";
  }
};

const utilizationAmountUnitPerToDescription = (unit: string) => {
  switch (unit) {
    case "WEEK":
      return "week";
    case "HOUR":
      return "hour";
    case "MONTH":
      return "month";
  }
};
