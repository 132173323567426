import { ReactNode } from "react";
import Card from "../../basics/Card/Card";
import { Props } from "../../data/types/Props";
import { InformationCircleIcon } from "@heroicons/react/24/outline";

interface SidemenuClearListTabsProps extends Props {
  items: SidemenuClearListTabsItem[];
  setter: any;
  getter: any;
  mode?: "vertical" | "horizontal";
}

interface SidemenuClearListTabsItem {
  identifier: string;
  text: string;
  icon: any;
}

function SidemenuClearListTabs({
  items,
  getter,
  setter,
  className,
  style,
  mode = "vertical",
}: SidemenuClearListTabsProps) {
  return (
    <div
      style={style}
      className={
        `flex ${mode === "horizontal" ? "flex-row bg-white shadow rounded-md " : "flex-col"}  ` + className
      }
    >
      {items.map((i) => {
        return (
          <div
            className={
              `cursor-pointer  ${
                mode === "horizontal"
                  ? "border-b-2 py-2 px-3"
                  : "border-r-4 py-3 pl-0 pr-5 "
              }  ` +
              (getter === i.identifier
                ? "border-blue-500 text-blue-500 font-medium"
                : " text-gray-600 font-medium")
            }
            onClick={() => setter(i.identifier)}
          >
            <h2 className="text-xs capitalize flex flex-row items-center">
              <i.icon
                height={mode === "horizontal" ? 14 : 18}
                className={`${mode === "horizontal" ? "mr-2" : "mr-3"}`}
              />
              {i.text}
            </h2>
          </div>
        );
      })}
    </div>
  );
}

export default SidemenuClearListTabs;
