import { TrashIcon } from "@heroicons/react/24/outline";
import {
  PencilIcon,
  EllipsisHorizontalIcon,
  DocumentDuplicateIcon,
} from "@heroicons/react/24/solid";
import { ReactNode } from "react";
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import PageHeader from "../PageHeader/PageHeader";
import { IconButton } from "../../basics/Buttons/IconButton";
import { BasicButtonStyles } from "../../basics/Buttons/BasicButton";
import {
  ExternalData,
  ExternalDataRelationship,
} from "../../data/types/base/BaseEntity";
import { selectIntegrationImageFromExternalRelationship } from "../../basics/Table/Table";
import { PageTitle } from "../../basics/PageTitle/PageTitle";
import { DropdownButton } from "../../basics/DropdownButton/DropdownButton";
import { PageSubtitle } from "../../basics/PageSubtitle/PageSubtitle";

interface DetailViewHeaderProps {
  name: string;
  onEdit: any;
  hideEdit?: boolean;
  entityName?: string;
  subheader?: ReactNode;
  additionalActions?: any;
  centerContent?: any;
  additionalLeftContent?: ReactNode;
  additionalRightContent?: ReactNode;
  onMerge?: any;
  externalData?: any;
}

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export function DetailViewHeader({
  name,
  onEdit,
  hideEdit,
  onMerge,
  entityName,
  subheader,
  additionalLeftContent,
  additionalRightContent,
  externalData,
  additionalActions,
  centerContent,
}: DetailViewHeaderProps) {
  return (
    <PageHeader
      disableBreadcrumbs={true}
      disableBg
      disableBorder
      disableShadow
      sticky={false}
      classNames="mb-5"
      leftContent={
        <>
          <div className="flex flex-col">
            <PageTitle title={name} />
            {entityName && name && <PageSubtitle text={entityName} />}
          </div>

          {additionalLeftContent}
          {centerContent && (
            <div
              className="absolute"
              style={{ left: "50%", transform: "translate(-50%)" }}
            >
              {centerContent}
            </div>
          )}
          {externalData && externalData.externalRelationships && (
            <>
              <div className="border-l ml-5"></div>
              <div className="pl-10 pt-1 flex flex-row align-center">
                {externalData?.externalRelationships.map((e: any) =>
                  selectIntegrationImageFromExternalRelationship(e)
                )}
              </div>
              <div className="font-bold text-gray-900 uppercase text-sm mt-1">
                Connected with{" "}
                {externalData?.externalRelationships?.map(
                  (e: ExternalDataRelationship, index: number) =>
                    index === externalData?.externalRelationships.length - 1
                      ? e.sourceIntegration?.target
                      : e.sourceIntegration?.target + ", "
                )}
              </div>
            </>
          )}
        </>
      }
      disableSubheaderDivider={true}
      subheader={subheader}
      rightContent={
        <>
          {additionalRightContent}
          {!hideEdit && (
            <IconButton
              onClick={onEdit}
              text="Edit"
              color="yellow"
              styler={BasicButtonStyles.DROPDOWN}
              iconClassNames="mr-2"
              icon={PencilIcon}
              classNames="mr-3"
            />
          )}
          {additionalActions}
          <DropdownButton
            items={[
              {
                text: "Triage",
                onClick: () => console.log("triage"),
              },
              {
                text: "Delete",
                color: "red",
                onClick: () => console.log("delete"),
              },
            ]}
          />
        </>
      }
    />
  );
}
