import { useMutation } from "@apollo/client";
import {
  GET_INTEGRATIONS,
  CREATE_INTEGRATION,
  SYNC_INTEGRATION,
  DELETE_INTEGRATION,
} from "../../data/queries/integrations";
import { useEffect, useState } from "react";
import { Integration } from "../../data/types/Integration";
import IntegrationManagementPanel from "../../components/IntegrationManagementPanel/IntegrationManagementPanel";
import PageHeader from "../../components/PageHeader/PageHeader";
import SidemenuClearListTabs from "../../components/SidemenuClearListTabs/SidemenuClearListTabs";
import {
  CurrencyDollarIcon,
  UsersIcon,
  ListBulletIcon,
} from "@heroicons/react/24/solid";
import SlideOverWrapper from "../../basics/SlideOverWrapper/SlideOverWrapper";
import MinimalForm from "../../components/MinimalForm/MinimalForm";
import { useForm } from "react-hook-form";
import { DefaultPagePaddingAndWidth } from "../../basics/DefaultPagePaddingAndWidth/DefaultPagePaddingAndWidth";
import { PageTitle } from "../../basics/PageTitle/PageTitle";
import { IconButton } from "../../basics/Buttons/IconButton";
import { BasicButtonStyles } from "../../basics/Buttons/BasicButton";
import { ChatBubbleBottomCenterIcon } from "@heroicons/react/24/outline";
import {
  LazyListQuerySM,
  useLazyListQuerySM,
} from "../../data/machines/queries";

export function Integrations() {
  const [state, data, send]: LazyListQuerySM<Integration> =
    useLazyListQuerySM(GET_INTEGRATIONS);
  const [tab, setTab] = useState("financial");
  const [moreInfoModalOpen, setMoreInfoModalOpen]: any = useState(false);
  const [moreInfoIntegration, setMoreInfoIntegration]: any = useState(null);
  const [createIntegration, createResult] = useMutation(CREATE_INTEGRATION);
  const [syncIntegration, syncResult] = useMutation(SYNC_INTEGRATION, {
    refetchQueries: [
      {
        query: GET_INTEGRATIONS,
      },
    ],
  });
  const [deleteIntegration, deleteResult] = useMutation(DELETE_INTEGRATION, {
    refetchQueries: [
      {
        query: GET_INTEGRATIONS,
      },
    ],
  });
  const [cursor, setCursor] = useState(null);
  const form = useForm<FormData>({});

  useEffect(() => {
    send({ type: "MAKE_QUERY", variables: { cursor } });
  }, [cursor]);

  useEffect(() => {
    if (
      createResult.data &&
      createResult.data.createIntegration &&
      !createResult.error &&
      !createResult.loading
    ) {
      window.location = createResult.data.createIntegration.oauthUrl;
    }
  }, [createResult]);

  const submitForm = (data: any) => {
    createIntegration({
      variables: {
        target: moreInfoIntegration,
        clientId: data.clientId,
        clientSecret: data.clientSecret,
        mondayBoardId: data.mondayBoardId,
      },
    });
    setMoreInfoModalOpen(false);
    setMoreInfoIntegration(null);
  };

  const onConnectIntegration = async (ev: any, target: string) => {
    await createIntegration({
      variables: {
        target,
      },
    });
  };

  const onDisconnectIntegration = async (e: any, intId: string) => {
    await deleteIntegration({
      variables: {
        id: intId,
      },
    });
  };

  const onSyncIntegration = async (e: any, intId: string) => {
    console.log(e, intId);
    await syncIntegration({
      variables: {
        id: intId,
      },
    }).then(async () => {
      send({ type: "REFETCH" });
    });
  };

  return (
    <>
      <PageHeader
        leftContent={
          <>
            <PageTitle title="Integrations" />
          </>
        }
        rightContent={
          <IconButton
            icon={ChatBubbleBottomCenterIcon}
            text="Request New"
            color="green"
            styler={BasicButtonStyles.DROPDOWN}
          />
        }
      />
      <DefaultPagePaddingAndWidth>
        <div className="flex flex-row pt-7">
          <SidemenuClearListTabs
            classNames="bg-none pl-6 mr-10"
            style={{ minWidth: "250px" }}
            getter={tab}
            setter={setTab}
            items={[
              {
                identifier: "all",
                icon: ListBulletIcon,
                text: "All Integrations (4)",
              },
              {
                identifier: "financial",
                icon: CurrencyDollarIcon,
                text: "Accounting & ERP (2)",
              },
              {
                identifier: "users",
                icon: UsersIcon,
                text: "HCM & Recruiting (2)",
              },
            ]}
          />
          {state.value === "loading" && (
            <div className="py-10 w-full align-center items-center flex flex-row justify-center relative text-center">
              <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12"></div>
            </div>
          )}
          {state.value === "completed" && (
            <div className="ml-10 w-full gap-2.5 grid grid-cols-1 md:grid-cols-2">
              <IntegrationManagementPanel
                description={"Sync invoices and customers with Quickbooks"}
                title={"Connect Quickbooks"}
                activeIntegration={data.edges.find(
                  (e: any) => e.node.target === "QUICKBOOKS"
                )}
                iconName="quickbooks.png"
                onSync={onSyncIntegration}
                onDisconnect={onDisconnectIntegration}
                classNames={""}
                onConnect={(e: any) => onConnectIntegration(e, "QUICKBOOKS")}
              />
              <IntegrationManagementPanel
                description={"Sync project tasks and task templates with Jira"}
                title={"Connect Jira"}
                classNames={""}
                activeIntegration={data.edges.find(
                  (e: any) => e.node.target === "JIRA"
                )}
                onSync={onSyncIntegration}
                onDisconnect={onDisconnectIntegration}
                onConnect={(e: any) => onConnectIntegration(e, "JIRA")}
              />
              <IntegrationManagementPanel
                description={"Sync projects and opportunities with Salesforce"}
                title={"Connect Salesforce"}
                iconName="salesforce.jpeg"
                classNames={""}
                activeIntegration={data.edges.find(
                  (e: any) => e.node.target === "SALESFORCE"
                )}
                onSync={onSyncIntegration}
                onDisconnect={onDisconnectIntegration}
                onConnect={(e: any) => onConnectIntegration(e, "SALESFORCE")}
              />
              <IntegrationManagementPanel
                description={"Sync projects and opportunities with Upwork"}
                title={"Connect Upwork"}
                iconName="upwork.png"
                classNames={""}
                activeIntegration={data.edges.find(
                  (e: any) => e.node.target === "UPWORK"
                )}
                onDisconnect={onDisconnectIntegration}
                onSync={onSyncIntegration}
                onConnect={(e: any) => onConnectIntegration(e, "UPWORK")}
              />

              <IntegrationManagementPanel
                description={"Sync time with TSheets"}
                title={"Connect TSheets (Quickbooks Time)"}
                iconName="quickbooks.png"
                classNames={""}
                activeIntegration={data.edges.find(
                  (e: any) => e.node.target === "TSHEETS"
                )}
                onSync={onSyncIntegration}
                onDisconnect={onDisconnectIntegration}
                onConnect={(e: any) => {
                  setMoreInfoModalOpen(true);
                  setMoreInfoIntegration("TSHEETS");
                }}
              />
              <IntegrationManagementPanel
                description={"Sync Time Off Requests and Employees from Gusto"}
                title={"Connect Gusto"}
                iconName="gusto.png"
                classNames={""}
                activeIntegration={data.edges.find(
                  (e: any) => e.node.target === "GUSTO"
                )}
                onSync={onSyncIntegration}
                onDisconnect={onDisconnectIntegration}
                onConnect={(e: any) => onConnectIntegration(e, "GUSTO")}
              />
              <IntegrationManagementPanel
                description={"Sync Opportunities from Monday.com Board"}
                title={"Connect Monday.com"}
                iconName="monday.png"
                classNames={""}
                activeIntegration={data.edges.find(
                  (e: any) => e.node.target === "MONDAY"
                )}
                onSync={onSyncIntegration}
                onDisconnect={onDisconnectIntegration}
                onConnect={(e: any) => {
                  setMoreInfoModalOpen(true);
                  setMoreInfoIntegration("MONDAY");
                }}
              />
            </div>
          )}
          <SlideOverWrapper
            title={`We need some more info to set up your integration`}
            open={moreInfoModalOpen}
            setOpen={setMoreInfoModalOpen}
          >
            <MinimalForm
              fields={
                moreInfoIntegration === "TSHEETS"
                  ? [
                      {
                        type: "textInput",
                        name: "clientId",
                        props: {
                          label: "Client ID",
                          width: 12,
                        },
                      },
                      {
                        type: "textInput",
                        name: "clientSecret",
                        props: {
                          label: "Client Secret",
                          width: 12,
                        },
                      },
                    ]
                  : [
                      {
                        type: "textInput",
                        name: "clientSecret",
                        props: {
                          label: "Client Secret",
                          width: 12,
                        },
                      },
                      {
                        type: "textInput",
                        name: "mondayBoardId",
                        props: {
                          label: "Opportunity Monday.com Board ID (from URL)",
                          width: 12,
                        },
                      },
                    ]
              }
              submitText={"Submit"}
              form={form}
              onSubmit={submitForm}
            />
          </SlideOverWrapper>
        </div>
      </DefaultPagePaddingAndWidth>
    </>
  );
}
