import { ClockIcon, PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import React, { memo } from "react";

import { Handle, Position } from "react-flow-renderer";
import { Dropdown } from "../../../basics/Dropdown/Dropdown";
import {
  translateEntities,
  translateFieldToName,
  translateOperator,
  translateSpecialValueCase,
} from "../../../utilities/workflows";

export const renderTriggerStepInformation = (step: any) => {
  return (
    <div className="text-sm">
      <div>
        For:{" "}
        {translateEntities(step.stepConfig?.TRIGGER.triggerRelation?.entity)}
      </div>
      {step.stepConfig?.TRIGGER.triggerRelation?.where && (
        <div>Where: {step.stepConfig?.TRIGGER.triggerRelation?.where}</div>
      )}
      <div>
        Where:{" "}
        {step.stepConfig?.TRIGGER.triggerConditions?.map(
          (condition: any, index: number) => {
            return (
              <>
                {index > 0 && step.stepConfig?.TRIGGER.triggerConditionLogic}
                {translateFieldToName(condition.field)}{" "}
                {translateOperator(condition.comparatorOperator)}{" "}
                {translateSpecialValueCase(condition.comparatorValue)}
              </>
            );
          }
        )}
      </div>
    </div>
  );
};

export const renderInfoBlock = (s2: any) => {
  if (s2?.type === "TRIGGER") {
    return renderTriggerStepInformation(s2);
  } else if (s2?.type === "WAIT") {
    return (
      <div className="text-sm">
        For: {s2.stepConfig?.WAIT.waitInMilliseconds}ms
      </div>
    );
  } else if (s2?.type === "REPEAT") {
    return <div className="text-sm">Repeat</div>;
  }
};

export default memo(({ data, id }: any) => {
  const step = data.rawStep;

  return (
    <>
      {data.options?.nodeConnectionType !== "input" && (
        <Handle
          type="target"
          position={Position.Left}
          style={{ background: "#555" }}
        />
      )}
      <div className="flex flex-row align-middle">
        {data.icon && <data.icon width={22} height={22} />}
        <div className="ml-1">{data.label}</div>
        <div className="flex-1 flex"></div>
        <div
          onClick={() => data.setStep(data.rawStep)}
          className="cursor-pointer"
        >
          <PencilIcon height={20} width={20} color={"#3b82f6"} />
        </div>
        <div
          onClick={() => data.deleteStep(data.rawStep)}
          className="cursor-pointer"
        >
          <TrashIcon height={20} width={20} color={"red"} />
        </div>
      </div>
      <div className="description">{renderInfoBlock(step)}</div>
      <Handle type="source" position={Position.Right} id="b" />
    </>
  );
});
