import { ReactNode } from "react";
import Card from "../../basics/Card/Card";
import { Props } from "../../data/types/Props";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import "./GraphCard.css";

interface GraphCardProps extends Props {
  title: string;
  tooltip?: ReactNode;
  style?: any;
}

function GraphCard({
  children,
  title,
  classNames,
  tooltip,
  style,
}: GraphCardProps) {
  return (
    <Card
      style={style}
      dashboardStyle
      classNames={"group flex-col rounded-none flex " + classNames}
    >
      <div className="rounded-t-lg pt-4 pb-4 items-center bg-white flex flex-row">
        <div className="px-6">
          <h3 className="text-base font-light dark:text-gray-200 text-black">
            {title}
          </h3>
        </div>
        <div className="flex flex-1"></div>
        <div className="pl-2 relative invisible group-hover:visible hover-trigger cursor-pointer mr-5">
          <InformationCircleIcon className=" text-gray-700 h-5 w-5 dark:text-gray-200" />
          <div className="absolute font-gray-700 font-regular bg-gray-100 shadow-lg rounded-sm border border-gray-300 z-10 right-0 px-6 py-3 hover-target">
            <div className="text-sm font-regular w-56">{tooltip}</div>
          </div>
        </div>
      </div>
      <div className="flex flex-1 w-full">{children}</div>
    </Card>
  );
}

export default GraphCard;
