import { TableColumn } from "../../basics/Table/Table";
import { FormConfiguration } from "./Form";
import { DetailViewConfiguration } from "../../components/BaseDetailView/BaseDetailView";
import { formatBaseItemForList } from "../../utilities/dates";
import {
  invoiceStatusToBadgeColor,
  invoiceStatusToBadgeText,
} from "../../pages/Invoice/Invoices";

export interface Invoice {
  id: string;
  tenantId: string;
  createdAt: Date;
  updatedAt: Date;
  status: string;
}

export const formatInvoiceForList = (item: any) => {
  return {
    ...formatBaseItemForList(item, [
      "invoiceDate",
      "invoicePeriodStartDate",
      "invoicePeriodEndDate",
      "dueOn",
      "periodStartDate",
      "periodEndDate",
    ]),
    statusText: invoiceStatusToBadgeText(item.status),
    badgeColor: invoiceStatusToBadgeColor(item.status),
  };
};

export const INVOICE_DETAIL_CONFIGURATION: DetailViewConfiguration = {
  sections: [
    {
      title: "Basic Information",
      subtitle: "Some basic info about your project",
      fields: [
        {
          key: "totalDue",
          label: "Total",
        },
      ],
    },
  ],
};

export const INVOICE_TABLE_CONFIGURATION: TableColumn[] = [
  {
    key: "totalDue",
    label: "Total Due",
    displayType: "link",
    linkToKey: "id",
    type: "currency",
    typeConfiguration: {
      rateUnitKey: "currency",
    },
    linkToPrefix: "/invoices/",
    styles: "text-gray-600 font-medium",
  },
  {
    key: "balance",
    label: "Balance",
    type: "currency",
    typeConfiguration: {
      rateUnitKey: "currency",
    },
  },
  {
    key: "statusText",
    label: "Status",
    displayType: "badge",
    rowStyleKey: "badgeColor",
    styles:
      "inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium",
  },
  {
    key: "customer.name",
    type: "composite",
    displayType: "link",
    linkToKey: "customer.id",
    linkToPrefix: "/customers/",
    styles: "text-gray-600 font-medium",
    label: "Customer",
  },
  {
    key: "dueOn",
    label: "Due On",
    type: "date",
  },
  {
    key: "invoiceDate",
    label: "Invoice Date",
    type: "date",
  },
  {
    key: "createdBy",
    label: "Created By",
    type: "render",
    typeConfiguration: {
      render: (item: any) => {
        return (
          <div className="flex flex-row align-center items-center">
            <img className="h-6 mr-2 -ml-1" src="/kensobot.png" /> <div>KensoBot</div>
          </div>
        );
      },
    },
  },
  {
    key: "documentsWithUrls",
    label: "Invoice PDF",
    type: "files",
    typeConfiguration: {
      number: "first", // many
      documentTypeSelector: "INVOICE",
    },
  },
];

export const CUSTOMER_INVOICE_TABLE_CONFIGURATION: TableColumn[] = [
  {
    key: "totalDue",
    label: "Total Due",
    displayType: "link",
    linkToKey: "id",
    type: "currency",
    typeConfiguration: {
      rateUnitKey: "currency",
    },
    linkToPrefix: "/projects/",
    styles: "text-gray-600 font-medium",
  },
  {
    key: "balance",
    label: "Balance",
    type: "currency",
    typeConfiguration: {
      rateUnitKey: "currency",
    },
  },
  {
    key: "statusText",
    label: "Status",
    displayType: "badge",
    rowStyleKey: "badgeColor",
    styles:
      "inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium",
  },
  {
    key: "dueOn",
    label: "Due On",
    type: "date",
  },
  {
    key: "invoiceDate",
    label: "Invoice Date",
    type: "date",
  },
];

export const INVOICE_FORM_CONFIGURATION: FormConfiguration = {
  requiredOptionsLoads: ["customers"],
  fields: [
    {
      name: "name",
      type: "textInput",
      validation: { required: true },
      props: {
        label: "Project Name",
        width: 3,
      },
    },
    {
      name: "customerId",
      type: "select",
      optionsPlaceholder: "customers",
      isNotEditable: true,
      validation: { required: true },
      props: {
        label: "Customer",
        width: 3,
      },
    },
  ],
};
