import { TableColumn } from "../../basics/Table/Table";
import { DetailViewConfiguration } from "../../components/BaseDetailView/BaseDetailView";
import { FormConfiguration } from "./Form";
import { BaseApiEntity } from "./base/BaseEntity";
import {
  CURRENCIES,
  CURRENCIES_OPTIONS,
  CUSTOMER_STATUSES,
  CUSTOMER_STATUSES_OPTIONS,
} from "./Shared";
import { MergeConfig } from "./MergeEntities";

export interface Customer extends BaseApiEntity {
  startingAt: Date;
  endingAt: Date;
  name: string;
  status: string;
  badgeColor: string; // Added at runtime
  customFields: object;
  inTriageFromIntegration?: boolean;
}

export interface CustomerCreateUpdateArgs {
  name?: string;
  status?: CUSTOMER_STATUSES;
  currency?: CURRENCIES;
  inTriageFromIntegration?: boolean;
}

export const formatCustomerForOptionsList = (e: Customer) => {
  return {
    label: e.name,
    value: e.id,
  };
};

export const BUILD_CUSTOMER_FORM_CONFIGURATION = (
  isEdit: boolean
): FormConfiguration => {
  return {
    fields: [
      {
        name: "name",
        type: "textInput",
        validation: { required: true },
        props: {
          label: "Name",
          width: 6,
        },
      },
      {
        name: "status",
        type: "select",
        options: CUSTOMER_STATUSES_OPTIONS,
        placeholder: "Status",
        validation: { required: true },
        props: {
          label: "Status",
          width: 6,
        },
      },
      {
        name: "currency",
        type: "select",
        options: CURRENCIES_OPTIONS,
        placeholder: "Currency",
        validation: { required: true },
        props: {
          label: "Currency",
          width: 6,
        },
      },
    ],
  };
};

export const CUSTOMER_DETAIL_CONFIGURATION: DetailViewConfiguration = {
  sections: [
    {
      title: "Basic Information",
      subtitle: "Some basic info about this customer",
      fields: [
        {
          key: "name",
          label: "Name",
        },
        {
          key: "status",
          label: "Status",
          rowStyleKey: "badgeColor",
          styles:
            "inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium",
          displayType: "badge",
        },
      ],
    },
  ],
};

export const CUSTOMER_TABLE_CONFIGURATION: TableColumn[] = [
  {
    key: "name",
    label: "Name",
    displayType: "link",
    linkToKey: "id",
    linkToPrefix: "/customers/",
    styles: "text-gray-600 font-medium",
  },
  {
    key: "status",
    label: "Status",
    displayType: "badge",
    rowStyleKey: "badgeColor",
    styles:
      "inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium",
  },
  {
    key: "updatedAt",
    label: "Updated At",
  },
  {
    key: "createdAt",
    label: "Created At",
  },
];

export const CUSTOMER_FORM_CONFIGURATION: FormConfiguration = {
  fields: [
    {
      name: "name",
      type: "textInput",
      validation: { required: true },
      props: {
        label: "Customer Name",
        width: 3,
      },
    },
  ],
};

export const CUSTOMER_MERGE_CONFIGURATION: MergeConfig = {
  fields: [
    {
      type: "SELECT_ONE",
      tableField: {
        key: "name",
        label: "Name",
      },
      formField: {
        name: "name",
        type: "textInput",
        validation: { required: true },
        props: {
          label: "Name",
          width: 3,
        },
      },
    },
    {
      type: "INTEGRATIONS",
      tableField: {
        key: "integrationConnections",
        label: "Integration Connections",
      },
      formField: {
        name: "integrationConnections",
        type: "textInput",
        validation: { required: true },
        props: {
          label: "Integration Connections",
          width: 3,
        },
      },
    },
  ],
};
