import { ReactNode } from "react";
import { useState } from "react";
import { Props } from "../../data/types/Props";

export interface TabsProps extends Props {
  tabs: Tab[];
  mode: "CONTENT" | "EVENT";
  activeTab: string;
  setTab: any;
  onSelect?: any;
  minimal?: boolean;
}

interface Tab {
  name: string;
  identifier: string;
  icon?: any;
  content?: ReactNode;
}

function Tabs({
  tabs,
  mode,
  onSelect,
  activeTab,
  setTab,
  classNames,
  minimal,
  style,
}: TabsProps) {
  let activeContent;
  if (mode === "CONTENT") {
    activeContent = tabs.find((t) => t.identifier === activeTab)?.content;
  }

  const onSelectTab = (item: any) => {
    setTab(item.identifier);
    if (mode === "EVENT" && onSelect) {
      onSelect(item.identifier);
    }
  };

  return (
    <>
      <div
        style={style}
        className={
          (minimal
            ? "flex flex-row bg-transparent overflow-visible " + classNames
            : "flex flex-row rounded-md overflow-hidden border border-gray-200 flex-wrap flex-row bg-white ") +
          classNames
        }
      >
        {tabs.map((t: Tab, index) => {
          return (
            <>
              {minimal ? (
                <div
                  className={
                    "cursor-pointer flex-1 border-b " +
                    (activeTab === t.identifier
                      ? "text-blue-500"
                      : "text-gray-500")
                  }
                  onClick={() => onSelectTab(t)}
                >
                  <div
                    className={
                      "px-2 py-2 text-xs font-bold flex-row flex uppercase align-middle items-center  " +
                      (index === 0 ? " " : " border-l border-gray-100")
                    }
                  >
                    {t.icon}
                    <span className={t.icon ? "ml-2" : ""}>{t.name}</span>
                  </div>
                </div>
              ) : (
                <div
                  className={
                    "cursor-pointer flex-1 " +
                    (activeTab === t.identifier
                      ? "border-b-2 border-blue-500 text-blue-500"
                      : "border-b-2 border-gray-200 text-gray-500")
                  }
                  onClick={() => onSelectTab(t)}
                >
                  <div
                    className={
                      "px-2 py-2 text-xs font-bold flex-row flex uppercase align-middle items-center " +
                      (index === 0 ? " " : " border-l border-gray-100")
                    }
                  >
                    {t.icon}
                    <span className={t.icon ? "ml-2" : ""}>{t.name}</span>
                  </div>
                </div>
              )}
            </>
          );
        })}
      </div>
      {mode === "CONTENT" && activeContent}
    </>
  );
}

export default Tabs;
