import { LinkIcon, PencilIcon } from "@heroicons/react/24/outline";
import { PlusIcon } from "@heroicons/react/24/solid";
import { BasicButtonStyles } from "../../basics/Buttons/BasicButton";
import { IconButton } from "../../basics/Buttons/IconButton";
import { SectionHeader } from "../../basics/SectionHeader/SectionHeader";

interface DocumentsListProps {
  items: any[];
  onEdit: any;
}

function DocumentsList({ items, onEdit }: DocumentsListProps) {
  const renderItemBlock = (item: any) => {
    return (
      <a target="__blank" href={item.presignedDownloadUrl}>
        <div className="w-70 flex flex-col p-2 align-center items-center cursor-pointer bg-white rounded-md border-gray-200 hover:shadow border">
          <div className="flex p-2 flex-row align-center items-center text-xs font-bold">
            <LinkIcon height={15} className="mr-2" /> {item.name}
          </div>
          <div className="text-xs "> {item.versions?.length} Versions</div>
          {item.presignedThumbnailUrl && (
            <img className="h-72" src={item.presignedThumbnailUrl} />
          )}
        </div>
      </a>
    );
  };

  return (
    <div className="mt-7">
      <SectionHeader
        title="All Project Documents"
        borderEnabled={true}
        button={
          <IconButton
            onClick={() => onEdit()}
            icon={PlusIcon}
            styler={BasicButtonStyles.CLEAR}
            text="Add Document"
          />
        }
      />
      <div className="grid gap-4 grid-flow-col auto-cols-max">
        {items.map((a) => renderItemBlock(a))}
      </div>
    </div>
  );
}

export default DocumentsList;
