import { useEffect, useState } from "react";
import {
  CurrencyDollarIcon,
  PencilIcon,
  PlusIcon,
} from "@heroicons/react/24/solid";
import { useForm } from "react-hook-form";
import { IconButton } from "../../basics/Buttons/IconButton";
import {
  BasicButton,
  BasicButtonStyles,
} from "../../basics/Buttons/BasicButton";
import { useLazyQuery } from "@apollo/client";
import { CALCULATE_FAKE_RESOURCE_MARGIN_SUMMARY } from "../../data/queries/resourceAssignments";
import { FakeResourceMarginSummary } from "../../data/types/ProfitabilityCalculator";
import { GET_FRINGE_COST_SETS } from "../../data/queries/fringeCosts";
import { GET_PORTFOLIO_FINANCIALS } from "../../data/queries/projects";
import Tabs from "../../basics/Tabs/Tabs";
import ProjectScenarioAddEditSlideOver from "./ProjectScenarioAddEditSlideOver";
import { DefaultPagePaddingAndWidth } from "../../basics/DefaultPagePaddingAndWidth/DefaultPagePaddingAndWidth";
import { SecondarySidemenu } from "../../components/SecondarySidemenu/SecondarySidemenu";
import { Dropdown } from "../../basics/Dropdown/Dropdown";
import { createSvgBackgroundStyles } from "../../utilities/svgBackgrounds";
import TextInput from "../../basics/TextInput/TextInput";
import {
  useLazyListQuerySM,
  useLazyQuerySM,
} from "../../data/machines/queries";
import { LoadingWrapper } from "../../basics/LoadingWrapper/LoadingWrapper";

export function Scenarios() {
  const [inEditIndex, setInEditIndex] = useState<any>(null);
  const [addEditMode, setAddEditMode] = useState<any>(null);
  const [stateFinancials, dataFinancials, sendFinancials] = useLazyQuerySM(
    GET_PORTFOLIO_FINANCIALS
  );
  const [stateSets, dataSets, sendSets] =
    useLazyListQuerySM(GET_FRINGE_COST_SETS);
  const [resources, setResources]: any = useState([]);
  const [getData, data] = useLazyQuery(CALCULATE_FAKE_RESOURCE_MARGIN_SUMMARY);
  const summary: FakeResourceMarginSummary = data?.data?.data;
  const form = useForm<any>({
    defaultValues: {
      name: "Default proj",
    },
  });
  const [numberOfDays, setNumberOfDays] = useState("30");
  const [isProjectAddEditOpen, setIsProjectAddEditOpen] = useState(false);
  const { errors, setValue, handleSubmit, watch }: any = form;
  const [selectedScenario, setSelectedScenario] = useState<any>("scenario1");

  useEffect(() => {
    sendSets({
      type: "MAKE_QUERY",
      variables: {},
    });
    sendFinancials({
      type: "MAKE_QUERY",
      variables: {
        numberOfDays: Number(numberOfDays),
      },
    });
  }, [numberOfDays]);

  const queryResults = () => {
    getData({
      variables: {
        numberOfMonths: 1,
        resources: resources,
      },
    });
  };

  const getResultForResource = (r: any) => {
    return summary?.resources?.find(
      (r2) => r2.fakeResourceIdentifier === r.identifier
    );
  };

  const renderResultsHeader = () => {
    return (
      <div>
        <div className="flex flex-row items-center pb-4 border-b w-full">
          <TextInput
            register={form.register}
            error={errors["name"]}
            validation={{ required: true }}
            name="name"
            placeholder="Project Name"
            label=""
            labelClass="bg-none"
            inputClass="border-none bg-transparent font-semibold text-3xl px-0"
            wrapperClass="bg-none border-b-4 border-gray-300 px-0"
          />
          <div className="flex flex-1"></div>
          <div className="justify-self-right justify-self-end ml-3">
            <BasicButton classNames="mr-3" text={"Add To Scenario"} />
            <BasicButton
              styler={BasicButtonStyles.DROPDOWN}
              text={"Cancel"}
              color={"red"}
              onClick={() => setAddEditMode(false)}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderCalculatorResources = () => {
    return (
      <div
        className="mt-9 w-full flex flex-col mx-auto"
        style={{ maxWidth: "800px" }}
      >
        <form
          className="flex flex-1 flex-col"
          onSubmit={handleSubmit((data: any) => {
            console.log(data);
          })}
        >
          {renderResultsHeader()}
          <div className="mt-4">
            {resources && resources.length ? (
              resources.map((r: any, index: number) => {
                return (
                  <div className="mb-5 text-sm flex flex-row pb-5 border bg-white p-3 rounded-md">
                    <div>
                      <div>
                        {r.name} - ${r.costRate} {r.costRateTimeUnit}
                      </div>
                      <div>
                        {r.fringeCostSetId && dataSets?.edges
                          ? dataSets?.edges
                              ?.map((e: any) => e.node)
                              .filter(
                                (e: any) => e.id === r.fringeCostSetId
                              )?.[0].name
                          : "No Fringe & Tax Costs Set"}
                      </div>
                    </div>
                    <div className="flex-1 ml-5 pl-5 border-l">
                      {r.utilizations ? (
                        r.utilizations.map((u: any) => {
                          return (
                            <div className="py-2 border-b">
                              {u.utilizationAmount} {u.utilizationAmountUnits}{" "}
                              per {u.utilizationAmountPerUnits} @ ${u.rate} per{" "}
                              {u.rateTimeUnit}
                            </div>
                          );
                        })
                      ) : (
                        <></>
                      )}
                      <IconButton
                        styler={BasicButtonStyles.CLEAR}
                        text="Add Utilization"
                        classNames="mt-2"
                        icon={PlusIcon}
                        onClick={() => {
                          setAddEditMode("utilization");
                          setInEditIndex(index);
                        }}
                      />
                    </div>
                    <div className="flex-1 ml-5 pl-5 border-l">
                      Resource Margin: $
                      {getResultForResource(r)?.grossMarginAmount}
                      {" (" +
                        getResultForResource(r)?.grossMarginPercentage +
                        "%)"}
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="mb-5 text-xl">No Resources in calculation</div>
            )}
            <IconButton
              onClick={() => setIsProjectAddEditOpen(true)}
              text="Add Resource"
              icon={PlusIcon}
              classNames="mr-3"
            />
            <IconButton
              color="green"
              icon={CurrencyDollarIcon}
              text="Add Fixed Revenue"
            />
          </div>
          {renderResultsPortion()}
        </form>
      </div>
    );
  };

  const renderResultsPortion = () => {
    return (
      <div className="mt-6 pt-6 border-t ">
        <div className="flex flex-row">
          <div>Total Revenue (30 day)</div>
          <div className="flex flex-1"></div>
          <div>${summary?.totalRevenueAmount}</div>
        </div>
        <div className="flex flex-row">
          <div>Total Cost (30 day)</div>
          <div className="flex flex-1"></div>
          <div>${summary?.totalCostAmount}</div>
        </div>
        <div className="flex flex-row text-xl font-bold">
          <div>Total Margin (30 day)</div>
          <div className="flex flex-1"></div>
          <div>
            ${summary?.totalGrossMarginAmount}
            {" (" + summary?.averageGrossMarginPercentage + "%)"}
          </div>
        </div>
      </div>
    );
  };

  const renderProjectBlocks = () => {
    return dataFinancials?.future?.projects?.map((p: any) => {
      return (
        <>
          <div className="bg-white shadow rounded-lg flex flex-col">
            <div className="text-sm p-3 flex flex-row align-center items-center">
              <div className="font-bold">{p.project?.name}</div>
              <div className="flex flex-1"></div>
              <div className="mr-1">(Edited) </div>
              <div className="text-gray-400">
                <PencilIcon width={17} />
              </div>
            </div>
            <div className="grid grid-cols-3">
              {p.billableResources?.map((r: any) => {
                return (
                  <div
                    className={`text-xs p-1 pl-3 pr-2 ${
                      r.calculated.finalMarginPercentage > 25
                        ? "bg-green-100"
                        : r.calculated.finalMarginPercentage >= 15
                        ? "bg-yellow-100"
                        : "bg-red-100"
                    }`}
                  >
                    <div className="font-semibold">
                      {r.employee
                        ? r?.employee?.firstName +
                          " " +
                          r?.employee?.lastName.slice(0, 1) +
                          "."
                        : r?.providerPersonnel?.firstName +
                          " " +
                          r?.providerPersonnel?.lastName?.slice(0, 1) +
                          "."}
                    </div>
                    <div>
                      ${r.calculated.finalMargin}/
                      {r.calculated.finalMarginTimeUnit === "HOURLY"
                        ? "hr"
                        : ""}{" "}
                      ({r.calculated.finalMarginPercentage}%)
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="flex flex-1"></div>
            <div className="border-t border-gray-100 flex flex-row ">
              <div className="py-2 px-3 text-sm border-r flex flex-col flex-1">
                <div>Revenue: </div>
                <div>${p.actualBillableRevenue.toLocaleString()}</div>
              </div>
              <div className="py-2 px-3 text-sm border-r flex flex-col flex-1">
                <div>Gross Profit:</div>
                <div> ${p.grossProfit.toLocaleString()}</div>
              </div>
              <div className="py-2 px-3 text-sm border-r flex flex-col flex-1">
                <div>Cost:</div>
                <div> ${p.cost.toLocaleString()}</div>
              </div>
            </div>
          </div>
        </>
      );
    });
  };

  const renderProfitabilityTab = () => {
    return (
      <div className="flex-1 px-10">
        <div className="pb-2 mb-3 mt-7 border-b">
          <div className="text-2xl font-semibold text-gray-800">Projects</div>
          <div style={{ maxWidth: "700px" }} className="text-sm">
            Hover to show difference between actual and projected margin for
            employee, as well as total profit. Add button to color code based on
            either percentage or absolute amount.
          </div>
        </div>
        <LoadingWrapper state={stateFinancials.value}>
          <div className="grid grid-cols-3 gap-4 place-content-end">
            {renderProjectBlocks()}
            <div
              onClick={() => setAddEditMode(true)}
              className="bg-none cursor-pointer hover:bg-white hover:shadow-lg hover:text-gray-900 border p-5 border-dashed text-align-center justify-center flex flex-row align-center items-center rounded-lg text-gray-400 uppercase font-bold"
            >
              <PlusIcon width="20" /> Add a scenario project
            </div>
          </div>
        </LoadingWrapper>
      </div>
    );
  };

  return (
    <>
      <div
        style={{ maxWidth: "100vw" }}
        className="flex flex-row w-full h-full"
      >
        <SecondarySidemenu
          classNames="bg-white"
          style={{
            ...createSvgBackgroundStyles(),
            minWidth: "430px",
            // inset box shadow
            boxShadow: "0px 0px 10px 1px rgba(0, 0, 0, 0.04)",
          }}
        >
          <div className="pb-2 flex flex-col">
            <div className="flex flex-col pt-8 pb-4 px-4 bg-none">
              <div className="text-3xl text-blue-600 font-semibold ">
                Scenario Designer
              </div>
              <div
                style={{ maxWidth: "750px" }}
                className="text-sm text-gray-700 mt-2"
              >
                Total values based on current scenario. Any changes made on this
                page don't affect your actual project settings.
              </div>
              <div className="border-2 mt-4 bg-gray-50 shadow-sm text-black rounded-md pb-2 pt-1 pl-3 pr-1">
                <Dropdown
                  onChange={(s: any) => {
                    console.log(s);
                    setSelectedScenario(s.value);
                  }}
                  selected={selectedScenario}
                  options={[
                    {
                      label: "Scenario 1",
                      value: "scenario1",
                    },
                  ]}
                  placeholder={"Select a scenario"}
                />
              </div>
              <div className="flex-row flex items-end align-end justify-end pr-1 pt-1">
                <IconButton
                  icon={PlusIcon}
                  styler={BasicButtonStyles.ZERO}
                  text={"Create New"}
                />
              </div>
            </div>
            <div className="font-semibold text-gray-800 pb-6 pt-4 px-4">
              <div className="text-md mb-3">Projection Length</div>
              <Tabs
                mode="EVENT"
                style={{ width: "100%" }}
                activeTab={numberOfDays}
                setTab={setNumberOfDays}
                tabs={[
                  {
                    name: "30 Days",
                    identifier: "30",
                  },
                  {
                    name: "90 Days",
                    identifier: "90",
                  },
                  {
                    name: "180 Days",
                    identifier: "180",
                  },
                  {
                    name: "365 Days",
                    identifier: "365",
                  },
                ]}
              />
            </div>
          </div>
          <div className="px-4 mb-4 flex flex-col">
            <div className="flex bg-white mb-3 rounded-md border shadow-sm flex-col p-6 py-4 flex-1 ">
              <div className="text-md uppercase font-bold text-gray-600">
                Total Revenue ({numberOfDays} day)
              </div>
              <div className="text-2xl">
                $
                {dataFinancials?.future?.total?.actualBillableRevenue.toLocaleString()}
              </div>
            </div>
            <div className="flex bg-white rounded-md mb-3 border shadow-sm  flex-col p-6 py-4 flex-1  ">
              <div className="text-md uppercase font-bold text-gray-600">
                Total Cost ({numberOfDays} day)
              </div>
              <div className="text-2xl text-red-500 font-semibold">
                ${dataFinancials?.future?.total?.cost.toLocaleString()}
              </div>
            </div>
            <div className="flex bg-white rounded-md border shadow-sm flex-col p-6 py-4 flex-1  ">
              <div className="text-md uppercase font-bold text-gray-600">
                Total Gross Profit ({numberOfDays} day)
              </div>
              <div className="text-2xl text-green-500 font-semibold">
                ${dataFinancials?.future?.total?.grossProfit.toLocaleString()}
              </div>
            </div>
          </div>
        </SecondarySidemenu>
        <DefaultPagePaddingAndWidth>
          {addEditMode ? renderCalculatorResources() : renderProfitabilityTab()}
        </DefaultPagePaddingAndWidth>
      </div>
      <ProjectScenarioAddEditSlideOver
        open={isProjectAddEditOpen}
        setOpen={setIsProjectAddEditOpen}
      />
    </>
  );
}
