import { PencilIcon } from "@heroicons/react/24/outline";
import { PlusIcon } from "@heroicons/react/24/solid";
import { BasicButtonStyles } from "../../basics/Buttons/BasicButton";
import { IconButton } from "../../basics/Buttons/IconButton";
import { SectionHeader } from "../../basics/SectionHeader/SectionHeader";
import {
  EXTERNAL_LINK_CATEGORIES_OPTIONS,
  ExternalResource,
} from "../../data/types/ExternalResource";
import { cleanUrl } from "../../utilities/links";

interface ExternalLinksListProps {
  items: ExternalLinksListItem[];
  onEdit: any;
}

interface ExternalLinksListItem {
  logoUrl: string;
  externalLink: ExternalResource;
}

function ExternalLinksList({ items, onEdit }: ExternalLinksListProps) {
  const renderItemBlock = (item: ExternalLinksListItem) => {
    return (
      <div className="w-70 p-2 cursor-pointer bg-white rounded-md border-gray-200 hover:shadow border">
        <a
          className="block w-full group"
          target="__blank"
          href={cleanUrl(item.externalLink.url)}
        >
          <div className="flex flex-row w-full items-center relative">
            <div className="pl-3 pr-2 py-2">
              <img
                className="w-5 h-auto"
                src={`${process.env.PUBLIC_URL}/external_logos/${item.externalLink.logoName}.png`}
              />
            </div>
            <div className="flex-1"></div>
            <div className="group-hover:opacity-0 justify-self-end px-3 py-2 text-sm uppercase font-bold text-gray-500">
              {
                EXTERNAL_LINK_CATEGORIES_OPTIONS.find(
                  (c) => c.value === item.externalLink.linkType
                )?.label
              }
            </div>
            <div
              style={{ right: "8px", top: "4px" }}
              onClick={($ev) => {
                onEdit(item.externalLink);
                $ev.stopPropagation();
                $ev.preventDefault();
              }}
              className="invisible group-hover:visible absolute"
            >
              <IconButton
                icon={PencilIcon}
                iconClassNames={"h-2 mr-1"}
                classNames={"text-sm"}
                color={"yellow"}
                text={"Edit"}
                styler={BasicButtonStyles.ZERO}
              />
            </div>
          </div>
          <div className="px-3 py-2 text-md font-medium text-black">
            {item.externalLink?.name}
          </div>
        </a>
      </div>
    );
  };

  return (
    <div style={{ minHeight: "300px" }} className="mt-7">
      <SectionHeader
        title="External Resources"
        borderEnabled={true}
        button={
          <IconButton
            onClick={() => onEdit()}
            icon={PlusIcon}
            styler={BasicButtonStyles.CLEAR}
            text="Add Project Resource"
          />
        }
      />
      <div className="grid gap-4 grid-flow-col auto-cols-max">
        {items.map((a) => renderItemBlock(a))}
      </div>
    </div>
  );
}

export default ExternalLinksList;
