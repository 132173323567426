import { TableColumn } from "../../basics/Table/Table";
import { FormConfiguration } from "./Form";
import { DetailViewConfiguration } from "../../components/BaseDetailView/BaseDetailView";
import { BaseApiEntity } from "./base/BaseEntity";
import { formatBaseItemForList } from "../../utilities/dates";
import { format } from "date-fns";

export interface CommissionPlan extends BaseApiEntity {
  status: string;
  versions: CommissionPlanVersion[];
}

export interface CommissionPlanVersion extends BaseApiEntity {
  identifier: number;
  strategies: CommissionPlanStrategy[];
}

export interface CommissionPlanStrategy extends BaseApiEntity {
  billingTypeTarget: string;
}

export interface CommissionEntry extends BaseApiEntity {}

export const COMMISSION_PLAN_DETAIL_CONFIGURATION: DetailViewConfiguration = {
  sections: [
    {
      title: "Basic Information",
      subtitle: "Some basic info about your Provider",
      fields: [
        {
          key: "title",
          label: "Title",
        },
      ],
    },
  ],
};

export const formatCommissionEntryForList = (item: any) => {
  let returner = {
    ...item,
    paidDate: item.paidDate
      ? format(new Date(item.paidDate), "MM/dd/yyyy")
      : "-",
    name: item.commissionAssignment?.employee
      ? item.commissionAssignment?.employee?.firstName +
        " " +
        item.commissionAssignment?.employee?.lastName
      : item.commissionAssignment?.providerPersonnel?.firstName +
        " " +
        item.commissionAssignment?.providerPersonnel?.lastName,
  };

  return returner;
};

export const formatCommissionAssignmentForList = (item: any) => {
  let totalValue = 0;
  let currency;
  let lastPaidOutDate: any;
  // TODO maybe we shouldnt assume all currency will be the same?
  // could also move this to the backend
  item.entries?.forEach((i: any) => {
    totalValue += i.value;
    currency = i.currency;
    if (!lastPaidOutDate) {
      lastPaidOutDate = new Date(i.paidDate);
    } else {
      lastPaidOutDate = new Date(
        Math.max(lastPaidOutDate.getTime(), new Date(i.paidDate).getTime())
      );
    }
  });
  let returner = {
    ...formatBaseItemForList(item),
    name:
      item.type +
      " - " +
      (item?.employee
        ? item?.employee?.firstName + " " + item?.employee?.lastName
        : item?.providerPersonnel?.firstName +
          " " +
          item?.providerPersonnel?.lastName),
    targetName: item?.employeeTarget
      ? item?.employeeTarget?.firstName + " " + item?.employeeTarget?.lastName
      : item?.providerPersonnelTarget?.firstName +
        " " +
        item?.providerPersonnelTarget?.lastName,
    totalValue,
    currency,
    numberOfEntries: item.entries?.length,
    commissionPlanName: item.planVersion?.commissionPlan?.name,
    lastPaidOutDate: lastPaidOutDate,
  };

  return returner;
};

export const COMMISSION_ASSIGNMENT_TABLE_CONFIGURATION: TableColumn[] = [
  {
    key: "name",
    label: "Assignee",
    displayType: "link",
    linkToKey: (row: any) => row.id,
    linkToPrefix: (row: any) => "/commission/",
    styles: "text-gray-600 font-medium",
  },
  {
    key: "targetName",
    label: "Commission Source",
    type: "render",
    typeConfiguration: {
      render: (r: any) => {
        return r.target + " - " + r.targetName;
      },
    },
  },
  {
    key: "totalValue",
    label: "Total Amount across all entries",
    type: "money",
    typeConfiguration: {
      rateUnitKey: "currency",
    },
  },
  {
    key: "lastPaidOutDate",
    label: "Last Paid Out Date",
  },
  {
    key: "numberOfEntries",
    label: "Number of entries",
  },
  {
    key: "numberOfEntries",
    label: "Missing Payout",
    type: "render",
    typeConfiguration: {
      render: (r: any) => {
        return (
          <div className="bg-red-100 text-red-600 px-3 py-1 rounded-md shadow">
            {r.numberOfEntries} entries
          </div>
        );
      },
    },
  },
  {
    key: "commissionPlanName",
    label: "Commission Plan",
  },
];

export const COMMISSION_ENTRY_TABLE_CONFIGURATION: TableColumn[] = [
  {
    key: "status",
    label: "Status",
  },
  {
    key: "paidDate",
    label: "Paid Date",
  },
  {
    key: "value",
    label: "Amount",
    type: "money",
    typeConfiguration: {
      rateUnitKey: "currency",
    },
  },
];

export const COMMISSION_ASSIGNMENT_DETAIL_CONFIGURATION: DetailViewConfiguration =
  {
    sections: [
      {
        title: "Basic Information",
        subtitle: "Some basic info about this customer",
        fields: [
          {
            key: "name",
            label: "Name",
          },
        ],
      },
    ],
  };

export const COMMISSION_PLAN_FORM_CONFIGURATION: FormConfiguration = {
  requiredOptionsLoads: ["customers"],
  fields: [
    {
      name: "name",
      type: "textInput",
      validation: { required: true },
      props: {
        label: "Provider Name",
        width: 3,
      },
    },
    {
      name: "customerId",
      type: "select",
      optionsPlaceholder: "customers",
      isNotEditable: true,
      validation: { required: true },
      props: {
        label: "Customer",
        width: 3,
      },
    },
  ],
};
