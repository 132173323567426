import { ArrowLeftOnRectangleIcon } from "@heroicons/react/24/solid";
import * as React from "react";
import { BasicButton } from "../../basics/Buttons/BasicButton";
import { formFields } from "../../data/forms/fields";
import { BaseFormProps, FormFieldConfiguration } from "../../data/types/Form";
import { multiIndexOf } from "../../utilities/arrays";
import { createSvgBackgroundStyles } from "../../utilities/svgBackgrounds";

interface MinimalFormProps extends BaseFormProps {
  submitText: string;
  hideSubmitButton?: boolean;
  additionalButtons?: any;
  formWrapperClassAdditions?: string;
  formGridClassAdditions?: string;
}

function MinimalForm({
  fields,
  submitText,
  form,
  optionsMap,
  onSubmit,
  hideSubmitButton,
  formGridClassAdditions,
  formWrapperClassAdditions,
  additionalButtons,
  form: {
    register,
    watch,
    setValue,
    handleSubmit,
    errors,
    control,
    hide,
    getValues,
  },
}: MinimalFormProps) {
  // filter out fields with a conditional
  let registeredFields: FormFieldConfiguration[] = [];

  // Handle Conditional Watches on fields
  fields.forEach((f, index) => {
    if (!f.toShow) {
      registeredFields.push(f);
    } else if (f.toShow?.boolean || f.toShow.watch) {
      let toShow = false;
      if (f.toShow.boolean) {
        toShow = true;
      }
      if (f.toShow.watch) {
        let watcher = watch(f.toShow.watch.toWatch);
        if (watcher === f.toShow.watch.toShowValue) {
          toShow = true;
        }
        if (f.toShow.watch.toShowValueSpecial === "EXISTS" && watcher != null) {
          toShow = true;
        }
      }
      if (toShow) {
        registeredFields.push(f);
      }
    }
  });

  // Split Registered Fields into multiple segments split by a divider
  const dividers = multiIndexOf(registeredFields, "divider", "type");

  let formSegments: any[] = [];
  let mostRecentEnd: any = dividers && dividers.length ? dividers[0] + 1 : 0;

  dividers.forEach((d, index) => {
    const fieldsToInclude = registeredFields.slice(
      mostRecentEnd,
      dividers[index + 1] ? dividers[index + 1] : undefined
    );
    formSegments.push({
      divider: registeredFields[d],
      fields: fieldsToInclude,
    });
    mostRecentEnd = dividers[index + 1] ? dividers[index + 1] + 1 : undefined;
  });

  const renderSubmitButton = () => {
    return (
      <div className="px-4 py-3 sm:px-6 border-t align-middle items-center flex flex-row">
        {additionalButtons ? additionalButtons : <></>}
        <BasicButton text={submitText} color={"green"} />
        <div className="ml-3 text-sm text-gray-400 flex flex-row align-middle items-center">
          <ArrowLeftOnRectangleIcon height={14} className="mr-1" /> Press enter
          to submit
        </div>
      </div>
    );
  };

  const formWrapperClass =
    "bg-white flex-1 " +
    (formWrapperClassAdditions ? formWrapperClassAdditions : "");
  const formGridClass =
    "grid grid-cols-6 py-6 px-4 sm:p-6 gap-6 " +
    (formGridClassAdditions ? formGridClassAdditions : "");

  const injectStyling = (field: FormFieldConfiguration) => {
    if (field.type === "textInput") {
      field.props = {
        ...field.props,
        wrapperClass: `col-span-6 sm:col-span-${field?.props?.width || 12}`,
        inputWrapperClass: "",
        labelClass: "block text-sm font-medium text-gray-700",
        errorClass:
          "mt-1 block w-full border border-red-300 text-red-900 placeholder-red-300 py-2 px-3  focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md",
        inputClass:
          "mt-1 block w-full border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm",
      };
    } else if (field.type === "textArea") {
      field.props = {
        ...field.props,
        wrapperClass: `col-span-6 sm:col-span-${field?.props?.width || 12}`,
        inputWrapperClass: "",
        labelClass: "block text-sm font-medium text-gray-700",
        errorClass:
          "mt-1 block w-full border border-red-300 text-red-900 placeholder-red-300 py-2 px-3  focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md",
        inputClass:
          "mt-1 block w-full border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm",
      };
    } else if (field.type === "select") {
      field.props = {
        ...field.props,
        wrapperClass: `col-span-6 sm:col-span-${field?.props?.width || 12}`,
        labelClass: "block text-sm font-medium text-gray-700",
        errorClass:
          "mt-1 block w-full border border-red-300 text-red-900 placeholder-red-300 py-2 px-3  focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md",
        inputClass:
          "relative block w-full bg-white border border-gray-300 rounded-md pl-3 pr-10 py-2 px-3 text-left cursor-pointer focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm",
      };
    } else if (field.type === "date") {
      field.props = {
        ...field.props,
        wrapperClass: `col-span-6 sm:col-span-${field?.props?.width || 12}`,
        labelClass: "block text-sm font-medium text-gray-700",
        errorClass:
          "mt-1 block w-full border border-red-300 text-red-900 placeholder-red-300 py-2 px-3  focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md",
        inputClass:
          "relative block w-full bg-white border border-gray-300 rounded-md pl-3 pr-10 py-2 px-3 text-left cursor-pointer focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm",
      };
    } else if (field.type === "chips") {
      field.props = {
        ...field.props,
        wrapperClass: `col-span-6 sm:col-span-${field?.props?.width || 12}`,
        labelClass: "block text-sm font-medium text-gray-700",
        errorClass:
          "mt-1 block w-full border border-red-300 text-red-900 placeholder-red-300 py-2 px-3  focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md",
        inputClass:
          "relative block w-full bg-white border border-gray-300 rounded-md pl-3 pr-10 py-2 px-3 text-left cursor-pointer focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm",
      };
    }
    return field;
  };

  const renderFieldsInGrid = (fields: FormFieldConfiguration[]) => {
    return (
      <div className={formGridClass}>
        {fields.map((field: FormFieldConfiguration) => {
          field = injectStyling(field);
          const FieldInput: any = formFields[field.type];
          let allValues = getValues();
          let preFilteredOptions = field.options
            ? field.options
            : field.optionsPlaceholder && optionsMap
            ? optionsMap[field.optionsPlaceholder] || []
            : [];
          let options = field.optionsFilter
            ? preFilteredOptions.filter(field.optionsFilter(allValues))
            : preFilteredOptions;
          return (
            <FieldInput
              {...field.props}
              allValues={allValues}
              options={options}
              name={field.name}
              validation={field.validation}
              placeholder={field.placeholder}
              error={errors[field.name]}
              setValue={setValue}
              register={register}
              control={control}
            />
          );
        })}
      </div>
    );
  };

  return (
    <form className="flex flex-1 flex-col" onSubmit={handleSubmit(onSubmit)}>
      <div className={formWrapperClass}>
        {formSegments && formSegments.length
          ? formSegments.map((s) => {
              return (
                <>
                  <div
                    style={createSvgBackgroundStyles()}
                    className="px-6 py-3 border-b border-t text-sm text-gray-500 font-bold"
                  >
                    {s.divider?.name}
                  </div>
                  {renderFieldsInGrid(s.fields)}
                </>
              );
            })
          : renderFieldsInGrid(registeredFields)}
      </div>
      {!hideSubmitButton && renderSubmitButton()}
    </form>
  );
}

export default MinimalForm;
