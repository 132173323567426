import { gql } from "@apollo/client";

const GET_EMPLOYEES = gql`
  query listEmployees(
    $skip: Int
    $after: String
    $first: Int
    $query: String
    $orderBy: EmployeeOrder
  ) {
    data: listEmployees(
      skip: $skip
      after: $after
      first: $first
      query: $query
      orderBy: $orderBy
    ) {
      edges {
        cursor
        node {
          id
          firstName
          lastName
          status
          baseRate
          rateTimeUnit
          rateCurrency
          baseCostRate
          costRateTimeUnit
          costRateCurrency
          createdAt
          updatedAt
          user {
            id
          }
          inTriageFromIntegration
          externalData {
            externalRelationships {
              sourceIntegration {
                id
                target
              }
            }
          }
        }
      }
      totalCount
      pageInfo {
        hasNextPage
      }
    }
  }
`;

const GET_EMPLOYEE = gql`
  query getEmployee($id: String!) {
    data: getEmployee(id: $id) {
      id
      firstName
      lastName
      baseRate
      status
      rateTimeUnit
      rateCurrency
      baseCostRate
      costRateTimeUnit
      costRateCurrency
      createdAt
      updatedAt
      fringeCostSetId
      externalData {
        externalRelationships {
          sourceIntegration {
            id
            target
          }
        }
      }
      fringeCostSet {
        id
        name
      }
      user {
        id
        firstName
        lastName
      }
    }
  }
`;

const UPDATE_EMPLOYEE = gql`
  mutation editEmployee(
    $id: String!
    $updatedEmployee: EmployeeUpdateRequest!
  ) {
    updateEmployee(id: $id, updatedEmployee: $updatedEmployee) {
      id
    }
  }
`;

const CREATE_EMPLOYEE = gql`
  mutation editEmployee($newEmployee: EmployeeUpdateRequest!) {
    createEmployee(newEmployee: $newEmployee) {
      id
    }
  }
`;

const DELETE_EMPLOYEES = gql`
  mutation deleteEmployees($ids: [String!]!) {
    deleteEmployees(ids: $ids) {
      count
    }
  }
`;

export {
  GET_EMPLOYEES,
  GET_EMPLOYEE,
  UPDATE_EMPLOYEE,
  CREATE_EMPLOYEE,
  DELETE_EMPLOYEES,
};
