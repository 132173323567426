import { ReactNode } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { Props } from "../../data/types/Props";
import GraphCard from "../GraphCard/GraphCard";

interface AreaGraphCardProps extends Props {
  data: any[];
  CustomTooltip?: React.ElementType;
  areaDataKey: string;
  xAxisDataKey: string;
  yAxisUnit?: string;
  title: string;
  tooltip?: string;
}

export function AreaGraphCard({
  data,
  CustomTooltip,
  tooltip,
  title,
  xAxisDataKey,
  yAxisUnit,
  areaDataKey,
  classNames,
}: AreaGraphCardProps) {
  return (
    <GraphCard classNames={classNames} tooltip={tooltip} title={title}>
      <div className="py-2 px-4 w-full h-full">
        <ResponsiveContainer>
          <AreaChart
            data={data}
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 0,
            }}
          >
            <XAxis
              tick={{ fontSize: 14, fill: "gray" }}
              dataKey={xAxisDataKey}
            />
            <YAxis tick={{ fontSize: 14, fill: "gray" }} unit={yAxisUnit} />
            {CustomTooltip ? (
              <Tooltip content={<CustomTooltip />} />
            ) : (
              <Tooltip />
            )}
            <Area
              type="monotone"
              dataKey={areaDataKey}
              stroke="#60A5FA"
              fill="#60A5FA"
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </GraphCard>
  );
}
