import { useState } from "react";
import { Transition } from "@headlessui/react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { ReactNode } from "react";
import TextType from "../TextType/TextType";
import { ReactElement } from "react";
import { ScissorsIcon } from "@heroicons/react/24/outline";
import { Checkbox } from "../Checkbox/Checkbox";

interface TableProps {
  data?: any[];
  columns: TableColumn[];
  actions?: TableAction[];
  page?: number;
  pages?: number;
  perPage?: number;
  start: number;
  end: number;
  onChangePage: any;
  onSelectItem?: any;
  triageEnabled?: boolean; // Allows for triage workflows
  onClickTriage?: any;
  paginationComponent?: any;
  selectionMode?: boolean;
  onSelectItems?: any;
  selectedItems?: any[];
}

export interface TableColumn {
  label: string;
  key: string;
  type?: string;
  linkToKey?: string | Function;
  linkToPrefix?: string | Function;
  typeConfiguration?: any;
  displayType?: string;
  styles?: string;
  rowStyleKey?: string;
  secondaryContentRenderer?: any;
}

export interface TableAction {
  label: string;
  color: string;
  icon?: any;
  onClick: any;
}

const renderTooltip = (text: string) => {
  return (
    <div className="absolute bg-white shadow-md rounded-md border border-gray-100 z-10 px-4 py-2 hover-target">
      <div className="text-sm font-regular w-56">{text}</div>
    </div>
  );
};

export const selectIntegrationImageFromExternalRelationship = (rel: any) => {
  if (rel?.sourceIntegration?.target === "UPWORK") {
    return (
      <div className="bg-white shadow-md hover-trigger rounded-full h-5 w-5 -ml-7 mr-2">
        {renderTooltip("Synced from Upwork")}
        <img
          className="h-auto w-4 ml-0.5 mt-1.5"
          src={"/external_logos/upwork.png"}
        />
      </div>
    );
  } else if (rel?.sourceIntegration?.target === "QUICKBOOKS") {
    return (
      <div className="hover-trigger display-inline h-5 w-5 -mr-5">
        {renderTooltip("Synced from Quickbooks")}
        <img
          className="h-auto h-5 w-5 -ml-7 mr-2 shadow-md rounded-full"
          src={"/external_logos/quickbooks.png"}
        />
      </div>
    );
  } else if (rel?.sourceIntegration?.target === "SALESFORCE") {
    return (
      <div className="hover-trigger display-inline h-5 w-5 -mr-5">
        {renderTooltip("Synced from Salesforce")}
        <img
          className="h-auto h-5 w-5 -ml-7 mr-2 shadow-md rounded-full"
          src={"/external_logos/salesforce.jpeg"}
        />
      </div>
    );
  } else if (rel?.sourceIntegration?.target === "MONDAY") {
    return (
      <div className="hover-trigger display-inline h-5 w-5 -mr-5">
        {renderTooltip("Synced from Monday.com")}
        <img
          className="h-auto h-5 w-5 -ml-7 mr-2 bg-white shadow-md rounded-full"
          src={"/external_logos/monday.png"}
        />
      </div>
    );
  } else if (rel?.sourceIntegration?.target === "TSHEETS") {
    return (
      <div className="hover-trigger display-inline h-5 w-7 -mr-5">
        {renderTooltip("Synced from TSheets")}
        <img
          className="h-auto h-7 w-7 -ml-7 mr-2 shadow-md rounded-full"
          src={"/external_logos/tsheets.png"}
        />
      </div>
    );
  } else if (rel?.sourceIntegration?.target === "GUSTO") {
    return (
      <div className="hover-trigger display-inline h-5 w-7 -mr-5">
        {renderTooltip("Synced from Gusto")}
        <img
          className="h-auto h-7 w-7 -ml-7 mr-2 shadow-md rounded-full"
          src={"/external_logos/gusto.png"}
        />
      </div>
    );
  }
};

function Table({
  data,
  columns,
  actions,
  page,
  pages,
  perPage,
  start,
  end,
  onChangePage,
  onSelectItem,
  triageEnabled,
  onClickTriage,
  paginationComponent,
  selectionMode,
  onSelectItems,
  selectedItems,
}: TableProps) {
  let anyTriage = false;
  data?.forEach((d) => {
    if (d.inTriageFromIntegration) {
      anyTriage = true;
    }
  });
  let triageAction: TableAction = {
    label: "Triage",
    color: "yellow",
    icon: ScissorsIcon,
    onClick: (d: any) => onClickTriage(d),
  };
  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div
            style={{ boxShadow: "0px 1px 2px 1px rgba(0,0,0,.03)" }}
            className="overflow-hidden border border-gray-100 sm:rounded-lg"
          >
            <table className="min-w-full divide-y divide-gray-100">
              <thead className="bg-white">
                <tr>
                  {selectionMode && (
                    <th scope="col" className="pl-4 bg-gray-50">
                      <Checkbox
                        onChange={(selected: boolean) => {
                          if (selected) {
                            onSelectItems(data);
                          } else {
                            onSelectItems([]);
                          }
                        }}
                        checked={selectedItems?.length === data?.length}
                      />
                    </th>
                  )}
                  {columns?.map((col, index) => {
                    return (
                      <th
                        scope="col"
                        className={`${
                          index === 0 ? "pl-8 pr-4" : "px-4"
                        } py-2 text-left text-xs font-bold text-gray-500 bg-gray-50 font-medium uppercase tracking-wider`}
                      >
                        {col.label}
                      </th>
                    );
                  })}
                  {actions?.map((act) => {
                    return (
                      <th scope="col" className="relative px-4 py-2 bg-gray-50">
                        <span className="sr-only">{act.label}</span>
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {data?.map((d, index) => {
                  let rowClass;
                  if (triageEnabled && d.inTriageFromIntegration) {
                    rowClass = "hover:bg-blue-100 bg-blue-50";
                  } else {
                    rowClass = "hover:bg-gray-100 bg-white";
                  }
                  return (
                    <>
                      <tr
                        className={
                          `relative z-1 ${
                            index === 0 ? "" : "border-t"
                          } border-gray-100 ` + rowClass
                        }
                      >
                        {selectionMode && (
                          <td
                            className={`relative px-2 pl-4 whitespace-nowrap text-sm font-regular text-gray-600`}
                          >
                            <Checkbox
                              onChange={(selected: boolean) => {
                                if (selected) {
                                  console.log("selecting");
                                  if (selectedItems && selectedItems.length) {
                                    onSelectItems([...selectedItems, d]);
                                  } else {
                                    onSelectItems([d]);
                                  }
                                } else {
                                  console.log("deselecting");
                                  onSelectItems(
                                    selectedItems?.filter((s) => s.id !== d.id)
                                  );
                                }
                              }}
                              checked={selectedItems?.find(
                                (i) => i.id === d.id
                              )}
                            />
                          </td>
                        )}
                        {columns?.map((col, index) => {
                          return (
                            <td
                              className={`relative ${
                                index === 0 ? "pl-8 pr-4" : "px-4"
                              } py-2 whitespace-nowrap text-sm font-regular text-gray-600`}
                            >
                              <div className="flex flex-row">
                                {d.externalData &&
                                  d.externalData.externalRelationships &&
                                  index == 0 && (
                                    <>
                                      {d.externalData?.externalRelationships.map(
                                        (e: any) =>
                                          selectIntegrationImageFromExternalRelationship(
                                            e
                                          )
                                      )}
                                    </>
                                  )}
                                <TextType
                                  type={col.type}
                                  typeConfiguration={col.typeConfiguration}
                                  dataObject={d}
                                  fieldKey={col.key}
                                  displayType={col.displayType}
                                  linkToKey={col.linkToKey}
                                  linkToPrefix={col.linkToPrefix}
                                  styles={col.styles}
                                  rowStyleKey={col.rowStyleKey}
                                  secondaryContentRenderer={
                                    col.secondaryContentRenderer
                                  }
                                />
                                {triageEnabled &&
                                  d.inTriageFromIntegration &&
                                  index === 0 && (
                                    <div
                                      onClick={
                                        triageEnabled &&
                                        d.inTriageFromIntegration
                                          ? () => onClickTriage(d)
                                          : () => {}
                                      }
                                      className="ml-2 -mt-0.5 border border-yellow-500 text-yellow-500 rounded-lg bg-none p-1 pl-2 pr-2 text-xs"
                                    >
                                      Triage
                                    </div>
                                  )}
                              </div>
                            </td>
                          );
                        })}
                        {actions?.map((act) => {
                          return (
                            <td className="px-4 py-3 whitespace-nowrap text-right text-sm font-medium">
                              <a
                                onClick={() => act.onClick(d)}
                                className={`text-${act.color}-600 hover:text-${act.color}-900 cursor-pointer flex flex-row align-middle`}
                              >
                                <act.icon className="h-4 mr-1" />
                                {act.label}
                              </a>
                            </td>
                          );
                        })}
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </table>
            {paginationComponent}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Table;
