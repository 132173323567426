import { FormConfiguration } from "./Form";
import { BaseApiEntity } from "./base/BaseEntity";
import { Provider } from "./Provider";
import { ProjectScopeOfWork } from "./ProjectScopeOfWork";
import { Employee } from "./Employee";
import {
  BILLABLE_RESOURCE_SOURCE,
  CURRENCIES,
  RATE_TIMEUNITS,
  RATE_TIMEUNITS_OPTIONS,
  RESOURCE_ASSIGNMENT_STATUSES,
  RESOURCE_ASSIGNMENT_STATUSES_OPTIONS,
  UTILIZATION_AMOUNT_UNITS,
  UTILIZATION_AMOUNT_UNITS_OPTIONS,
  UTILIZATION_AMOUNT_UNITS_OPTIONS_PLURAL,
  YES_NO_BOOLEAN_OPTIONS,
} from "./Shared";
import { formatBaseItemForList } from "../../utilities/dates";
import { TableColumn } from "../../basics/Table/Table";
import { IconButton } from "../../basics/Buttons/IconButton";
import { CalculatorIcon } from "@heroicons/react/24/solid";
import { BasicButtonStyles } from "../../basics/Buttons/BasicButton";
import { BasicPopover } from "../../basics/BasicPopover/BasicPopover";

export interface ResourceAssignment extends BaseApiEntity {
  description?: string;
  utilizationAmount: number;
  utilizationAmountUnits: string;
  utilizationAmountPerUnits: UTILIZATION_AMOUNT_UNITS;
  status: RESOURCE_ASSIGNMENT_STATUSES;
  rate: number;
  rateCurrency: CURRENCIES;
  rateTimeUnit: RATE_TIMEUNITS;
  costRate: number;
  costRateCurrency: CURRENCIES;
  costRateTimeUnit: RATE_TIMEUNITS;
  source: BILLABLE_RESOURCE_SOURCE;
  //scopedRole: ScopedRole;
  scopedRoleId: string;
  projectScopeOfWork: ProjectScopeOfWork;
  projectScopeOfWorkId: string;
  employee?: Employee;
  employeeId?: string;
  providerPersonnelId?: string;
  providerPersonnel?: Provider;
  billable: boolean;
  active: boolean;
  /** CALCULATED VALUES */
  calculated?: ResourceAssignmentCalculatedValues;
}

export interface ResourceAssignmentCalculatedValues {
  finalRate?: number;
  finalRateCurrency?: CURRENCIES;
  finalRateTimeUnit?: RATE_TIMEUNITS;
  finalCostRate?: number;
  finalCostRateCurrency?: CURRENCIES;
  finalCostRateTimeUnit?: RATE_TIMEUNITS;
  finalMargin?: number;
  finalMarginTimeUnit?: RATE_TIMEUNITS;
}

export interface ResourceAssignmentCreateUpdateArgs {
  description?: string;
  utilizationAmount?: number;
  utilizationAmountUnits?: string;
  utilizationAmountPerUnits?: UTILIZATION_AMOUNT_UNITS;
  status?: RESOURCE_ASSIGNMENT_STATUSES;
  rate?: number;
  rateCurrency?: CURRENCIES;
  rateTimeUnit?: RATE_TIMEUNITS;
  source?: BILLABLE_RESOURCE_SOURCE;
  scopedRoleId?: string;
  projectScopeOfWorkId?: string;
  employeeId?: string;
  ProviderId?: string;
  billable?: boolean;
  active?: boolean;
}

export const formatResourceAssignmentForList = (item: ResourceAssignment) => {
  let returner = {
    ...formatBaseItemForList(item),
  };

  return returner;
};

export const RESOURCE_ASSIGNMENT_TABLE_CONFIGURATION: TableColumn[] = [
  {
    key: "name",
    label: "Name",
    displayType: "link",
    linkToKey: (row: ResourceAssignment) =>
      row.source === "EMPLOYEE" ? row.employee?.id : row.providerPersonnel?.id,
    linkToPrefix: (row: ResourceAssignment) =>
      row.source === "EMPLOYEE" ? "/employees/" : "/providers/",
    styles: "text-gray-600 font-medium",
  },
  {
    key: "totalTrackedHours",
    label: "Total Hours",
    typeConfiguration: {
      subObject: "calculated",
    },
  },
  {
    key: "status",
    label: "Status",
  },
  {
    key: "billable",
    label: "Billable?",
    type: "boolean",
  },
  {
    key: "finalRate",
    label: "Project Billable Rate",
    type: "rate",
    typeConfiguration: {
      subObject: "calculated",
      rateUnitKey: "finalRateCurrency",
      rateTimeUnitKey: "finalRateTimeUnit",
    },
  },
  {
    key: "finalCostRate",
    label: "Project Cost Rate",
    displayType: "secondaryContent",
    type: "rate",
    secondaryContentRenderer: (data: ResourceAssignment) => (
      <>
        <BasicPopover
          content={
            <div className="text-sm font-regular w-56">
              This is how the calculation breaks down, including salary and
              fringe costs. Warning: this calculation does not include revenue
              lost as a result of PTO - check the Resource or Profitability page
              to see the impact of PTO on per-resource profitability
            </div>
          }
          button={
            <CalculatorIcon className="text-blue-500 h-5 w-5 dark:text-gray-200" />
          }
        />
      </>
    ),
    typeConfiguration: {
      subObject: "calculated",
      rateUnitKey: "finalCostRateCurrency",
      rateTimeUnitKey: "finalCostRateTimeUnit",
    },
  },
  {
    key: "finalMargin",
    label: "Approx. Margin",
    type: "rate",
    typeConfiguration: {
      subObject: "calculated",
      rateUnitKey: "finalCostRateCurrency",
      rateTimeUnitKey: "finalMarginTimeUnit",
      percentageKey: "finalMarginPercentage",
    },
  },
  {
    key: "utilizationString",
    label: "Utilization",
  },
];

export const BUILD_RESOURCE_ASSIGNMENT_FORM_CONFIGURATION = (
  isEdit: boolean
): FormConfiguration => {
  return {
    requiredOptionsLoads: ["customers"],
    fields: [
      {
        name: "Source Information",
        type: "divider",
      },
      {
        name: "source",
        type: "select",
        placeholder: "Select a Type",
        options: [
          { label: "Employee", value: "EMPLOYEE" },
          { label: "Provider Personnel", value: "PROVIDER" },
        ],
        validation: { required: true },
        toShow: {
          boolean: !isEdit,
        },
        props: {
          label: "Resource Type",
          width: 6,
        },
      },
      {
        name: "providerMainId",
        type: "select",
        optionsPlaceholder: "providers",
        placeholder: "Select a Provider",
        validation: { required: true },
        toShow: {
          watch: {
            toWatch: "source",
            toShowValue: "PROVIDER",
          },
        },
        props: {
          label: "Provider",
          width: 6,
        },
      },
      {
        name: "providerPersonnelId",
        type: "select",
        optionsPlaceholder: "providerPersonnel",
        optionsFilter: (data: any) => {
          return (o: any) => o.providerId === data.providerMainId;
        },
        placeholder: "Select a Provider Personnel",
        validation: { required: true },
        toShow: {
          watch: {
            toWatch: "providerMainId",
            toShowValue: null,
            toShowValueSpecial: "EXISTS",
          },
        },
        props: {
          label: "Provider Personnel",
          width: 6,
        },
      },
      {
        name: "employeeId",
        type: "select",
        optionsPlaceholder: "employees",
        placeholder: "Select an Employee",
        validation: { required: true },
        toShow: {
          watch: {
            toWatch: "source",
            toShowValue: "EMPLOYEE",
          },
        },
        props: {
          label: "Employee",
          width: 6,
        },
      },
      {
        name: "status",
        type: "select",
        options: RESOURCE_ASSIGNMENT_STATUSES_OPTIONS,
        placeholder: "Status",
        validation: { required: true },
        props: {
          label: "Status",
          width: 3,
        },
      },
      {
        name: "billable",
        type: "select",
        options: YES_NO_BOOLEAN_OPTIONS,
        placeholder: "Billable",
        props: {
          label: "Billable",
          width: 3,
        },
      },
      {
        name: "scopedRoleId",
        type: "select",
        optionsPlaceholder: "scopedRoles",
        placeholder: "Project Role",
        validation: { required: true },
        props: {
          label: "Project Role",
          width: 6,
        },
      },
      {
        name: "Utilization",
        type: "divider",
      },
      {
        name: "rate",
        type: "chips",
        options: [
          {
            label: "Full Time",
            value: {
              utilizationAmount: 40,
              utilizationAmountUnits: "HOUR",
              utilizationAmountPerUnits: "WEEK",
            },
          },
          {
            label: "Half Time",
            value: {
              utilizationAmount: 20,
              utilizationAmountUnits: "HOUR",
              utilizationAmountPerUnits: "WEEK",
            },
          },
        ],
        props: {
          width: 6,
          label: "",
        },
      },
      {
        name: "utilizationAmount",
        type: "textInput",
        placeholder: "Utilization Amount",
        validation: { required: true, valueAsNumber: true },
        props: {
          label: "Amount",
          width: 3,
          subType: "number",
        },
      },
      {
        name: "utilizationAmountUnits",
        type: "select",
        options: UTILIZATION_AMOUNT_UNITS_OPTIONS_PLURAL,
        placeholder: "Utilization Units",
        validation: { required: true },
        props: {
          label: "Units",
          width: 3,
        },
      },
      {
        name: "utilizationAmountPerUnits",
        type: "select",
        options: UTILIZATION_AMOUNT_UNITS_OPTIONS,
        placeholder: "Utilization Per Units",
        validation: { required: true },
        props: {
          label: "Per",
          width: 6,
        },
      },

      {
        name: "rate",
        type: "textInput",
        placeholder: "Override Bill Rate",
        validation: { valueAsNumber: true },
        props: {
          label: "Override Bill Rate",
          width: 3,
          subType: "number",
        },
      },
      {
        name: "rateTimeUnit",
        type: "select",
        options: RATE_TIMEUNITS_OPTIONS,
        placeholder: "Rate Per Period",
        validation: {},
        props: {
          label: "Per",
          width: 3,
        },
      },
      {
        name: "costRate",
        type: "textInput",
        placeholder: "Override Cost Rate",
        validation: { valueAsNumber: true },
        props: {
          label: "Override Cost Rate",
          width: 3,
          subType: "number",
        },
      },
      {
        name: "costRateTimeUnit",
        type: "select",
        options: RATE_TIMEUNITS_OPTIONS,
        placeholder: "Rate Per Period",
        validation: {},
        props: {
          label: "Per",
          width: 3,
        },
      },
    ],
  };
};
