import { ReactNode, useState } from "react";

interface ProjectStatsQuadrantProps {
  stats: ProjectStatsQuadrantStat[];
}

interface ProjectStatsQuadrantStat {
  label: string;
  value: string;
  emphasis?: "red" | "green";
}

function ProjectStatsQuadrant({ stats }: ProjectStatsQuadrantProps) {
  const renderStatBlock = (stat: ProjectStatsQuadrantStat) => {
    return (
      <div
        className={`flex-1 flex-col p-2 border-b flex justify-center hover:bg-gray-200 ${
          stat.emphasis ? `bg-${stat.emphasis}-100` : ""
        }`}
      >
        <div className="text-xs mb-1 font-bold text-gray-500 uppercase">
          {stat.label}
        </div>
        <div
          className={`text-xl ${
            stat.emphasis
              ? `text-${stat.emphasis}-500 font-bold`
              : "text-gray-600"
          }`}
        >
          {stat.value}
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="h-full flex-1 flex flex-col border-r bg-white">
        {renderStatBlock(stats[0])}
        {renderStatBlock(stats[1])}
        {renderStatBlock(stats[2])}
        {renderStatBlock(stats[3])}
      </div>
    </>
  );
}

export default ProjectStatsQuadrant;
