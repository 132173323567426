import Card from "../../basics/Card/Card";
import { Props } from "../../data/types/Props";
import "./IntegrationManagementPanel.css";

interface IntegrationManagementPanelProps extends Props {
  title: string;
  description: string;
  logo?: string;
  onConnect?: any;
  onDisconnect?: any;
  onSync?: any;
  activeIntegration: any;
  iconName?: string;
}

function IntegrationManagementPanel({
  children,
  title,
  classNames,
  iconName,
  description,
  onConnect,
  onSync,
  onDisconnect,
  activeIntegration,
}: IntegrationManagementPanelProps) {
  return (
    <Card classNames={classNames}>
      <div className="px-4 py-5 sm:p-6 flex flex-row items-center">
        <div className="mr-10">
          <img
            className="h-auto h-10 w-10"
            src={`/external_logos/${iconName}`}
          />
        </div>
        <div className="flex flex-col">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            {title}
          </h3>
          <div className="mt-2 max-w-xl text-sm text-gray-500">
            <p>{description}</p>
          </div>
        </div>

        <div className="flex flex-1"></div>
        <div className="ml-5">
          {activeIntegration ? (
            <>
              {activeIntegration.node.syncing ? (
                <button
                  type="button"
                  onClick={(e) => onSync(e, activeIntegration.node.id)}
                  className="inline-flex mr-3 items-center justify-center px-4 py-2 border border-transparent font-medium rounded-md text-green-700 bg-gray-100 hover:bg-green-200 border-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:text-sm"
                >
                  Syncing
                </button>
              ) : (
                <button
                  type="button"
                  onClick={(e) => onSync(e, activeIntegration.node.id)}
                  className="inline-flex mr-3 items-center justify-center px-4 py-2 border border-transparent font-medium rounded-md text-green-700 bg-green-100 hover:bg-green-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:text-sm"
                >
                  Sync
                </button>
              )}
              <button
                type="button"
                onClick={(e) => onDisconnect(e, activeIntegration.node.id)}
                className="inline-flex items-center justify-center px-4 py-2 border border-transparent font-medium rounded-md text-red-700 bg-white hover:bg-red-200 outline-none border-red-400 sm:text-sm"
              >
                Disconnect
              </button>
            </>
          ) : (
            <button
              type="button"
              onClick={onConnect}
              className="inline-flex items-center justify-center px-4 py-2 border border-transparent font-medium rounded-md text-green-700 bg-green-100 hover:bg-green-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:text-sm"
            >
              Connect
            </button>
          )}
        </div>
      </div>
    </Card>
  );
}

export default IntegrationManagementPanel;
