import { gql } from "@apollo/client";

const MERGE_ENTITIES = gql`
  mutation mergeEntities(
    $toMergeId: String!
    $masterId: String!
    $entityType: String!
  ) {
    mergeEntities(
      toMergeId: $toMergeId
      masterId: $masterId
      entityType: $entityType
    ) {
      success
    }
  }
`;

export { MERGE_ENTITIES };
