import { useLazyQuery, useMutation } from "@apollo/client";
import ListView from "../../components/ListView/ListView";
import { GET_PROVIDERS } from "../../data/queries/providers";
import { PaginatedResponse } from "../../data/types/PaginatedResponse";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import {
  Provider,
  PROVIDER_TABLE_CONFIGURATION,
} from "../../data/types/Provider";
import { useHistory } from "react-router-dom";
import TriageModal from "../../components/TriageModal/TriageModal";
import { UPDATE_PROVIDER } from "../../data/queries/providers";
import { useLazyListQuerySM } from "../../data/machines/queries";

export function Providers() {
  const [state, data, send] = useLazyListQuerySM(GET_PROVIDERS);
  const history = useHistory();
  const [cursor, setCursor] = useState(null);
  const [isTriageModalOpen, setIsTriageModalOpen] = useState(false);
  const [triageRecord, setTriageRecord] = useState(null);
  const [updateProvider, updateProviderResult] = useMutation(UPDATE_PROVIDER);

  const form = useForm<FormData>();

  useEffect(() => {
    send({ type: "MAKE_QUERY", variables: { cursor } });
  }, [cursor]);

  useEffect(() => {
    send({ type: "REFETCH" });
  }, [updateProviderResult.data]);

  const onSubmitTriage = (res: any) => {
    if (!res || !triageRecord || triageRecord == null) return;
    updateProvider({
      variables: {
        providerToUpdate: {
          id: ((triageRecord || { id: "" }) as any).id,
          inTriageFromIntegration: false,
        },
      },
    });
    setIsTriageModalOpen(false);
    setTriageRecord(null);
  };

  return (
    <>
      <ListView
        title={"Providers"}
        data={state.value === "completed" ? data : undefined}
        state={state.value}
        error={state.value === "error" ? state.context.error : undefined}
        columns={PROVIDER_TABLE_CONFIGURATION}
        cursor={cursor || 0}
        entityName={"provider"}
        onAddNew={() => history.push("/providers/add")}
        setCursor={setCursor}
        onClickTriage={(d: any) => {
          setTriageRecord(d);
          setIsTriageModalOpen(true);
        }}
      />
      <TriageModal
        triageRecord={triageRecord}
        open={isTriageModalOpen}
        setOpen={setIsTriageModalOpen}
        onSubmit={onSubmitTriage}
      />
    </>
  );
}
