import { ReactNode, useContext } from "react";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import { Props } from "../../data/types/Props";
import { IconButton } from "../../basics/Buttons/IconButton";
import { BasicButtonStyles } from "../../basics/Buttons/BasicButton";
import { Inbox } from "@trycourier/react-inbox";
import { AlertsSlideOver } from "../AlertsSlideOver/AlertsSlideOver";
import { TasksButton } from "../TasksButton/TasksButton";
import { ArrowLeftIcon, LinkIcon } from "@heroicons/react/24/outline";
import { SMART_SEARCH } from "../../data/queries/search";
import { SearchLightbox } from "../SearchLightbox/SearchLightbox";
import { MAX_INNER_PAGE_WIDTH } from "../../data/constants";

interface PageHeaderProps extends Props {
  disableBreadcrumbs?: boolean;
  disableBorder?: boolean;
  disableBg?: boolean;
  disableShadow?: boolean;
  disableSubheaderDivider?: boolean;
  leftContent?: ReactNode;
  rightContent?: ReactNode;
  sticky?: boolean;
  subheader?: ReactNode;
  disableEverythingButSearch?: boolean;
}

function PageHeader({
  disableBreadcrumbs,
  disableBorder,
  disableBg,
  disableShadow,
  leftContent,
  rightContent,
  subheader,
  classNames,
  disableSubheaderDivider,
  disableEverythingButSearch,
}: PageHeaderProps) {
  const breadcrumbs = useBreadcrumbs();
  const history = useHistory();

  const [notifications, setNotifications] = useState<any[]>([]);
  const [cursor, setCursor] = useState(null);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  console.log(notifications);

  const renderBreadcrumb = (crumb: any, index: any) => {
    if (index !== 0 && index !== breadcrumbs.length - 1) {
      // Not home
      return (
        <div>
          <div className="text-blue-700 text-xs flex px-1 rounded-br-md cursor-pointer hover:bg-gray-200 hover:text-black flex-row bg-gray-100 border border-gray-300">
            <ArrowLeftIcon width={10} /> {crumb.breadcrumb.props.children}
          </div>
        </div>
      );
    }
  };

  return (
    <>
      <>
        <nav
          style={{ maxWidth: "100%" }}
          className={`flex flex-col w-full relative z-1 mt-6 ${classNames}`}
          aria-label="Breadcrumb"
        >
          <div
            className="flex flex-row items-center md:px-3 px-5 w-full mx-auto"
            style={{
              maxWidth: "100%",
              width: MAX_INNER_PAGE_WIDTH,
            }}
          >
            <div className={`flex flex-row items-center relative w-full `}>
              {leftContent}
              {!disableBreadcrumbs && (
                <div className="flex items-center align-middle absolute top-0 left-0">
                  {breadcrumbs.map((b, index) => renderBreadcrumb(b, index))}
                </div>
              )}
              <div className="flex-1"></div>
              {rightContent}
            </div>
          </div>
          {subheader && (
            <>
              {!disableSubheaderDivider && <div className="border-t"></div>}
              {subheader}
            </>
          )}
        </nav>
      </>
    </>
  );
}

export default PageHeader;
