import ListView from "../../components/ListView/ListView";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import TriageModal from "../../components/TriageModal/TriageModal";
import { useLazyListQuerySM } from "../../data/machines/queries";
import {
  EXPENSE_ITEM_TABLE_CONFIGURATION,
  formatExpenseItemForList,
} from "../../data/types/Expense";
import { GET_EXPENSE_ITEMS } from "../../data/queries/expenses";
export function Expenses() {
  const [state, data, send] = useLazyListQuerySM(GET_EXPENSE_ITEMS);
  const history = useHistory();
  const [cursor, setCursor] = useState(null);
  const [isInvoiceAddEditOpen, setIsInvoiceAddEditOpen] = useState(false);
  const [isTriageModalOpen, setIsTriageModalOpen] = useState(false);
  const [triageRecord, setTriageRecord] = useState(null);
  const form = useForm<FormData>();

  const { errors, setValue, handleSubmit } = form;
  console.log(errors);

  useEffect(() => {
    send({ type: "MAKE_QUERY", variables: { cursor } });
  }, [cursor]);

  const onSubmitTriage = (res: any) => {
    if (!res || !triageRecord || triageRecord == null) return;
    // TODO
    setIsTriageModalOpen(false);
    setTriageRecord(null);
  };

  return (
    <>
      <ListView
        title={"Expense Items"}
        data={state.value === "completed" ? data : undefined}
        state={state.value}
        error={state.value === "error" ? state.context.error : undefined}
        columns={EXPENSE_ITEM_TABLE_CONFIGURATION}
        cursor={cursor || 0}
        onAddNew={() => setIsInvoiceAddEditOpen(true)}
        setCursor={setCursor}
      />
      <TriageModal
        triageRecord={triageRecord}
        open={isTriageModalOpen}
        setOpen={setIsTriageModalOpen}
        onSubmit={onSubmitTriage}
      />
    </>
  );
}
