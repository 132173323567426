import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useState } from "react";
import PageHeader from "../../components/PageHeader/PageHeader";
import { ReportDisplay } from "../../components/ReportDisplay/ReportDisplay";
import { SecondarySidemenu } from "../../components/SecondarySidemenu/SecondarySidemenu";
import SidemenuClearListTabs from "../../components/SidemenuClearListTabs/SidemenuClearListTabs";
import {
  GET_REPORT_CONFIGURATIONS,
  GET_REPORT,
  UPDATE_REPORT_CONFIGURATION,
} from "../../data/queries/reportsAndDashboards";
import CodeEditor from "@uiw/react-textarea-code-editor";

export function Reports() {
  const reportsData = useQuery(GET_REPORT_CONFIGURATIONS);
  const [reportConfig, setReportConfig]: any = useState(null);
  const [editMode, setEditMode]: any = useState(false);
  const [code, setCode]: any = useState(null);
  const [updateReport, updateReportData] = useMutation(
    UPDATE_REPORT_CONFIGURATION,
    {
      onCompleted: () => {
        setEditMode(false);
        reportsData.refetch();
      },
    }
  );

  const [getSpecificReport, { loading, error, data }] =
    useLazyQuery(GET_REPORT);

  const getSpecific = (id: string) => {
    const reportConf = reportsData?.data?.data?.edges?.find(
      (r: any) => r.node?.id === id
    ).node;
    setReportConfig(reportConf);
    setCode(reportConf.runnable);
    getSpecificReport({
      variables: {
        id,
      },
    });
  };

  const save = () => {
    updateReport({
      variables: {
        id: reportConfig.id,
        updatedReportConfiguration: {
          runnable: code,
        },
      },
    });
  };

  console.log(reportsData);
  console.log(data);
  const reportData = data?.data;

  return (
    <>
      <div className="flex flex-row h-full">
        <SecondarySidemenu>
          <div className="pt-6 pb-4 font-bold px-4 border-b text-right">
            My Dashboards
          </div>
          <div className="py-1 border-b text-sm shadow-sm font-medium px-4 text-right bg-white text-blue-500">
            CXO Board
          </div>
          <div className="pt-6 pb-4 font-bold px-4 border-b text-right">
            My Reports
          </div>
          {reportsData.data?.data?.edges
            ?.map((a: any) => a.node)
            .map((report: any) => (
              <div className="p-6 border-b text-right text-sm">
                <div
                  className="font-bold cursor-pointer "
                  onClick={() => {
                    getSpecific(report.id);
                  }}
                >
                  {report.name}
                </div>
                <div className="text-sm mt-2">{report.description}</div>
                <div onClick={() => setEditMode(true)}>Edit</div>
                <div onClick={() => save()}>Save</div>
              </div>
            ))}
        </SecondarySidemenu>
        <div className="flex flex-1 bg-white">
          {reportData && (
            <>
              {editMode ? (
                <div style={{ maxWidth: "1000px" }} className="mx-auto">
                  <CodeEditor
                    value={code}
                    language="js"
                    placeholder="Please enter JS code."
                    onChange={(evn) => setCode(evn.target.value)}
                    padding={15}
                    style={{
                      fontSize: 15,
                      backgroundColor: "#333",
                      fontFamily:
                        "ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace",
                    }}
                  />
                </div>
              ) : (
                <div className="mx-auto border-l border-r">
                  <ReportDisplay
                    name={reportConfig.name}
                    sections={reportData.reportSections}
                  />
                </div>
              )}
            </>
          )}
        </div>
        <SecondarySidemenu classNames="border-l bg-blue-100">
          <div className="pt-6 pb-4 font-bold px-4 border-b">Filters</div>
        </SecondarySidemenu>
      </div>
    </>
  );
}
