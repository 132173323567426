import { BaseModalProps } from "../../basics/ModalWrapper/ModalWrapper";
import MinimalForm from "../../components/MinimalForm/MinimalForm";
import { useForm } from "react-hook-form";
import SlideOverWrapper from "../../basics/SlideOverWrapper/SlideOverWrapper";
import { useEffect, useState } from "react";
import { FormFieldConfiguration } from "../../data/types/Form";
import DatePicker from "react-datepicker";
import { InvoiceBuilderViewer } from "../../components/InvoiceBuilderViewer/InvoiceBuilderViewer";
import {
  CREATE_INVOICE,
  GET_INVOICE,
  UPDATE_INVOICE,
} from "../../data/queries/invoices";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  GET_PREDICTED_INVOICE_FOR_PROJECT,
  GET_PROJECT,
  GET_PROJECTS,
} from "../../data/queries/projects";
import "react-datepicker/dist/react-datepicker.css";
import DateInput from "../../basics/DateInput/DateInput";
import { IconButton } from "../../basics/Buttons/IconButton";
import { PencilIcon, SparklesIcon } from "@heroicons/react/24/solid";
import Card from "../../basics/Card/Card";
import { EntityUnchangeableDataBlock } from "../../basics/EntityStaticDataBlock/EntityStaticDataBlock";
import { DocumentCheckIcon } from "@heroicons/react/24/outline";
import { format } from "date-fns";
import TextInput from "../../basics/TextInput/TextInput";
import DropdownInput from "../../basics/DropdownInput/DropdownInput";
import { GET_INTEGRATIONS } from "../../data/queries/integrations";
import { formatIntegrationForOptionsList } from "../../data/types/Integration";
import { formatProjectForOptionsList } from "../../data/types/Project";

export interface InvoiceAddEditSlideOverProps extends BaseModalProps {
  onSelect?: any;
  invoicingConfig?: any;
  existingInvoiceId?: any;
  customerId?: string;
  projectId?: string;
  sowId?: string;
  projectName?: string;
  periodStartDate?: Date;
  periodEndDate?: Date;
  dateOfInvoice?: Date;
  refetch: any;
}

function InvoiceAddEditSlideOver({
  open,
  setOpen,
  existingInvoiceId,
  invoicingConfig,
  customerId,
  sowId,
  projectId,
  projectName,
  onSubmit,
  onSelect,
  periodEndDate,
  periodStartDate,
  dateOfInvoice,
  refetch,
}: InvoiceAddEditSlideOverProps) {
  const [getData, { loading, error, data }] = useLazyQuery(GET_INVOICE);
  const [createInvoice, createInvoiceResult] = useMutation(CREATE_INVOICE, {
    onCompleted: (data) => {
      refetch();
      setOpen(false);
    },
  });
  const [updateInvoice, updateInvoiceResult] = useMutation(UPDATE_INVOICE, {
    onCompleted: (data) => {
      refetch();
      setOpen(false);
    },
  });
  const [
    getIntegrations,
    { loading: loadingIntegrations, data: integrationsData },
  ] = useLazyQuery(GET_INTEGRATIONS);

  const { loading: loadingProjects, data: projectsData } =
    useQuery(GET_PROJECTS);

  const [getPrediction, predictionData] = useLazyQuery(
    GET_PREDICTED_INVOICE_FOR_PROJECT,
    {
      partialRefetch: true,
    }
  );
  const [editMode, setEditMode] = useState(true);
  const [currentDates, setCurrentDates]: any = useState({});
  const form = useForm<FormData>({
    defaultValues: currentDates,
    shouldUnregister: false,
  });
  const [invoiceData, setInvoiceData] = useState({});
  const { errors, setValue, handleSubmit, watch, getValues } = form;
  const [localProject, setLocalProject]: any = useState({});

  // watch for changes in projectId in form
  const watchProjectId = watch("projectId");

  useEffect(() => {
    console.log(watchProjectId);
    if (watchProjectId) {
      setLocalProject(
        projectsData?.data?.edges?.find(
          (p: any) => p.node.id === watchProjectId
        ).node
      );
    }
  }, [watchProjectId]);

  useEffect(() => {
    if (existingInvoiceId) {
      getData({ variables: { id: existingInvoiceId } });
    } else {
      // do something if dates are set
    }
    getIntegrations();
  }, []);

  useEffect(() => {
    if (!loading && data) {
      let existingInvoiceData = data.data;
      console.log(existingInvoiceData);
      setInvoiceData(existingInvoiceData);
    }

    if (!predictionData.loading && predictionData.data) {
      let autoInvoicerData = predictionData.data.data;
      console.log("AUTO INVOICER RESPONSE", autoInvoicerData);
      setInvoiceData(autoInvoicerData);
    }
  }, [data, predictionData]);

  useEffect(() => {
    if (
      periodEndDate &&
      periodStartDate &&
      dateOfInvoice &&
      (periodStartDate !== currentDates?.overrideInvoicePeriodStart ||
        periodEndDate !== currentDates?.overrideInvoicePeriodEnd ||
        dateOfInvoice !== currentDates?.dateOfInvoice)
    ) {
      console.log("LOAAADIIIIINNNGGG");
      setCurrentDates({
        dateOfInvoice,
        overrideInvoicePeriodEnd: periodEndDate,
        overrideInvoicePeriodStart: periodStartDate,
      });
      setValue("dateOfInvoice", dateOfInvoice);
      setValue("overrideInvoicePeriodStart", periodStartDate);
      setValue("overrideInvoicePeriodEnd", periodEndDate);
      setTimeout(() => {
        fetchInvoice();
      }, 300);
    }
  }, [periodEndDate, periodStartDate, dateOfInvoice]);

  const onChangeInvoice = (data: any) => {
    let total = 0;
    data.lineItems.forEach((item: any) => {
      total += item.rate * item.quantity;
    });
    data.totalDue = total;
    // todo fix
    data.subtotal = total;
    data.balance = total;
    setInvoiceData(data);
  };

  const saveInvoice = () => {
    const values: any = getValues();
    const {
      overrideInvoicePeriodEnd,
      overrideInvoicePeriodStart,
      dateOfInvoice,
      integrationId,
    } = values;
    if (!!existingInvoiceId) {
      updateInvoice({
        variables: {
          id: existingInvoiceId,
          updatedInvoice: {
            ...invoiceData, // inherit everything from auto invoicer data
            status: "UNPAID", // todo allow to fix?
            periodStartDate: overrideInvoicePeriodStart,
            periodEndDate: overrideInvoicePeriodEnd,
          },
        },
      });
    } else {
      createInvoice({
        variables: {
          newInvoice: {
            projectId: projectId || localProject?.id,
            customerId: customerId || localProject?.customer?.id, // todo
            status: "UNPAID",
            projectScopeOfWorkId: sowId,
            integrationToPushToId: integrationId,
            ...invoiceData, // inherit everything from auto invoicer data
            periodStartDate: overrideInvoicePeriodStart,
            periodEndDate: overrideInvoicePeriodEnd,
          },
        },
      });
    }
  };

  const fetchInvoice = () => {
    const values: any = getValues();
    console.log(values);
    const {
      overrideInvoicePeriodEnd,
      overrideInvoicePeriodStart,
      dateOfInvoice,
      integrationId,
    } = values;
    getPrediction({
      variables: {
        projectId: projectId || localProject?.id,
        dateOfInvoice,
        overrideInvoicePeriodStart,
        overrideInvoicePeriodEnd,
      },
    });
    setCurrentDates({
      dateOfInvoice,
      overrideInvoicePeriodEnd,
      overrideInvoicePeriodStart,
      integrationId,
    });
    setEditMode(false);
  };

  const renderForm = () => {
    let props = {
      labelClass: "block text-sm font-medium text-gray-700",
      errorClass:
        "mt-1 block w-full border border-red-300 text-red-900 placeholder-red-300 py-2 px-3  focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md",
      inputClass:
        "mt-1 relative block w-full bg-white border border-gray-300 rounded-md pl-3 pr-10 py-2 px-3 text-left cursor-pointer focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm",
    };
    return (
      <>
        <div
          className="flex flex-col relative"
          style={{
            maxHeight: "calc(100vh - 50px)",
            height: "calc(100vh - 50px)",
          }}
        >
          {projectId ? (
            <EntityUnchangeableDataBlock
              title={"Invoice for Project: " + projectName}
            ></EntityUnchangeableDataBlock>
          ) : null}
          {editMode ? (
            <div className="bg-gray-100 border-b border-gray-300 shadow-sm">
              <div className="grid grid-cols-6 py-6 px-4 sm:p-6 gap-6">
                {!projectId && (
                  <DropdownInput
                    {...props}
                    wrapperClass="col-span-6 sm:col-span-6"
                    name="projectId"
                    label="Project to Invoice"
                    control={form.control}
                    register={form.register}
                    placeholder="Select Project to Invoice"
                    setValue={form.setValue}
                    options={projectsData?.data?.edges.map((e: any) => {
                      return formatProjectForOptionsList(e.node);
                    })}
                  />
                )}
                <DateInput
                  {...props}
                  wrapperClass="col-span-6 sm:col-span-6"
                  name="dateOfInvoice"
                  label="Date of Invoice"
                  control={form.control}
                  register={form.register}
                />
                <DateInput
                  {...props}
                  wrapperClass="col-span-3 sm:col-span-3"
                  name="overrideInvoicePeriodStart"
                  label="Invoice Period Start"
                  control={form.control}
                  register={form.register}
                />
                <DateInput
                  {...props}
                  wrapperClass="col-span-3 sm:col-span-3"
                  name="overrideInvoicePeriodEnd"
                  label="Invoice Period End"
                  control={form.control}
                  register={form.register}
                  setValue={form.setValue}
                />
                <DropdownInput
                  {...props}
                  wrapperClass="col-span-6 sm:col-span-6"
                  name="integrationId"
                  label="Export To Integration (must be linked to project and Integration must have an Invoice Export setting)"
                  control={form.control}
                  register={form.register}
                  placeholder="Select Integration to push Invoice to"
                  setValue={form.setValue}
                  options={integrationsData?.data?.edges
                    ?.filter((a: any) => {
                      let returner = false;
                      let int = a.node;
                      // check if there is an export config map for Invoice
                      int.integrationExportConfiguration?.maps.forEach(
                        (m: any) => {
                          if (m.integrationEntityToExport === "invoice") {
                            returner = true;
                          }
                        }
                      );
                      return returner;
                    })
                    .map((e: any) => {
                      return formatIntegrationForOptionsList(e.node);
                    })}
                />
              </div>
              <div className="p-6 py-0 pb-4">
                <IconButton
                  icon={DocumentCheckIcon}
                  iconClassNames="mr-1"
                  text="Save Dates"
                  onClick={fetchInvoice}
                />
              </div>
            </div>
          ) : (
            <div className="bg-gray-100 border-b border-gray-300 shadow-sm py-2 px-6 flex flex-row items-center">
              From{" "}
              <b className="mx-2">
                {" "}
                {currentDates?.overrideInvoicePeriodStart &&
                  format(currentDates.overrideInvoicePeriodStart, "MM/dd/yyyy")}
              </b>{" "}
              to{" "}
              <b className="mx-2">
                {currentDates?.overrideInvoicePeriodEnd &&
                  format(currentDates.overrideInvoicePeriodEnd, "MM/dd/yyyy")}
              </b>
              <PencilIcon
                className="ml-1 cursor-pointer"
                color="blue"
                height={19}
                fontSize={10}
                onClick={() => setEditMode(true)}
              />
            </div>
          )}
          <div
            className="p-8 flex-1 overflow-y-auto"
            style={{ paddingBottom: "100px" }}
          >
            <div className="flex-1 rounded-lg shadow-lg border border-black">
              <InvoiceBuilderViewer
                className="rounded-lg"
                onChange={onChangeInvoice}
                invoiceData={invoiceData}
              />
            </div>
          </div>
          <div
            style={{
              width: "100%",
              boxShadow: "0px -2px 3px 0px rgba(0,0,0,.03)",
            }}
            className="flex flex-row bg-gray-100 absolute bottom-0 left-0 w-max py-3 px-6 border-t border-gray-300"
          >
            <IconButton
              color="green"
              iconClassNames="mr-1"
              icon={SparklesIcon}
              onClick={saveInvoice}
              text="Save Invoice"
            />
          </div>
        </div>
      </>
    );
  };

  return (
    <SlideOverWrapper
      bodyStyles={{ maxWidth: "1000px" }}
      title={`${editMode ? "Edit" : "Create"} Invoice`}
      open={open}
      setOpen={() => setOpen(null)}
    >
      {renderForm()}
    </SlideOverWrapper>
  );
}

export default InvoiceAddEditSlideOver;
