import { useState } from "react";

interface PaginationProps {
  page: number;
  pages: number;
  count: number;
  start: number;
  end: number;
  onChangePage: any;
}

function Pagination({
  page,
  pages,
  count,
  start,
  end,
  onChangePage,
}: PaginationProps) {
  let pageItems = [];
  for (let index = 0; index < pages; index++) {
    pageItems.push(index + 1);
  }

  return (
    <div className="flex flex-1 items-center bg-gray-50 justify-between pt-1 pb-1 pl-8 pr-2 text-xs border-t">
      <div className="flex-1 flex justify-between sm:hidden">
        <a
          href="#"
          className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-xs font-medium rounded-md text-gray-700 bg-white hover:text-gray-500"
        >
          Previous
        </a>
        <a
          href="#"
          className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-xs font-medium rounded-md text-gray-700 bg-white hover:text-gray-500"
        >
          Next
        </a>
      </div>
      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
        <div>
          <p className="text-xs text-gray-700">
            Showing
            <span className="font-medium"> {start} </span>
            to
            <span className="font-medium"> {end} </span>
            of
            <span className="font-medium"> {count} </span>
            results
          </p>
        </div>
        <div className="flex-1 flex"></div>
        <div className="uppercase text-xs text-gray-500 font-bold mr-3">Pages</div>
        <div>
          <nav
            className="relative z-0 inline-flex -space-x-px"
            aria-label="Pagination"
          >
            <a
              onClick={onChangePage.bind(page - 1)}
              className="relative inline-flex items-center px-1 py-1 text-xs font-medium text-gray-700 hover:bg-gray-100"
            >
              <span className="sr-only">Previous</span>

              <svg
                className="h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                  clip-rule="evenodd"
                />
              </svg>
            </a>
            {pageItems.slice(0, 5).map((i) => {
              return (
                <a
                  href="#"
                  className="relative inline-flex items-center px-2 py-1 text-xs font-medium text-gray-900 hover:bg-white"
                >
                  {i}
                </a>
              );
            })}
            {pageItems && pageItems.length > 5 ? (
              <span className="relative inline-flex items-center px-1 py-1 border border-gray-300 bg-white text-xs font-medium text-gray-700">
                ...
              </span>
            ) : null}
            {pageItems?.length > 5 &&
              pageItems
                .slice(pageItems.length - 6, pageItems.length)
                .map((i) => {
                  return (
                    <a
                      href="#"
                      className="relative inline-flex items-center px-3 py-1 border border-gray-300 bg-white text-xs font-medium text-gray-700 hover:bg-gray-100"
                    >
                      {i}
                    </a>
                  );
                })}
            <a
              onClick={onChangePage.bind(page + 1)}
              className="relative inline-flex items-center px-1 py-1 text-xs font-medium text-gray-700 hover:bg-gray-100"
            >
              <span className="sr-only">Next</span>
              <svg
                className="h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clip-rule="evenodd"
                />
              </svg>
            </a>
          </nav>
        </div>
      </div>
    </div>
  );
}

export default Pagination;
