import { gql } from "@apollo/client";

const REGISTER = gql`
  mutation register($signupInput: SignupInput!) {
    register(data: $signupInput) {
      firstUser {
        id
        email
      }
      tenant {
        name
      }
    }
  }
`;

const GET_SCOPED_ROLES = gql`
  query listScopedRoles(
    $skip: Int
    $after: String
    $first: Int
    $query: String
    $orderBy: ScopedRoleOrder
  ) {
    data: listScopedRoles(
      skip: $skip
      after: $after
      first: $first
      query: $query
      orderBy: $orderBy
    ) {
      edges {
        cursor
        node {
          id
          name
          description
        }
      }
      totalCount
      pageInfo {
        hasNextPage
      }
    }
  }
`;

export { REGISTER, GET_SCOPED_ROLES };
