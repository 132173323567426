import ListView from "../../components/ListView/ListView";
import {
  GET_FRINGE_COST_ITEMS,
  GET_FRINGE_COST_SETS,
} from "../../data/queries/fringeCosts";
import { PaginatedResponse } from "../../data/types/PaginatedResponse";
import { useEffect, useState } from "react";

import { Employee } from "../../data/types/Employee";
import { useHistory } from "react-router-dom";
import FringeCostAddEditSlideOver from "./FringeCostAddEditSlideOver";
import {
  FringeCostItem,
  FRINGE_COST_ITEMS_TABLE_CONFIGURATION,
} from "../../data/types/FringeCost";
import FringeCostSetsList from "../../components/FringeCostSetsList/FringeCostSetsList";
import { useLazyListQuerySM } from "../../data/machines/queries";

export function FringeCosts() {
  const [state, data, send] = useLazyListQuerySM(
    GET_FRINGE_COST_ITEMS
  );
  const [stateSets, dataSets, sendSets] =
    useLazyListQuerySM(GET_FRINGE_COST_SETS);
  const history = useHistory();
  const [cursor, setCursor] = useState(null);
  const [isFringeCostAddModalOpen, setFringeCostAddModalOpen] = useState(false);

  useEffect(() => {
    send({ type: "MAKE_QUERY", variables: { cursor } });
    sendSets({ type: "MAKE_QUERY", variables: { cursor } });
  }, [cursor]);

  return (
    <>
      <ListView
        title={"Fringe & Tax Cost Items"}
        entityName={"Fringe/Tax Cost Item"}
        data={state.value === "completed" ? data : undefined}
        state={state.value}
        error={state.value === "error" ? state.context.error : undefined}
        columns={FRINGE_COST_ITEMS_TABLE_CONFIGURATION}
        onAddNew={() => setFringeCostAddModalOpen(true)}
        cursor={cursor || 0}
        setCursor={setCursor}
        sideComponent={
          <FringeCostSetsList
            state={stateSets.value}
            items={
              stateSets.value === "completed"
                ? dataSets.edges.map((a: any) => a.node)
                : []
            }
          />
        }
      />
      <FringeCostAddEditSlideOver
        open={isFringeCostAddModalOpen}
        setOpen={setFringeCostAddModalOpen}
        onSubmit={() => {}}
      />
    </>
  );
}
