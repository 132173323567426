import { PencilIcon, PlusIcon, UserCircleIcon } from "@heroicons/react/24/solid";

import { Link } from "react-router-dom";
import Table from "../../basics/Table/Table";
import {
  formatResourceAssignmentForList,
  RESOURCE_ASSIGNMENT_TABLE_CONFIGURATION,
} from "../../data/types/ResourceAssignment";
import { utilizationToDescriptionString } from "../../utilities/utilization";

interface TeamOrResourcesListProps {
  items: any[];
  type: string;
  addEdit?: any;
}

function TeamOrResourcesList({
  items,
  type,
  addEdit,
}: TeamOrResourcesListProps) {
  const getLink = (item: any) => {
    if (item.source === "PROVIDER") {
      return "/providers/" + item.provider.id;
    } else if (item.source === "EMPLOYEE") {
      return "/employees/" + item.employee.id;
    } else {
      return "/";
    }
  };

  if (items) {
    items = items.map((item) => {
      let name, utilizationString;
      if (item.source === "PROVIDER") {
        name =
          item.providerPersonnel?.firstName +
          " " +
          item.providerPersonnel?.lastName;
      } else {
        name = item.employee?.firstName + " " + item.employee?.lastName;
      }
      utilizationString = utilizationToDescriptionString(item);
      return { ...item, name, utilizationString };
    });
  }

  return (
    <div className="flex flex-col mb-3">
      <Table
        data={items?.map((n: any) => {
          return formatResourceAssignmentForList(n);
        })}
        columns={RESOURCE_ASSIGNMENT_TABLE_CONFIGURATION}
        start={1}
        end={items?.length}
        onChangePage={() => {}}
        actions={[
          {
            label: "Edit",
            color: "blue",
            icon: PencilIcon,
            onClick: (i: any) => addEdit(i),
          },
        ]}
      />
    </div>
  );
}

export default TeamOrResourcesList;
