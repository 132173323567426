import { BaseModalProps } from "../../basics/ModalWrapper/ModalWrapper";
import MinimalForm from "../../components/MinimalForm/MinimalForm";
import { useForm } from "react-hook-form";
import SlideOverWrapper from "../../basics/SlideOverWrapper/SlideOverWrapper";
import { useEffect } from "react";
import { FormFieldConfiguration } from "../../data/types/Form";
import { BUILD_JOB_ROLE_FORM_CONFIGURATION } from "../../data/types/JobRole";
import { EntityUnchangeableDataBlock } from "../../basics/EntityStaticDataBlock/EntityStaticDataBlock";

export interface JobRoleAddEditSlideOverProps extends BaseModalProps {
  toEdit?: any;
}

function JobRoleAddEditSlideOver({
  open,
  setOpen,
  onSubmit,
  toEdit,
}: JobRoleAddEditSlideOverProps) {
  let initialValues: any = {};

  const form = useForm<FormData>({ defaultValues: initialValues });
  const { errors, setValue, handleSubmit, watch } = form;

  /**
   * Set Initial Values for Form
   */
  useEffect(() => {
    if (toEdit && open) {
      setTimeout(() => {
        Object.keys(toEdit).forEach((k) => {
          setValue(k, toEdit[k]);
        });
      });
    }
  }, [toEdit, open]);

  /* SET FIELDS */
  let fields: FormFieldConfiguration[] =
    BUILD_JOB_ROLE_FORM_CONFIGURATION(toEdit).fields;

  const submitForm = (data: any) => {
    return toEdit ? onSubmit(data, toEdit.id) : onSubmit(data);
  };

  const renderEdit = () => {
    return (
      <MinimalForm
        fields={fields}
        submitText={"Submit"}
        form={form}
        onSubmit={submitForm}
      />
    );
  };

  return (
    <SlideOverWrapper
      title={`Add or edit Job Role`}
      open={open}
      setOpen={setOpen}
    >
      {toEdit && <EntityUnchangeableDataBlock title={toEdit?.name || ""} />}
      {renderEdit()}
    </SlideOverWrapper>
  );
}

export default JobRoleAddEditSlideOver;
