import ListView from "../../components/ListView/ListView";
import {
  CREATE_CUSTOMER,
  GET_CUSTOMERS,
  UPDATE_CUSTOMER,
} from "../../data/queries/customers";
import { useEffect, useState } from "react";

import {
  Customer,
  CustomerCreateUpdateArgs,
  CUSTOMER_TABLE_CONFIGURATION,
} from "../../data/types/Customer";
import { useHistory } from "react-router-dom";
import {
  customerStatusToBadgeColor,
  customerStatusToBadgeText,
} from "./Customer";
import TriageModal from "../../components/TriageModal/TriageModal";
import toast from "react-hot-toast";
import CustomerAddEditSlideOver from "./CustomerAddEditSlideOver";
import {
  LazyListQuerySM,
  useLazyListQuerySM,
  useMutationSM,
} from "../../data/machines/queries";

export function Customers() {
  const history = useHistory();
  const [cursor, setCursor] = useState(null);
  const [isTriageModalOpen, setIsTriageModalOpen] = useState(false);
  const [triageRecord, setTriageRecord] = useState(null);
  const [isCustomerAddModalOpen, setCustomerAddModalOpen] = useState(false);
  const [state, data, send]: LazyListQuerySM<Customer> = useLazyListQuerySM(
    GET_CUSTOMERS,
    (customer: any) => {
      return {
        ...customer,
        badgeColor: customerStatusToBadgeColor(customer.status),
        status: customerStatusToBadgeText(customer.status),
      };
    }
  );
  const [stateCreate, sendCreate] = useMutationSM(CREATE_CUSTOMER, {
    onCompleted: () => {
      toast.success("Created Customer");
      setCustomerAddModalOpen(false);
      send({ type: "REFETCH" });
    },
  });

  const [stateUpdate, sendUpdate] = useMutationSM(UPDATE_CUSTOMER, {
    onCompleted: () => {
      toast.success("Updated Customer");
      send({ type: "REFETCH" });
    },
  });

  useEffect(() => {
    send({ type: "MAKE_QUERY", variables: { cursor } });
  }, [cursor]);

  const onSubmitTriage = (res: any) => {
    if (!res || !triageRecord || triageRecord == null) return;
    sendUpdate({
      type: "MAKE_MUTATION",
      variables: {
        id: ((triageRecord as any) || { id: "" }).id,
        updatedCustomer: {
          inTriageFromIntegration: false,
        },
      },
    });
    setIsTriageModalOpen(false);
    setTriageRecord(null);
  };

  const submitCustomerForm = (data: CustomerCreateUpdateArgs, id?: string) => {
    if (id) {
      //
    } else {
      sendCreate({
        type: "MAKE_MUTATION",
        variables: {
          newCustomer: {
            ...data,
            inTriageFromIntegration: false,
          },
        },
      });
    }
  };

  console.log("state", state);

  return (
    <>
      <ListView
        title={"Customers"}
        entityName={"customer"}
        data={state.value === "completed" ? data : undefined}
        state={state.value}
        error={state.value === "error" ? state.context.error : undefined}
        columns={CUSTOMER_TABLE_CONFIGURATION}
        onAddNew={() => setCustomerAddModalOpen(true)}
        cursor={cursor || 0}
        setCursor={setCursor}
        onClickTriage={(d: any) => {
          setTriageRecord(d);
          setIsTriageModalOpen(true);
        }}
      />
      <TriageModal
        triageRecord={triageRecord}
        open={isTriageModalOpen}
        setOpen={setIsTriageModalOpen}
        onSubmit={onSubmitTriage}
      />
      <CustomerAddEditSlideOver
        open={isCustomerAddModalOpen}
        setOpen={setCustomerAddModalOpen}
        onSubmit={submitCustomerForm}
      />
    </>
  );
}
