import ListView from "../../components/ListView/ListView";
import { GET_INVOICES } from "../../data/queries/invoices";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { Invoice, INVOICE_TABLE_CONFIGURATION } from "../../data/types/Invoice";
import TriageModal from "../../components/TriageModal/TriageModal";
import InvoiceAddEditSlideOver from "./InvoiceAddEditSlideOver";
import { useLazyListQuerySM } from "../../data/machines/queries";

export const invoiceStatusToBadgeColor = (status: string) => {
  if (status === "PAID") {
    return "bg-green-100 text-green-800";
  } else if (status === "PARTIALLY_PAID") {
    return "bg-yellow-100 text-yellow-800";
  } else {
    return "bg-red-100 text-red-800";
  }
};

export const invoiceStatusToBadgeText = (status: string) => {
  if (status === "PAID") {
    return "Paid";
  } else if (status === "PARTIALLY_PAID") {
    return "Partial";
  } else {
    return "Unpaid";
  }
};

export function Invoices() {
  const [state, data, send] = useLazyListQuerySM(
    GET_INVOICES,
    (invoice: any) => {
      return {
        ...invoice,
        badgeColor: invoiceStatusToBadgeColor(invoice.status),
        statusText: invoiceStatusToBadgeText(invoice.status),
      };
    }
  );
  const history = useHistory();
  const [cursor, setCursor] = useState(null);
  const [isInvoiceAddEditOpen, setIsInvoiceAddEditOpen] = useState(false);
  const [isTriageModalOpen, setIsTriageModalOpen] = useState(false);
  const [triageRecord, setTriageRecord] = useState(null);
  const form = useForm<FormData>();

  const { errors, setValue, handleSubmit } = form;
  console.log(errors);

  useEffect(() => {
    send({ type: "MAKE_QUERY", variables: { cursor } });
  }, [cursor]);

  const onSubmitTriage = (res: any) => {
    if (!res || !triageRecord || triageRecord == null) return;
    // TODO
    setIsTriageModalOpen(false);
    setTriageRecord(null);
  };

  return (
    <>
      <ListView
        title={"Invoices"}
        data={state.value === "completed" ? data : undefined}
        state={state.value}
        error={state.value === "error" ? state.context.error : undefined}
        columns={INVOICE_TABLE_CONFIGURATION}
        cursor={cursor || 0}
        onAddNew={() => setIsInvoiceAddEditOpen(true)}
        setCursor={setCursor}
      />
      <TriageModal
        triageRecord={triageRecord}
        open={isTriageModalOpen}
        setOpen={setIsTriageModalOpen}
        onSubmit={onSubmitTriage}
      />
      <InvoiceAddEditSlideOver
        projectId={undefined}
        projectName={undefined}
        refetch={() => send({ type: "REFETCH" })}
        customerId={undefined}
        open={isInvoiceAddEditOpen}
        periodEndDate={undefined}
        periodStartDate={undefined}
        dateOfInvoice={undefined}
        setOpen={setIsInvoiceAddEditOpen}
        invoicingConfig={undefined}
        onSubmit={() => {}}
      />
    </>
  );
}
