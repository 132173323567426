import { PlusIcon } from "@heroicons/react/24/solid";
import { BasicButtonStyles } from "../../basics/Buttons/BasicButton";
import { IconButton } from "../../basics/Buttons/IconButton";
import { createSvgBackgroundStyles } from "../../utilities/svgBackgrounds";

interface JobRolesListProps {
  items: any[];
  addEdit?: any;
  state: string;
}

export function JobRolesList({ items, addEdit, state }: JobRolesListProps) {
  const renderItemBlock = (item: any) => {
    return (
      <div
        className="border-b  cursor-pointer"
        onClick={($ev) => {
          $ev.stopPropagation();
          addEdit(item);
        }}
      >
        <div className="p-4 pl-10 hover:bg-gray-100">
          <div className="text-sm text-gray-600 font-bold mb-2">
            {item.name}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      style={{ minWidth: "440px" }}
      className="border-r flex flex-col bg-white shadow-lg h-full"
    >
      <div
        style={{ ...createSvgBackgroundStyles("") }}
        className="pl-10 pr-4 border-b bg-white flex flex-row items-center"
      >
        <div className="py-3 font-bold text-black text-sm uppercase">
          Job Roles
        </div>
        <div className="flex flex-1"></div>
        <IconButton
          icon={PlusIcon}
          styler={BasicButtonStyles.ZERO}
          classNames={"h-7 font-bold"}
          text="Add Job Role"
        />
      </div>

      {state === "loading" ? (
        <div className="py-10 w-full align-center items-center flex flex-row justify-center relative text-center">
          <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12"></div>
        </div>
      ) : (
        <div className="overflow-auto">
          {items.map((a) => renderItemBlock(a))}
        </div>
      )}
    </div>
  );
}
