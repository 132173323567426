import { format } from "date-fns";

export const formatBaseItemForList = (
  item: any,
  additionalDateFieldsToFormat?: string[]
) => {
  let a = {
    ...item,
    createdAt: item.createdAt
      ? format(new Date(item.createdAt), "MM/dd/yyyy")
      : "-",
    updatedAt: item.updatedAt
      ? format(new Date(item.updatedAt), "MM/dd/yyyy")
      : "-",
    lastPaidOutDate: item.lastPaidOutDate
      ? format(item.lastPaidOutDate, "MM/dd/yyyy")
      : "-",
  };
  if (additionalDateFieldsToFormat?.length) {
    additionalDateFieldsToFormat.forEach((f) => {
      a[f] = a[f] ? format(new Date(item[f]), "MM/dd/yyyy") : "-";
    });
  }
  return a;
};

export const dateToUTC = (date: Date) => {
  var now_utc = Date.UTC(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );
  return new Date(now_utc);
};
