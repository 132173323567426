import { MagnifyingGlassIcon, UserIcon } from "@heroicons/react/24/solid";
import React, { useContext, useState } from "react";
import ReactDOM from "react-dom";
import Tabs from "../../basics/Tabs/Tabs";
import "./style.css";
import {
  InstantSearch,
  SearchBox,
  Hits,
  Highlight,
} from "react-instantsearch-dom";
import { instantMeiliSearch } from "@meilisearch/instant-meilisearch";
import Card from "../../basics/Card/Card";
import { entityTypeToIcon } from "../../utilities/icons";
import { Link, useHistory } from "react-router-dom";
import { BarGraphCard } from "../BarGraphCard/BarGraphCard";
import Table from "../../basics/Table/Table";
import { PieGraphCard } from "../PieGraphCard/PieGraphCard";
import { AreaGraphCard } from "../AreaGraphCard/AreaGraphCard";
import { AuthContext } from "../../App";
import axios from "axios";

export const SearchLightbox = ({
  isOpen,
  setIsOpen,
  submit,
  results,
  loading,
}: any) => {
  const [message, setMessage] = useState("");
  const [sentMessage, setSentMessage] = useState("");
  const [mode, setMode] = useState("normal"); // normal | conversational
  const [hits, setHits]: any = useState([]);
  const authContext: any = useContext(AuthContext);
  console.log(results);

  console.log(authContext);

  const handleChange = (event: any) => {
    setMessage(event.target.value);
  };

  const submitBasic = async () => {
    const res = await axios.post(
      process.env.REACT_APP_SEARCH_URL as string,
      {
        query: message,
      },
      {
        headers: {
          "x-tenant-id": authContext.currentTenantId,
        },
      }
    );
    console.log(res.data);
    setHits(res.data);
  };

  // could probably make this better and more reusable
  const getEntityLink = (hit: any): string => {
    if (hit.searchEntityType === "user") {
      return `/users/${hit.entityId}`;
    } else if (hit.searchEntityType === "project") {
      return `/projects/${hit.entityId}`;
    } else if (hit.searchEntityType === "employee") {
      return `/employees/${hit.entityId}`;
    } else if (hit.searchEntityType === "customer") {
      return `/customers/${hit.entityId}`;
    } else {
      return "";
    }
  };

  // meilisearch hits
  const Hit = ({ hit }: any) => {
    const Icon: any = entityTypeToIcon(hit.searchEntityType);
    return (
      <Link onClick={() => setIsOpen(false)} to={getEntityLink(hit)}>
        <Card
          classNames="p-2 px-4 cursor-pointer items-center flex flex-row hover:bg-gray-100 bg-gray-50 shadow-sm mb-3"
          style={{ width: "400px" }}
        >
          <div className="mr-4">
            <Icon height={25} />
          </div>
          <div className="flex flex-col highlight-em">
            {" "}
            <div dangerouslySetInnerHTML={{ __html: hit._formatted.name }} />
            <div className="text-sm font-medium text-gray-500">
              <div
                dangerouslySetInnerHTML={{
                  __html: hit._formatted.searchEntityType,
                }}
              ></div>
            </div>
          </div>
        </Card>
      </Link>
    );
  };

  return (
    <>
      {ReactDOM.createPortal(
        <>
          {isOpen && (
            <div onClick={() => setIsOpen(false)} className="v-full h-full">
              <div
                style={{ zIndex: 15, background: "rgba(0, 0, 0, 0.3)" }}
                className="absolute inset-0"
              >
                <div
                  onClick={($ev) => $ev.stopPropagation()}
                  style={{
                    width: "100vw",
                    margin: "0 auto",
                  }}
                  className="bg-white shadow-lg p-2"
                >
                  <div
                    className="m-auto flex-col flex justify-center text-center"
                    style={{ maxWidth: "900px" }}
                  >
                    {loading ? (
                      <div className="text-xl">
                        LOADING{" "}
                        <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-64 w-64"></div>
                      </div>
                    ) : (
                      <div>
                        <Tabs
                          classNames="ml-10 mb-3"
                          mode="EVENT"
                          activeTab={mode}
                          minimal
                          setTab={(t: string) => {
                            setMode(t);
                          }}
                          tabs={[
                            {
                              name: "Basic Search",
                              identifier: "normal",
                            },
                            {
                              name: "[BETA] Introspective Search",
                              identifier: "conversational",
                            },
                          ]}
                        ></Tabs>
                        {mode === "normal" ? (
                          <div className="flex flex-row align-items items-center">
                            <div
                              onClick={() => {
                                setSentMessage(message);
                                submitBasic();
                              }}
                              className="ml-5"
                            >
                              <MagnifyingGlassIcon height={30} />
                            </div>
                            <input
                              onChange={handleChange}
                              value={message}
                              className="w-full p-4 ml-5 outline-none text-xl"
                            />
                          </div>
                        ) : (
                          <div className="flex flex-row align-items items-center">
                            <div
                              onClick={() => {
                                setSentMessage(message);
                                submit(message);
                              }}
                              className="ml-5"
                            >
                              <MagnifyingGlassIcon height={30} />
                            </div>
                            <input
                              onChange={handleChange}
                              value={message}
                              className="w-full p-4 ml-5 outline-none text-xl"
                            />
                          </div>
                        )}
                      </div>
                    )}
                    {mode === "normal" ? (
                      <div className="ml-20 mt-3">
                        {hits && hits.length > 0
                          ? hits.map((r: any) => <Hit hit={r} />)
                          : ""}
                      </div>
                    ) : (
                      <>
                        <div className="ml-20 mt-3">
                          {results && (
                            <Card
                              classNames="p-2 px-4 cursor-pointer hover:bg-gray-100 bg-gray-50 shadow mb-3"
                              style={{ width: "1200px" }}
                            >
                              {/* for pie charts, its switched... */}
                              {results?.componentConfig?.type ===
                              "PIE_CHART" ? (
                                <PieGraphCard
                                  style={{ height: "200px" }}
                                  classNames="m-4"
                                  data={results?.rawData}
                                  yAxisDataKey={results?.componentConfig?.xaxis}
                                  xAxisDataKey={results?.componentConfig?.yaxis}
                                  title={sentMessage}
                                />
                              ) : results?.componentConfig?.type ===
                                "BAR_CHART" ? (
                                <BarGraphCard
                                  style={{ height: "200px" }}
                                  classNames="m-4"
                                  data={results?.rawData}
                                  xAxisDataKey={results?.componentConfig?.xaxis}
                                  yAxisDataKey={results?.componentConfig?.yaxis}
                                  title={sentMessage}
                                />
                              ) : results?.componentConfig.type === "TABLE" ? (
                                <Card>
                                  <div>{sentMessage}</div>
                                  <Table
                                    onChangePage={() => {}}
                                    start={0}
                                    end={results?.rawData?.length}
                                    data={results?.rawData}
                                    columns={Object.keys(
                                      results?.rawData?.[0]
                                    ).map((a) => {
                                      return {
                                        key: a,
                                        label: a,
                                      };
                                    })}
                                  />
                                </Card>
                              ) : results?.componentConfig.type ===
                                "LINE_CHART" ? (
                                <AreaGraphCard
                                  style={{ height: "200px" }}
                                  classNames="m-4"
                                  data={results?.rawData}
                                  xAxisDataKey={results?.componentConfig?.xaxis}
                                  areaDataKey={results?.componentConfig?.yaxis}
                                  title={sentMessage}
                                />
                              ) : results?.componentConfig?.type ===
                                "SINGLE_VALUE" ? (
                                <div>
                                  <div>{sentMessage}</div>
                                  <div className="text-3xl mt-3 text-gray-700">
                                    {results?.text?.map((a: string) => a)}
                                  </div>
                                </div>
                              ) : null}
                              <div
                                style={{ maxWidth: "450px" }}
                                className="text-gray-500 text-xs m-4"
                              >
                                {" "}
                                {results?.sqlText}
                              </div>
                            </Card>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </>,
        document.querySelector("#root") as any
      )}
    </>
  );
};
