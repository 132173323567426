/* This ModalWrapper requires Tailwind CSS v2.0+ */
import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ReactNode } from "react";
import { XMarkIcon } from "@heroicons/react/24/outline";

export interface BaseModalProps {
  open: boolean;
  setOpen: Function;
  children?: ReactNode[];
  onSubmit?: any;
  title: string;
  description?: string;
  bodyClassNames?: string;
  bodyStyles?: any;
  lightHeader?: boolean;
}

export default function SlideOverWrapper({
  open,
  setOpen,
  children,
  title,
  description,
  bodyClassNames,
  bodyStyles,
  lightHeader,
}: any) {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 z-20 overflow-hidden"
        open={open}
        onClose={setOpen}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div
                style={bodyStyles}
                className="relative z-50 w-screen max-w-md"
              >
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-500"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-500"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 left-0 -ml-8 pt-4 pr-2 flex sm:-ml-10 sm:pr-4">
                    <button
                      className="rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                      onClick={() => setOpen(false)}
                    >
                      <span className="sr-only">Close panel</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                <div
                  className={
                    `h-full flex flex-col bg-white shadow-xl overflow-y-auto ` +
                    bodyClassNames
                  }
                >
                  <div>
                    <div
                      className={
                        lightHeader
                          ? "py-3 px-4 bg-white sm:px-6"
                          : "py-3 px-4 bg-blue-700 sm:px-6"
                      }
                    >
                      <div className="flex items-center justify-between">
                        <Dialog.Title
                          className={
                            lightHeader
                              ? "text-md font-medium text-gray-900"
                              : "text-md font-medium text-white"
                          }
                        >
                          {title}
                        </Dialog.Title>
                      </div>
                      {description && (
                        <div className="mt-1">
                          <p className="text-sm text-blue-300">{description}</p>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="relative flex-1 flex-col flex">
                    {children}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
