import { TableColumn } from "../../basics/Table/Table";
import { FormFieldConfiguration } from "./Form";

export enum MERGE_SUPPORTED_ENTITIES {
  PROJECT = "PROJECT",
  EMPLOYEE = "EMPLOYEE",
  PROVIDER = "PROVIDER",
  PROVIDER_PERSONNEL = "PROVIDER_PERSONNEL",
  JOB_ROLE = "JOB_ROLE",
  JOB_POST = "JOB_POST",
  CUSTOMER = "CUSTOMER",
}

export interface MergeConfig {
  fields: MergeFieldConfig[];
}

export interface MergeFieldConfig {
  type: "SELECT_ONE" | "SELECT_MANY" | "INTEGRATIONS";
  formField: FormFieldConfiguration;
  tableField?: TableColumn;
  hideInTable?: boolean;
}
