import ListView from "../../components/ListView/ListView";
import {
  GET_COMMISSION_ASSIGNMENTS,
  GET_COMMISSION_PLANS,
} from "../../data/queries/commissionPlans";
import { PaginatedResponse } from "../../data/types/PaginatedResponse";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import CommissionPlanAddEditSlideOver from "./CommissionPlanAddEditSlideOver";
import CommissionPlansList from "../../components/CommissionPlansList/CommissionPlansList";
import {
  CommissionPlan,
  COMMISSION_ASSIGNMENT_TABLE_CONFIGURATION,
  formatCommissionAssignmentForList,
} from "../../data/types/CommissionPlan";
import { PlusIcon, SparklesIcon } from "@heroicons/react/24/solid";
import {
  LazyListQuerySM,
  useLazyListQuerySM,
} from "../../data/machines/queries";

export function CommissionPlans() {
  const [state, data, send]: LazyListQuerySM<any> = useLazyListQuerySM(
    GET_COMMISSION_ASSIGNMENTS,
    (e) => formatCommissionAssignmentForList(e)
  );
  const [statePlans, plansData, sendPlans]: LazyListQuerySM<CommissionPlan> =
    useLazyListQuerySM(GET_COMMISSION_PLANS);
  const history = useHistory();
  const [cursor, setCursor] = useState(null);
  const [isFringeCostAddModalOpen, setFringeCostAddModalOpen] = useState(false);

  useEffect(() => {
    send({
      type: "MAKE_QUERY",
      variables: { cursor },
    });
    sendPlans({
      type: "MAKE_QUERY",
      variables: { cursor },
    });
  }, [cursor]);

  return (
    <>
      <ListView
        title={"Commission Entries"}
        entityName={"Commission Entry"}
        actions={[
          {
            label: "Autocalculate new commission entries",
            color: "blue",
            icon: SparklesIcon,
            onClick: (i: any) => {
              console.log(i);
            },
          },
        ]}
        data={state.value === "completed" ? data : undefined}
        state={state.value}
        error={state.value === "error" ? state.context.error : undefined}
        columns={COMMISSION_ASSIGNMENT_TABLE_CONFIGURATION}
        onAddNew={() => setFringeCostAddModalOpen(true)}
        cursor={cursor || 0}
        setCursor={setCursor}
        sideComponent={
          <CommissionPlansList
            items={
              statePlans.value === "completed"
                ? plansData.edges.map((a: any) => a.node)
                : undefined
            }
            state={state.value}
          />
        }
      />
      <CommissionPlanAddEditSlideOver
        open={isFringeCostAddModalOpen}
        setOpen={setFringeCostAddModalOpen}
        onSubmit={() => {}}
      />
    </>
  );
}
