import { useState } from "react";
import { SectionHeader } from "../../basics/SectionHeader/SectionHeader";
import Table, { TableAction, TableColumn } from "../../basics/Table/Table";
import TextType from "../../basics/TextType/TextType";
import { PaginatedResponse } from "../../data/types/PaginatedResponse";
import { formatBaseItemForList } from "../../utilities/dates";

export interface BaseDetailViewProps {
  title: string;
  config: DetailViewConfiguration;
  dataObject: any;
}

export interface DetailViewConfiguration {
  sections: DetailViewSection[];
}

export interface DetailViewSection {
  title?: string;
  subtitle?: string;
  hideHeader?: boolean;
  fields: DetailViewField[];
}

export interface DetailViewField {
  label: string;
  key: string;
  type?: string;
  typeConfiguration?: any;
  width?: number;
  styles?: string;
  linkToPrefix?: string;
  linkToKey?: string;
  displayType?: string;
  rowStyleKey?: string;
}

function BaseDetailView({ title, config, dataObject }: BaseDetailViewProps) {
  const renderField = (field: DetailViewField) => {
    return (
      <div className={`mb-6`}>
        <dt className="text-xs uppercase font-semibold text-gray-700">
          {field.label}
        </dt>
        <dd className="mt-1 text-md text-gray-600">
          <TextType
            fieldKey={field.key}
            type={field.type}
            typeConfiguration={field.typeConfiguration}
            displayType={field.displayType}
            dataObject={dataObject}
            linkToKey={field.linkToKey}
            styles={field.styles}
            rowStyleKey={field.rowStyleKey}
            linkToPrefix={field.linkToPrefix}
          />
        </dd>
      </div>
    );
  };

  const renderSection = (section: DetailViewSection) => {
    return (
      <div className="border-r border-gray-200 pt-1 ">
        {!section.hideHeader && (
          <SectionHeader borderEnabled title={section.title} />
        )}
        <div style={{ minWidth: "280px" }}>
          <div className="py-5">
            {section.fields.map((f) => renderField(f))}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col pt-5" style={{ maxWidth: "500px" }}>
      {config.sections.map((a) => renderSection(a))}
    </div>
  );
}

export default BaseDetailView;
