import ListView from "../../components/ListView/ListView";
import { PaginatedResponse } from "../../data/types/PaginatedResponse";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { useHistory } from "react-router-dom";
import {
  ItemDefinition,
  ITEM_DEFINITION_TABLE_CONFIGURATION,
} from "../../data/types/ItemDefinition";
import { GET_ITEM_DEFINITIONS } from "../../data/queries/itemDefinitions";
import TriageModal from "../../components/TriageModal/TriageModal";
import {
  LazyListQuerySM,
  useLazyListQuerySM,
} from "../../data/machines/queries";

export function ItemsAndRateCards() {
  const [state, data, send]: LazyListQuerySM<ItemDefinition> =
    useLazyListQuerySM(GET_ITEM_DEFINITIONS);
  const history = useHistory();
  const [cursor, setCursor] = useState(null);
  const [isTriageModalOpen, setIsTriageModalOpen] = useState(false);
  const [triageRecord, setTriageRecord] = useState(null);

  const form = useForm<FormData>();

  const { errors, setValue, handleSubmit } = form;
  console.log(errors);

  useEffect(() => {
    send({ type: "MAKE_QUERY", variables: { cursor } });
  }, [cursor]);

  const onSubmitTriage = (res: any) => {
    if (!res || !triageRecord || triageRecord == null) return;
    setIsTriageModalOpen(false);
    setTriageRecord(null);
  };
  return (
    <>
      <ListView
        title={"Item and Rate Cards"}
        data={state.value === "completed" ? data : undefined}
        state={state.value}
        error={state.value === "error" ? state.context.error : undefined}
        columns={ITEM_DEFINITION_TABLE_CONFIGURATION}
        cursor={cursor || 0}
        onAddNew={() => history.push("/items/add")}
        setCursor={setCursor}
      />
      <TriageModal
        triageRecord={triageRecord}
        open={isTriageModalOpen}
        setOpen={setIsTriageModalOpen}
        onSubmit={onSubmitTriage}
      />
    </>
  );
}
