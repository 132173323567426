import { useContext, useEffect, useState } from "react";
import { Transition } from "@headlessui/react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
  useLocation,
} from "react-router-dom";
import { ReactNode } from "react";
import {
  ArrowDownOnSquareIcon,
  ClipboardIcon,
  CogIcon,
  CurrencyDollarIcon,
  HomeIcon,
  LinkIcon,
  MagnifyingGlassIcon,
  SparklesIcon,
  Square2StackIcon,
  Squares2X2Icon,
  UsersIcon,
} from "@heroicons/react/24/solid";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import { useLazyQuery } from "@apollo/client";
import { AuthContext } from "../../App";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { SMART_SEARCH } from "../../data/queries/search";
import { SearchLightbox } from "../SearchLightbox/SearchLightbox";
import { TasksButton } from "../TasksButton/TasksButton";

const providerLinks: TopLevelSubHeaderLink[] = [
  {
    text: "My Home",
    route: "/",
  },
  {
    text: "Reports",
    route: "/reports",
  },
  {
    text: "Projects",
    route: "/projects",
  },
  {
    text: "Customers",
    route: "/customers",
  },
  {
    text: "Providers",
    route: "/providers",
  },
  {
    text: "Items/Rates",
    route: "/items",
  },
  {
    text: "Invoices",
    route: "/invoices",
  },
  {
    text: "Scenario Designer",
    route: "/scenarios",
  },
  {
    text: "Commission",
    route: "/commission",
  },
  {
    text: "Bills",
    route: "/bills",
  },
  {
    text: "Expenses",
    route: "/expenses",
  },
  {
    text: "Employees",
    route: "/employees",
  },
  {
    text: "Fringe/Tax Costs",
    route: "/fringecosts",
  },
  {
    text: "Recruiting",
    route: "/recruiting",
  },
  {
    text: "Workflows",
    route: "/workflows",
  },
  {
    text: "Integrations",
    route: "/integrations",
  },
];

interface TopLevelSubHeaderProps {
  currentRoute: string;
  toggleDarkMode: any;
}

interface TopLevelSubHeaderLink {
  text: string;
  route?: string;
  icon?: Function;
  type?: string;
}

function TopLevelSubHeader({ toggleDarkMode }: TopLevelSubHeaderProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [isSmall, setIsSmall] = useState(false);
  const context: any = useContext(AuthContext);
  const history = useHistory();

  const breadcrumbs = useBreadcrumbs();

  const [notifications, setNotifications] = useState<any[]>([]);
  const [cursor, setCursor] = useState(null);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [performSearch, performSearchData] = useLazyQuery(SMART_SEARCH);

  const search = (text: string) => {
    console.log(text);
    performSearch({
      variables: {
        search: { text },
      },
    });
  };

  let links = providerLinks;
  console.log(context);
  const logoutUrl = context.logoutUrl;
  console.log(logoutUrl);

  const location = useLocation();
  const path = location.pathname;

  return (
    <>
      <nav
        style={{ height: "35px", zIndex: 9 }}
        className="bg-white relative border-b border-gray-200 flex flex-row shadow-sm"
      >
        <div className="flex flex-row relative items-center mx-auto">
          {providerLinks.map((l: any) => {
            return (
              <Link to={l.route}>
                <div
                  className={`flex flex-row p-2 border-l border-gray-200 font-medium text-sm ${
                    path === l.route || path.includes(l.route + "/")
                      ? "font-medium text-blue-500"
                      : "text-gray-500"
                  }`}
                >
                  {l.text}
                </div>
              </Link>
            );
          })}
        </div>
      </nav>
    </>
  );
}

export default TopLevelSubHeader;
