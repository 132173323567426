import { TableColumn } from "../../basics/Table/Table";
import { DetailViewConfiguration } from "../../components/BaseDetailView/BaseDetailView";
import { FormConfiguration } from "./Form";

export interface User {
  id: string;
  firstName: string;
  lastName: string;
  tenantId: string;
  createdAt: Date;
  updatedAt: Date;
  customFields: object;
}

export const USER_DETAIL_CONFIGURATION: DetailViewConfiguration = {
  sections: [
    {
      title: "Basic Information",
      subtitle: "Some basic info about this User",
      fields: [
        {
          key: "firstName",
          label: "First Name",
        },
        {
          key: "lastName",
          label: "Last Name",
        },
      ],
    },
  ],
};

export const USER_TABLE_CONFIGURATION: TableColumn[] = [
  {
    key: "firstName + lastName",
    label: "Name",
    type: "composite",
    displayType: "link",
    linkToKey: "id",
    linkToPrefix: "/users/",
  },
  {
    key: "updatedAt",
    label: "Updated At",
  },
  {
    key: "createdAt",
    label: "Created At",
  },
];

export const USER_FORM_CONFIGURATION: FormConfiguration = {
  fields: [
    {
      name: "firstName",
      type: "textInput",
      validation: { required: true },
      props: {
        label: "First Name",
        width: 3,
      },
    },
    {
      name: "lastName",
      type: "textInput",
      validation: { required: true },
      props: {
        label: "Last Name",
        width: 3,
      },
    },
  ],
};
