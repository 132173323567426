import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { Fragment } from "react";
import { BasicButtonStyles } from "../Buttons/BasicButton";
import { IconButton } from "../Buttons/IconButton";

export interface DropdownButtonProps {
  style?: any;
  className?: string;
  text?: string;
  items: DropdownButtonItem[];
  customButton?: any;
}

export interface DropdownButtonItem {
  text: string;
  onClick?: any;
  href?: string;
  color?: string;
}

export const DropdownButton = ({
  items,
  style,
  className,
  text,
  customButton,
}: DropdownButtonProps) => {
  return (
    <Menu as="div" className={`relative z-10 ${className}`}>
      {({ open }) => (
        <>
          <Menu.Button style={{ width: "100%" }}>
            {customButton ? (
              customButton
            ) : (
              <IconButton
                styler={BasicButtonStyles.DROPDOWN}
                icon={ChevronDownIcon}
                color="blue"
                classNames=""
                style={{ width: "150px", ...style }}
                text={text || "More Actions"}
              />
            )}
          </Menu.Button>
          <Transition
            show={open}
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              static
              style={{ width: "150px" }}
              className="origin-top-right absolute right-0 mt-0 w-40 rounded-md shadow-md bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none"
            >
              {items.map((item) => (
                <Menu.Item>
                  {({ active }) => (
                    <>
                      {item.href ? (
                        <a href={item.href}>
                          <div
                            className={`border-b cursor-pointer pl-4 py-2 hover:text-${
                              item.color || "gray"
                            }-800 hover:bg-gray-50 text-sm text-${
                              item.color || "gray"
                            }-600 font-medium`}
                          >
                            {item.text}
                          </div>
                        </a>
                      ) : (
                        <div
                          onClick={item.onClick}
                          className={`border-b cursor-pointer pl-4 py-2 hover:text-${
                            item.color || "gray"
                          }-800 hover:bg-gray-50 text-sm text-${
                            item.color || "gray"
                          }-600 font-medium`}
                        >
                          {item.text}
                        </div>
                      )}
                    </>
                  )}
                </Menu.Item>
              ))}
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};
