import { TableColumn } from "../../basics/Table/Table";
import { FormConfiguration } from "./Form";
import { DetailViewConfiguration } from "../../components/BaseDetailView/BaseDetailView";
import { CURRENCIES, RATE_TIMEUNITS } from "./Shared";

export interface Provider {
  id: string;
  tenantId: string;
  createdAt: Date;
  updatedAt: Date;
  name: string;
  firstName?: string;
  lastName?: string;
  personnel: ProviderPersonnel[];
  type: string;
}

export interface ProviderPersonnel {
  id: string;
  tenantId: string;
  createdAt: Date;
  updatedAt: Date;
  firstName?: string;
  lastName?: string;
  baseRate?: number;
  baseCostRate?: number;
  providerId: string;
  costRateCurrency?: CURRENCIES;
  costRateTimeUnit?: RATE_TIMEUNITS;
}

export const formatProviderForOptionsList = (v: Provider) => {
  return {
    label: v.name,
    value: v.id,
  };
};

export const formatProviderPersonnelForOptionsList = (v: ProviderPersonnel) => {
  return {
    label: v.firstName + " " + v.lastName,
    value: v.id,
    providerId: v.providerId,
  };
};

export const PROVIDER_DETAIL_CONFIGURATION: DetailViewConfiguration = {
  sections: [
    {
      title: "Basic Information",
      subtitle: "Some basic info about your Provider",
      fields: [
        {
          key: "name",
          label: "Name",
        },
      ],
    },
  ],
};

export const PROVIDER_TABLE_CONFIGURATION: TableColumn[] = [
  {
    key: "name",
    label: "Name",
    displayType: "link",
    linkToKey: "id",
    linkToPrefix: "/providers/",
    styles: "text-gray-600 font-medium",
  },
  {
    key: "firstName + lastName",
    label: "Billing Name",
    type: "composite",
  },
  {
    key: "type",
    label: "Type",
  },
  {
    key: "contractualType",
    label: "Contractual Relationship",
  },
  {
    key: "billingType",
    label: "Billing Model",
  },
];

export const PROVIDER_PERSONNEL_TABLE_CONFIGURATION: TableColumn[] = [
  {
    key: "firstName",
    label: "First Name",
  },
  {
    key: "lastName",
    label: "Last Name",
  },
  {
    key: "baseCostRate",
    label: "Base Cost Rate",
    type: "rate",
    typeConfiguration: {
      rateUnitKey: "costRateCurrency",
      rateTimeUnitKey: "costRateTimeUnit",
    },
  },
];

export const PROVIDER_FORM_CONFIGURATION: FormConfiguration = {
  requiredOptionsLoads: ["customers"],
  fields: [
    {
      name: "name",
      type: "textInput",
      validation: { required: true },
      props: {
        label: "Provider Name",
        width: 3,
      },
    },
    {
      name: "customerId",
      type: "select",
      optionsPlaceholder: "customers",
      isNotEditable: true,
      validation: { required: true },
      props: {
        label: "Customer",
        width: 3,
      },
    },
  ],
};
