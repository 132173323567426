import { FieldsOnCorrectTypeRule } from "graphql";
import TextType from "../../basics/TextType/TextType";

export interface ReportDisplayProps {
  sections: ReportSection[];
  name: string;
}

export interface ReportSection {
  name: string;
  rows: any[];
  summaryData: any;
  columns: ReportColumn[];
}

export interface ReportColumn {
  label: string;
  key: string;
  type?: string;
  typeConfiguration?: any;
  displayType?: string;
  linkToKey?: string;
  linkToPrefix?: string;
  styles?: any;
  rowStyleKey?: string;
  secondaryContentRenderer?: any;
}

export function ReportDisplay({ sections, name }: ReportDisplayProps) {
  return (
    <>
      <div className="text-xs" style={{ maxWidth: "1100px" }}>
        <div
          style={{ fontSize: "16px" }}
          className="font-bold border-b p-3 mt-4 align-center text-center
        uppercase bg-white"
        >
          {name}
        </div>
        {sections.map(({ columns, rows, name, summaryData }: ReportSection) => (
          <div className="mb-4 bg-white">
            <div className="text-md font-bold p-3 border-b ">{name}</div>
            <div className="p-1 flex flex-row border-b font-medium bg-gray-50 text-gray-500 uppercase ">
              {columns.map((column: any) => (
                <div className="flex-1 px-2" style={{ minWidth: "200px" }}>
                  {column.label}
                </div>
              ))}
            </div>
            {rows.map((r: any) => (
              <div className="p-1 flex flex-row border-b ">
                {columns.map((column: ReportColumn) => (
                  <div className="px-2 flex-1">
                    {
                      <TextType
                        type={column.type}
                        typeConfiguration
                        dataObject={r}
                        fieldKey={column.key}
                        displayType={column.displayType}
                        linkToKey={column.linkToKey}
                        linkToPrefix={column.linkToPrefix}
                        styles={column.styles}
                        rowStyleKey={column.rowStyleKey}
                        secondaryContentRenderer={
                          column.secondaryContentRenderer
                        }
                      />
                    }
                  </div>
                ))}
              </div>
            ))}
            {summaryData && (
              <div className="p-1 flex bg-gray-50 font-medium flex-row border-b">
                {columns.map((column: ReportColumn) => (
                  <div className="px-2 flex-1  w-20">
                    {
                      <TextType
                        type={column.type}
                        typeConfiguration
                        dataObject={summaryData}
                        fieldKey={column.key}
                        displayType={column.displayType}
                        linkToKey={column.linkToKey}
                        linkToPrefix={column.linkToPrefix}
                        styles={column.styles}
                        rowStyleKey={column.rowStyleKey}
                        secondaryContentRenderer={
                          column.secondaryContentRenderer
                        }
                      />
                    }
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </>
  );
}
