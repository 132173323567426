import { useMutation } from "@apollo/client";
import ListView from "../../components/ListView/ListView";
import { GET_PROJECTS, UPDATE_PROJECT } from "../../data/queries/projects";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Project,
  projectStatusToBadgeColor,
  projectStatusToBadgeText,
  PROJECT_TABLE_CONFIGURATION,
} from "../../data/types/Project";
import { useHistory } from "react-router-dom";
import TriageModal from "../../components/TriageModal/TriageModal";
import {
  LazyListQuerySM,
  useLazyListQuerySM,
} from "../../data/machines/queries";

export function Projects() {
  const [state, data, send]: LazyListQuerySM<Project> = useLazyListQuerySM(
    GET_PROJECTS,
    (project: any) => {
      return {
        ...project,
        badgeColor: projectStatusToBadgeColor(project.status),
        status: projectStatusToBadgeText(project.status),
      };
    }
  );
  const [updateProject, updateProjectResult] = useMutation(UPDATE_PROJECT);
  const history = useHistory();
  const [cursor, setCursor] = useState(null);
  const [isTriageModalOpen, setIsTriageModalOpen] = useState(false);
  const [triageRecord, setTriageRecord] = useState(null);
  const form = useForm<FormData>();
  const { errors, setValue, handleSubmit } = form;

  useEffect(() => {
    send({ type: "MAKE_QUERY", variables: { cursor } });
  }, [cursor]);

  // accept customer id
  const onSubmitTriage = (res: any) => {
    if (!res || !triageRecord || triageRecord == null) return;
    updateProject({
      variables: {
        updatedProject: {
          id: ((triageRecord as any) || { id: "" }).id,
          inTriageFromIntegration: false,
          // todo add customer id from list
        },
      },
    });
    setIsTriageModalOpen(false);
    setTriageRecord(null);
  };

  return (
    <>
      <ListView
        title={"Projects"}
        entityName={"project"}
        data={state.value === "completed" ? data : undefined}
        state={state.value}
        error={state.value === "error" ? state.context.error : undefined}
        columns={PROJECT_TABLE_CONFIGURATION}
        cursor={cursor || 0}
        onAddNew={() => history.push("/projects/add")}
        setCursor={setCursor}
        onClickTriage={(d: any) => {
          setTriageRecord(d);
          setIsTriageModalOpen(true);
        }}
      />
      <TriageModal
        triageRecord={triageRecord}
        open={isTriageModalOpen}
        setOpen={setIsTriageModalOpen}
        onSubmit={onSubmitTriage}
      />
    </>
  );
}
